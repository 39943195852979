import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ListViewDesktop from 'shared/components/layout/Desktop/ListView/ListViewDesktop';
import {
    USER_ADD_ROUTE, USER_EDIT_ROUTE, USERS_BULK,
} from 'shared/constants/RouteConstants';
// @ts-ignore
import { CSVLink } from 'react-csv';

import {
    Button,
    Space,
    Col,
    Row,
    Table,
    Tooltip,
    Typography,
    Input,
    Pagination,
    Popover,
    Tag,
    notification, Modal, message,
} from 'antd';
import { EditFilled, CheckCircleOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import useDebounce from 'shared/hooks/useDebounce';
import { GlobalListLimit, ListPagination } from 'shared/constants/AppConstants';
import { getPaginationLimit, hasPermissionOf, numberFormat, setPaginationLimit } from 'shared/services/Utility';
import {
    EXPORT_USERS,
    USERS_INSERT_ONE,
    USERS_UPDATE_ONE,
} from 'shared/constants/Permissions';
import useUsers from 'shared/hooks/Users/useUsers';
import Link from 'antd/lib/typography/Link';
import { AuthConsumer } from 'shared/contexts/AuthContext';
import useUserUpdate from 'shared/hooks/Users/useUserUpdate';
import {
    BranchSelectFilter,
    DepartmentSelectFilter, RoleSelectFilter,
} from 'shared/components/Common/CommonFilterComponents';


const { Text } = Typography;
type Props = {
    user: any
}


const UsersPage: React.FunctionComponent<Props> = ({ user }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [search, setSearch] = useState();
    const [_export, setExport] = useState(false);
    const [pagination, setPagination] = useState({
        limit: getPaginationLimit(),
        currentPage: 1,
    } as { limit: number, currentPage: number });
    const debouncedSearch = useDebounce(search, 500);
    const [selectedBranchId, setSelectedBranchId] = useState(null as any);
    const [selectedRoleId, setSelectedRoleId] = useState(null as any);
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(null as any);

    const { users, total, loading: loadingUsers, refetch } = useUsers({
        offset: 0,
        limit: pagination.limit,
    });
    const {
        mutation,
        saving,
    } = useUserUpdate((e: any) => {
        if (e) {
            notification.success({ message: 'Updated successfully' });
            refresh();
        }
    });
    const columns = [
        {
            title: '#',
            key: 'sNo',
            render: (a: any, value: any, index: number) =>
                <Text>{(index + 1) + ((pagination.currentPage - 1) * pagination.limit)}</Text>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Roles',
            dataIndex: 'user_roles',
            render: (a: any[]) => a.length ? <Popover content={<div>
                {
                    a.map(({ role }: any) => {
                        return <Tag>{role ? role.name : 'NA'}</Tag>;
                    })
                }
            </div>}>
                <Link>Roles ({a.length})</Link>
            </Popover> : '-',
        },
        {
            title: 'Hospitals',
            dataIndex: 'user_branches',
            render: (a: any[]) => a.length ? <Popover content={<div>
                {
                    a.map(({ branch: hospital }: any) => {
                        return <Tag>{hospital ? hospital.name : 'NA'}</Tag>;
                    })
                }
            </div>}>
                <Link>Hospitals ({a.length})</Link>
            </Popover> : '-',

        },
        {
            title: 'Departments',
            dataIndex: 'user_departments',
            render: (a: any[]) => a.length ? <Popover content={<div>
                {
                    a.map(({ department }: any) => {
                        return <Tag>{department ? department.name : 'NA'}</Tag>;
                    })
                }
            </div>}>
                <Link>Departments ({a.length})</Link>
            </Popover> : '-',

        },
        {
            title: 'Questionnaire Groups',
            dataIndex: 'user_groups',
            render: (a: any[]) => a.length ? <Popover content={<div>
                {
                    a.map(({ group }: any) => {
                        return <Tag>{group ? group.name : 'NA'}</Tag>;
                    })
                }
            </div>}>
                <Link>Questionnaire Groups ({a.length})</Link>
            </Popover> : '-',

        },
        {
            title: 'Is Doctor',
            dataIndex: 'is_doctor',
            render: (a: any) => a && <CheckCircleOutlined />,
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
        },
        {
            title: 'User Status',
            key: 'userStatus',
            render: (a: any) =>
                <AuthConsumer>
                    {({ hasPermissionOf, user, authStatus }) => {
                        return <Col>
                            <Tooltip title={a.block ? 'Activate' : 'Deactivate'}>
                                <Button onClick={() => {
                                    if (user?.action_role === 'superadmin')
                                        mutation({
                                            variables: {
                                                set: {
                                                    block: !a.block,
                                                    blocked_at: a.block ? null : new Date().toISOString(),
                                                }, id: a.id,
                                            },
                                        });
                                }}

                                        size={'middle'}>
                                    {a.block ? 'Activate' : 'Deactivate'}
                                </Button>
                            </Tooltip>
                        </Col>;
                    }}</AuthConsumer>,
        },
        {
            fixed: 'right' as 'right',
            width: '150px',
            title: 'Action',
            key: 'action',
            render: (a: any) => {
                return <Row gutter={10} wrap={false}>
                    {
                        hasPermissionOf(USERS_UPDATE_ONE) && <Col>
                            <Tooltip title={t('genDict.edit')}>
                                <Button shape='circle' onClick={() => history.push(USER_EDIT_ROUTE(a.id))}
                                        icon={<EditFilled />}
                                        size={'middle'} />
                            </Tooltip>
                        </Col>
                    }

                </Row>;
            },
        },
    ];
    useEffect(() => {
        setPagination({
            ...pagination,
            currentPage: 1,
        });
    }, [debouncedSearch]);
    const refresh = () => {
        refetch({
            search, offset: (pagination.currentPage - 1) * pagination.limit,
            branch_id: selectedBranchId,
            role_id: selectedRoleId,
            department_id: selectedDepartmentId,
            limit: pagination.limit,
        });
    };
    useEffect(() => {
        refresh();
    }, [pagination, selectedRoleId, selectedDepartmentId, selectedBranchId]);
    return (
        <div className={'users-page page-wrapper'}>
            {
                _export &&
                <Modal visible={_export} title={'Exporting'} onCancel={() => setExport(false)}
                       okButtonProps={{ hidden: true }}>
                    <UserExport onFinish={() => setExport(false)} filters={{
                        search,
                        branch_id: selectedBranchId,
                        role_id: selectedRoleId,
                        department_id: selectedDepartmentId,
                    }} />
                </Modal>
            }
            <ListViewDesktop title={t('genDict.users')}
                             filters={[
                                 <Space size={0}>
                                     <Input value={search} placeholder={t('genDict.search')} onChange={
                                         (e: any) => {
                                             setSearch(e.target.value);
                                         }
                                     } />
                                 </Space>,
                                 <RoleSelectFilter value={selectedRoleId} onChange={(e: any) => {
                                     setSelectedRoleId(e);
                                 }} />,
                                 <BranchSelectFilter value={selectedBranchId} onChange={(e: any) => {
                                     setSelectedBranchId(e);
                                     setSelectedDepartmentId(null);
                                 }} />,
                                 selectedBranchId ? <DepartmentSelectFilter
                                         userId={user.id}
                                         value={selectedDepartmentId}
                                         branchId={selectedBranchId}
                                         onChange={(e: any) => setSelectedDepartmentId(e)} />
                                     : null,
                                 hasPermissionOf(EXPORT_USERS) &&
                                 <Button type={'primary'} onClick={() => setExport(true)}>
                                     Export
                                 </Button>,
                             ]}
                             addActionConfig={
                                 hasPermissionOf(USERS_INSERT_ONE) && {
                                     text: t('genDict.add'),
                                     onClick: () => history.push(USER_ADD_ROUTE),
                                 }
                             }
                             additionalActionConfig={
                                 [
                                     {
                                         text: 'Bulk Users',
                                         show: hasPermissionOf(USERS_INSERT_ONE),
                                         onClick: () => history.push(USERS_BULK),
                                     },
                                 ]
                             }>
                <Table
                    sticky={true}
                    locale={{
                        emptyText: (
                            <>
                                <Space direction={'vertical'} size={10} style={{ padding: '10px' }}>
                                    <Title level={3} className={'text-primary'}>{t('genDict.woops')}</Title>
                                    <Text className={'text-primary'}>No User added</Text>
                                    <Button type={'primary'}
                                            onClick={() => history.push(USER_ADD_ROUTE)}>{t('genDict.add')}</Button>
                                </Space>
                            </>
                        ),
                    }}
                    pagination={{
                        hideOnSinglePage: true,
                        showSizeChanger: true,
                        total,
                        current: pagination.currentPage,
                        pageSize: pagination.limit,
                        showTotal: (total: number) => <Text>Total <b>{numberFormat(total)}</b> items</Text>,
                        onChange: (page: number, pageSize?: number) => {
                            if (pageSize !== pagination.limit) {
                                setPaginationLimit(pageSize);
                                page = 1;
                            }
                            setPagination({ currentPage: page, limit: (pageSize || pagination.limit) });
                        },
                    }} scroll={{ x: true }}
                    dataSource={users}
                    loading={loadingUsers}
                    columns={columns} />
            </ListViewDesktop>
        </div>
    );
};
const UserExport = ({ filters, onFinish }: any) => {

    const { users, loading } = useUsers({
        offset: 0,
        ...filters,
        limit: 1000,
    });
    const _users = users?.map((u) => {
        u['user_roles'] = u.user_roles?.length ? '(' + u.user_roles.map((a: any) => a?.role?.name).join(' | ') + ')' : '-';
        u['user_branches'] = u.user_branches?.length ? '(' + u.user_branches.map((a: any) => a?.branch?.name).join(' | ') + ')' : '-';
        u['user_groups'] = u.user_groups?.length ? '(' + u.user_groups.map((a: any) => a?.group?.name).join(' | ') + ')' : '-';
        u['user_departments'] = u.user_departments?.length ? '(' + u.user_departments.map((a: any) => a?.department?.name).join(' | ') + ')' : '-';
        if(u['block']){
            u['blocked_at'] = u['blocked_at'] || u['updated_at']
        }else{
            u['blocked_at'] = '-'
        }
        return u;
    });
    return <>
        <Button loading={loading}>
            <CSVLink
                filename={'users.csv'}
                data={_users}
                className='btn btn-primary'
                onClick={() => {
                    message.success('The file is downloading');
                }}
            >
                Export to Users.csv
            </CSVLink>
        </Button>
    </>;
};
export default UsersPage;
