import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import useLocations from "shared/hooks/useLocations";
import QuestionnaireForm from "./QuestionnaireForm";
import useQuestionnaireCreate from "shared/hooks/Templates/useQuestionnaireCreate";
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import GroupForm from '../../Groups/CreateOrEdit/GroupForm';
import { notification } from 'antd';

type Props = {}

const QuestionnaireCreate: React.FunctionComponent<Props> = (props:any) => {
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocations();
    const {mutation, saving, questionnaire: savedQuestionnaire, error} = useQuestionnaireCreate((e: any) => {
        // if(e){
        //     history.goBack()
        // }
        notification.success({message:'Questionnaire saved successfully'})
        if(props.saved){
            props.saved()
        }
        
    });
    const onSubmit = (data: any) => {
        if(!data.is_supplementary){
            data['is_supplementary']=false
        }
        mutation({variables: data})
    }
    return (
        <div className={'user-group-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Questionnaire Create'}>
                <QuestionnaireForm 
                saving={saving} 
                onCancel={() => history.goBack()} 
                onSubmit={onSubmit}
                questionnaire={props.questionnaire}/>

            </FormViewDesktop>
        </div>
    );
};


export default QuestionnaireCreate;
