import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import { Spin, Card, Divider, Row, Col, Typography, notification, Button } from 'antd';
import DynamicFormContainer from 'shared/components/ProcedureFromView/DynamicFormContainer';
import useGetPromData from 'shared/hooks/prom/useGetPromById';
import addOrUpdatePrescription from 'shared/hooks/PatientPrescriptions/addOrUpdatePrescription';
import moment from 'moment';
import {
    PrescriptionEditorStatusInProgress,
    PrescriptionEditorStatusSubmitted,

} from 'shared/constants/AppConstants';
import PromPreview from './PromPreview';
import { ROUTE_PAGE_404 } from 'shared/constants/RouteConstants';
import { useState, useEffect } from 'react';
const { Text, Title } = Typography;
type Props = {}
const PromEdit: React.FunctionComponent<Props> = (props: any) => {

    const RouteParams: { pid: string, id: string } = useParams();
    /*******************************************
    To redirect the staff or doctor to dashboard
    *********************************************/
    if (localStorage.getItem('auth-token')) {
        window.location.href = '/'
    }

    sessionStorage.setItem('pid', RouteParams.pid)

    const { follow_up, loading, refetch: refetchProcedure } = useGetPromData(RouteParams.id);
    if (!loading && !follow_up) {
        notification.error({ message: "Questionnaire not found." })
        window.location.href = ROUTE_PAGE_404
    }

    if (follow_up?.editor_status == 'DELETED') {
        window.location.href = ROUTE_PAGE_404
    }

    let add_pres_data = follow_up?.template_view; //add mode
    const template_id = follow_up?.template?.id
    let patient_details = follow_up?.patient || {}
    let edit_pres_data = follow_up?.prescriptions//edit mode
    let [template_content, set_template_content]: any = useState(null as any)
    let [isSubmitted, setIsSubmitted] = useState(false as boolean)
    let [showContinueEdit, setShowContinueEdit] = useState(true as boolean)
    useEffect(() => {
        if (follow_up) {
            let t_content = null
            if (edit_pres_data) {
                t_content = edit_pres_data.data.data
            } else {
                t_content = add_pres_data.content.data
            }
            if (typeof t_content == 'string') {
                t_content = JSON.parse(t_content)
            }
            set_template_content(t_content)
            if (follow_up?.prescriptions?.review?.editor_notes) {
                for (let f of follow_up?.prescriptions?.review?.editor_notes) {
                    if (f.status == 'SUBMITTED' || f.status == 'RE_WORK' || follow_up.editor_status == 'DELETED') {
                        setIsSubmitted(true)
                        setShowContinueEdit(false)
                        break;
                    }
                }
            }
        }
    }, [loading])

    console.log(' ---->', follow_up)
    // VIEW
    const render_PatientDetails = () => {
        return (
            <Card >
                <Row>
                    <Col md={5} sm={24} xs={24}>
                        <Col>
                            <img src="/logo-fortis.jpg" alt="Image" style={{ width: "170px", marginTop: "10px" }} />
                        </Col>
                    </Col >
                    <Col md={19} sm={24} xs={24} >
                        <Row >
                            <Title level={3}>Please fill the follow up form </Title>
                        </Row>
                        <Row gutter={[20, 20]} >

                            <Col md={4} sm={12} xs={24}>
                                <div>
                                    <Text >Name  </Text>
                                </div>
                                <Text type={'secondary'}>{patient_details.first_name || 'NA'} {patient_details.last_name || ''}</Text>
                            </Col>
                            <Col md={4} sm={12} xs={24}>
                                <div>
                                    <Text >UHID  </Text>
                                </div>
                                <Text type={'secondary'}>{patient_details.uhid}</Text>
                            </Col>
                            <Col md={4} sm={12} xs={24}>
                                <div>
                                    <Text >Procedure Name </Text>
                                </div>
                                <Text type={'secondary'}>{follow_up?.encounter_type}</Text>
                            </Col>
                            <Col md={4} sm={12} xs={24}>
                                <div>
                                    <Text >Procedure Date </Text>
                                </div>
                                <Text type={'secondary'}>{follow_up?.start_date ? moment(follow_up?.start_date).format('LL') : 'NA'}</Text>
                            </Col>
                            <Col md={4} sm={12} xs={24}>
                                <div>
                                    <Text >Status </Text>
                                </div>
                                <Text type={'secondary'}>{follow_up?.editor_status}</Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>


            </Card>)
    }
    const renderForm = () => {
        return (< >
            {
                isSubmitted ? (
                    <>
                        <PromPreview id={edit_pres_data.id}></PromPreview>
                        {showContinueEdit && <Button style={{ marginTop: "10px" }} onClick={()=>{setIsSubmitted(false)}}>Continue Editing</Button>}
                    </>

                ) : (
                    <Card>
                        < DynamicFormContainer
                            templateId={template_id}
                            onCancel={
                                () => {
                                    setTimeout(() => {
                                        window.location.href = '/'
                                    }, 2000);
                                    notification.info({ message: "Thank you for you time" })
                                }
                            }

                            saving={loading}
                            onSubmit={
                                formSubmit
                            }
                            formData={template_content} />
                    </Card>
                )
            }



        </>
        )
    }
    // FUNCTIONS

    const {
        mutation: addOrUpdatePrescriptionMutation,
        saving: addingOrUpdatingPrescription,
        error,
    } = addOrUpdatePrescription((e: any) => {
        if (e) {
            if (e?.add_or_update_prescription) {
                notification.success({ message: "Questionnaire updated successfully" })
                setIsSubmitted(true)
            }

        }
    });

    const formSubmit = (data: any, submitted?: boolean) => {
        const reqObject: any = {};
        if (edit_pres_data) {
            reqObject['id'] = edit_pres_data.id;
            reqObject['prescription_review_id'] = edit_pres_data.review.id;
            reqObject['encounter_id'] = follow_up.encounter_id;
        } else {
            reqObject['template_id'] = add_pres_data.id;
            reqObject['encounter_id'] = follow_up.encounter_id;
        }
        reqObject['status'] = submitted ? PrescriptionEditorStatusSubmitted : PrescriptionEditorStatusInProgress;
        reqObject['remarks'] = '';
        reqObject['data'] = data;
        addOrUpdatePrescriptionMutation({
            variables: reqObject,
        });
        if(submitted){
            setShowContinueEdit(false)
        }
    }

    return < div className='page-wrapper'>

        <Spin style={{ position: 'fixed', top: '40%', minWidth: '100%' }} spinning={loading}>
            {/* Patient Details */}
            {patient_details && render_PatientDetails()}
            <Divider />
            {/* template */}
            {renderForm()}
        </Spin>
    </div>;
}

export default PromEdit;
