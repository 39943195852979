import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
    notification,
} from 'antd';
import useLocations from 'shared/hooks/useLocations';
import UserForm from './UserForm';
import useLocationCreate from 'shared/hooks/Locations/useLocationCreate';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import useUserCreate from 'shared/hooks/Users/useUserCreate';

type Props = {}

const UserCreate: React.FunctionComponent<Props> = () => {
    const history = useHistory();
    const location = useLocations();
    const { mutation, saving } = useUserCreate((e: any) => {
        if (e) {
            notification.success({ message: 'Created successfully' });
            history.goBack();
        }
    });
    const onSubmit = (object: any) => {
        mutation({ variables: { object } });
    };
    return (
        <div className={'user-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'User Create'}>
                <UserForm saving={saving} onCancel={() => history.goBack()} onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};


export default UserCreate;
