import React from 'react';
import ReactTooltip from 'react-tooltip';
import MultiAutocompleteComponent from '../../Common/FormFields/MultiAutocompleteComponent';
import AdditionalOptions from '../../Constants/AdditionalOptions';
import Validations from './Validations';
import { Input, Select } from 'antd';

const { Option } = Select
const ColIdRegex = /^[a-zA-Z0-9_]*$/;
const MAX_OPTIONS = 100;
const MAX_VARS = 15;
let FormulaRegex = '';
const TagconfigEnum = {
    p: 'patient',
    s: 'symptoms',
    ds: 'diagnosis',
    d: 'drug',
    v: 'vitals',
    i: 'investigations'
}
export default class ToolProps extends React.Component {
    // only for rendering the selected element on a specific location acc. to a uuid
    // and basic view with edit buttons on sub elements
    constructor(props) {
        super(props);
        let properties = this.getProperties();
        this.state = {
            properties: properties, // maintains which properties will be displayed according to element
            colIdValid: true,
            formulaValid: true,
            colIdArray: this.props.colIdArray,
            openValidation: false,
            variablesMoreThanMaxLmt: false,
            optionsMoreThanMaxLmt: false,
            hideShowSource: null,
            openValidation: false
        };
        this.propsChanged = this.propsChanged.bind(this);
    }

    getProperties() {
        let properties = {
            showType: false,
            tag: false,
            rowType: false,
            config: false,
            status: false,
            type: false,
            title: false,
            rowName: false,
            name: false,
            info: false,
            placeholder: false,
            spaceNeeded: false,
            label: false,
            value: false,
            size: false,
            appSize: false,
            labelSize: false,
            pattern: false,
            conditionalFormulas: false,
            globalsectionvars: false,
            readOnly: false,
            uiData: false,
            colId: false,
            colIdFormula: false,
            formula: false,
            localvar: false,
            auto: false,
            singleLine: false,
            validation: false,
            showUnit: '',
            subSectionSize: false,
            autoKey: false,
            enableHideShow: false,
            additionalSectionOptions: false,
            multiselect: false,
            primaryDate: false,
            columnDetailConfig: false,
            subType: false,
            dataType: false,
            async: false,
            rowId: false
        };
        let { showType, tag, rowType, subType, dataType, config, status, type, uiData, colId, colIdFormula, showUnit, auto, pattern, autoKey, enableHideShow,
            name, placeholder, spaceNeeded, label, size, singleLine, title, rowName, labelSize, appSize, multiselect, primaryDate, columnDetailConfig, conditionalFormulas, globalsectionvars, formula,
            localvar, readOnly, value, validation, subSectionSize, additionalSectionOptions, async, info, rowId } = properties;
        switch (this.props.element) {
            case 'section':
                placeholder = showType = tag = title = status = additionalSectionOptions = true;
                break;
            case 'compactRow':
                break;
            case 'row':
                config = type = columnDetailConfig = additionalSectionOptions = spaceNeeded = rowId = true;
                break;
            case 'group':
                config = rowName = type = additionalSectionOptions = true;
                break;
            case 'subSection':
                config = rowName = subSectionSize = true;
                break;
            case 'childrow':
                config = columnDetailConfig = spaceNeeded = rowId = true;
                break;
            case 'textFormatter':
                pattern = true;
            case 'textarea':
                case 'hyper_link':
                    case 'numeric_pain_score':
            case 'textbox':
            case 'typeahead':
            case 'time':
            case 'hidden':
            case 'date':
            case 'autoComplete':
                placeholder = value = info = true;
            case 'radio':
              
            case 'selection':
            case 'checkbox':
                name = colId = singleLine = true;
                size = labelSize = appSize = info = true;
                validation = true;
                break;
            default:
                break;
        }

        // specific leftover properties
        switch (this.props.element) {
            case 'hyper_link':
                break;
            case 'numeric_pain_score':
                break;
            case 'textbox':
                readOnly = subType = dataType = info = true;
                auto = colIdFormula = true;
                formula = localvar = showUnit = true;
                break;
            case 'textarea':
                readOnly = colIdFormula = true;
                subType = dataType = info = true;
                formula = localvar = showUnit = true;
                auto = true;
                break;
            case 'radio':
                uiData = subType = dataType = enableHideShow = true;
                break;
            case 'typeahead':
                uiData = autoKey = async = auto = true;
                subType = dataType = info = true;
                break;
            case 'selection':
                placeholder = enableHideShow = dataType = subType = uiData = true;
                break;
            case 'autoComplete':
                autoKey = auto = multiselect = true;
                subType = dataType = info = true;
                break;
            case 'date':
                readOnly = subType = true;
                primaryDate = this.props.showPrimaryDate;
                break;
            case 'checkbox':
                uiData = subType = dataType = enableHideShow = true;
                break;
            default:
                break;
        }
        properties = {
            showType, tag, rowType, config, status, type, subType, dataType, uiData, multiselect, primaryDate, showUnit, enableHideShow, colId, colIdFormula, auto, autoKey,
            name, placeholder, spaceNeeded, label, size, title, rowName, labelSize, appSize, conditionalFormulas, globalsectionvars, readOnly,
            formula, localvar, info, value, pattern, singleLine, validation, subSectionSize, additionalSectionOptions, columnDetailConfig, async, rowId
        };
        return properties;
    }

    propsChanged = (e) => {
        this.props.onToolPropsChange(e);
    }
    selectPropsEvent = (name, value) => {
        console.log(name, value)
        this.props.onToolPropsChange({ name, value });
    }

    onColIdChanged = (e) => {
        let { value } = e.target;
        let colIdValid = ColIdRegex.test(value);
        this.props.onToolPropsChange(e);
        this.setState({ colIdValid });
    }

    onFormulaChanged = (e) => {
        let { value } = e.target;
        let formulaValid = FormulaRegex.test(value);
        this.props.onToolPropsChange(e);
        this.setState({ formulaValid })
    }

    onLocalVarSelect = (variable) => {
        let newFormula = this.props.meta['formula'] + variable;
        this.props.onToolPropsChange({ name: 'formula', value: newFormula });
    }

    onCheckboxChanged = (e) => {
        this.props.onToolPropsChange({ name: e.target.name, value: e.target.checked });
    }

    additionalSectionOptionsChanged = (isActions, checkedList) => {
        let list = checkedList && checkedList.length ? checkedList.map(l => l.value) : [];
        let Options = isActions ? AdditionalOptions.Actions : AdditionalOptions.SectionOptions;
        let savedOptions = Options.map(o => ({
            name: o.value,
            value: list.indexOf(o.value) !== -1
        }));
        this.props.saveOptionsInElement(savedOptions);
    }

    getSelectedAdditionalValues(isActions) {
        let options = isActions ? AdditionalOptions.Actions : AdditionalOptions.SectionOptions;
        return options.filter(o => this.props.meta.hasOwnProperty(o.value) && !!this.props.meta[o.value]);
    }

    makeFormulaRegex() {
        // Allow only variables and special characters in formula
        let re = '^[' + this.props.variables.join('|') + '\\d()\\-+\\/%&*]{0,}$';
        return new RegExp(re);
    }

    validationChange = (e) => {
        const { name, value, checked } = e.target;
        let validation = this.props.meta['validation'];
        validation = {
            ...validation,
            [name]: value === 'on' ? checked : value
        };
        this.props.onToolPropsChange({ name: 'validation', value: validation });
    }

    onVariableNoChange = (e) => {
        if (e.target.value <= MAX_VARS) {
            this.setState({ variablesMoreThanMaxLmt: false }, this.props.onVariableNumberChange(e));
        } else {
            this.setState({ variablesMoreThanMaxLmt: true });
        }
    }

    onOptionNoChange = (e) => {
        if (e.target.value <= MAX_OPTIONS) {
            this.setState({ optionsMoreThanMaxLmt: false }, this.props.onOptionNumberChange(e));
        } else {
            this.setState({ optionsMoreThanMaxLmt: true });
        }
    }

    hideShowValuesSelected = (checkedList) => {
        let str = checkedList ? Array.isArray(checkedList) ? checkedList.length ? checkedList.map(l => l.label).join(',') : '' : checkedList.label : '';
        this.props.onToolPropsChange({ name: 'displayconditionValues', value: str });
    }

    onColumnDetailChanged = (idx, e) => {
        this.props.onColumnDetailChanged(e, idx);
    }

    render() {
        let { showType, tag, spaceNeeded, pattern, subType, dataType, subSectionSize, singleLine, title, multiselect, showUnit, rowName, status, type, uiData, colId, info, colIdFormula, auto, autoKey,
            name, placeholder, label, size, labelSize, appSize, columnDetailConfig, config, enableHideShow, globalsectionvars, primaryDate, readOnly, additionalSectionOptions,
            formula, localvar, value, validation, async, rowId } = this.props.element ? this.getProperties() : this.state.properties; // written here to render again when props.element is changed 
        FormulaRegex = formula && this.props.variables.length > 0 ? this.makeFormulaRegex() : '';
        let noVariablesDefined = this.state.colIdArray && this.state.colIdArray.length === 0;
        let { meta, availableNameArr } = this.props;
        let selectedHideShowSource = this.props.showHideParentArray && this.props.showHideParentArray.length && meta.config && meta.config['displaycondition'] && meta.config['displaycondition'].length && meta.config['displaycondition'][0]['source'] ? this.props.showHideParentArray.filter(o => o.colIdKey === meta.config['displaycondition'][0]['source'])[0] : { values: '' };
        let selectedSectionOptions = this.getSelectedAdditionalValues(false);
        let selectedActions = this.getSelectedAdditionalValues(true);
        return (
            <div className="prop-container" style={{ width: '94%' }}>
                {name && availableNameArr && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Name<span className="required">*</span></label>{/* Name should have  regex or automatically turn label into name */}
                    <Select style={{ width: "100%" }} className="form-control" type="select" name="name" 
                    onSelect={(val) => this.selectPropsEvent("name", val)} value={meta['name'] ? meta['name'] : 'initial'}>
                        <Option value='initial' disabled>Select name</Option>
                        {availableNameArr.map(o => <Option value={o}>{o}</Option>)}
                    </Select>
                </div>}
                {dataType && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Data Type</label>
                    <Select style={{ width: "100%" }} className="form-control" type="select" name="dataType" 
                    onSelect={(val) => this.selectPropsEvent("dataType", val)} value={meta['dataType'] ? meta['dataType'] : 'initial'}>
                        <Option value='initial' disabled>Select data type</Option>
                        <Option value='name'>Name</Option>
                        <Option value='value'>Value</Option>
                        <Option value='drugs'>Drugs</Option>
                        <Option value='drugType'>Drug Type</Option>
                        <Option value='frequency'>Frequency</Option>
                        <Option value='secondValue'>Second Value</Option>
                        <Option value='dosage'>Dosage</Option>
                        <Option value='route'>Route</Option>
                        <Option value='cycle'>cycle</Option>
                        <Option value='molecule'>molecule</Option>
                        <Option value='brand'>brand</Option>
                        <Option value='unit'>unit</Option>
                        <Option value='inference'>Inference</Option>
                        <Option value='enddate'>End Date</Option>
                        <Option value='childName'>Child Name</Option>
                        <Option value='date'>Date</Option>
                        <Option value='duration'>Duration</Option>
                        <Option value='comment'>Comment</Option>
                    </Select>
                </div>}
                {subType && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Sub-Type</label>
                    <Input className="form-control" type="textbox" name="subType" placeholder='Enter subType' onChange={this.propsChanged} value={meta['subType']} />
                </div>}
                {label && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Label<span className="required">*</span></label>
                    <Input className="form-control" type="textbox" name="label" placeholder='Enter text here' onChange={this.propsChanged} value={meta['label']} />
                </div>}
                {placeholder && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Placeholder<span className="required">*</span></label>
                    <Input placeholder="Please enter placeholder" name='placeholder' onChange={this.propsChanged} value={meta['placeholder']} value={meta['placeholder']} />
                </div>}
                {async && <div className='mb-2 mr-3 mt-1' style={{ marginTop: '15px' }}>
                    <Input type="checkbox" name='async' checked={meta['async']} onChange={this.onCheckboxChanged.bind(this)} />
                    <label className='ml-2'> Fetch data from API</label>
                </div>}
                {showUnit && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>Enter unit</label>
                    <Input className="form-control" type="textbox" name="showUnit" placeholder='Eg: inch, mg' onChange={this.propsChanged} value={meta['showUnit']} />
                </div>}
                {autoKey && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Key for auto-complete<span className="required">*</span></label>
                    <Select className="form-control" type="S" name="autoKey" onSelect={(val) => this.selectPropsEvent("autoKey", val)} value={meta['autoKey'] ? meta['autoKey'] : 'initial'}>
                        <Option value='initial' disabled>Select key for autocomplete</Option>
                        {['patient', 'symptoms', 'disease', 'diagnosis', 'drugs', 'vitals', 'biomarkers', 'investigations', 'complaints', 'test'].map(o => <option value={o}>{o}</option>)}
                    </Select>
                </div>}
                {primaryDate && <div className='mb-2 mr-3 mt-1' style={{ marginTop: '15px' }}>
                    <Input type="checkbox" name='primaryDate' checked={meta['primaryDate']} onChange={this.onCheckboxChanged.bind(this)} />
                    <label className='ml-2'> Make Primary Date for this section
                    </label>
                </div>}
                {multiselect && <div className='mb-2 mr-3 mt-1' style={{ marginTop: '15px' }}>
                    <Input type="checkbox" name='multiselect' checked={meta['multiselect']} onChange={this.onCheckboxChanged.bind(this)} />
                    <label className='ml-2'> Allow multiselect
                    </label>
                </div>}
                {value && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>Default Value</label>
                    <Input className="form-control" type="textbox" name="value" placeholder='Enter default value' onChange={this.propsChanged} value={meta['value']} />
                </div>}
                {pattern && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>Pattern</label>
                    <Input className="form-control" type="textbox" name="pattern" placeholder='Enter pattern' onChange={this.propsChanged} value={meta['pattern']} />
                </div>}
                {colIdFormula && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>FieldId<small> (For use in formulae)</small></label>
                    <Input className="form-control" type="textbox" name="colId" placeholder='Enter text here' onChange={this.onColIdChanged.bind(this)} value={meta['colId']} />
                    {!this.state.colIdValid && <small className="text-danger error-text" >No special characters allowed!</small>}
                </div>}
                {colId && meta['class'] === 'displayparent' && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>FieldId<span className="required">*</span></label>
                    <Input className="form-control" type="textbox" name="colId" placeholder='Enter text here' onChange={this.onColIdChanged.bind(this)} value={meta['colId']} />
                    {!this.state.colIdValid && <small className="text-danger error-text" >No special characters allowed!</small>}
                </div>}
                {info && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>Tooltip</label>
                    <Input className="form-control" type="text" name="info" placeholder='Enter tooltip text' onChange={this.propsChanged} value={meta['info']} />
                </div>}
                {rowId && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>Row Id</label>
                    <Input className="form-control" type="text" name="rowId" placeholder='Enter rowId' onChange={this.propsChanged} value={meta['rowId']} />
                </div>}
                {size && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>Column Size(1-12)</label>
                    <Input className="form-control" type="number" max="12" min="1" name="size" placeholder='Enter value here' onChange={this.propsChanged} value={meta['size']} />
                </div>}
                {labelSize && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>Label Size(1-12)</label>
                    <Input className="form-control" type="number" max="12" min="1" name="labelSize" placeholder='Enter value here' onChange={this.propsChanged} value={meta['labelSize']} />
                </div>}
                {appSize && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>App Size(1-12)<i className="mdi mdi-information" title="Sets size of box in Clinytics Mobile App" /></label>
                    <Input className="form-control" type="number" max="12" min="1" name="appSize" placeholder='Enter value here' onChange={this.propsChanged} value={meta['appSize'] || ''} />
                </div>}
                {singleLine && <div className='mb-2 mr-3 mt-1' style={{ marginTop: '15px' }}>
                    <Input style={{width:'10%'}} type="checkbox" name='singleLine' checked={meta['offset']} onChange={this.onCheckboxChanged.bind(this)} />
                    <label className='ml-2'> Show column in single line
                    </label>
                </div>}
                {uiData && <div className='mb-2 mr-3 mt-1' style={{ width: '100%' }}>
                    <label className='mr-2'>No. of Options (maximum {MAX_OPTIONS})<span className="required">*</span></label>
                    <Input className="form-control" type="number" min="1" name="optionsTotal" placeholder='Enter no of options' onChange={this.onOptionNoChange.bind(this)} value={this.props.options.length > 0 ? this.props.options.length : ''} />
                    {this.state.optionsMoreThanMaxLmt && <small className="text-danger error-text">Options should be less than {MAX_OPTIONS}!</small>}
                    <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                        {this.props.options.length > 0 && this.props.options.map((option, idx) =>
                            <div className="mr-2 mt-2" key={idx} >
                                <span>Option {idx + 1}</span>
                                <input className="form-control" style={{ width: '134px' }} type="textbox" name={idx} placeholder='Enter value' onChange={(e) => this.props.onOptionValueChanged(e)} value={option.value} />
                                {this.props.options.length < MAX_OPTIONS && <i style={{ border: 'none' }} className="mdi mdi-plus-circle" onClick={idx => this.props.onOptAddition(idx)}></i>}
                                {this.props.options.length > 1 && <i style={{ border: 'none' }} onClick={() => this.props.onOptDeletion(idx)} className="mdi mdi-minus-circle"></i>}
                            </div>)
                        }
                    </div>
                    {this.props.isOptionsEmpty && <small className="text-danger error-text">All options must have a value!</small>}
                </div>}
                {localvar && <div className='mb-2 mr-3 mt-1' style={{ width: '100%' }}>
                    <label className='mr-2'>Select variables for creating formula (max {MAX_VARS})</label>{noVariablesDefined && <i className="mdi mdi-information-outline" style={{ border: 'none' }} data-tip data-for="noVariablesDefined"></i>}
                    <input className="form-control" disabled={noVariablesDefined} type="number" min="0" name='varTotal' placeholder='Select no. of variables' onChange={this.onVariableNoChange.bind(this)} value={this.props.variables.length ? this.props.variables.length : ''} />
                    {this.state.variablesMoreThanMaxLmt && <small className="text-danger error-text">Variables should be less than {MAX_VARS}!</small>}
                    <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                        {this.props.variables.length > 0 && this.props.variables.map((variable, idx) =>
                            <div className="mr-2 mt-2" key={idx} > {/*variable selectors*/}
                                <select className="form-control" type="select" name={idx} onChange={(e) => this.props.onVariableChanged(e)} value={variable ? variable : 'initial'}>
                                    <option value='initial' disabled>Select FieldId</option>
                                    {this.state.colIdArray.map((colId) =>
                                        <option value={colId}>{colId}</option>
                                    )}
                                </select>
                                {this.props.variables.length < MAX_VARS && <i className="mdi mdi-plus-circle" style={{ border: 'none' }} onClick={() => this.props.onVariableAddition(idx)}></i>}
                                {this.props.variables.length > 1 && <i style={{ border: 'none' }} onClick={() => this.props.onVariableDeletion(idx)} className="mdi mdi-minus-circle"></i>}
                            </div>
                        )}
                    </div>
                    {this.props.isVariablesEmpty && <small className="text-danger error-text">All variables must have a value!</small>}
                    <ReactTooltip id='noVariablesDefined' aria-haspopup='true' effect='solid'>
                        <p className='tooltip-info'>You have not defined fieldId for any column. Please fill in fieldId for columns you wish to use for defining formulae.</p>
                    </ReactTooltip>
                </div>
                }
                {formula && this.props.variables.length > 0 && <div className='mb-2 mr-3' style={{ width: '100%' }}>
                    <label className='mr-2 mt-1'>Formula</label>
                    <Input className="form-control" type="textbox" name="formula" placeholder='Create formula by selecting below variables' onChange={this.onFormulaChanged.bind(this)} value={meta['formula']} />
                    {/* {!this.state.formulaValid && <div><small className="text-danger error-text" >Only below variables can be used in formula!</small></div>} */}
                    {this.props.variables.map((variable) =>
                        variable && <button className="btn btn-primary btn-rounded" style={{ padding: '10px' }} onClick={this.onLocalVarSelect.bind(this, variable)}>{variable}</button>)}
                </div>}
                {globalsectionvars && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>Global Section Variables Go here</label>
                </div>}
                {readOnly && <div className='mb-2 mr-3 mt-1' style={{ marginTop: '15px' }}>
                    <Input style={{width:'10%'}}  type="checkbox" name='readOnly' checked={meta['readOnly']} onChange={this.onCheckboxChanged.bind(this)} />
                    <label className='ml-2'> Is Field ReadOnly?
                    </label>
                </div>}
                {auto && <div className='mb-2 mr-3 mt-1' style={{ marginTop: '15px' }}>
                    <Input  style={{width:'10%'}}  type="checkbox" name='auto' checked={meta['auto']} onChange={this.onCheckboxChanged.bind(this)} />
                    <label className='ml-2'> Should field show suggestions?
                    </label>
                </div>}

                {enableHideShow && <div className='mb-2 mr-3 mt-1' style={{ marginTop: '15px' }}>
                    <Input style={{width:'10%'}}  type="checkbox" name='enableHideShow' checked={meta['class'] === 'displayparent'} onChange={this.onCheckboxChanged.bind(this)} />
                    <label className='ml-2'> Should hide/show other rows?
                    </label>
                </div>}
                {validation && meta['validation'] && <div className='mb-2 mr-3 mt-1' style={{ marginTop: '15px' }}>
                    <div style={{ backgroundColor: '#fff', fontSize: '18px' }} onClick={() => this.setState({ openValidation: !this.state.openValidation })}>
                        <h6>Add Validations<i style={{ marginLeft: '10px' }} className={this.state.openValidation ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'}></i></h6>
                    </div>
                    {!!this.state.openValidation && <div>
                        <Validations meta={meta} onValidationChange={this.validationChange.bind(this)}></Validations>
                    </div>}
                </div>}
                {type && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Type<span className="required">*</span></label>
                    <Select style={{ width: "100%" }} className="form-control" type="select" name="type" 
                    onSelect={(val) => this.selectPropsEvent("type", val)}
                    value={meta['type'] ? meta['type'] : 'initial'}>
                        <Option value='initial' disabled>Select display type</Option>
                        <Option value='row'>Row with Columns</Option>
                        <Option value='group'>Group with more Rows</Option>
                    </Select>
                </div>}
                {spaceNeeded && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Space Needed<span className="required">*</span></label>
                    <Select style={{ width: "100%" }} className="form-control" type="select" name="spaceNeeded" 
                    onSelect={(val) => this.selectPropsEvent("spaceNeeded", val)} value={meta['spaceNeeded'] ? meta['spaceNeeded'] : 'initial'}>
                        <Option value='12'>Full line</Option>
                        <Option value='6'>1/2 (Half)</Option>
                        <Option value='4'>1/3 (One-third)</Option>
                        <Option value='8'>2/3 (Two-third)</Option>
                        <Option value='3'>1/4 (One-fourth)</Option>
                    </Select>
                </div>}
                {columnDetailConfig && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Column Serial No & Name</label>
                    {meta.columnDetailConfig ? meta.columnDetailConfig.map((detail, idx) => <>
                        <Input className="form-control" type="textbox" name='serialno' placeholder="Eg: 2-6" onChange={this.onColumnDetailChanged.bind(this, idx)} value={detail.serialno} />
                        <Input className="form-control" type="textbox" name='name' placeholder="Eg: Chemo_Regimen" onChange={this.onColumnDetailChanged.bind(this, idx)} value={detail.name} />
                        <i className="mdi mdi-plus-circle" style={{ border: 'none' }} onClick={() => this.props.onColumnDetailAdd(idx)}></i>
                        {meta.columnDetailConfig.length > 1 && <i style={{ border: 'none' }} onClick={() => this.props.onColumnDetailDel(idx)} className="mdi mdi-minus-circle"></i>}
                    </>) : <>
                        <Input className="form-control" type="textbox" name='serialno' placeholder="Eg: 2-6" onChange={(e) => this.props.onColumnDetailChanged(e, 0)} value={''} />
                        <Input className="form-control" type="textbox" name='name' placeholder="Eg: Chemo_Regimen" onChange={(e) => this.props.onColumnDetailChanged(e, 0)} value={''} />
                        <i className="mdi mdi-plus-circle" style={{ border: 'none' }} onClick={() => this.props.onColumnDetailAdd(0)}></i>
                    </>}
                </div>}

                {rowName && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>{meta['type'] === 'group' ? 'Row Name' : 'Sub-section Name'}</label>
                    <Input className="form-control" type="textbox" name='name' placeholder="Enter row name" onChange={this.propsChanged} value={meta['name']} />
                </div>}
                {type && meta['type'] === 'group' && <div className='mb-1 mt-1'>
                    <Input type="checkbox" name='cloningAllowed' checked={meta['cloningAllowed']} onChange={this.onCheckboxChanged.bind(this)} />
                    <label className='ml-2'> Enable inline addition/deletion of groups (+/-)</label>
                </div>}
                {subSectionSize && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Sub Section Size (1-12)<span className="required">*</span></label>
                    <Input className="form-control" type="number" max="12" min="1" name="subSectionSize" placeholder='Enter text here' onChange={this.propsChanged} value={meta['subSectionSize']} />
                </div>}
                {title && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Section Title<span className="required">*</span></label>
                    <Input className="form-control" type="textbox" name='title' placeholder="Enter section title" onChange={this.propsChanged} value={meta['title']} />
                </div>}
                {showType && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Showtype<span className="required">*</span></label>
                    <Select style={{ width: "100%" }} className="form-control"
                        style={{ width: "100%" }}
                        type="select" name="showType" 
                        onSelect={(val) => this.selectPropsEvent("showType", val)} value={meta['showType'] ? meta['showType'] : 'initial'}>
                        <Option value='initial' disabled>Select show type</Option>
                        <Option value='1d'>1 D</Option>
                        <Option value='2d'>2 D</Option>
                        <Option value='3d'>3 D</Option>
                        <Option value='compact'>Compact</Option>
                        <Option value='none'>None</Option>
                        <Option value='table'>Table</Option>
                        <Option value='xyTable'>XY Header Table</Option>
                        <Option value='compactTable'>Compact Table</Option>
                        <Option value='compactTextbox'>Compact Textbox</Option>
                        <Option value='freeText'>Editable Free Text</Option>
                    </Select>
                </div>}
                {tag && this.props.tagConfigList && this.props.tagConfigList.length && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Tag</label>
                    <Select style={{ width: "100%" }}
                     className="form-control" type="select" name="tag" 
                     onSelect={(val) => this.selectPropsEvent("tag", val)} value={meta['tag'] ? meta['tag'] : ''}>
                        <Option value=''>None</Option>
                        {meta['tag'] && <Option value={meta['tag']}>{TagconfigEnum[meta['tag']]}</Option>}
                        {this.props.tagConfigList.map(l => <Option value={l}>{TagconfigEnum[l]}</Option>)}
                    </Select>
                </div>}
                {status && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Status<span className="required">*</span></label>
                    <Select style={{ width: "100%" }} className="form-control" 
                    type="select" name="status" 
                    value={meta['status'] ? meta['status'] : ''}
                    onSelect={(val) => this.selectPropsEvent("status", val)}>
                        <Option value='initial' disabled>Select Status</Option>
                        <Option value='empty'>Empty</Option>
                        <Option value='filled'>Filled</Option>
                    </Select>
                </div>}
                {this.props.showHideParentArray && !!(this.props.showHideParentArray.length) && config && <div className='mb-2 mr-3'>
                    <label className='mr-2'>Show when..</label>
                    <Select style={{ width: "100%" }} className="form-control" type="select" name="source" 
                    onSelect={(val) => this.selectPropsEvent("source", val)}
                        value={meta.config['displaycondition'] && meta.config['displaycondition'].length && meta.config['displaycondition'][0]['source'] ?
                            meta.config['displaycondition'][0]['source'] : 'none'}>
                        <Option value='none'>Select hide/show source</Option>
                        {this.props.showHideParentArray.map((o, idx) => <Option key={idx} value={o.colIdKey}>{o.colIdValue}</Option>)}
                    </Select>
                    {selectedHideShowSource && selectedHideShowSource.values && <div style={{ padding: '0' }} className='col-12 mb-2 mt-2'>
                        <div>
                            <label className='mr-2'>has values<span className="required">*</span></label>
                            <MultiAutocompleteComponent isMulti={selectedHideShowSource.type === 'checkbox'} optionsData={this.props.uiDataDict[selectedHideShowSource.values].options && this.props.uiDataDict[selectedHideShowSource.values].options.length ? this.props.uiDataDict[selectedHideShowSource.values].options.map((v, idx) => ({ value: v.value, label: v.value })) : []}
                                selectedOption={meta.config['displaycondition'][0].value ? meta.config['displaycondition'][0].value.split(',').map(v => ({ label: v, value: v })) : []}
                                emitSelectedAutoCompleteData={this.hideShowValuesSelected.bind(this)}
                                closeMenuOnSelect={selectedHideShowSource.type !== 'checkbox'}>
                            </MultiAutocompleteComponent>
                        </div>
                    </div>}
                </div>}
                {this.props.element === 'section' && meta['showType'] === 'xyTable' && <>
                    <div className='mb-2 mr-3'>
                        <label className='mr-2'>No of Columns<span className="required">*</span></label>
                        <Input className="form-control" type="number" min="1" name="noOfColumns" placeholder='Enter no of columns in table' onChange={this.propsChanged} value={meta['noOfColumns'] || ''} />
                    </div>
                    <div className='mb-2 mr-3'>
                        <label className='mr-2'>No of Rows<span className="required">*</span></label>
                        <Input className="form-control" type="number" min="1" name="noOfRows" placeholder='Enter no of rows in table' onChange={this.propsChanged} value={meta['noOfRows'] || ''} />
                    </div>
                    <div className='mb-2 mr-3 mt-1'>
                        <label className='mr-2'>Column Headers<span> (separate with ',')</span></label>
                        <Input className="form-control" type="text" name="colHeaders" placeholder='right eye,left eye' onChange={this.propsChanged} value={meta['colHeaders']} />
                    </div>
                    <div className='mb-2 mr-3 mt-1'>
                        <label className='mr-2'>Row Headers<span> (separate with ',')</span></label>
                        <Input className="form-control" type="text" name="rowHeaders" placeholder='DV,MV' onChange={this.propsChanged} value={meta['rowHeaders']} />
                    </div>
                </>}
                {this.props.element === 'section' && meta['showType'] === 'compactTable' && <div className='mb-2 mr-3 mt-1' style={{ marginTop: '15px' }}>
                    <Input type="checkbox" name='splitTable' checked={meta['splitTable']} onChange={this.onCheckboxChanged.bind(this)} />
                    <label className='ml-2'> Split Table
                    </label>
                </div>}
                {((this.props.element === 'section' && meta['showType'] === 'compactTable' && !meta['splitTable']) || meta['type'] === 'subSection') && <div className='mb-2 mr-3'>
                    <label className='mr-2'>No of Columns<span className="required">*</span></label>
                    <Input className="form-control" type="number" min="1" name="noOfColumns" placeholder='Enter no of columns in table' onChange={this.propsChanged} value={meta['noOfColumns']} />
                </div>}
                {((this.props.element === 'section' && meta['showType'] === 'compactTable' && !meta['splitTable']) || meta['type'] === 'subSection') && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>Header<span> (separate with ',')</span></label>
                    <Input className="form-control" type="text" name="header" placeholder='Enter no of table columns' onChange={this.propsChanged} value={meta['header']} />
                </div>}
                {/* {meta && meta['header'] !== undefined && meta['noOfColumns'] !== undefined && rowType && <div className='mb-2 mr-3 mt-1'>
                    <label className='mr-2'>Row Type</label>
                    <select className="form-control" type="select" name="rowType" onChange={this.propsChanged} value={meta['rowType'] ? meta['rowType'] : 'initial'}>
                        <option value='initial' disabled>Select row type</option>
                        <option value='1col'>1 column</option>
                        <option value='2col'>2 column</option>
                        <option value='3col'>3 column</option>
                    </select>
                </div>} */}
                {additionalSectionOptions && <div style={{ padding: '0' }} className='mb-2 mt-2 col-12'>
                    {this.props.element === 'section' && <div>
                        <label>Additional Options</label>
                        <MultiAutocompleteComponent isMulti={true} optionsData={AdditionalOptions.SectionOptions} selectedOption={selectedSectionOptions} emitSelectedAutoCompleteData={this.additionalSectionOptionsChanged.bind(this, false)}></MultiAutocompleteComponent>
                    </div>}
                    <div className='mb-2 mt-2'>
                        <label>Actions</label>
                        <MultiAutocompleteComponent isMulti={true} optionsData={AdditionalOptions.Actions} selectedOption={selectedActions} emitSelectedAutoCompleteData={this.additionalSectionOptionsChanged.bind(this, true)}></MultiAutocompleteComponent>
                    </div>
                </div>}
            </div>
        );
    }
}
