import React from "react";
import MobileRouteViewComponent from '../Common/Header/RouteView/MobileRouteViewComponent';
import BrandViewComponent from '../Common/Header/BrandView/BrandViewComponent';
import routes from '../Routes/FormBuilderRoutes';
// import logoutService from '../../../Services/logout.service';

class HeaderContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // navType: this.props.type
            activeKey: 'templates', //or create-template
            version: sessionStorage.getItem('appVersion')
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isTemplateSelected && this.props.isTemplateSelected) {
            this.setState({activeKey: ''});
        }
    }

    getEmitEventOnNavClick = (key) => {
        if (key === 'create-template' || key === 'templates') {
            let isEditMode = (key === 'create-template');
            this.setState({activeKey: key}, this.props.emitActiveViewConfig(isEditMode));
            return;
        }
        let route = key + (this.state.version ? "?version=" + this.state.version : '');
        this.props.history.push(route);
    }

    getEmitedLogoutEvent = (event) => {
        sessionStorage.setItem('showUser', '');
        sessionStorage.setItem('apiStage', '');
        sessionStorage.setItem('emrToken', '');
        sessionStorage.setItem('templateResponse', '');
        sessionStorage.setItem('isSuperUser', '');
        sessionStorage.setItem('s3AccountId', '');
        sessionStorage.setItem('userId', '');
        sessionStorage.setItem('hideprescription', '');
        sessionStorage.setItem('identityId', '');
        sessionStorage.setItem('substaff', '');
        logoutService.logout(this.props.history);
    }

    // getEmitedSidebarClickEvent(event) {
    //     // this.props.emitSidebarClickEvent();
    // }

    render() {
        const { emrNavBarRoutes, navType } = this.state;
        let navroutes = routes.map(r => ({...r, isActive: r.key === this.state.activeKey }));
        return (
            <React.Fragment>
                <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                    <BrandViewComponent
                        header={"header view "}
                        showBackButton={true}
                    // navType={navType}
                    emitBackClickEvent={(e) => this.props.emitBackClickEvent(e)}
                    //emitSidebarClickEvent={this.getEmitedSidebarClickEvent.bind(this)}
                    >
                    </BrandViewComponent>
                    <RouteViewComponent
                        emrNavBarRoutes={navroutes}
                        emitLogoutEvent={this.getEmitedLogoutEvent.bind(this)}
                        emitEventOnNavClick={this.getEmitEventOnNavClick.bind(this)}>
                    </RouteViewComponent>
                </nav>
                <nav className="sidebar m-sidemenu sidebar-offcanvas" id="sidebar ">
                    <ul className="nav">
                        <MobileRouteViewComponent
                            emrNavBarnavroutes={routes}
                            emitEventOnNavClick={this.getEmitEventOnNavClick.bind(this)}
                            emitLogoutEvent={this.getEmitedLogoutEvent.bind(this)}>
                        </MobileRouteViewComponent>
                    </ul>
                </nav>
            </React.Fragment>
        );
    }
}

export default HeaderContainer;
