import * as React from 'react';
import { Button, Select, Modal } from 'antd';
import useCountries from '../../hooks/Locations/useCountries';
import useStates from '../../hooks/Locations/useStates';
import useCities from '../../hooks/Locations/useCities';
import useLocations from '../../hooks/Locations/useLocations';
import { useEffect, useState } from 'react';
import LocationForm from '../../../client/src/views/Locations/CreateOrEdit/LocationForm';
import { LOCATION_ADD_ROUTE, TABLE_PERMISSIONS_LIST_ROUTE } from '../../constants/RouteConstants';
import { hasPermissionOf } from '../../services/Utility';
import { LOCATIONS_INSERT_ONE } from '../../constants/Permissions';
import useLocationCreate from '../../hooks/Locations/useLocationCreate';
import { useHistory } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';

const { Option } = Select;
export const CountrySelect: React.FunctionComponent<{ value: any, onChange: any }> = ({ value, onChange }) => {
    const { countries, loading: loadingCountries } = useCountries();
    return <Select value={value} showSearch={true} onChange={onChange}
                   filterOption={(input: string, option: any) =>
                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                   }
    >
        {
            countries.map((country: { country_code: string, country: string }) => {
                return <Option value={country.country_code}>{country.country}</Option>;
            })
        }
    </Select>;
};


export const StateSelect: React.FunctionComponent<{ value: any, onClose?: any, onChange: any, country_code: string, addNew?: any }> = ({
                                                                                                                                           value,
                                                                                                                                           onChange,
                                                                                                                                           onClose,
                                                                                                                                           addNew,
                                                                                                                                           country_code,
                                                                                                                                       }) => {
    const { states, loading: loadingStates, refetch } = useStates(country_code);
    const [addNewLocation, setAddNewLocation] = useState(false);
    const history = useHistory();
    const { mutation, saving } = useLocationCreate((e: any) => {
        if (e && e.insert_c_meta_locations_one) {
            refetch({ country_code });
            if (onClose) {
                onClose(e.insert_c_meta_locations_one);
            }
            setAddNewLocation(false);
        }
    });
    return <>
        {
            addNewLocation && hasPermissionOf(LOCATIONS_INSERT_ONE) &&
            <Modal width={800} title='Add Location' footer={null} onCancel={() => setAddNewLocation(false)}
                   visible={true}>
                <LocationForm onSubmit={(e: any) => mutation({ variables: { object: e } })} />
            </Modal>
        }
        <Select
            filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent={addNew && hasPermissionOf(LOCATIONS_INSERT_ONE) ?
                <Button onClick={() => setAddNewLocation(true)}>Add New Location</Button> : 'No Data'}
            loading={loadingStates}
            value={value} showSearch={true} onChange={onChange}>
            {
                states.map((state: { state_code: string, state: string }) => {
                    return <Option value={state.state_code}>{state.state}</Option>;
                })
            }
        </Select>
    </>;
};

export const CitySelect: React.FunctionComponent<{ value: any, state_code: string, addNew?: any, onClose?: any, onChange: any }> = ({
                                                                                                                                        value,
                                                                                                                                        onClose,
                                                                                                                                        addNew,
                                                                                                                                        state_code,
                                                                                                                                        onChange,
                                                                                                                                    }) => {
    const { cities, loading: loadingStates, refetch } = useCities({ state_code });
    const [addNewLocation, setAddNewLocation] = useState(false);
    const history = useHistory();

    const { mutation, saving } = useLocationCreate((e: any) => {
        if (e && e.insert_c_meta_locations_one) {
            refetch({ state_code });
            if (onClose) {
                onClose(e.insert_c_meta_locations_one);
            }
            setAddNewLocation(false);
        }
    });
    return <>
        {
            addNewLocation && hasPermissionOf(LOCATIONS_INSERT_ONE) &&
            <Modal width={800} title='Add Location' footer={null} onCancel={() => setAddNewLocation(false)}
                   visible={true}>
                <LocationForm onSubmit={(e: any) => mutation({ variables: { object: e } })} />
            </Modal>
        }
        <Select loading={loadingStates} value={value} showSearch={true} onChange={onChange}
                filterOption={(input: string, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={addNew && hasPermissionOf(LOCATIONS_INSERT_ONE) ?
                    <Button onClick={() => setAddNewLocation(true)}>Add New Location</Button> : 'No Data'}
        >
            {
                cities.map((city: { id: string, city: string }) => {
                    return <Option value={city.id}>{city.city}</Option>;
                })
            }
        </Select></>;
};

