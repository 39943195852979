import React, { useEffect, useState } from 'react';
import {
  Col,
  ConfigProvider,
  notification,
  Typography,
  Row,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Divider,
  Space, Image,
} from 'antd';
import './i18n';

import { ApolloProvider } from '@apollo/react-hooks';
import { client } from 'shared/services/ApolloClient';


import en_US from 'antd/es/locale/en_US';

// Router
import ProtectedRoute from 'shared/components/ProtectedRoute';
import { BrowserRouter as Router, NavLink, Route, Switch, useHistory } from 'react-router-dom';
import { AuthProvider } from 'shared/contexts/AuthContext';

import { BugOutlined } from '@ant-design/icons';
// Views
import Layout from 'views/Layout';
import Login from 'views/login/Login';
import { MsalProvider } from '@azure/msal-react';


import './App.scss';
import Signup from 'shared/view/login/Signup/Signup';
import LoaderOverlay from 'shared/components/LoaderOverlay';
import { CustomNavigationClient } from 'shared/Apps/CustomAzureAD/MSUtils/NavigationClient';
import VerifyLoginPage from './views/login/VerifyLogin';
import PAGE_400 from 'shared/components/PAGE_400';
import Page403 from 'shared/view/Page403';
import { PROM_EDIT_OR_VIEW_ROUTE, ROUTE_PAGE_404 } from 'shared/constants/RouteConstants';
import PromEdit from 'views/Prom/CreateOrEdit/PromEdit';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import TextArea from 'antd/es/input/TextArea';

const { Text } = Typography;

function App({ pca }: any) {
  useEffect(() => {
    // window.localStorage.removeItem('permissions');
  }, []);


  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);
  const { t } = useTranslation();
  return (
    <ApolloProvider client={client}>
      <ConfigProvider locale={en_US}>
        <AuthProvider>
          <MsalProvider instance={pca}>
            {loading ? <LoaderOverlay size={'large'} /> : <Router>
              <Switch>
                <Route exact path='/login' component={Login} />
                <Route exact path='/pages/400' component={PAGE_400} />
                <Route exact path='/pages/403' component={Page403} />
                <Route exact path={ROUTE_PAGE_404} component={Page403} />
                <Route exact path='/register' component={Signup} />
                <Route exact path='/verify' component={VerifyLoginPage} />
                <Route exact path='/' component={Login} />
                <Route exact path={PROM_EDIT_OR_VIEW_ROUTE} component={PromEdit} />
                {/*<Route exact path="/confirm-email" component={ConfirmEmail} />*/}
                {/*<Route exact path="/reset-password-request" component={ResetPasswordRequest} />*/}
                <ProtectedRoute path='/' component={Layout} />
                {/*<ClosedProtectedRoute path="/" component={LayoutClosed}/>*/}
              </Switch>
            </Router>}
          </MsalProvider>
        </AuthProvider>
        <div className='footer-wrapper'>
          <Row gutter={20} className={'secondary-footer'}>
            <Col flex={'auto'}>
              <ReportABugComponent />
            </Col>
            <Col>
              <NavLink to={'about-us'}><Text
                type={'secondary'}>{t('genDict.version')}</Text></NavLink>
            </Col>
          </Row>
        </div>
      </ConfigProvider>

    </ApolloProvider>

  );
}

function ReportABugComponent() {
  const [showPopup, setShowPopup] = useState(false);
  const [initialValues, setInitialValues] = useState({} as any);
  const { t } = useTranslation();

  return (
    <>
      {
        showPopup && <Modal title='Would you like to report a bug ?' visible={showPopup} onCancel={() => setShowPopup(false)}
                            footer={null}>
          <div style={{ textAlign: 'center' }}>{initialValues.screenshot &&
          <Image height={100} src={initialValues.screenshot} />}</div>
          <Form
            size='large'
            onFinish={async (formValues) => {
              // onSubmit(formValues);
            }}
            initialValues={initialValues}
            requiredMark={true}
            layout='vertical'

          >
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Form.Item
                  label={'User Email'}
                  name={'email'}
                  rules={[
                    { required: true, type: 'email', message: 'User Email required' },
                  ]}>
                  <Input disabled={initialValues.disableEmail} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={'App Version'}
                  name={'appVersion'}
                  rules={[
                    { required: true, message: 'App Version required' },
                  ]}>
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={'URL'}
                  name={'url'}
                  rules={[
                    { required: true, message: 'URL required' },
                  ]}>
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={'Description'}
                  name={'description'}
                  rules={[
                    { required: true, message: 'Description required' },
                  ]}>
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider />
                <Space>
                  <Form.Item>
                    <Button type='default'
                            onClick={() => setShowPopup(false)}
                      // disabled={saving}
                            size={'large'}>
                      Cancel
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button type='primary'
                            htmlType='submit'
                      // loading={saving}
                            size={'large'}>
                      Submit
                    </Button>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      }
      <Button icon={<BugOutlined />} onClick={() => {
        const data = {
          user: localStorage.getItem('user'),
          email: '',
          disableEmail: false,
          url: window.location.href,
          appVersion: t('genDict.version'),
          'auth-token': localStorage.getItem('auth-token'),
          'permissions': localStorage.getItem('permissions'),
          'screenshot': '',
        };

        if (data.user) {
          data.email = JSON.parse(data.user as string)?.email;
          data.disableEmail = !!data.email;
        }
        console.log(initialValues)
        const screenshotTarget = document.body;

        html2canvas(screenshotTarget).then((canvas) => {
          data['screenshot'] = canvas.toDataURL('image/png');
          setInitialValues(data);
          setShowPopup(true);
        }).catch(() => {
          setInitialValues(data);
          setShowPopup(true);
        });

      }}>Report a bug </Button>
    </>
  );
}

export default App;
