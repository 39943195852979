import React, { useState, useEffect } from "react";
// common imports
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Placeholders from './Placeholders';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// For Docs Refer: https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
// find css classes in enhance.css
function TextEditor(props) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [html, setHtml] = useState(draftToHtml(convertToRaw(editorState.getCurrentContent())));

    useEffect(() => {
        if (props.html) {
            setHtml(props.html);
            convertInitialTextToEditorState();
        }
    }, [])

    function convertInitialTextToEditorState() {
        const contentBlock = htmlToDraft(props.html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
    }

    function onEditorStateChange(newState) {
        setEditorState(newState);
        let updatedHtml = draftToHtml(convertToRaw(newState.getCurrentContent()))
        setHtml(updatedHtml);
        props.onEditTextChange(updatedHtml);
    }

    return (
        <div>
            <Editor
                editorState={editorState}
                wrapperClassName="react-wysiwyg-editor-wrapper"
                editorClassName="react-wysiwyg-editor"
                onEditorStateChange={(newState) => onEditorStateChange(newState)}
                toolbarCustomButtons={[<Placeholders />]}
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'], //deleted embedded & emoji options
                  }}
            />
            {/* <textarea
                disabled
                value={html}
            /> */}
        </div>
    );
}

export default TextEditor;