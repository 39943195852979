import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    notification,
    Skeleton,
} from 'antd';
import RoleForm from './RoleForm';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import useBranchUpdate from 'shared/hooks/Branches/useBranchUpdate';
import useDepartmentById from 'shared/hooks/Departments/useDepartmentById';
import useDepartmentUpdate from 'shared/hooks/Departments/useDepartmentUpdate';
import useRoleById from 'shared/hooks/Roles/useRoleById';
import useRoleUpdate from 'shared/hooks/Roles/useRoleUpdate';

type Props = {}

const RoleEdit: React.FunctionComponent<Props> = () => {
    const history = useHistory();
    const RouteParams: { id: string, mode: 'edit' } = useParams();
    const { loading: loadingRole, role,refetch } = useRoleById(RouteParams['id']);

    const {
        mutation,
        saving,
    } = useRoleUpdate((e: any) => {
        if (e) {
            notification.success({ message: 'Updated successfully' });
            history.goBack();
        }
    });

    if (!RouteParams['id']) {
        history.goBack();
    }

    const onSubmit = (set: any) => {
        mutation({ variables: { set, id: RouteParams['id'] } });
    };
    return (
        <div className={'role-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Role Edit '}>
                <RoleForm loading={loadingRole} saving={saving} role={role}
                          onCancel={() => history.goBack()}
                          refresh={refetch}
                          onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};


export default RoleEdit;
