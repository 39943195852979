import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw,convertFromRaw,convertFromHTML } from 'draft-js';


import usePrintPreference from '../../hooks/AccountPreferences/usePrintPreference';
import { Redirect, Link ,useHistory, useParams } from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    Modal,
    Divider,
    Form,
    Input, InputNumber,Checkbox,Switch,
    Row,
    Select,
    Skeleton,
    Space,
    Tooltip,
    Steps,
    notification,
    Typography
} from 'antd';
const { Title } = Typography;
import TextArea from 'antd/es/input/TextArea';
import Text from 'antd/es/typography/Text';
import FormViewDesktop from '../../components/layout/Desktop/FormView/FormViewDesktop';

type Props = {
    preferences?: any
    onSubmit: any
    saving?: boolean
    loading?: boolean
    refresh?: any
    onCancel?:any
}
const PreferencesView: React.FunctionComponent<Props> = ({
    onSubmit,
    saving,
    preferences,
    loading,
    refresh,
    onCancel
}) => {
    if(typeof preferences.data=='string'){
        preferences.data=JSON.parse(preferences.data);
    }
   
    //console.log(preferences);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const history = useHistory();
    const RouteParams: any = useParams();
    const [fields]: any[] = useState([]);
    const [initialValues, setInitialValues] = useState( preferences?{
        marginBottom:preferences.data.marginBottom?preferences.data.marginBottom:'',
        marginLeft:preferences.data.marginLeft?preferences.data.marginLeft:'',
        marginRight:preferences.data.marginRight?preferences.data.marginRight:'',
        marginTop:preferences.data.marginTop?preferences.data.marginTop:'',
        printButtonEnable:preferences.data.printButtonEnable?preferences.data.printButtonEnable:'',
        /*printDoctorInfoHeader:preferences.data.printDoctorInfoHeader?preferences.data.printDoctorInfoHeader:'',
        signature:preferences.data.signature?(preferences.data.signature):'',*/
    }:{
        /*footerBanner:'',
        headerBanner:'',*/
        marginBottom:'',
        marginLeft:'',
        marginRight:'',
        marginTop:'',
        printButtonEnable:'',
        /*printDoctorInfoHeader:'',
        signature:'',*/
    }  );
  
    //const { preferences, loading, refetch } = usePrintPreference();
    //console.log(preferences);
    /*const onSubmit = (data: any) => {

        console.log(data);
        console.log(draftToHtml((data.signature)));
    };

    const onCancel =(data:any) =>{
        history.goBack();
    };
   */
    return (
        <>
            <FormViewDesktop title={'Preferences'}>
                <Form
                    size='large'
                    form={form}
                    fields={fields}

                    initialValues={{
                        ...initialValues,
                    }}
                    onFinish={(formValues) => {
                        
                        onSubmit(formValues);
                    }}
                    requiredMark={true}
                    layout='vertical'
                >
                    <Row gutter={[20, 20]}>
                        <Col xs={24}>
                            <Text type={'secondary'}>{t('genDict.printPreferences')}</Text>
                        </Col>
                    </Row>   
                    <Row gutter={[20, 20]} align={'middle'}>
                        <Col xs={24} md={8} sm={8} >
                            <Title  level={5} >{t('genDict.paperMargin')}</Title>
                        </Col>
                        <Col xs={24} md={16} sm={16}>
                            <Row gutter={[20, 20]}>
                                <Col xs={6} md={6} sm={6}>
                                    <Form.Item
                                        label={t('genDict.top')}
                                        name={'marginTop'}
                                        rules={[]}>
                                        <InputNumber />
                                    </Form.Item>
                                </Col> 
                                <Col xs={6} md={6} sm={6}>
                                    <Form.Item
                                        label={t('genDict.right')}
                                        name={'marginRight'}
                                        rules={[]}>
                                        <InputNumber />
                                    </Form.Item>
                                </Col> 
                                <Col xs={6} md={6} sm={6}>
                                    <Form.Item
                                        label={t('genDict.bottom')}
                                        name={'marginBottom'}
                                        rules={[]}>
                                        <InputNumber />
                                    </Form.Item>
                                </Col> 
                                <Col xs={6} md={6} sm={6}>
                                    <Form.Item
                                        label={t('genDict.left')}
                                        name={'marginLeft'}
                                        rules={[]}>
                                        <InputNumber />
                                    </Form.Item>
                                </Col>    
                            </Row>
                            
                        </Col>  
                    </Row>   
                    {/*}
                        <Row gutter={[20,20]}>
                            <Col xs={24} md={24} sm={24}>
                                
                                
                                    
                                        <Form.Item   
                                        name={'printDoctorInfoHeader'}
                                        valuePropName="checked"                                 
                                        rules={[]}>
                                            <Checkbox >{t('genDict.printDoctorInfoHeader')}</Checkbox>
                                        </Form.Item>
                                    
                                
                            </Col>
                        </Row>
                    {*/}
                    <Row gutter={[20,20]}>
                        <Col xs={24} md={24} sm={24}>
                            
                            
                                
                                    <Form.Item
                                    label={t('genDict.printButtonEnable')}
                                    name={'printButtonEnable'}
                                    valuePropName="checked"
                                    rules={[]}>
                                        <Switch ></Switch>
                                    </Form.Item>
                               
                            
                        </Col>
                    </Row>
                    {/*}
                    <Row gutter={[20,20]}>
                        <Col xs={24} md={24} sm={24}>
                            
                            
                            <Title  level={5} >{t('genDict.signature')}</Title>
                            
                        </Col>
                        <Col xs={24} md={24} sm={24}>
                            
                            
                            <Form.Item
                                name={'signature'}
                                rules={[]}>
                                <Editor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                
                                />
                            </Form.Item>    
                            
                        </Col>
                    </Row>
                    <Row gutter={[20,20]} style={{ marginBottom: 8 }}>
                        <Col xs={6} md={6} sm={6}>
                            
                            
                            <Title  level={5} >{t('genDict.headerBanner')}</Title>
                            
                        </Col>
                        <Col xs={18} md={18} sm={18}>
                            
                            
                            <Form.Item
                                name={'headerBanner'}
                                rules={[]}>
                                <Input
                                
                                />
                            </Form.Item>    
                            
                        </Col>
                    </Row>
                    <Row gutter={[20,20]}>
                        <Col xs={6} md={6} sm={6}>
                            
                            
                            <Title  level={5} >{t('genDict.footerBanner')}</Title>
                            
                        </Col>
                        <Col xs={18} md={18} sm={18}>
                            
                            
                            <Form.Item
                                name={'footerBanner'}
                                rules={[]}>
                                <Input
                                
                                />
                            </Form.Item>     
                            
                        </Col>
                    </Row>
                    {*/}
                    <Row gutter={[20, 20]}>
                        <Col span={24}>
                            <Divider />
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button type='default'
                                        onClick={onCancel}
                                        size={'large'}>
                                    {t('genDict.back')}
                                </Button>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item>
                                <Button type='primary'
                                        htmlType='submit'
                                        
                                        
                                        size={'large'}>
                                    {t('genDict.submit')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>     
            </FormViewDesktop>

        </>
    );
};


export default PreferencesView;
