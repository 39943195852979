import React from 'react';
import ReactJson from 'react-json-view';
import Spinner from '../Common/Spinner/Spinner';
const $ = window["jQuery"];

export default class JsonViewerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalSectionsToLoad: 2,
            loading: false,
            textCopied: false
        }
        this['scrollRef'] = React.createRef();
    }

    componentDidMount() {
        this['scrollRef'].current.onscroll = () => {
            if (this.state.totalSectionsToLoad < this.props.JsonObj.sections.length &&
                this['scrollRef'].current.scrollTop + this['scrollRef'].current.clientHeight >=
                this['scrollRef'].current.scrollHeight
            ) {
                this.setState({ totalSectionsToLoad: this.state.totalSectionsToLoad + 1, loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 1500);
            }
        };
    }

    copy() {
        var input = document.createElement('textarea');
        input.innerHTML = JSON.stringify(this.props.JsonObj);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        this.setState({textCopied: true});
        setTimeout(() => {
            this.setState({textCopied: false})
        }, 3000);
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.activeKey === 'json';
    }

    render() {
        //splice the json obj only till the currentSection (the currently loaded section)
        const currentObj = this.props.JsonObj && this.props.JsonObj.sections.length ?
            { ...this.props.JsonObj, sections: this.props.JsonObj.sections.slice(0, this.state.totalSectionsToLoad) } : this.props.JsonObj;
        return <>
            {this.state.loading && <div style={{ position: 'absolute', bottom: '75px', right: '49%' }}><Spinner height={30} width={30}></Spinner></div>}
            <button className="badge badge-info mb-3 mr-2" onClick={() => this.copy()}>Copy</button>{this.state.textCopied && <label>JSON Copied!</label>}
            < div ref={this['scrollRef']} style={{ height: "500px", overflow: "auto" }}>
                { <ReactJson src={currentObj} /> }
            </div >
        </>
    }
}
