import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';

type ReturnType = {
  follow_up: any;
  loading: boolean;
  error: any;
  refetch: any;
};

const QUERY = gql`
query query($id:uuid!) {
    c_prom_follow_up_list_by_pk(id: $id) {
      follow_up_prescription_id
      follow_up_data {
        followup_template_id
      }
      patient_encounter_data {
        id
        patient_id
        patient {
          first_name
          last_name
          email
          age
          id
          uhid       
        }
        prescriptions(order_by: [{created_at: desc}]) {
          created_at
          created_by_id
          deleted_at
          latest_editor_status
          id
          data {
            data
            id
          }
          template {
            name
            code
          }
          review {
            id
            editor_notes(limit: 1, order_by: [{created_at: desc}]) {
              remarks
              status
              id
              created_by {
                name
              }
              created_at
            }
            reviewer_notes(limit: 1, order_by: [{created_at: desc}]) {
              remarks
              status
              id
              created_by {
                name
              }
              created_at
            }
          }
        }
        admission_date
        discharge_date
        start_date
        visit_date
        encounter_type {
          id
          name
          encounter_templates(where: {template: {id: {_is_null: false}, deleted_at: {_is_null: true}}}) {
            encounter_type {
              name
              id
            }
            template {
              name
              id
              code
              content {
                data
              }
            }
          }
        }
       
       
        
       
      }
    }
  }
  
`;

const useGetPromData: (id: string) => ReturnType = (id) => {
  const { data, loading, error, refetch } = useQuery(QUERY, { variables: { id } });
  console.log(data)
  let follow_up:any = null;
  if (!loading && !error && data?.c_prom_follow_up_list_by_pk) {
    let { c_prom_follow_up_list_by_pk: { follow_up_data, patient_encounter_data, follow_up_prescription_id } } = data || {}
    console.log(follow_up_data, patient_encounter_data)
    let { encounter_type: { encounter_templates } } = patient_encounter_data;
    let template_view = encounter_templates.find((e: any) => e.template?.id == follow_up_data?.followup_template_id)
    follow_up = {
      templated_id: follow_up_data.followup_template_id,
      template_view: template_view.template,
      patient: patient_encounter_data.patient,
      performing_doctor: patient_encounter_data.performing_doctor,
      primary_doctor: patient_encounter_data.primary_doctor,
      start_date: patient_encounter_data.start_date,
      discharge_date: patient_encounter_data.discharge_date,
      admission_date: patient_encounter_data.admission_date,
      encounter_id: patient_encounter_data.id,
      encounter_type: patient_encounter_data?.encounter_type?.name,
      prescriptions: null,
      editor_status: null

    }
    for (let pres of patient_encounter_data.prescriptions) {
      if (pres.id == follow_up_prescription_id) {
        follow_up['prescriptions'] = pres
        if (pres.deleted_at) {
          follow_up['editor_status'] = "DELETED"
        } else {
          follow_up['editor_status'] = pres?.review?.editor_notes[0]?.status
        }
        break
      }
    }

    // console.log(follow_up)
  }
  return {
    follow_up,
    loading,
    refetch,
    error,
  };
};
export default useGetPromData;

























// query query($id:uuid!) {
//   c_prom_follow_up_list_by_pk(id: $id) {
//     follow_up_prescription_id
//     follow_up_data {
//       followup_template_id
//     }
//     patient_encounter_data {
//       id
//       patient_id
//       patient {
//         first_name
//         last_name
//         email
//         age
//         id
//         uhid
//         date_of_birth
//         phone
//         branch_id
//       }
//       encounter_type_id
//       type
//       type_id
//       department_id
//       performing_doctor_id
//       primary_doctor_id
//       department {
//         name
//         id
//       }
//       department_id
//       prescriptions(order_by: [{created_at: desc}]) {
//         created_at
//         created_by_id
//         latest_editor_status
//         id
//         data {
//           data
//           id
//         }
//         template {
//           name
//           code
//         }
//         review {
//           id
//           editor_notes(limit: 1, order_by: [{created_at: desc}]) {
//             remarks
//             status
//             id
//             created_by {
//               name
//             }
//             created_at
//           }
//           reviewer_notes(limit: 1, order_by: [{created_at: desc}]) {
//             remarks
//             status
//             id
//             created_by {
//               name
//             }
//             created_at
//           }
//         }
//       }
//       admission_date
//       discharge_date
//       visit_date
//       encounter_type {
//         id
//         name
//         encounter_templates(where: {template: {id: {_is_null: false}, deleted_at: {_is_null: true}}}) {
//           encounter_type {
//             name
//             id
//           }
//           template {
//             name
//             id
//             code
//             content {
//               data
//             }
//           }
//         }
//       }
//       start_date
//       end_date
//       created_at
//       reviewers {
//         id
//         user {
//           id
//           name
//         }
//         user_id
//       }
//       editors {
//         id
//         user {
//           id
//           name
//         }
//         user_id
//       }
//       performing_doctor {
//         id
//         name
//       }
//       primary_doctor {
//         id
//         name
//       }
//     }
//   }
// }
