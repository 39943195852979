import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Divider,
    Skeleton,
} from 'antd';
import useLocations from 'shared/hooks/useLocations';
import ProcedureTypeForm from './ProcedureTypeForm';
import useProcedureTypeById from 'shared/hooks/ProcedureTypes/useProcedureTypeById';
import useProcedureTypeQuestionnaires from 'shared/hooks/ProcedureTypes/useProcedureTypeQuestionnaires';
import useProcedureTypeUpdate from 'shared/hooks/ProcedureTypes/useProcedureTypeUpdate';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import FollowUpEdit from '../followup/followupEdit';
import { useState } from 'react';

type Props = {}

const ProcedureTypeEdit: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocations();
    const RouteParams: { id: string, mode: 'edit' } = useParams();
    const { loading: loadingProcedureType, procedureType, error } = useProcedureTypeById(RouteParams['id']);
    let {
        loading,
        procedureTypeQuestionnaires,
        refetch
    } = useProcedureTypeQuestionnaires({ procedure_type_id: RouteParams['id'] });
    // const [pq, setpq] = useState([] as any)

    const {
        mutation,
        saving,
        procedureType: savedProcedureType,
    } = useProcedureTypeUpdate((e: any) => {
        if (e) {
            history.goBack();
        }
    });

    if (!RouteParams['id']) {
        history.goBack();
    }
    if (loadingProcedureType) {
        return <Skeleton active={true} />;
    }
    const onSubmit = (data: any) => {
        mutation({ variables: { set: data, id: RouteParams['id'] } });
    };
    // To refetch the template names for followup when template change in questionnaire
    const refetchOptions = () => {
        refetch().then((d: any, e: any) => {
            procedureTypeQuestionnaires = d.data
            // setpq(d.data)
        })

    }
    return (
        <div className={'user-group-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Procedure Type Edit'}>
                <ProcedureTypeForm procedureTypeQuestionnaires={procedureTypeQuestionnaires} saving={saving}
                    procedureType={procedureType} onCancel={() => history.goBack()} refetchOption={refetchOptions}
                    onSubmit={onSubmit} />
              {/*<ProcedureTypeForm procedureTypeQuestionnaires={procedureTypeQuestionnaires} saving={saving}*/}
              {/*      procedureType={procedureType} onCancel={() => history.goBack()} refetchOption={refetchOptions}*/}
              {/*      onSubmit={onSubmit} />*/}
                <Divider />
                {procedureTypeQuestionnaires && <FollowUpEdit procedureTypeQuestionnaires={procedureTypeQuestionnaires}></FollowUpEdit>}
            </FormViewDesktop>
        </div>
    );
};


export default ProcedureTypeEdit;
