// Apollo

import { ApolloClient, DefaultOptions } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { AuthContext, getAuthToken, logout } from 'shared/contexts/AuthContext';
import { HttpLink } from 'apollo-link-http';
import BaseUrlConstants from '../constants/BaseUrlConstants';
import { ApolloLink, from } from 'apollo-link';
import { notification } from 'antd';

let AUTH_TOKEN: string | null = null;

const cache: any = new InMemoryCache();

const httpLink = new HttpLink({
    uri: BaseUrlConstants.BASE_URL + 'v1/graphql/',
});

// @ts-ignore
const uploadLink = new createUploadLink({
    uri: BaseUrlConstants.BASE_URL + 'v1/graphql/',
});

let subscription: any;
export const GetHeaders = () => {
    let headers: any = {}

    // For PROM patient public token
    let patient_token: string | null = sessionStorage.getItem('pid');
    if (patient_token) {
        headers = {
            'authorization': `Bearer public ${patient_token}`,
        };
    }

    let authToken: any = localStorage.getItem('auth-token');
    if (authToken) {
        authToken = JSON.parse(authToken).idToken;
        headers = {
            'authorization': `Bearer ${authToken}`,
        };
    }
    return headers;
};
const authLink = new ApolloLink((operation, forward) => {

    operation.setContext({
        headers: GetHeaders(),
    });

    return forward(operation);
});

const loggOutAfterware = onError(({ graphQLErrors }) => {
    if (!graphQLErrors) {
        return;
    }
    const error = graphQLErrors.filter((pError: any) => pError.unauthenticated)[0];
    if (error) {
        logout();
    } else {
        let AccessDenied = false;
        graphQLErrors.forEach((e: any) => {
            if (e.extensions && e.extensions.code === 'access-denied') {
                AccessDenied = true;
            }
            notification.error({ message: e.message });
        });
        if (AccessDenied) {
            logout();
        }
    }
});
const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'all',
    },
};
const client = new ApolloClient({
    defaultOptions,
    link: from([loggOutAfterware, authLink, uploadLink, httpLink]),
    cache,
});

function updateAuthToken(authToken: any) {
    AUTH_TOKEN = authToken;
}

//AuthContext.addListener(updateAuthToken);

const clientWithErrors = async (req: any) => {
    try {
        const response = await req;
        if (response && response.errors && response.errors.length) {
            throw showApolloErrors(response.errors);
        }
        return response;
    } catch (e) {
        throw e;
    }
};
const showApolloErrors = (e: any[]) => {
    let message = 'Something went wrong !!';
    let errors: string[] = [];
    e.forEach((error: any) => {
        if (error.state && error.state.formErrors) {
            error.state.formErrors.forEach((error1: any) => {
                if (error1.message) {
                    errors.push(error1.message);
                }
            });
        } else if (error.message) {
            errors.push(error.message);
        }
    });
    return errors.length ? errors.join(', ') : message;
};
export { client, clientWithErrors, updateAuthToken };
