import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { notification } from 'antd';
import Parameters from '../parameters';
import API_SERVICE from '../services/api-service';
import { useTranslation } from 'react-i18next';
import useCurrentUserPermissions from '../hooks/useCurrentUserPermissions';

let AUTH_TOKEN: any = null;
let listener: any = null;

interface AuthContextProps {
    authToken: any | null;
    user: any | undefined;
    isAuth: boolean;
    authStatus: 'UN_AUTHENTICATED' | 'AUTHENTICATING' | 'AUTHENTICATED';
    updateAuthToken: (authToken: any | null) => void;
    setAuth: (value: boolean) => void;
    hasPermissionOf: (value: string) => void;
}


const AuthContext = React.createContext<AuthContextProps>({
    authToken: null,
    user: undefined,
    isAuth: false,
    authStatus: 'UN_AUTHENTICATED',
    updateAuthToken: () => {
    },
    setAuth: () => {
    },
    hasPermissionOf: () => {
    },
});

function AuthProvider({ children }: any) {
    const { t } = useTranslation();
    let permissions: any[] = [];
    if (localStorage.getItem('permissions')) {
        permissions = JSON.parse(localStorage.getItem('permissions') as string);
    }
    const [authToken, setAuthToken] = useState(() => {
        const authTokenStr = localStorage.getItem('auth-token');
        const lAuthToken = authTokenStr ? JSON.parse(authTokenStr) : undefined;
        AUTH_TOKEN = lAuthToken;
        return lAuthToken;
    });

    const [user, setUser] = useState<any | undefined>(null as any);
    const [isAuth, setIsAuth] = useState(() => {
        const userStr = localStorage.getItem('user');
        const lUser = userStr ? JSON.parse(userStr) : undefined;
        setUser(lUser);
        return !!lUser;
    });
    const hasPermissionOf = (permission: string) => {
        //console.log(permissions);
        return permissions.indexOf(permission) > -1;
    };
    const updateAuthToken = useCallback((pAuthToken: any) => {
        if (pAuthToken) {
            localStorage.setItem('auth-token', JSON.stringify(pAuthToken));
            // setUser(pAuthToken.user);
        } else {
            localStorage.removeItem('auth-token');
        }

        AUTH_TOKEN = pAuthToken;
        setAuthToken(pAuthToken);
    }, []);
    const setAuth = useCallback((value: boolean) => {
        const userStr = localStorage.getItem('user');
        const lUser = userStr ? JSON.parse(userStr) : undefined;
        setUser(lUser);
    }, []);

    useEffect(() => {
        function forceLogout() {
            notification.error({
                message: 'You are logged Out',
            });
            updateAuthToken(null);
        }
    }, [authToken]);

    return (
        <AuthContext.Provider
            value={{
                updateAuthToken,
                authToken,
                user,
                hasPermissionOf,
                authStatus: !authToken ? 'UN_AUTHENTICATED' : ((!user || !permissions.length) ? 'AUTHENTICATING' : 'AUTHENTICATED'),
                isAuth,
                setAuth,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

const AuthConsumer = AuthContext.Consumer;

/**
 * We use this function to be able to access
 * the auth token from outside React components.
 */
function getAuthToken() {
    return AUTH_TOKEN;
}

function getUrlFormattedToken() {
    const token = getAuthToken();
    return token ? encodeURIComponent(token.value) : '';
}

function logout() {
    if (listener != null) {
        listener(null);
    }
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/login';
    notification.error({ message: 'You are logged out' });
}

export {
    AuthProvider,
    AuthConsumer,
    AuthContext,
    getAuthToken,
    getUrlFormattedToken,
    logout,
};

