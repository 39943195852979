import gql from 'graphql-tag';
import {useQuery} from '@apollo/react-hooks';

type ReturnType = {
    currentUser: any;
    loading: boolean;
    error: any;
};

const QUERY = gql`
    query($token: String!) {
        current_user(token: $token){
            userId
            error
            token
        }
    }
`;
const QUERYB2B = gql`
    query($token: String!) {
        fetch_token_user(token: $token){
            userId
            error
            token
        }
    }
`;

const useCurrentUser: (token: string) => ReturnType = (token) => {
    const {data, loading, error} = useQuery(QUERY, {variables: {token}});
    let currentUser = null;
    if (!loading && !error) {
        currentUser = data.current_user;
    }
    return {
        currentUser,
        loading,
        error,
    };
};
const useCurrentUserB2B: (token: string) => ReturnType = (token) => {
    const {data, loading, error} = useQuery(QUERYB2B, {variables: {token}});
    let currentUser = null;
    if (!loading && !error) {
        currentUser = data.fetch_token_user;
    }
    return {
        currentUser,
        loading,
        error,
    };
};
export default process.env.MODE !=='B2C' ? useCurrentUserB2B : useCurrentUser;
