import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { logout } from '../contexts/AuthContext';

type ReturnType = {
    permissions: any[];
    loading: boolean;
    error: any;
    refetch: any;
};

const QUERY = gql`
    query{
        c_acl_user_permissions_only {
            query
        }
    }
`;

const useCurrentUserPermissions: () => ReturnType = () => {
    const { data, loading, error, refetch } = useQuery(QUERY);
    let permissions: any[] = [];
    if (!loading && !error && data) {
        permissions = data.c_acl_user_permissions_only.map((permission: any) => permission.query);
    }
    return {
        permissions,
        loading,
        refetch,
        error,
    };
};
export default useCurrentUserPermissions;
