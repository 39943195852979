import * as React from 'react';
import { Button, Col, Row, Typography } from 'antd';
import './index.scss';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface ListViewDesktopProps {
    title?: any,
    addActionConfig?: any,
    filters?: any[],
    additionalActionConfig?: any[],
    children?: any,
}

export default function ListViewDesktop({
                                            title,
                                            children,
                                            additionalActionConfig,
                                            addActionConfig,
                                            filters,
                                        }: ListViewDesktopProps) {
    return (
        <div className={'desktop-list-view-wrapper'}>
            {
                title && <div className={'title-bar'}>
                    <Row gutter={20} align={'middle'} wrap={false}>
                        <Col flex={'auto'}>
                            <Row gutter={[10, 10]} align={'middle'}>
                                <Col flex={'auto'}>
                                    <Title level={4} className={'page-title'}>{title}</Title>
                                </Col>
                                {
                                    filters && filters.map((filter) => {
                                        if (!filter) {
                                            return <></>;
                                        }
                                        return <Col>
                                            {filter}
                                        </Col>;
                                    })
                                }
                            </Row>
                        </Col>
                        {
                            additionalActionConfig && additionalActionConfig.map((config: any) => {
                                if(!config.show){
                                    return <></>
                                }
                                return <Col>
                                    <Button type={'primary'}
                                            onClick={config.onClick}>
                                        {config.text}
                                    </Button>
                                </Col>;
                            })
                        }
                        {
                            addActionConfig && <Col>
                                <Button type={'primary'} icon={<PlusOutlined />}
                                        onClick={addActionConfig.onClick}>
                                    {addActionConfig.text}
                                </Button>
                            </Col>
                        }
                    </Row>
                </div>
            }
            {
                children && children
            }
        </div>
    );
}
;
