import React from 'react';

interface Props {
    location: any;
}

export default function DashboardView(props: Props) {
    let url = ""
    let auth_token: any = localStorage.getItem('auth-token');
    if (auth_token) {
        auth_token = JSON.parse(auth_token);
        url = `https://plotly-flask.azurewebsites.net/fortis_report_dashboard/?token=${auth_token.idToken}&labname=fortis&embedded=true`
    }


    return (
        <iframe default-src={url} style={{ height: '100vh' }} src={url} title="Dashboard" sandbox="allow-same-origin allow-scripts" ></iframe>
    );
}