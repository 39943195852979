import React, { useEffect, useState } from 'react';
import './index.scss';
import {
    Avatar,
    Badge,
    Button,
    Calendar,
    Card,
    Divider,
    Form, Image,
    Input,
    notification, Rate,
    Select,
    Skeleton, Space, Table,
    Tabs, Tag, Tooltip,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import Parameters from 'shared/parameters';
import { AuthConsumer, logout } from 'shared/contexts/AuthContext';
import { Link } from 'react-router-dom';
import API_SERVICE from 'shared/services/api-service';
import moment from 'moment';
import Text from 'antd/es/typography/Text';
import useUserDepartments from 'shared/hooks/UserDepartments/useUserDepartments';
import useUserBranches from 'shared/hooks/UserBranches/useUserBranches';
import useGroups from 'shared/hooks/Groups/useGroups';
import useUserGroups from 'shared/hooks/UserGroups/useUserGroups';

const { Header } = Layout;
const { TabPane } = Tabs;

interface Props {
    location: any;
}

const { Title } = Typography;
export default function ProfilePage(props: Props) {
    const { t } = useTranslation();

    return (
        <Card className={'profile-wrapper'}>
            <AuthConsumer>
                {({ user }) => (
                    <Card className={'page-wrapper'}>
                        <Title level={3}>Profile</Title>
                        <div>
                            <Text strong>Name: </Text>
                            <Text>{user.name}</Text>
                        </div>
                        <div>
                            <Text strong>Email: </Text>
                            <Text>{user.email || 'NA'}</Text>
                        </div>
                        <div>
                            <Text strong>Phone: </Text>
                            <Text>{user.phone || 'NA'}</Text>
                        </div>
                        <div>
                            <Text strong>Roles: </Text>
                            {
                                (user.user_roles || []).map(({ role }: any) => {
                                    return <Tag>{role ? role.name : 'NA'}</Tag>;
                                })
                            }
                        </div>
                        <Divider />
                        <AccessComponent user={user} />
                    </Card>
                )}
            </AuthConsumer>
        </Card>
    );
}

function AccessComponent({ user }: any) {
    const { t } = useTranslation();
    const { userDepartments, loading: loadingUserDepartments } = useUserDepartments({
        limit: 100,
        user_id: user.id,
    });
    const { userBranches } = useUserBranches({
        limit: 100,
    });
    const { userGroups } = useUserGroups({
        limit: 100,
    });
    return (
        <div>
            <Title level={4}>Hospitals</Title>
            {
                user.action_role === 'superadmin' ?
                    <Tag>{'All Hospitals'}</Tag> : userBranches.map((d: any) => {
                        return <Tag style={{ marginBottom: '5px' }}>{d.branch.name}</Tag>;
                    })
            }

            <Divider />
            <Title level={4}>Departments</Title>
            {
                user.action_role === 'superadmin' ?
                    <Tag>{'All Departments'}</Tag> : userDepartments.map((d: any) => {
                        return <Tag style={{ marginBottom: '5px' }}>{d.department.name}</Tag>;
                    })
            }

            <Divider />
            <Title level={4}>Questionnaire Groups</Title>
            {
                user.action_role === 'superadmin' ?
                    <Tag>{'All Questionnaire Groups'}</Tag> : userGroups.map((d: any) => {
                        if (!d.group) {
                            return <></>;
                        }
                        return <Tag style={{ marginBottom: '5px' }}>{d.group.name}</Tag>;
                    })
            }

        </div>
    );
}
