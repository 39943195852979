import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
  mutation: any;
  questionnaire: any;
  saving: boolean;
  error: any;
};

const MUTATION = gql`
  mutation (  $name: String!, $code: String!, $is_supplementary: Boolean, $group_id: uuid!,$is_follow_up:Boolean!, $content: jsonb!,$is_approved:Boolean!){
    insert_c_data_template_data_one(object: {data: $content, template: {data:{name: $name,is_supplementary: $is_supplementary, code: $code,group_id: $group_id,is_follow_up:$is_follow_up,is_approved:$is_approved}}}) {
      id
    }
  }
`;

const useQuestionnaireCreate: (onCompleted?: any) => ReturnType = (onCompleted) => {
  const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted: onCompleted });
  let questionnaire = null;
  if (!saving && !error && data) {
    questionnaire = data.insert_org_user_groups_one;
  }
  return {
    mutation,
    questionnaire,
    saving,
    error,
  };
};
export default useQuestionnaireCreate;
