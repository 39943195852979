import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    role: any;
    loading: boolean;
    error: any;
    refetch: any;
};

const QUERY = gql`
    query ($id:uuid!){
        c_acl_roles_by_pk(id: $id) {
            name
            created_at
            id
            branch_id
            description
            role_permissions{
                id
                role{
                    name
                    id
                }
                permission_id
                permission{
                    name
                    id
                }
            }
            branch{
                name
                location{
                    city
                }
            }
            created_by{
                name
            }
        }
    }
`;

const useRoleById: (id: string) => ReturnType = (id) => {
    const { data, loading, error, refetch } = useQuery(QUERY, { variables: { id } });
    let role = null;
    if (!loading && !error) {
        const r = data.c_acl_roles_by_pk;
        r.permission_ids = r.role_permissions.map((p: any) => p.permission_id);
        role = r;
    }
    return {
        role,
        refetch,
        loading,
        error,
    };
};
export default useRoleById;
