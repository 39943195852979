import React from "react";
import DoctorListView from "./DoctorListView";
import NotificationComponent from "../../Notification/NotificationComponent";
// import emrNavBarMessageView from '../messageJson';
class MobileRouteViewComponent extends React.Component {
    userRole = sessionStorage.getItem('userRole')
    
    onRouteClick = (event) => {
        this.props.emitEventOnNavClick(event);
    }

    onLogOutEvent = (event) => {
        this.props.emitLogoutEvent(true);
    }

    render() {
        return (
            <React.Fragment>
                <nav className={`sidebar m-sidemenu sidebar-offcanvas ${this.props.mobileNav ? 'active' : ''}`} id="sidebar ">
                    <ul className="nav">
                        {this.props.emrNavBarRoutes && this.props.emrNavBarRoutes.map((indEmrNavBarRoutes, index) =>
                            <React.Fragment key={index}>
                                {indEmrNavBarRoutes.type === "single" && indEmrNavBarRoutes.display && <li>
                                    <a className="nav-link" data-toggle="collapse" href="#!" aria-expanded="false" aria-controls="ui-basic" onClick={this.onRouteClick.bind(this, indEmrNavBarRoutes.route)}>
                                        <span className="menu-title">{indEmrNavBarRoutes.navbarName}</span>
                                        {indEmrNavBarRoutes.key === 'patient-queries' && this.props.openChatCount > 0 && <label className="mt-2">({this.props.openChatCount})</label>}
                                    </a>
                                </li>
                                }
                                {(indEmrNavBarRoutes.type === "multi" && indEmrNavBarRoutes.display) &&
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="collapse" href={`#${(indEmrNavBarRoutes.key).toLowerCase()}`} aria-expanded="false" aria-controls="ui-basic">
                                            <span className="menu-title">{indEmrNavBarRoutes.navbarName}</span>
                                            <i className="menu-arrow"></i>

                                        </a>
                                        <div className="collapse" id={`${(indEmrNavBarRoutes.key).toLowerCase()}`}>
                                            <ul className="nav flex-column sub-menu">
                                                {indEmrNavBarRoutes.options.map((indRouteOptions, jndex) =>
                                                    <React.Fragment key={jndex}>
                                                        <li className="nav-item" onClick={this.onRouteClick.bind(this, indRouteOptions.route)}> <a className="nav-link" >{indRouteOptions.navbarName} </a></li>
                                                    </React.Fragment>
                                                )}
                                            </ul>
                                        </div>
                                    </li>
                                }
                            </React.Fragment>
                        )}
                        {/* {this.props.showDoctorList && <DoctorListView doctorList={this.props.doctorList} getListForDoctor={e => this.props.getListForDoctor(e)}
                    selectedDoc={this.props.selectedDoc}></DoctorListView>} */}
                    </ul>
                    {this.userRole&&this.userRole.indexOf('doctor') !== -1 && false && <NotificationComponent />}
                </nav>

            </React.Fragment>
        );
    }
}

export default MobileRouteViewComponent;
