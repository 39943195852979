import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Radio, Row, Select, Spin } from 'antd';
import { CitySelect, CountrySelect, StateSelect } from 'shared/components/LocationInputs/LocationSelect';
import TextArea from 'antd/es/input/TextArea';
import Text from 'antd/es/typography/Text';
import useBranches from 'shared/hooks/Branches/useBranches';
import useUserBranches from 'shared/hooks/UserBranches/useUserBranches';
import usePatients from 'shared/hooks/Patients/usePatients';
import { RegexNumbersOnly } from 'shared/constants/RegexConstants';
import { EDIT_UHID } from 'shared/constants/Permissions';
import { hasPermissionOf } from 'shared/services/Utility';

const PatientInfoForm: React.FunctionComponent<{ saving: boolean, onCancel?: any, patient?: any, onSubmit: any }> = ({
                                                                                                                         onSubmit,
                                                                                                                         saving,
                                                                                                                         onCancel,
                                                                                                                         patient,
                                                                                                                     }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const {
        userBranches,
        loading: loadingUserBranches,
        refetch: refreshUserBranches,
    } = useUserBranches({ limit: 100 });

    const [fields]: any[] = useState([]);
    const [initialValues, setInitialValues] = useState(patient ? {
        ...patient,
        country_code: patient.location ? patient.location.country_code : null,
        state_code: patient.location ? patient.location.state_code : null,
    } : {} as any);
    const [selectedCountry, setSelectedCountry] = useState(initialValues.country_code as string);
    const [uhid, setUHID] = useState(null as any);
    const { patients: uhidUsers, loading: loadingUHIDUsers, refetch } = usePatients({ e_uhid: 'NA' });
    const [selectedState, setSelectedState] = useState(initialValues.state_code as string);
    const [addNewLocation, setAddNewLocation] = useState(false);
    const [selectedCity, setSelectedCity] = useState(initialValues.address ? initialValues.address.location_id : '' as string);
    const [stateSelector, setStateSelector] = useState(null as any);
    const [citySelector, setCitySelector] = useState(null as any);
    const [form] = Form.useForm();
    useEffect(() => {
            refetch({ e_uhid: uhid || 'NA' });
        }, [uhid],
    );

    useEffect(() => {
            if (userBranches && userBranches.length === 1) {
                form.setFieldsValue({ branch_id: userBranches[0].branch.id });
            }
        }, [userBranches],
    );

    useEffect(() => {
        if (selectedCountry) {
            setStateSelector(
                <StateSelect value={selectedState}
                             addNew={true}
                             onClose={
                                 (data: any) => {
                                     setSelectedCountry(data.country_code);
                                     setSelectedState(data.state_code);
                                     setTimeout(() => {
                                         form.setFieldsValue({
                                             location_id: data.id,
                                             state_code: data.state_code,
                                             city_code: data.city_code,
                                             country_code: data.country_code,
                                         });
                                     }, 1000);
                                 }
                             }
                             onChange={
                                 (e: any) => {
                                     setSelectedCity('');
                                     form.setFieldsValue({ location_id: null });
                                     setSelectedState(e);
                                 }
                             }
                             country_code={selectedCountry} />);
        } else {
            setStateSelector(null);
        }
        form.resetFields(['state_code']);
    }, [selectedCountry]);
    useEffect(() => {
        if (selectedState) {
            setCitySelector(null);
            setTimeout(() => {
                setCitySelector(<CitySelect value={selectedCity}
                                            addNew={true}
                                            onClose={
                                                (data: any) => {
                                                    setSelectedCountry(data.country_code);
                                                    setSelectedState(data.state_code);
                                                    setTimeout(() => {
                                                        form.setFieldsValue({
                                                            location_id: data.id,
                                                            state_code: data.state_code,
                                                            city_code: data.city_code,
                                                            country_code: data.country_code,
                                                        });
                                                    }, 1000);
                                                }
                                            }
                                            onChange={(e: any) => setSelectedCity(e)}
                                            state_code={selectedState} />);
            }, 10);

        } else {
            setCitySelector(null);
        }
    }, [selectedState]);
    return (
        <Form
            size='large'
            form={form}
            fields={fields}
            initialValues={{
                ...initialValues,
            }}
            onFinish={(formValues) => {
                delete formValues['country_code'];
                delete formValues['state_code'];
                onSubmit(formValues);
            }}
            requiredMark={true}
            layout='vertical'
        >
            <Row gutter={[20, 20]}>
                <Col xs={24}>
                    <Text type={'secondary'}>{t('genDict.patientInfo')}</Text>
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <Form.Item
                        label={'First name'}
                        name={'first_name'}
                        rules={[
                            {
                                required: true,
                                message: 'First name required',
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <Form.Item
                        label={'Last Name'}
                        name={'last_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Last name required',
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <Form.Item
                        label={t('patients.uhid')}
                        name={'uhid'}
                        rules={[
                            {
                                required: true,
                                message: 'UHID required',
                            },
                            {
                                validator: (rule, value) => {
                                    return new Promise(async (resolve, reject) => {
                                        if (!value) {
                                            await reject('Patient UHID required');
                                        }
                                        if (!loadingUHIDUsers && uhidUsers.length) {
                                            await reject('Patients already exists with same UHID');
                                        } else {
                                            await resolve('');
                                        }
                                    });
                                },
                            },
                        ]}>

                        <Input disabled={!!patient && !hasPermissionOf(EDIT_UHID)}
                               onBlur={(e: any) => setUHID(e.target.value)} />
                    </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <Form.Item
                        label={'Age (in Years)'}
                        name={'age'}
                        rules={[
                            {
                                required:true,
                                message:"Age is required"
                            }
                        ]}>
                        <InputNumber />
                    </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <Form.Item
                        label={t('patients.gender')}
                        name={'gender'}
                        rules={[
                            {
                                required: true,
                                message: 'Gender is required',
                            },
                        ]}>
                        <Radio.Group>
                            <Radio value={'Male'}>{t('genDict.male')}</Radio>
                            <Radio value={'Female'}>{t('genDict.female')}</Radio>
                            <Radio value={'Others'}>{t('genDict.others')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <Form.Item
                        label={t('patients.email')}
                        name={'email'}
                        rules={[
                            {
                                type: 'email',
                                message: t('patients.email.required'),
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <Form.Item
                        label={t('patients.phone')}
                        name={'phone'}
                        rules={[
                            {
                                required: true,
                                message: 'Phone required',
                            },
                            {
                                pattern: RegexNumbersOnly,
                                message: 'Invalid Phone',
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <Form.Item
                        label={t('patients.alternatePhone')}
                        name={'alternate_phone'}
                        rules={[
                            {
                                pattern: RegexNumbersOnly,
                                message: 'Invalid Phone',
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <Form.Item
                        label={'Hospital'}
                        name={'branch_id'}
                        rules={[
                            { required: true, message: 'Hospital required' },
                        ]}>
                        <Select
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                userBranches?.sort(function(a, b){
                                    let nameA = a.branch.name.toLowerCase(), nameB = b.branch.name.toLowerCase();
                                    if (nameA < nameB) //sort string ascending
                                        return -1;
                                    if (nameA > nameB)
                                        return 1;
                                    return 0; //default return value (no sorting)
                                }).map((b: any) => {
                                    return <Select.Option value={b.branch.id}>{b.branch.name}</Select.Option>;
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>

            </Row>

            <Row gutter={[20, 20]}>
                <Col xs={24}>
                    <Text type={'secondary'}>{t('genDict.address')}</Text>
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <Form.Item
                        label={t('patients.country')}
                        name={'country_code'}
                        rules={[]}>
                        <CountrySelect value={selectedCountry} onChange={(e: any) => setSelectedCountry(e)} />
                    </Form.Item>
                </Col>
                {
                    stateSelector && <Col md={8} sm={12} xs={24}>
                        <Form.Item
                            label={t('patients.state')}
                            name={'state_code'}
                            rules={[]}>
                            {stateSelector}
                        </Form.Item>
                    </Col>
                }

                {
                    citySelector && <Col md={8} sm={12} xs={24}>
                        <Form.Item
                            label={t('patients.city')}
                            name={'location_id'}
                            rules={[]}>
                            {citySelector}
                        </Form.Item>
                    </Col>
                }

                <Col md={8} sm={12} xs={24}>
                    <Form.Item
                        label={t('patients.zipcode')}
                        name={'zipcode'}
                        rules={[]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={24} sm={24} xs={24}>
                    <Form.Item
                        label={t('patients.address')}
                        name={'address'}
                        rules={[]}>
                        <TextArea rows={2} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Divider />
                </Col>
                <Col>
                    <Form.Item>
                        <Button type='default'
                                onClick={onCancel}
                                size={'large'}>
                            {t('genDict.back')}
                        </Button>
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item>
                        <Button type='primary'
                                htmlType='submit'
                                disabled={loadingUHIDUsers}
                                loading={saving}
                                size={'large'}>
                            {t('genDict.submit')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
export default PatientInfoForm;
