import gql from 'graphql-tag';
// @ts-ignore
import { useQuery,useMutation } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import moment from 'moment';
import { GlobalListLimit } from '../../constants/AppConstants';

type ReturnType = {
    mutation: any;
    department: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`mutation MyMutation($payload:String!) {
    generateReport(payload: $payload) {
      status
      url
    }
  }
  
  `

const useExportReport: (filters?: Filters) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted: onCompleted });
    let department = null;
    if (!saving && !error && data) {
        department = data.insert_c_org_user_departments_one;
    }
    return {
        mutation,
        department,
        saving,
        error,
    };
}
export default useExportReport