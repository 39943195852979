import { useMedia } from 'react-use';
import {
    BRANCHES_SELECT, DEPARTMENTS_SELECT,
    ENCOUNTER_TYPES_SELECT,
    ENCOUNTERS_SELECT, GROUPS_SELECT,
    LOCATIONS_SELECT, PATIENTS_ENCOUNTER_SELECT,
    PATIENTS_SELECT, ROLES_SELECT,
    TEMPLATES_SELECT, USERS_SELECT,
} from '../constants/Permissions';
import { logout } from '../contexts/AuthContext';
import {
    BRANCHES_LIST_ROUTE, DEPARTMENTS_LIST_ROUTE,
    ENCOUNTER_TYPES_LIST_ROUTE, GROUPS_LIST_ROUTE, LOCATIONS_LIST_ROUTE,
    PATIENT_REGISTRATION_LIST_ROUTE,
    PROCEDURES_LIST_ROUTE,
    QUESTIONNAIRES_LIST_ROUTE, ROLES_LIST_ROUTE, ROUTE_PAGE_403, USERS_LIST_ROUTE,
} from '../constants/RouteConstants';
import { GlobalListLimit } from '../constants/AppConstants';
import { parse } from 'acorn';

export const useLayoutMedia = (layoutsToVerify: string[]) => {
    let layoutMode: 'Mobile' | 'TabletPortrait' | 'TabletLandscape' | 'Desktop' = 'Desktop';
    if (useMedia('(max-width: 1024px)')) {
        console.log('here');
        layoutMode = 'TabletLandscape';
    }
    if (useMedia('(max-width: 768px)')) {
        layoutMode = 'TabletPortrait';
    }
    if (useMedia('(max-width: 576px)')) {
        layoutMode = 'Mobile';
    }
    return layoutsToVerify.indexOf(layoutMode) > -1;
};

export const hasPermissionOf = (permission: string) => {
    if (!localStorage.getItem('permissions')) {
        return false;
    }
    return JSON.parse(localStorage.getItem('permissions') as string).indexOf(permission) > -1;
};

export const inWords = (num: any) => {
    const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    if ((num = num.toString()).length > 9) return 'overflow';
    let n: any = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    let str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    return str;
};

export const getPaginationLimit = () => {
    let result = GlobalListLimit;
    if (localStorage.getItem('pagination-limit')) {
        result = parseInt(localStorage.getItem('pagination-limit') as string);
    }
    return result;
};
export const setPaginationLimit = (limit: number = GlobalListLimit) => {
    localStorage.setItem('pagination-limit', JSON.stringify(limit));
};
export const parseBulkUsersFromXLSX = (content: any) => {
    const users: any[] = [];
    let index = 2;
    while (content && content['A' + index]) {
        const user: any = {};
        user['name'] = content['A' + index] ? content['A' + index].v : '';
        user['email'] = content['B' + index] && content['B' + index].v ? content['B' + index].v.toLowerCase() : '';
        user['phone'] = content['C' + index] ? content['C' + index].v : '';
        user['gender'] = content['D' + index] ? content['D' + index].v : '';
        user['branch'] = { name: content['E' + index] ? content['E' + index].v : '' };
        user['department'] = { name: content['F' + index] ? content['F' + index].v : '' };
        user['questionnaires'] = content['G' + index] && content['G' + index].v ? content['G' + index].v.split(',').map((a: any) => {
            return { name: a };
        }) : [];
        user['roles'] = content['H' + index] && content['H' + index].v ? content['H' + index].v.split(',').map((a: any) => {
            return { name: a };
        }) : [];
        user['is_doctor'] = content['I' + index] ? content['I' + index].v : '';
        users.push(user);
        index++;
    }
    return users;
};
export const numberFormat = (number: number) => {
    let x = number + '';
    let pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, '$1,$2');
    return x;
};
export const latestAllowedRoute = (): string => {
    // if (!localStorage.getItem('permissions')) {
    //     return false;
    // }
    if (hasPermissionOf(PATIENTS_SELECT))
        return PATIENT_REGISTRATION_LIST_ROUTE;
    else if (hasPermissionOf(PATIENTS_ENCOUNTER_SELECT))
        return PROCEDURES_LIST_ROUTE;
    else if (hasPermissionOf(TEMPLATES_SELECT))
        return QUESTIONNAIRES_LIST_ROUTE;
    else if (hasPermissionOf(ENCOUNTER_TYPES_SELECT))
        return ENCOUNTER_TYPES_LIST_ROUTE;
    else if (hasPermissionOf(LOCATIONS_SELECT))
        return LOCATIONS_LIST_ROUTE;
    else if (hasPermissionOf(BRANCHES_SELECT))
        return BRANCHES_LIST_ROUTE;
    else if (hasPermissionOf(DEPARTMENTS_SELECT))
        return DEPARTMENTS_LIST_ROUTE;
    else if (hasPermissionOf(GROUPS_SELECT))
        return GROUPS_LIST_ROUTE;
    else if (hasPermissionOf(USERS_SELECT))
        return USERS_LIST_ROUTE;
    else if (hasPermissionOf(ROLES_SELECT))
        return ROLES_LIST_ROUTE;
    else {
        return ROUTE_PAGE_403;
    }

    // return JSON.parse(localStorage.getItem('permissions') as string).indexOf(permission) > -1;
};
export const filteredList = (searchKey: string, items: any[]) => {
    if (!searchKey) {
        return items;
    }
    return items.filter((c: any) => {
        let passed = false;
        for (const key in c) {
            const value = c[key];
            if (value && typeof value === 'string' && value.toLowerCase().indexOf(searchKey.toLowerCase()) > -1) {
                passed = true;
            }
        }
        return passed;
    });
};
export const getEpisodeType = (type: string) => {
    const v = [{
        key: 'DAY_CARE',
        name: 'DC',
    }, {
        key: 'IPD',
        name: 'IP',
    }, {
        key: 'OPD',
        name: 'OP',
    }];
    return v.find((a: any) => type === a.key)?.name;
};

export interface Filters {
    [key: string]: string | number | any;
}

export type Filterable = {
    type: 'EXACT' | 'LIKE' | 'UUID';
    key: string
}

export const createFilters = (filterable: Filterable[], filters?: Filters) => {
    const result: any = { where: { _and: [] } };
    if (!filters) {
        filters = {};
        return result;
    }
    filterable = JSON.parse(JSON.stringify(filterable));
    filters = JSON.parse(JSON.stringify(filters));

    filterable.forEach((f: Filterable) => {
        if (filters) {
            if (filters[f.key]) {
                if (f.type === 'LIKE') {
                    result['where']['_and'].push({ [f.key]: { _ilike: `%${filters[f.key]}%` } });
                } else {
                    result['where']['_and'].push({ [f.key]: { _eq: `%${filters[f.key]}%` } });
                }
            }
        }
    });
    return result;
};
export const mapFilters = (filterable: string[] = [], filters: any) => {
    if (!filters) {
        return {};
    }
    filterable.forEach((f) => {
        if (!filters[f]) {
            filters[f] = '';
        }
    });
    return filters;
};

function parseDotNotation(str: string, val: any, obj: any) {
    let currentObj = obj,
        keys = str.split('.'),
        i, l = Math.max(1, keys.length - 1),
        key;

    for (i = 0; i < l; ++i) {
        key = keys[i];
        currentObj[key] = currentObj[key] || {};
        currentObj = currentObj[key];
    }

    currentObj[keys[i]] = val;
    delete obj[str];
}

export function dotToObject(obj: any) {
    for (let key in obj) {
        if (key.indexOf('.') !== -1) {
            parseDotNotation(key, obj[key], obj);
        }
    }
    return obj;
}

export function objectToDot(obj: any) {
    let res: any = {};
    (function recurse(obj, current) {
        for (let key in obj) {
            let value = obj[key];
            let newKey = (current ? current + '.' + key : key);
            if (value && typeof value === 'object') {
                // @ts-ignore
                recurse(value, newKey);
            } else {
                res[newKey] = value;
            }
        }
    })(obj);
    return res;
}
