import * as React from 'react';
import {
    HeartFilled,
} from '@ant-design/icons';
import { Suspense, useEffect, useState } from 'react';

import {
    Layout,
    Typography,
    Alert,
    Row,
    Col,
} from 'antd';
import { useTranslation } from 'react-i18next';

import LoaderOverlay from 'shared/components/LoaderOverlay';

import '../Layout.scss';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { AuthConsumer } from '../../../contexts/AuthContext';
import DesktopNavbar from './DesktopNavbar/DesktopNavbar';

const { ErrorBoundary } = Alert;

const { Content } = Layout;
const { Text } = Typography;

export default function DesktopLayout({
                                          children,
                                      }: any) {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <ErrorBoundary>
            <AuthConsumer>
                {({ hasPermissionOf, user, authStatus }) => (
                    <Layout
                        style={{ minHeight: '100vh' }}
                        className={`desktop-layout`}>
                        <DesktopNavbar authStatus={authStatus} user={user} hasPermissionOf={hasPermissionOf} />
                        <Content className={'content-layout'}>
                            <Suspense fallback={<LoaderOverlay size='large' loading={true} />}>
                                {children}
                            </Suspense>
                        </Content>
                    </Layout>
                )
                }
            </AuthConsumer>
        </ErrorBoundary>
    );
}
