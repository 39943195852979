import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './index.scss';
import { Card, Col, Input, Row, DatePicker, Space, Button, Divider, Form, Alert, notification, Modal } from 'antd';
import { ExclamationCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Text from 'antd/es/typography/Text';
import { Select } from 'antd';
import FormView from 'shared/components/FormView/FormView';
import {
    PrescriptionReviewerApproved,
    PrescriptionReviewerPending,
    PrescriptionReviewerReopen,
    PrescriptionReviewerUpdateRejected,
    PrescriptionReviewStatuses,
} from '../../constants/AppConstants';
import Title from 'antd/es/typography/Title';
import StatusTags from '../StatusTags/StatusTags';
import { PATIENT_ENCOUNTER_REVIEW, PATIENT_ENCOUNTER_UPDATE, REOPEN_APPROVED } from '../../constants/Permissions';
import { hasPermissionOf } from '../../services/Utility';
import moment from 'moment';
import addOrUpdatePrescription from '../../hooks/PatientPrescriptions/addOrUpdatePrescription';

const { TextArea } = Input;
const { confirm } = Modal;
const { Option } = Select;
type Props = {
    prescription: any
    setComponentRef: any
    encounterId: any
    onContinueEditing: any
    dischargeDate: any
    episodeType: any
    refresh: any
}

const ProcedureView: React.FunctionComponent<Props> = ({
                                                           onContinueEditing,
                                                           episodeType,
                                                           dischargeDate,
                                                           encounterId,
                                                           setComponentRef,
                                                           prescription,
                                                           refresh,
                                                       }) => {
    const { t } = useTranslation();
    const routeParams = useParams();
    const [data, setData] = useState(prescription.data.data);
    const [status, setStatus] = useState(null as any);
    useEffect(() => {
        setData(null);
        setTimeout(() => {
            setData(JSON.parse(JSON.stringify(prescription.data.data)));
        }, 10);
    }, [prescription]);

    const {
        mutation: addOrUpdatePrescriptionMutation,
        saving: addingOrUpdatingPrescription,
        error,
    } = addOrUpdatePrescription((e: any) => {
        if (e) {
            if (e.add_or_update_prescription && e.add_or_update_prescription.error) {
                notification.error({ message: e.add_or_update_prescription.error });
                return;
            } else {
                history.go(0);
            }
        }
    });
    return (
        <div className={'procedures-view-component'}>

            <div ref={el => setComponentRef(el)}>
                {
                    data && data.sections && data.sections.length && <FormView prescriptionId={prescription.id} />
                }
            </div>

            {/*TODO roleName === 'Reviewer' &&*/}
            {
                hasPermissionOf(PATIENT_ENCOUNTER_REVIEW) && (!prescription?.template?.is_supplementary) && (prescription.review.latest_reviewer_status && prescription.review.latest_reviewer_status.status === PrescriptionReviewerPending) ? <>
                    <Divider />
                    <Card className={'review-form'}>
                        <Form
                            size='large'
                            fields={[]}
                            onFinish={(status === PrescriptionReviewerApproved && (episodeType !== 'OPD') && !dischargeDate) ? (formValues) => confirm({
                                icon: <ExclamationCircleOutlined />,
                                title: 'Approve Procedure',
                                okText: 'Yes',
                                cancelText: 'Cancel',
                                content: 'Are you sure, you want to approve this procedure without discharge date!',// TODO
                                okButtonProps: { loading: addingOrUpdatingPrescription, danger: true },
                                onOk: async () => {
                                    const reviewerStatus = {
                                        prescription_review_id: prescription.review.id,
                                        encounter_id: encounterId,
                                        ...formValues,
                                    };
                                    addOrUpdatePrescriptionMutation({
                                        variables: reviewerStatus,
                                    });
                                },
                            }) : (formValues) => {
                                const reviewerStatus = {
                                    prescription_review_id: prescription.review.id,
                                    encounter_id: encounterId,
                                    ...formValues,
                                };
                                addOrUpdatePrescriptionMutation({
                                    variables: reviewerStatus,
                                });
                            }}
                            requiredMark={true}
                            layout='vertical'
                        >
                            <Row>
                                <Col>
                                    <Text>{t('genDict.addRemarksAndStatus')}</Text>
                                </Col>
                            </Row>
                            <Divider style={{ margin: '5px 0 0 0' }} />
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={t('procedureView.status')}
                                        name={'status'}
                                        rules={[
                                            { required: true, message: 'Status Required' },
                                        ]}>
                                        <Select onChange={(e) => setStatus(e)} placeholder={t('genDict.select')}>
                                            {
                                                PrescriptionReviewStatuses.map((status) => {
                                                    if (status.cannotSelect) {
                                                        return <></>;
                                                    }
                                                    return <Option value={status.key}>{status.name}</Option>;
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={t('procedureView.remarks')}
                                        name={'remarks'}
                                        rules={[{
                                            required: status === PrescriptionReviewerUpdateRejected ? true : false,
                                            message: `${t('procedureView.remarks')} required`,
                                        }]}>
                                        <TextArea />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[20, 20]}>
                                {/*{*/}
                                {/*  status === PrescriptionReviewerApproved && !dischargeDate && episodeType !== 'OPD' &&*/}
                                {/*  <Col span={24}>*/}
                                {/*    <Alert*/}
                                {/*      message='Patient discharge date required to approve the procedure'*/}
                                {/*      type='warning'*/}
                                {/*    />*/}
                                {/*  </Col>*/}
                                {/*}*/}
                                {
                                    hasPermissionOf(PATIENT_ENCOUNTER_UPDATE) && <Col>
                                        <Button
                                            disabled={addingOrUpdatingPrescription}
                                            onClick={onContinueEditing} type={'default'}>Continue Editing</Button>
                                    </Col>
                                }
                                <Col>
                                    <Button
                                        loading={addingOrUpdatingPrescription}
                                        htmlType={'submit'} type={'primary'}>Save</Button>
                                </Col>
                            </Row>
                        </Form>
                        <Divider />

                    </Card>
                </> : (


                    (!prescription?.template?.is_supplementary) && prescription.review && prescription.review.latest_reviewer_status ?
                        <div>

                            <Divider />
                            <Title level={5}>Latest Review</Title>
                            <div>
                                <Text
                                    strong>Status: </Text> <StatusTags
                                type={prescription.review.latest_reviewer_status.status} />
                            </div>
                            {prescription.review.latest_reviewer_status.remarks &&
                            <div>
                                <Text strong>Remarks: </Text>
                                <Text>{prescription.review.latest_reviewer_status.remarks}</Text>
                            </div>
                            }
                            {
                                prescription.review.latest_reviewer_status.created_by ? <div>
                                    <Text
                                        strong> {PrescriptionReviewStatuses.find((d: any) => d.key === prescription.review.latest_reviewer_status.status)?.name} by: </Text>
                                    <Text>{prescription.review.latest_reviewer_status.created_by ? prescription.review.latest_reviewer_status.created_by.name : 'NA'} on <small>{moment(prescription.review.latest_reviewer_status.created_at).format('DD-MM-YYYY')}</small></Text>
                                </div> : (
                                    prescription.review.latest_editor_status.created_by && <div>
                                        <Text
                                            strong> {PrescriptionReviewStatuses.find((d: any) => d.key === prescription.review.latest_reviewer_status.status)?.name} by: </Text>
                                        <Text>{prescription.review.latest_editor_status.created_by ? prescription.review.latest_editor_status.created_by.name : 'NA'} on {moment(prescription.review.latest_reviewer_status.created_at).format('DD-MM-YYYY')}</Text>
                                    </div>
                                )
                            }
                            <Divider />
                            {
                                hasPermissionOf(REOPEN_APPROVED) && !(prescription.review.latest_reviewer_status && prescription.review.latest_reviewer_status.status === PrescriptionReviewerPending) && (prescription.review.latest_reviewer_status && prescription.review.latest_reviewer_status.status !== PrescriptionReviewerReopen) &&
                                <Card className={'review-form'}>
                                    <Form
                                        size='large'
                                        fields={[]}
                                        onFinish={(status === PrescriptionReviewerApproved && (episodeType !== 'OPD') && !dischargeDate) ? (formValues) => confirm({
                                            icon: <ExclamationCircleOutlined />,
                                            title: 'Approve Procedure',
                                            okText: 'Yes',
                                            cancelText: 'Cancel',
                                            content: 'Are you sure, you want to approve this procedure without discharge date!',// TODO
                                            okButtonProps: { loading: addingOrUpdatingPrescription, danger: true },
                                            onOk: async () => {
                                                const reviewerStatus = {
                                                    prescription_review_id: prescription.review.id,
                                                    encounter_id: encounterId,
                                                    ...formValues,
                                                };
                                                addOrUpdatePrescriptionMutation({
                                                    variables: reviewerStatus,
                                                });
                                            },
                                        }) : (formValues) => {
                                            const reviewerStatus = {
                                                prescription_review_id: prescription.review.id,
                                                encounter_id: encounterId,
                                                ...formValues,
                                            };
                                            addOrUpdatePrescriptionMutation({
                                                variables: reviewerStatus,
                                            });
                                        }}
                                        requiredMark={true}
                                        layout='vertical'
                                    >
                                        <Row>
                                            <Col>
                                                <Text>{t('Reject and Reopen with remark')}</Text>
                                            </Col>
                                        </Row>
                                        <Divider style={{ margin: '5px 0 0 0' }} />
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={t('procedureView.status')}
                                                    name={'status'}
                                                    rules={[
                                                        { required: true, message: 'Status Required' },
                                                    ]}>
                                                    <Select onChange={(e) => setStatus(e)}
                                                            placeholder={t('genDict.select')}>
                                                        <Option value={PrescriptionReviewerReopen}>Reopen</Option>;
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={t('procedureView.remarks')}
                                                    name={'remarks'}
                                                    rules={[{
                                                        required: status === PrescriptionReviewerReopen,
                                                        message: `${t('procedureView.remarks')} required`,
                                                    }]}>
                                                    <TextArea />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={[20, 20]}>
                                            {/*{*/}
                                            {/*  status === PrescriptionReviewerApproved && !dischargeDate && episodeType !== 'OPD' &&*/}
                                            {/*  <Col span={24}>*/}
                                            {/*    <Alert*/}
                                            {/*      message='Patient discharge date required to approve the procedure'*/}
                                            {/*      type='warning'*/}
                                            {/*    />*/}
                                            {/*  </Col>*/}
                                            {/*}*/}
                                            {/*{*/}
                                            {/*  hasPermissionOf(PATIENT_ENCOUNTER_UPDATE) && <Col>*/}
                                            {/*    <Button*/}
                                            {/*      disabled={addingOrUpdatingPrescription}*/}
                                            {/*      onClick={onContinueEditing} type={'default'}>Continue Editing</Button>*/}
                                            {/*  </Col>*/}
                                            {/*}*/}
                                            <Col>
                                                <Button
                                                    loading={addingOrUpdatingPrescription}
                                                    htmlType={'submit'} type={'primary'}>Save</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Divider />

                                </Card>
                            }
                        </div> : <></>

                )
            }

        </div>
    );
};

export default ProcedureView;
