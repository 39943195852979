import React from "react";
import ValidationComponent from "./ValidationComponent";
import { Tooltip, Select, Space, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import "./tooltip.css"
const { Option } = Select;


class SelectComponent extends React.Component {

    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue = (event) => {
        this.props.emitTextboxValue({ name: this.props.name, value: event, type: 'selection' })
    }

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str) {
        if (!str || !str.trim()) {
            return false;
        }
        return true
    }

    render() {
        const { name, placeholder, value, label, labelSize,
            size, options, offset, info, viewType, validation } = this.props;
        return (
            <React.Fragment>
                {labelSize &&
                    <>
                        <Col span={`${labelSize * 2}`}
                            style={{
                                textAlign: "left",
                                paddingRight: 10,
                                display: this.checkForLabelValue(label) ? '' : 'none'
                            }}>
                            <label ant-form-item-label for="basic_username" className="ant-form-item"
                                style={{ color: "#000000d9" }}>
                                {/* ant-form-item-required */}
                                {label}{validation && validation.required &&
                                    <span style={{ color: '#ff4d4f' }}>*</span>
                                } {info &&
                                    <Tooltip title={info}>
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            </label>
                        </Col>
                    </>
                }

                <Col span={`${size * 2}`} style={{ marginBottom: '2rem' }}>
                    {!labelSize && <label style={{
                        display: this.checkForLabelValue(label) ? '' : 'none',
                        paddingRight: 10, color: "#000000d9"
                    }}>
                        {label} {info &&
                            <Tooltip title={info}>
                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                        }</label>}
                    {viewType ? (
                        <>
                            {value}
                        </>
                    ) : (
                        <>
                            <Select
                                className="form-control"
                                style={{ width: "100%" }}
                                name={name}
                                value={value}
                                onChange={this.changeValue}>
                                <Option value="">{placeholder}</Option >
                                {options &&
                                    <React.Fragment>
                                        {options.map((indOption, index) =>
                                            <Option key={index} value={indOption.value}>{indOption.value}</Option >
                                        )
                                        }
                                    </React.Fragment>
                                }
                            </Select >
                        </>
                    )

                    }

                    {this.props.validation && <ValidationComponent value={value === placeholder ? "" : value} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)} />}
                </Col>
                {offset &&
                    <div className={offset}> </div>
                }
            </React.Fragment>
        );
    }
}

export default SelectComponent;
