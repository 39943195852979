import React from 'react';
// component imports 
import ID from '../Common/ID';
import RowViewComponent from './Rows/RowViewComponent';
import CompactTableRowComponent from './Rows/CompactTableRowComponent';
import {
    Form, Col, Row, Button, Collapse, Divider, Space, notification,
} from 'antd';

const { Panel } = Collapse;
import TextEditor from '../Common/TextEditor';

var Parser = require('expr-eval').Parser;
const parser = new Parser();

class SectionViewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicFormData: this.props.dynamicFormData,
            viewType: this.props.viewType ? this.props.viewType : '',
            suggestionList: [],
            show: false,
            suggestionIndexDetails: null,
            showPatient: false,
            fieldConfig: null,
            errorStatus: false,
            patientData: null,
            checkFormTrigger: 0,
            isDirty: false,
            crashSavedData: null,
            isLoadRx: false,
            selectedRxSection: null,
            sectionsList: [],
            rxIndex: '',
            alert: {
                show: false,
                type: '',
                title: '',
                confirmBtnText: ``,
                text: ``,
                alertType: '',
            },
            formulaConfig: [],
            conditionalHideConfig: [],
            sectionRowErrorArray: [],
            requiredFields: {},
            rowConfig: null,
            groupConfig: null,
            activeTabs: this.checkForActiveKeys(),
            isView: this.props.isView ? this.props.isView : false,
        };
        this.checkForUiDataDicWRTColumns();

        //NOTE: To map the default values in case of Euro score
        if(this.props.currentSectionId){ //<--To show the changes only in edit not not in template builder 
            this.props.dynamicFormData.sections.map((indSection, index)=>{
                if(indSection.type === "euro_Calculator"){
                    indSection.rows.map((indRowData, row_index) =>{
                        if(indRowData.columns.length>1 ){
                            indRowData.columns.forEach((indColData,col_index)=>{
                                if(col_index==0){ // In Euroscore 2 columns should be there , second column value will be calculated
                                    this.setEuroScoreValues(row_index, index, col_index, indColData)  
                                }
        
                            })
                        }
                        
                    })
                }
                
            })
        }
       
    }
    checkForUiDataDicWRTColumns() {
        if (!this.state.dynamicFormData) {
            return;
        }
        this.mapUiDataDicToColumnData();
    }

    mapUiDataDicToColumnData() {
        let sectionsData = this.state.dynamicFormData.sections;
        if (!sectionsData || sectionsData.length === 0) {
            return;
        }
        for (let i = 0; i < this.state.dynamicFormData.sections.length; i++) {
            let uiDataDic = this.state.dynamicFormData.sections[i].uiDataDic;
            for (let j = 0; j < this.state.dynamicFormData.sections[i].rows.length; j++) {
                if (this.state.dynamicFormData.sections[i].rows[j].type === 'row' || this.state.dynamicFormData.sections[i].rows[j].type === 'compactRow') {
                    this.mapTypeRowDataToDFdata(uiDataDic, i, j);
                } else if (this.state.dynamicFormData.sections[i].rows[j].type === 'group') {
                    this.mapTypeGroupDataToDFdata(uiDataDic, i, j);
                } else if (this.state.dynamicFormData.sections[i].rows[j].type === 'subSection') {
                    this.mapTypeGroupDataToDFdata(uiDataDic, i, j);
                }
            }
        }
    }

    mapTypeRowDataToDFdata(uiDataDic, i, j) {
        for (let k = 0; k < this.state.dynamicFormData.sections[i].rows[j].columns.length; k++) {
            let index = 0;
            const { dynamicFormData, requiredFields } = this.state;
            let indColumn = this.state.dynamicFormData.sections[i].rows[j].columns[k];
            if (indColumn.name === 'name' && (indColumn.type === 'textbox' || indColumn.type === 'textarea' || indColumn.type === 'autoComplete' || indColumn.type === 'typeahead')) {
                dynamicFormData.sections[i].rows[j]['ismultiple'] = true;
                if (!dynamicFormData.sections[i].rows[j]['index']) {
                    dynamicFormData.sections[i].rows[j]['index'] = index;
                    index = index + 1;
                }

                dynamicFormData.sections[i].rows[j] = JSON.parse(JSON.stringify(dynamicFormData.sections[i].rows[j]));
                this.setState({ dynamicFormData });
            };

            let uiDatakey = this.state.dynamicFormData.sections[i].rows[j].columns[k].uiData;
            if (!uiDatakey) {
                continue;
            }
            for (let key in uiDataDic) {
                if (key === uiDatakey) {
                    dynamicFormData.sections[i].rows[j].columns[k]['options'] = JSON.parse(JSON.stringify(uiDataDic[key].options));
                    this.setState({ dynamicFormData });
                }
            }
        }
    }

    checkIfConfigIsEmpty(data) {
        let config = data.config;
        if (!config || Object.keys(config).length === 0) {
            return true;
        }
        return false;
    }

    mapTypeGroupDataToDFdata(uiDataDic, i, j) {
        try {

            for (let k = 0; k < this.state.dynamicFormData.sections[i].rows[j].children.length; k++) {
                let index = 0;
                for (let l = 0; l < this.state.dynamicFormData.sections[i].rows[j].children[k].columns.length; l++) {
                    const { dynamicFormData } = this.state;
                    let indColumn = this.state.dynamicFormData.sections[i].rows[j].children[k].columns[l];
                    if (indColumn.name === 'name' && (indColumn.type === 'textbox' || indColumn.type === 'textarea' || indColumn.type === 'autoComplete' || indColumn.type === 'typeahead')) {
                        dynamicFormData.sections[i].rows[j].children[k]['ismultiple'] = true;
                        if (!dynamicFormData.sections[i].rows[j].children[k]['index']) {
                            dynamicFormData.sections[i].rows[j].children[k]['index'] = index;
                            index = index + 1;
                        }

                        dynamicFormData.sections[i].rows[j].children[k] = JSON.parse(JSON.stringify(dynamicFormData.sections[i].rows[j].children[k]));
                        this.setState({ dynamicFormData });
                    }
                    let uiDatakey = this.state.dynamicFormData.sections[i].rows[j].children[k].columns[l].uiData;
                    if (!uiDatakey) {
                        continue;
                    }
                    for (let key in uiDataDic) {
                        if (key === uiDatakey) {
                            dynamicFormData.sections[i].rows[j].children[k].columns[l]['options'] = JSON.parse(JSON.stringify(uiDataDic[key].options));
                            this.setState({ dynamicFormData });
                        }
                    }
                }
            }
        } catch (err) {
            console.log(err);
            this.logErrorToService(err);
        }
    }

    getEmitedHeaderCollapseEvent = (event) => {
        if (!event) {
            console.log(`---- No formname for this event!!! ----`);
            return;
        }
        let formname = event;
        for (let index in this.state.dynamicFormData.sections) {
            if (this.state.dynamicFormData.sections[index].formname === formname) {
                const { dynamicFormData } = this.state;
                dynamicFormData.sections[index].collapse = !this.state.dynamicFormData.sections[index].collapse;
                this.setState({ dynamicFormData });
                return;
            }
        }
    };

    getSuggestionData = (selectionSection, rowIndex, sectionIndex, e, str) => {
        const allowedTypes = ['drugs', 'diagnosis', 'symptoms', 'investigation', 'investigations', 'complaints', 'advice', 'vitals'];
        this.checkForFilledSections(allowedTypes, selectionSection, rowIndex, sectionIndex, e, str);
    };

    checkForFilledSections(allowedTypes, selectionSection, rowIndex, sectionIndex, e, str) {
        let { dynamicFormData } = this.state;
        let finalPayloadData = {};
        for (let index in dynamicFormData.sections) {
            let indSection = dynamicFormData.sections[index];
            // if (indSection.formname === selectionSection) {
            //    continue;
            // }
            if (indSection.status === 'filled' && allowedTypes.indexOf(indSection.formname) !== -1) {
                // finalPayloadData[indSection.formname]
                let indSectionName = (indSection.formname === 'complaints') ? 'symptoms' : (indSection.formname === 'testRequired') ? 'investigations' : indSection.formname;
                let valueStr = '';
                for (let rowIndex in indSection.rows) {
                    let indRow = indSection.rows[rowIndex];
                    if (indRow.type === 'row') {

                        for (let colIndex in indRow.columns) {
                            let indCol = indRow.columns[colIndex];
                            if (indSection.formname === 'vitals') {
                                valueStr = indCol.value ? valueStr + `${indCol.label}:${indCol.value} ` + `${(colIndex === indRow.columns.length - 1) ? '' : ','}` : valueStr + '';
                            } else {
                                valueStr = indCol.value ? valueStr + indCol.value + `${(colIndex === indRow.columns.length - 1) ? '' : ','}` : valueStr + '';
                            }

                        }
                    }
                }
                str = str.substring(0, str.length - 1);
                if (!valueStr || valueStr == str) {
                    continue;
                }
                let valArr = (valueStr.indexOf(',') !== -1) ? valueStr.split(',') : [valArr];
                valArr = valArr.filter(e => e !== str && e !== '');
                finalPayloadData[indSectionName] = valArr;
            }
        }
        this.fetchAndSaveSuggestions(finalPayloadData, this.props.template_id, selectionSection, rowIndex, sectionIndex, e, str);
    }

    updateChildFieldConfigData(rowIndex, sectionIndex, childIndex, columnIndex) {
        let { fieldConfig } = this.state;
        fieldConfig = {
            type: 'group',
            sectionIndex: sectionIndex,
            rowIndex: rowIndex,
            childIndex: childIndex,
            columnIndex: columnIndex,
        };
        this.setState({ fieldConfig });

    }

    updateFieldConfigData(rowIndex, sectionIndex, columnIndex) {
        let { fieldConfig } = this.state;
        fieldConfig = {
            type: 'row',
            sectionIndex: sectionIndex,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
        };
        this.setState({ fieldConfig }, () => console.log(JSON.stringify(this.state.fieldConfig)));
    }

    getEmitedChildFormalLocalVar = (rowIndex, sectionIndex, childIndex, columnIndex, event) => {
        console.log(rowIndex, sectionIndex, childIndex, columnIndex, event);
        if (!event) {
            return;
        }
        try {
            let childrenData = [...this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children];
            console.log(childrenData);
            let localvarArray = event.localvar.split(',');
            let data = {};
            for (let i = 0; i < localvarArray.length; i++) {
                data[localvarArray[i]] = '';
            }

            for (let i = 0; i < localvarArray.length; i++) {
                let indLocalVar = localvarArray[i];
                for (let j = 0; j < childrenData.length; j++) {
                    if (childIndex === j) {
                        continue;
                    }
                    let indChild = childrenData[j];
                    for (let k = 0; k < indChild.columns.length; k++) {
                        let indColumn = indChild.columns[k];
                        if (!indColumn.colId) {
                            continue;
                        }
                        if (indColumn.colId === indLocalVar) {
                            data[indLocalVar] = indColumn.value;
                        }
                    }
                }
            }
            let computedValue = this.mapFormulaWithLocalVarValue(event.formula, data);
            if (!computedValue && computedValue !== '') {
                return;
            }
            const { dynamicFormData } = this.state;
            dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex].value = computedValue !== '' ? computedValue.toFixed(3) : '';

            this.setState({ dynamicFormData }, () => this.updateChildFieldConfigData(rowIndex, sectionIndex, childIndex, columnIndex));
        } catch (err) {
            console.log(err);
            this.logErrorToService(err);
        }
    };

    getEmitedFormalLocalVar = (rowIndex, sectionIndex, columnIndex, event) => {
        console.log('eventdata: - ' + 'rowIndex: ' + rowIndex + ',sectionIndex:-' + sectionIndex + 'columnIndex:- ' + columnIndex, event);
        console.log(this.state.dynamicFormData);
        if (!event) {
            return;
        }
        let rowsData = [...this.state.dynamicFormData.sections[sectionIndex].rows];
        let localvarArray = event.localvar.split(',');
        let data = {};
        for (let i = 0; i < localvarArray.length; i++) {
            data[localvarArray[i]] = '';
        }
        for (let i = 0; i < localvarArray.length; i++) {
            let indLocalVar = localvarArray[i];
            for (let j = 0; j < rowsData.length; j++) {
                let indRow = rowsData[j];
                for (let k = 0; k < indRow.columns.length; k++) {
                    let indColumn = indRow.columns[k];
                    if (!indColumn.colId) {
                        continue;
                    }
                    if (indColumn.colId === indLocalVar) {
                        data[indLocalVar] = indColumn.value;
                    }
                }
            }
        }
        let computedValue = this.mapFormulaWithLocalVarValue(event.formula, data);
        if (!computedValue && computedValue !== '') {
            return;
        }
        const { dynamicFormData } = this.state;
        dynamicFormData.sections[sectionIndex].rows[rowIndex].columns[columnIndex].value = computedValue !== '' ? computedValue.toFixed(3) : '';

        this.setState({ dynamicFormData }, () => this.updateFieldConfigData(rowIndex, sectionIndex, columnIndex));
    };

    setColumnsWithColIdToEmitOnUpdate = (sectionIndex, e) => {
        let { formulaConfig } = this.state;
        let config = { ...e, sectionIndex };
        let repeatIdx = formulaConfig.findIndex(c => {
            return ((!config.childIndex && c.sectionIndex === config.sectionIndex && c.colIndex === config.colIndex && c.rowIndex === config.rowIndex) ||
                (config.childIndex && c.sectionIndex === config.sectionIndex && c.colIndex === config.colIndex && c.rowIndex === config.rowIndex && c.childIndex === config.childIndex));
        });
        if (repeatIdx !== -1) formulaConfig.splice(repeatIdx, 1);
        formulaConfig.push(config);
        this.setState({ formulaConfig });
    };

    changeFormulaDependentValue = (target) => {
        if (target.childIndex || target.childIndex === 0) {
            this.getEmitedChildFormalLocalVar(target.rowIndex, target.sectionIndex, target.childIndex, target.colIndex, {
                localvar: target.localvar.join(),
                formula: target.formula,
            });
        } else {
            this.getEmitedFormalLocalVar(target.rowIndex, target.sectionIndex, target.colIndex, {
                localvar: target.localvar.join(),
                formula: target.formula,
            });
        }
    };

    extractFirstText(str) {
        const matches = str.match(/"(.*?)"/);
        return matches ? matches[1] : str;
    }

    mapFormulaWithLocalVarValue(formula, data) {
        if (!data || !formula) {
            return;
        }
        let newFormula = '';
        for (let key in data) {
            if (!data[key]) {
                return '';
            }
            // formula= formula.split(`${key}`).join(data[key])
        }
        try {
            let expr = parser.parse(formula);
            return expr.evaluate(data);
        } catch (error) {
            console.log('wrong values entered in formula field');
        }
    }

    getEmitedDeleteRowObjectData = (rowIndex, sectionIndex, columnIndex, event) => {
        let rowsData = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows));
        let data = rowsData.splice(rowIndex, 1);
        const { dynamicFormData } = this.state;
        dynamicFormData.sections[sectionIndex].rows = JSON.parse(JSON.stringify(rowsData));
        this.setState({ dynamicFormData }, () => this.updateFieldConfigData(rowIndex, sectionIndex, columnIndex));
        console.log(this.state.dynamicFormData);

    };

    getEmitedChildAddNewRowEvent = (rowIndex, sectionIndex, childIndex, columnIndex) => {
        try {
            let childrenData = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children));
            childrenData[childIndex].index = childIndex + 1;
            childrenData[childIndex].rowId = ID.uuid();
            if (childrenData[childIndex].hasOwnProperty('index')) {
                childrenData[childIndex]['index'] = JSON.parse(JSON.stringify(childrenData[childIndex]['index'] + 1));
            }
            childrenData[childIndex].status = 'empty';
            for (let i = 0; i < childrenData[childIndex].columns.length; i++) {
                if (childrenData[childIndex].columns[i].type === 'header') {
                    childrenData[childIndex].columns[i].readOnly = false;
                }
                childrenData[childIndex].columns[i].value = '';
            }
            const { dynamicFormData } = this.state;
            dynamicFormData.sections[sectionIndex].rows[rowIndex].children.splice(childIndex + 1, 0, childrenData[childIndex]);
            this.setState({ dynamicFormData });
            this.updateChildFieldConfigData(rowIndex, sectionIndex, childIndex, columnIndex);
            // updateChildFieldConfigData
        } catch (err) {
            console.log(err);
            this.logErrorToService(err);
        }
    };

    getEmitedChildDeleteRowObjectData = (rowIndex, sectionIndex, childIndex, columnIndex) => {
        try {
            let childrenData = [...this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children];
            let data = childrenData.splice(childIndex, 1);
            const { dynamicFormData } = this.state;
            dynamicFormData.sections[sectionIndex].rows[rowIndex].children = childrenData;
            this.setState({ dynamicFormData });
            console.log(this.state.dynamicFormData);
            this.updateChildFieldConfigData(rowIndex, sectionIndex, childIndex, columnIndex);
        } catch (err) {
            console.log(err);
            this.logErrorToService(err);
        }
    };


    getEmitedAddNewRowEvent = (rowIndex, sectionIndex, columnIndex, event) => {
        // if (!event) {
        //    return;
        // }
        let rowsData = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows));
        if (!rowsData || rowsData[rowIndex].type === 'subSection') {
            return;
        }

        try {
            rowsData[rowIndex].index = rowIndex + 1;
            rowsData[rowIndex].rowId = ID.uuid();
            rowsData[rowIndex].status = 'empty';
            if (rowsData[rowIndex].hasOwnProperty('index')) {
                rowsData[rowIndex]['index'] = JSON.parse(JSON.stringify(rowsData[rowIndex]['index'] + 1));
            }
            if (rowsData[rowIndex]) {
                if (rowsData[rowIndex].type === 'row' && rowsData[rowIndex].columns) {
                    let columns = rowsData[rowIndex].columns;
                    for (let index in columns) {
                        columns[index]['value'] = '';
                    }
                    rowsData[rowIndex].columns = columns;
                    // [columnIndex] && rowsData[rowIndex].columns[columnIndex]['value']
                    // rowsData[rowIndex].columns[columnIndex]['value'] = '';
                } else if (rowsData[rowIndex].type === 'group') {
                    let childrenData = rowsData[rowIndex].children;
                    for (let j = 0; j < childrenData.length; j++) {
                        for (let i = 0; i < childrenData[j].columns.length; i++) {
                            childrenData[j].columns[i].value = '';
                            childrenData[j].columns[i].values = [];
                        }
                    }
                    rowsData[rowIndex].children = childrenData;
                } else if (rowsData[rowIndex].type === 'compactRow' && rowsData[rowIndex].columns) {
                    rowsData[rowIndex].columns[0]['readOnly'] = false;
                    for (let index in rowsData[rowIndex].columns) {
                        rowsData[rowIndex].columns[index]['value'] = '';
                    }
                    // rowsData[rowIndex].columns[columnIndex]['value'] = '';
                }
            }
            const { dynamicFormData } = this.state;
            if (rowsData[rowIndex].type === 'compactRow') {
                dynamicFormData.sections[sectionIndex].rows.push(rowsData[rowIndex]);
            } else {
                if (dynamicFormData.sections[sectionIndex].formname === 'drugs') {
                    dynamicFormData.sections[sectionIndex].rows.push(rowsData[rowIndex]);
                } else {
                    dynamicFormData.sections[sectionIndex].rows.splice(rowIndex + 1, 0, rowsData[rowIndex]);
                }
            }
            this.setState({ dynamicFormData }, () => {
                if (dynamicFormData.sections[sectionIndex].rows[rowIndex].type === 'group') this.updateChildFieldConfigData(rowIndex + 1, sectionIndex, '', columnIndex);
                else this.updateFieldConfigData(rowIndex + 1, sectionIndex, columnIndex);
            });
        } catch (err) {
            console.log(err);
            this.logErrorToService(err);
        }
    };
    getEmitedCloneRowEvent = (rowIndex, sectionIndex, columnIndex, event) => {
        if (!event) {
            return;
        }
        let rowsData = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows));
        if (!rowsData || rowsData[rowIndex].type === 'subSection' || !rowsData[rowIndex]) {
            return;
        }

        rowsData[rowIndex].index = rowIndex + 1;
        rowsData[rowIndex].rowId = ID.uuid();
        if (rowsData[rowIndex].hasOwnProperty('index')) {
            rowsData[rowIndex]['index'] = JSON.parse(JSON.stringify(rowsData[rowIndex]['index'] + 1));
        }
        const { dynamicFormData } = this.state;
        if (rowsData[rowIndex].type === 'compactRow') {
            dynamicFormData.sections[sectionIndex].rows.push(rowsData[rowIndex]);
        } else {
            dynamicFormData.sections[sectionIndex].rows.splice(rowIndex + 1, 0, rowsData[rowIndex]);
        }
        this.setState({ dynamicFormData }, () => {
            if (dynamicFormData.sections[sectionIndex].rows[rowIndex].type === 'group') this.updateChildFieldConfigData(rowIndex + 1, sectionIndex, '', columnIndex);
            else this.updateFieldConfigData(rowIndex + 1, sectionIndex, columnIndex);
        });

    };

    getEmitedAddNewTableRowEvent = (rowIndex, sectionIndex, event) => {
        let rowsData = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows));
        if (!rowsData || rowsData[rowIndex].type === 'subSection') {
            return;
        }
        rowsData[rowIndex].index = rowIndex + 1;
        rowsData[rowIndex].status = 'empty';
        if (rowsData[rowIndex])
            if (rowsData[rowIndex].type === 'compactRow' && rowsData[rowIndex].columns) {
                rowsData[rowIndex].columns[0]['readOnly'] = false;
                for (let index in rowsData[rowIndex].columns) {
                    rowsData[rowIndex].columns[index]['value'] = '';
                }
                // rowsData[rowIndex].columns[columnIndex]['value'] = '';
            }
        const { dynamicFormData } = this.state;
        if (rowsData[rowIndex].type === 'compactRow') {
            dynamicFormData.sections[sectionIndex].rows.splice(rowIndex + 1, 0, rowsData[rowIndex]);
        }
        this.setState({ dynamicFormData });
        this.updateFieldConfigData(rowIndex, sectionIndex, '');
    };

    updateChildCompactTableRow(sectionIndex, rowIndex, childIndex, columnIndex, event) {
        try {
            let indChild = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex]));
            for (let index in indChild.columns) {
                if (indChild.columns[index].type === 'header') {
                    indChild.columns[index].readOnly = false;
                }
                indChild.columns[index].value = '';
            }
            let { dynamicFormData } = this.state;
            indChild.columns[columnIndex].value = event.value;
            dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex].value = event.value;
            dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns, 'column');
            dynamicFormData.sections[sectionIndex].rows[rowIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows[rowIndex].children, 'compactTable');
            dynamicFormData.sections[sectionIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows, 'row');
            this.setState({ dynamicFormData });
            return;
        } catch (err) {
            console.log(err);
            this.logErrorToService(err);
        }
    }

    getEmitedChildTextboxValue = (rowIndex, sectionIndex, childIndex, columnIndex, event) => {
        console.log(rowIndex, sectionIndex, childIndex, columnIndex, event);
        if (!event) {
            return;
        }
        this.checkForIsDirtyData();
        this.updateChildFieldConfigData(rowIndex, sectionIndex, childIndex, columnIndex);
        if (this.state.dynamicFormData.sections[sectionIndex].showType === 'compactTable') {
            let indColumnData = this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex];
            if (indColumnData.auto && event.value.length >= 3) {
                this.fetchSuggestions(rowIndex, sectionIndex, { columnIndex, childIndex }, event.value);
            }
            this.updateChildCompactTableRow(sectionIndex, rowIndex, childIndex, columnIndex, event);
        } else {
            try {
                let indColumnData = this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex];
                if (indColumnData.type && indColumnData.type === 'date' && indColumnData.primaryDate) {
                    this.setPrimaryDate(this.state.dynamicFormData.sections[sectionIndex].formname, event.value);
                }
                if (indColumnData.auto && event.value.length >= 3) {
                    this.fetchSuggestions(rowIndex, sectionIndex, { columnIndex, childIndex }, event.value);
                }
                if (!indColumnData.auto && this.state.isSuggestionActive) {
                    this.setState({ isSuggestionActive: false });
                }
                if (event.type && event.type === 'radio') {
                    for (var j = 0; j < indColumnData.options.length; j++) {
                        if (indColumnData.options[j].value !== event.value) {
                            indColumnData.options[j]['checked'] = false;
                            continue;
                        }
                        indColumnData.options[j]['checked'] = (indColumnData.value === event.value) ? false : true;
                    }
                    indColumnData.value = (indColumnData.value === event.value) ? '' : event.value;
                } else if (event.type && event.type === 'checkbox') {
                    if (indColumnData.values.length > 0 && indColumnData.values.indexOf(event.value) !== -1) {
                        let valueIndex = indColumnData.values.indexOf(event.value);
                        indColumnData.values.splice(valueIndex, 1);
                    } else {
                        indColumnData.values.push(event.value);
                    }
                } else {
                    indColumnData.value = event.value;
                }
                const { dynamicFormData } = this.state;
                let child = dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex];
                child.columns[columnIndex] = indColumnData;
                child.status = this.getFilledStatus(child.columns, 'column');
                dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex] = child;
                dynamicFormData.sections[sectionIndex].rows[rowIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows[rowIndex].children, 'row');
                dynamicFormData.sections[sectionIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows, 'row');
                this.setState({ dynamicFormData }, () => {
                    if (indColumnData.colId) { // this column's value is used in some formula
                        let { formulaConfig } = this.state;
                        formulaConfig.forEach(config => {
                            if (config.localvar.indexOf(indColumnData.colId) !== -1) { // this column's value is used in this formula
                                this.changeFormulaDependentValue(config);
                            }
                        });
                    }
                    if ((event.type === 'checkbox' || event.type === 'radio' || event.type === 'selection' || event.type === 'typeahead') && (indColumnData && indColumnData.class && indColumnData.class.indexOf('displayparent') !== -1)) {
                        let values = event.type === 'checkbox' ? indColumnData.values : [indColumnData.value];
                        this.renderConditionalClass(indColumnData.colId, values, {
                            rowIndex,
                            sectionIndex,
                            childIndex,
                            columnIndex,
                        });
                    }
                });
            } catch (err) {
                console.log(err);
                this.logErrorToService(err);
            }
        }
    };

    checkForIsDirtyData() {
        let { isDirty } = this.state;
        isDirty = true;
        this.setState({ isDirty });
        //   () => this.props.emitEventIfFormValueChange(true)
    }

    getEmitedFocusEvent = (rowIndex, sectionIndex, columnIndex, event) => {
        let indColumnData = this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].columns[columnIndex];
        console.log(indColumnData);
    };

    setEuroScoreValues(rowIndex, sectionIndex, columnIndex, event){
        // 
        const { currentSectionId } = this.props
        let currentRow = currentSectionId[sectionIndex].rows[rowIndex]
        this.euroCalculation(columnIndex, event, currentRow)


        let finalScore = currentSectionId[sectionIndex].rows.find(element => element.rowId === "euroscore_ii")
        let total = 0
        currentSectionId[sectionIndex].rows.map((item) => {
           if (item.rowId !== "header") {
              if(item.columns.length !== 1){
                 total += item.columns[1].value === "" ? 0 :item.columns[1].value
              } 
           }
        })
        let constant_value = -5.324537 + parseFloat(total) 
        finalScore.columns[0].value = parseFloat((Math.exp(constant_value) / (1+ Math.exp(constant_value))) * 100).toFixed(2) 
        finalScore.status = "filled"
        const { dynamicFormData } = this.state
        dynamicFormData.sections[sectionIndex] = currentSectionId[sectionIndex]
        this.setState({ dynamicFormData })
    }

   euroCalculation(rowIndex, selection, ChangedRow,) {
    if(selection.value === ""){
       ChangedRow.columns[rowIndex + 1].value = ""
       return
    }
    if (ChangedRow.rowId === "age_1_years") {
       if (parseInt(selection.value) === 0 || selection.value === "") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
      else if (selection.value < 61) {
          ChangedRow.columns[rowIndex + 1].value = 0.0285181
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = (selection.value - 59) * 0.0285181
       }
    }
    if (ChangedRow.rowId === "gender") {
       if (selection.value === "Male" ) {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = 0.2196434
       }
    }
    if (ChangedRow.rowId === "renal_impairment") {
       if (selection.value === "normal (CC >85ml/min)") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else if (selection.value === "moderate (CC >50 & <85)") {
          ChangedRow.columns[rowIndex + 1].value = .303553
       }
       else if (selection.value === "severe (CC <50)") {
          ChangedRow.columns[rowIndex + 1].value = .8592256
       }
       else if (selection.value === "dialysis (regardless of CC)") {
          ChangedRow.columns[rowIndex + 1].value = .6421508
       }
    }
    if (ChangedRow.rowId === "extracardiac_arteriopathy") {
       if (selection.value === "No") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = .5360268
       }
    }
    if (ChangedRow.rowId === "poor_mobility") {
       if (selection.value === "No") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = .2407181
       }
    }

    if (ChangedRow.rowId === "previous_cardiac_surgery") {
       if (selection.value === "No") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = 1.118599
       }
    }
    if (ChangedRow.rowId === "chronic_lung_disease") {
       if (selection.value === "No") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = .1886564
       }
    }
    if (ChangedRow.rowId === "active_endocarditis") {
       if (selection.value === "No") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = 0.6194522
       }
    }
    if (ChangedRow.rowId === "critical_preoperative_state") {
       if (selection.value === "No") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = 1.086517
       }
    }
    if (ChangedRow.rowId === "diabetes_on_insulin") {
       if (selection.value === "No") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = .3542749
       }
    }
    if (ChangedRow.rowId === "nyha_0091") {
       if (selection.value === "I") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else if (selection.value === "II") {
          ChangedRow.columns[rowIndex + 1].value = .1070545
       }
       else if (selection.value === "III") {
          ChangedRow.columns[rowIndex + 1].value = .2958358
       }
       else if (selection.value === "IV") {
          ChangedRow.columns[rowIndex + 1].value = .5597929
       }
    }
    if (ChangedRow.rowId === "ccs_class_4_angina") {
       if (selection.value === "No") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = .2226147
       }
    }
    if (ChangedRow.rowId === "lv_function") {
       if (selection.value === "good (LVEF > 50%)") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else if (selection.value === "moderate (LVEF 31%-50%)") {
          ChangedRow.columns[rowIndex + 1].value = .3150652
       }
       else if (selection.value === "poor (LVEF 21%-30%)") {
          ChangedRow.columns[rowIndex + 1].value = .8084096
       }
       else if (selection.value === "very poor (LVEF 20% or less)") {
          ChangedRow.columns[rowIndex + 1].value = .9346919
       }
    }
    if (ChangedRow.rowId === "recent_mi") {
       if (selection.value === "No") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = .1528943
       }
    }
    if (ChangedRow.rowId === "pulmonary_hypertension") {
       if (selection.value === "No") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else if (selection.value === "31  -  55 mmHg (Moderate)") {
          ChangedRow.columns[rowIndex + 1].value = .1788899
       }
       else if (selection.value === " >55mmHg (Severe)") {
          ChangedRow.columns[rowIndex + 1].value = .3491475
       }
    }
    if (ChangedRow.rowId === "urgency_453") {
       if (selection.value === "Elective") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else if (selection.value === "Urgent") {
          ChangedRow.columns[rowIndex + 1].value = .3174673
       }
       else if (selection.value === "Emergency") {
          ChangedRow.columns[rowIndex + 1].value = .7039121
       }
       else if (selection.value === "Salvage") {
          ChangedRow.columns[rowIndex + 1].value = 1.362947
       }
    }
    if (ChangedRow.rowId === "weight_of_the_intervention") {
       if (selection.value === "Isolated CABG") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else if (selection.value === "Single non CABG") {
          ChangedRow.columns[rowIndex + 1].value = .0062118
       }
       else if (selection.value === "2 Procedures") {
          ChangedRow.columns[rowIndex + 1].value = .5521478
       }
       else if (selection.value === "3 Procedures") {
          ChangedRow.columns[rowIndex + 1].value = .9724533
       }
    }
    if (ChangedRow.rowId === "surgery_on_thoracic_aorta") {
       if (selection.value === "No") {
          ChangedRow.columns[rowIndex + 1].value = 0
       }
       else {
          ChangedRow.columns[rowIndex + 1].value = .6527205
       }
    }
 }

    getEmitedTextboxValue = (rowIndex, sectionIndex, columnIndex, event) => {
        const { currentSectionId } = this.props
        if (currentSectionId !== undefined) {
           if (currentSectionId[sectionIndex].type === "euro_Calculator") {
              this.setEuroScoreValues(rowIndex, sectionIndex, columnIndex, event)
           }
        }
       
        if (!event) {
            return;
        }
        this.checkForIsDirtyData();
        this.updateFieldConfigData(rowIndex, sectionIndex, columnIndex);

        try {
            let indColumnData = this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].columns[columnIndex];
            if (indColumnData.auto && event.value.length >= 3) {
                this.getSuggestionData(this.state.dynamicFormData.sections[sectionIndex].formname, rowIndex, sectionIndex, { columnIndex }, event.value);
                // this.fetchSuggestions(rowIndex, sectionIndex, { columnIndex }, event.value);
            }

            if (indColumnData.type && indColumnData.type === 'date' && indColumnData.primaryDate) {
                this.setPrimaryDate(this.state.dynamicFormData.sections[sectionIndex].formname, event.value);
            }
            if (event.type && event.type === 'radio') {
                for (var j = 0; j < indColumnData.options.length; j++) {
                    if (indColumnData.options[j].value !== event.value) {
                        indColumnData.options[j]['checked'] = false;
                        continue;
                    }
                    indColumnData.options[j]['checked'] = (indColumnData.value === event.value) ? false : true;
                }
                indColumnData.value = (indColumnData.value === event.value) ? '' : event.value;
            } else if (event.type && event.type === 'checkbox') {
                if (indColumnData.values.length > 0 && indColumnData.values.indexOf(event.value) !== -1) {
                    let valueIndex = indColumnData.values.indexOf(event.value);
                    indColumnData.values.splice(valueIndex, 1);
                } else {
                    indColumnData.values.push(event.value);
                }
            } else {
                if (indColumnData.autoKey && (indColumnData.autoKey === 'complaints' || indColumnData.autoKey === 'diagnosis' || indColumnData.autoKey === 'investigations' || indColumnData.autoKey === 'drugs')) {
                    indColumnData.value = event.value.toUpperCase();
                } else {
                    indColumnData.value = event.value;
                }
            }

            const { dynamicFormData } = this.state;
            let row = dynamicFormData.sections[sectionIndex].rows[rowIndex];
            row.columns[columnIndex] = indColumnData;
            row.status = this.getFilledStatus(row.columns, 'column');
            dynamicFormData.sections[sectionIndex].rows[rowIndex] = row;
            dynamicFormData.sections[sectionIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows, 'row');
            this.setState({ dynamicFormData }, () => {
                if (indColumnData.colId) { // this column's value is used in some formula
                    let { formulaConfig } = this.state;
                    formulaConfig.forEach(config => {
                        if (config.localvar.indexOf(indColumnData.colId) !== -1) { // this column's value is used in this formula
                            this.changeFormulaDependentValue(config);
                        }
                    });
                }
                if ((event.type === 'checkbox' || event.type === 'radio' || event.type === 'selection' || event.type === 'typeahead') && (indColumnData && indColumnData.class && indColumnData.class.indexOf('displayparent') !== -1)) {
                    let values = event.type === 'checkbox' ? indColumnData.values : [indColumnData.value];
                    this.renderConditionalClass(indColumnData.colId, values, { rowIndex, sectionIndex, columnIndex });
                }
            });
        } catch (err) {
            console.log(err);
            this.logErrorToService(err);
        }
    };

    // changing html for section with text editor
    changeFreeText = (sectionIndex, html) => {
        try {
            const { dynamicFormData } = this.state;
            dynamicFormData.sections[sectionIndex].html = html;
            dynamicFormData.sections[sectionIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].html, 'freeText');
            this.setState({ dynamicFormData }, () => {
                if (!this.props.emitFreeTextData) {
                    return;
                }
                this.props.emitFreeTextData(html, sectionIndex);
            });
        } catch (err) {
            console.log(err);
            this.logErrorToService(err);
        }
    };

    setPrimaryDate(sectionName, date) {
        let { dynamicFormData } = this.state;
        if (!dynamicFormData.config) {
            dynamicFormData.config = {};
        }
        if (!dynamicFormData.config.hasOwnProperty(sectionName)) {
            dynamicFormData.config = {
                ...dynamicFormData.config,
                [sectionName]: {},
            };
        }
        dynamicFormData.config[sectionName] = {
            ...dynamicFormData.config[sectionName],
            primaryDate: date,
        };
        this.setState({ dynamicFormData });
    }

    getFilledStatus(arr, level) {
        switch (level) {
            case 'freeText':
                return arr ? 'filled' : 'empty';
            case 'compactTable':
            case 'subSection':
            case 'column':
                // return arr.some(col => ((col.value !== "" && col.value !== null) || (col.values.length !== 0 || !col.values))) ? 'filled' : 'empty';
                let allThreeExist = 0;
                let allThreeHaveValues = 0;
                arr.forEach(col => {
                    if (col.name === 'name' || col.name === 'value' || col.name === 'comment')
                        allThreeExist += 1;
                    if ((col.value !== '' && col.value !== null) || (col.values && col.values.length !== 0)) {
                        if (col.name !== 'drugType') {
                            allThreeHaveValues += 1;
                        }
                    }
                });
                let nameValueCommCheckPassed = allThreeHaveValues !== 0;
                return arr.some(col => ((col.value !== '' && col.value !== null) || (col.values.length !== 0 || !col.values))) &&
                    nameValueCommCheckPassed ? 'filled' : 'empty';
            case 'row':
            case 'compactRow':
                return arr.some(row => row.status === 'filled') ? 'filled' : 'empty';
        }
    }

    onSuggestionButtonClicked = (event) => {
        event.preventDefault();
        this.setState({
            isSuggestionActive: true,
        });
    };

    onSuggestionCloseEvent = (event) => {
        event.preventDefault();
        if (event)
            this.setState({
                isSuggestionActive: false,
                //  suggestionIndexDetails: null
            });
    };

    getSectionIndex = (key) => {
        let { dynamicFormData } = this.state;
        // key = (key === "symptoms") ? "complaints" : key;
        for (let index in dynamicFormData.sections) {
            let formName = dynamicFormData.sections[index].formname.toLowerCase();
            if (formName === key) {
                return index;
            }
        }
    };

    getColumnIndex = (label, rowData) => {
        for (let index in rowData.columns) {
            let indCol = rowData.columns[index];
            if (((indCol.label).toLowerCase()).indexOf(label.level2) !== -1) {
                return index;
            }
        }
    };

    getEmitedSuggestionData = (event, sectionType) => {
        console.log(event, sectionType);
    };

    mapDataWithSuggestionEvent(row, event) {
        let keys = Object.keys(event);
        row.columns.forEach(c => {
            let key = keys.find(k => k === (c.name ? c.name.toLowerCase() : ''));
            if (key) {
                if (key === 'drugtype') {
                    c.value = event[key].replace(event[key][0], event[key][0].toUpperCase());
                } else if (key === 'othercolumns') {
                    c.value = event['duration'] ? event['duration'] : event['othercolumns'] && event['othercolumns']['duration'] ? event['othercolumns']['duration'] : '';
                } else if ((key === 'frequency')) {
                    if (event[key].indexOf('0') !== -1 || event[key].indexOf('1') !== -1) {
                        c.value = event[key].replace(/\s/g, '');
                    } else {
                        c.value = event[key] ? event[key].toUpperCase() : '';
                    }
                } else if ((key === 'duration')) {
                    c.value = event[key] ? event[key].toUpperCase() : '';
                } else if (key === 'comment') {
                    c.value = event[key] ? event[key].toUpperCase() : '';
                } else {
                    c.value = event[key];
                }
            }
        });
        return row;
    }

    checkForRequiredData = () => {
        let { sectionRowErrorArray, dynamicFormData } = this.state;
        let isError = false;
        if (!this.state.sectionRowErrorArray ||
            (this.state.sectionRowErrorArray && this.state.sectionRowErrorArray.length === 0)) {
            for (let i in this.state.dynamicFormData.sections) {
                let indSection = this.state.dynamicFormData.sections[i];
                for (let j in indSection.rows) {
                    if (indSection.rows[j].class === "deferRendering hide") {
                        continue;
                    }
                    if (indSection.rows[j].type === "group") {
                        isError = this.checkErrorInTypeGroup(indSection.rows[j].children);

                    } else if (indSection.rows[j].type === "row") {
                        isError = this.checkErrorInTypeRow(indSection.rows[j].columns)
                    }
                    if (isError) {
                        return true;
                    }
                }
            }
        } else {
            if (sectionRowErrorArray && sectionRowErrorArray.length > 0) {
                for (let i in sectionRowErrorArray) {
                    let indErrSec = sectionRowErrorArray[i];
                    if (dynamicFormData.sections[indErrSec.sectionIndex].rows[indErrSec.rowIndex].class === "deferRendering hide") {
                        continue;
                    }
                    let col = dynamicFormData.sections[indErrSec.sectionIndex].rows[indErrSec.rowIndex].columns[indErrSec.colIndex];
                    let errorStatus = !!(col && col.value);
                    isError = this.setErrorStatus(indErrSec.rowIndex, indErrSec.sectionIndex, errorStatus, indErrSec.colIndex, 'save');
                }
            }
        }

        this.setState({ checkFormTrigger: true });
        this.checkForIsDirtyData();
        return isError;
    }

    checkErrorInTypeGroup = (children) => {
        for (let i in children) {
            return this.checkErrorInTypeRow(children[i])
        }
    }
    checkErrorInTypeRow = (columns) => {

        let isError = false;
        if (!columns) {
            return isError
        }
        for (let i in columns) {
            if (columns[i] && columns[i].validation && columns[i].validation.required && (!columns[i].value && !columns[i].values.length)) {
                isError = true;
            }
        }
        return isError;
    }

    setErrorStatus(rowIndex, sectionIndex, errorStatus, colIndex, type) {
        let arr = this.state.sectionRowErrorArray;
        let isFound = false;
        arr.forEach((o, idx) => {
            if (o.sectionIndex === sectionIndex && o.rowIndex === rowIndex) {
                if (!errorStatus) {
                    arr.splice(idx, 1);
                    let s = { sectionRowErrorArray: arr };
                    if (this.state.checkFormTrigger == true && arr.length === 0) {
                        s = { ...s, errorStatus: false };
                    }
                    this.setState(s);
                }
                isFound = true;
            }
        });
        if (!isFound && errorStatus) {
            arr.push({ sectionIndex, rowIndex, colIndex });
            let s = { sectionRowErrorArray: arr };
            if (this.state.checkFormTrigger == true && arr.length === 1) {
                s = { ...s, errorStatus: true };
            }
            this.setState(s);
        }
        if (type) {

            return isFound;
        }

    }

    onQcDataChange = (event) => {
        this.props.emitPrescriptionStatus(event);
    };

    onPrescriptionStatusUpdateEvent = (event) => {
        this.props.emitQcStatusSaveEvent(true);
    };

    setConditionalHide = (sectionIndex, target) => {
        let { conditionalHideConfig } = this.state;
        let config = {
            ...target,
            sectionIndex,
        };
        let idx = conditionalHideConfig.findIndex(c => c.sectionIndex === sectionIndex && c.rowIndex === target.rowIndex && (c.childIndex === target.childIndex || (!c.childIndex && !target.childIndex)));
        if (idx === -1) {
            conditionalHideConfig.push(config);
        } else {
            conditionalHideConfig[idx] = config;
        }
        this.setState({ conditionalHideConfig });
    };

    unsetConditionalHide = (sectionIndex, target) => {
        let { conditionalHideConfig } = this.state;
        let idx = conditionalHideConfig.findIndex(c => c.sectionIndex === sectionIndex && c.rowIndex === target.rowIndex && (c.childIndex === target.childIndex || (!c.childIndex && !target.childIndex)));
        if (idx !== -1) conditionalHideConfig.splice(idx, 1);
        this.setState({ conditionalHideConfig });
    };

    renderConditionalClass(colId, values, indexes) {
        let { conditionalHideConfig, dynamicFormData } = this.state;
        try {
            conditionalHideConfig.forEach(config => {
                let { sectionIndex, rowIndex, childIndex, displaycondition } = config;
                if (displaycondition && displaycondition[0].source === colId) {
                    if (childIndex) {
                        if (indexes.sectionIndex !== sectionIndex || indexes.rowIndex !== rowIndex) return;
                    } else {
                        if (indexes.sectionIndex !== sectionIndex) return;
                    }
                    let display = displaycondition[0].value.split(',').every(v => (values.indexOf(v) !== -1));
                    if (config.childIndex !== undefined) {
                        let child = dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex];
                        if (!child) {
                            return;
                        }
                        child['class'] = display ? '' : config.class ? config.class : 'deferRendering hide';
                        dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex] = child;
                        this.updateChildFieldConfigData(rowIndex, sectionIndex, childIndex);
                    } else {
                        let row = dynamicFormData.sections[sectionIndex].rows[rowIndex];
                        if (row) {
                            row['class'] = display ? '' : config.class ? config.class : 'deferRendering hide';
                            dynamicFormData.sections[sectionIndex].rows[rowIndex] = row;
                            this.updateFieldConfigData(rowIndex, sectionIndex);
                        }
                    }
                }
            });
            this.setState({ dynamicFormData });
        } catch (err) {
            console.log(err);
            this.logErrorToService(err);
        }
    }

    getVerifiedHeader(indSection) {
        return indSection.header.split(',');
    }

    checkIfRoleIsDataAdminType() {
        if (sessionStorage.getItem('userRole').indexOf('dataadmin') !== -1 || sessionStorage.getItem('userRole').indexOf('dataqc') !== -1 || sessionStorage.getItem('userRole').indexOf('dataops') !== -1) {
            return true;
        }
        return false;
    }

    togglePatientDetilSection() {
        let { showPatient } = this.state;
        showPatient = !showPatient;
        this.setState({ showPatient });
    }

    getEmitedPatientData = (event) => {
        let { patientData } = this.state;
        patientData = event;
        this.setState({ patientData });
    };

    getSectionList = () => {
        if (!this.state.dynamicFormData || !this.state.dynamicFormData.sections) {
            return [''];
        }
        let sectionArr = [];
        for (let i = 0; i < this.state.dynamicFormData.sections.length; i++) {
            sectionArr.push(this.state.dynamicFormData.sections[i].title);
            let uiDataDic = this.state.dynamicFormData.sections[i].uiDataDic;
            for (let j = 0; j < this.state.dynamicFormData.sections[i].rows.length; j++) {
                if (this.state.dynamicFormData.sections[i].rows[j].type === 'row' || this.state.dynamicFormData.sections[i].rows[j].type === 'compactRow') {
                    this.mapTypeRowDataToDFdata(uiDataDic, i, j);
                } else if (this.state.dynamicFormData.sections[i].rows[j].type === 'group') {
                    this.mapTypeGroupDataToDFdata(uiDataDic, i, j);
                } else if (this.state.dynamicFormData.sections[i].rows[j].type === 'subSection') {
                    this.mapTypeGroupDataToDFdata(uiDataDic, i, j);
                }
            }
        }
        return sectionArr;
    };

    checkIfEmptyPrescription() {
        let { dynamicFormData } = this.state;
        let isFilled = false;
        for (let i in dynamicFormData.sections) {
            if (dynamicFormData.sections[i].status === 'filled') {
                if (dynamicFormData.sections[i].formname !== 'drugs') {
                    isFilled = true;
                } else {
                    let rows = dynamicFormData.sections[i].rows;
                    let isDrugFilled = false;
                    let type = '';
                    for (let j in rows) {
                        if (rows[j].type === 'row') {
                            type = 'row';
                            let columns = rows[j].columns;
                            for (let k in columns) {
                                if (columns[k].type === 'date') {
                                    isDrugFilled = true;
                                }
                                if ((columns[k].name === 'drug' || columns[k].name === 'value' || columns[k].name === 'name') && columns[k].value) {
                                    isDrugFilled = true;
                                }
                            }
                        } else {
                            type = 'group';
                        }
                    }

                    if (type === 'row') {
                        if (!isDrugFilled) {
                            dynamicFormData.sections[i].status = 'empty';
                            isFilled = false;
                            this.setState({ dynamicFormData });
                        } else {
                            isFilled = true;
                        }
                    } else {
                        if (dynamicFormData.sections[i].status === 'filled') {
                            isFilled = true;
                        }
                    }
                }
            }
        }
        return isFilled;
    }

    checkForActiveKeys = () => {
        if (!this.props.dynamicFormData || !this.props.dynamicFormData.sections) {
            return [''];
        }
        let count = this.props.dynamicFormData.sections.length;
        return Array.from({ length: count }, (_, i) => i)
    }

    render() {
        const { isEditable, viewType } = this.props;
        if (!this.state.dynamicFormData) {
            return '';
        }
        return (
            <>
                <>
                    <Form
                        name='basic'
                        onFinish={(values) => {
                            console.log('finish', values);
                        }}
                        onFinishFailed={(values) => {
                            console.log('finish-faild', values);
                        }}
                    >

                        <Row>
                            <Col span={24}>
                                <Collapse bordered={true} defaultActiveKey={this.state.activeTabs}>

                                    {this.state.dynamicFormData && this.state.dynamicFormData.sections.map((indSection, index) =>
                                        <Panel header={`${indSection.title}`} key={index}
                                            style={{ display: (viewType && indSection.status === 'filled') ? '' : !viewType ? '' : 'none' }}
                                        >

                                            <div id='collapse-13'
                                                className={`collapse ${indSection.collapse ? '' : 'show'}`}
                                                role='tabpanel'
                                                aria-labelledby='heading-13'>
                                                {indSection.showType === 'freeText' &&
                                                    <TextEditor html={indSection.html}
                                                        onEditTextChange={this.changeFreeText.bind(this, index)} />}
                                                {indSection.showType === 'compactTable' ?
                                                    <>
                                                        {indSection.splitTable ? (
                                                            <>
                                                                <Row style={{marginTop:"10px"}}>
                                                                    {indSection.rows.map((indRowData, jndex) =>
                                                                        <Col span={indRowData.subSectionSize}
                                                                            className={`col-${indRowData.subSectionSize} mb-2`}
                                                                            key={jndex}>
                                                                            <div id='table' className='table-editable '>
                                                                                <table
                                                                                    className='table table-bordered table-responsive-md table-hover text-center table-sm table-striped'>
                                                                                    <thead className='thead-light'
                                                                                        style={{ textTransform: 'capitalize' }}>
                                                                                        <tr>
                                                                                            {indRowData.header &&
                                                                                                this.getVerifiedHeader(indRowData).map(headerLabel =>
                                                                                                    <th className='text-center'>{headerLabel}</th>)}
                                                                                            <th className='text-center'>Actions</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <CompactTableRowComponent
                                                                                            uiDataDic={indSection.uiDataDic}
                                                                                            collapse={indSection.collapse}
                                                                                            indRowData={indRowData}
                                                                                            sectionIndex={index}
                                                                                            isView={this.state.isView}
                                                                                            rowIndex={jndex}
                                                                                            setConditionalHide={this.setConditionalHide.bind(this, index)}
                                                                                            unsetConditionalHide={this.unsetConditionalHide.bind(this, index)}
                                                                                            setConfigForFormula={this.setColumnsWithColIdToEmitOnUpdate.bind(this, index)}
                                                                                            emitChildTextboxValue={this.getEmitedChildTextboxValue.bind(this, jndex, index)}
                                                                                            emitTypeAheadSuggestionObj={this.getEmitedSuggestionData.bind(this)}
                                                                                            emitTextboxValue={this.getEmitedTextboxValue.bind(this, jndex, index)}
                                                                                            emitChildAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(this, jndex, index)}
                                                                                            emitChildDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(this, jndex, index)}
                                                                                            emitAddNewRowEvent={this.getEmitedAddNewRowEvent.bind(this, jndex, index)}
                                                                                            emitDeleteRowObjectData={this.getEmitedDeleteRowObjectData.bind(this, jndex, index)}

                                                                                            fieldConfig={this.state.fieldConfig}
                                                                                            checkForm={this.state.checkFormTrigger}
                                                                                            emitErrorStatus={this.setErrorStatus.bind(this, jndex, index)}>
                                                                                        </CompactTableRowComponent>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </Col>,
                                                                    )}
                                                                </Row>
                                                            </>
                                                        ) : (
                                                            <div id='table' className='table-editable mb-2'>
                                                                <table
                                                                    className='table table-bordered table-responsive-md table-hover text-center table-sm table-striped'>
                                                                    <thead className='thead-light'
                                                                        style={{ textTransform: 'capitalize' }}>
                                                                        <tr>
                                                                            {indSection.header && this.getVerifiedHeader(indSection).map(headerLabel =>
                                                                                <th className='text-center'>{headerLabel}</th>)}
                                                                            <th className='text-center'>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {indSection.rows.map((indRowData, jndex) =>
                                                                            <>
                                                                                <tr>
                                                                                    <React.Fragment key={jndex}>
                                                                                        <CompactTableRowComponent
                                                                                            uiDataDic={indSection.uiDataDic}
                                                                                            collapse={indSection.collapse}
                                                                                            indRowData={indRowData}
                                                                                            sectionIndex={index}
                                                                                            isView={this.state.isView}
                                                                                            rowIndex={jndex}
                                                                                            setConditionalHide={this.setConditionalHide.bind(this, index)}
                                                                                            unsetConditionalHide={this.unsetConditionalHide.bind(this, index)}
                                                                                            setConfigForFormula={this.setColumnsWithColIdToEmitOnUpdate.bind(this, index)}
                                                                                            emitChildTextboxValue={this.getEmitedChildTextboxValue.bind(this, jndex, index)}
                                                                                            emitTextboxValue={this.getEmitedTextboxValue.bind(this, jndex, index)}
                                                                                            emitChildAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(this, jndex, index)}
                                                                                            emitChildDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(this, jndex, index)}
                                                                                            emitAddNewRowEvent={this.getEmitedAddNewRowEvent.bind(this, jndex, index)}
                                                                                            emitDeleteRowObjectData={this.getEmitedDeleteRowObjectData.bind(this, jndex, index)}
                                                                                            fieldConfig={this.state.fieldConfig}
                                                                                            checkForm={this.state.checkFormTrigger}
                                                                                            emitErrorStatus={this.setErrorStatus.bind(this, jndex, index)}>
                                                                                        </CompactTableRowComponent>
                                                                                    </React.Fragment>
                                                                                    <td style={{
                                                                                        padding: '0.1rem',
                                                                                        width: '10%',
                                                                                    }}>
                                                                                        {indSection.rows.length > 1 &&
                                                                                            <span className='table-remove'>
                                                                                                <span
                                                                                                    className='link-icon mdi mdi-delete-forever  text-dark table-remove'
                                                                                                    onClick={this.getEmitedDeleteRowObjectData.bind(this, jndex, index)}> </span>
                                                                                            </span>}
                                                                                        <span className='table-remove'>
                                                                                            <span
                                                                                                className='link-icon mdi mdi-plus text-dark table-remove'
                                                                                                onClick={this.getEmitedAddNewTableRowEvent.bind(this, jndex, index)}> </span>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </>,
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}
                                                    </>
                                                    :
                                                    <div className={'card-body row'}
                                                        style={{ padding: '.5rem 1rem', width: '100%' }}>
                                                        <RowParent isEditable={isEditable}>
                                                            {indSection.showType === 'xyTable' && indSection.rows && indSection.rows.length > 0 &&
                                                                <XyTableColumnHeader section={indSection} />}
                                                            {indSection.rows.map((indRowData, jndex) =>
                                                                <RowSubParent idx={jndex} isEditable={isEditable}
                                                                    onDragOver={() => this.props.onDragOver ? this.props.onDragOver(jndex) : {}}
                                                                    onDragStart={(e) => this.props.onDragStart ?
                                                                        this.props.onDragStart(e, jndex) : {}}
                                                                    onDragEnd={(e) => this.props.onDragEnd ? this.props.onDragEnd(e) : {}}>
                                                                    <RowViewComponent
                                                                        title={indSection.title}
                                                                        viewType={viewType}
                                                                        rowProps={this.props.rowProps}
                                                                        isEditable={isEditable}
                                                                        ordered={indSection.ordered}
                                                                        hideShowOff={indSection.hideShowOff}
                                                                        uiDataDic={indSection.uiDataDic}
                                                                        collapse={indSection.collapse}
                                                                        emitTypeAheadSuggestionObj={this.getEmitedSuggestionData.bind(this)}
                                                                        indRowData={indRowData}
                                                                        sectionIndex={index}
                                                                        isView={this.state.isView}
                                                                        rowIndex={jndex}
                                                                        showType={indSection.showType}
                                                                        setConditionalHide={this.setConditionalHide.bind(this, index)}
                                                                        unsetConditionalHide={this.unsetConditionalHide.bind(this, index)}
                                                                        setConfigForFormula={this.setColumnsWithColIdToEmitOnUpdate.bind(this, index)}
                                                                        emitChildTextboxValue={this.getEmitedChildTextboxValue.bind(this, jndex, index)}
                                                                        emitFocusEvent={this.getEmitedFocusEvent.bind(this, jndex, index)}
                                                                        emitTextboxValue={this.getEmitedTextboxValue.bind(this, jndex, index)}
                                                                        emitChildAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(this, jndex, index)}
                                                                        emitChildDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(this, jndex, index)}
                                                                        emitAddNewRowEvent={this.getEmitedAddNewRowEvent.bind(this, jndex, index)}
                                                                        emitCloneRowEvent={this.getEmitedCloneRowEvent.bind(this, jndex, index)}
                                                                        emitDeleteRowObjectData={this.getEmitedDeleteRowObjectData.bind(this, jndex, index)}

                                                                        fieldConfig={this.state.fieldConfig}
                                                                        checkForm={this.state.checkFormTrigger}
                                                                        template_id={this.props.template_id}
                                                                        emitErrorStatus={this.setErrorStatus.bind(this, jndex, index)}
                                                                    />
                                                                </RowSubParent>,
                                                            )}
                                                        </RowParent>
                                                    </div>
                                                }
                                            </div>

                                        </Panel>,
                                    )}

                                </Collapse>
                            </Col>
                            <Col span={24} gutter={16}>
                                <Divider />
                            </Col>

                            <Col span={24} gutter={16} style={{ display: viewType ? 'none' : '' }}>
                                <Row gutter={16} justify={'space-between'}>
                                    <Col>
                                        <Space>
                                            <Button loading={this.props.saving} disabled={this.props.saving}
                                                onClick={() => {
                                                    this.props.onSubmit(this.state.dynamicFormData);
                                                }}
                                                type={'default'}
                                                style={{ display: this.props.isView ? 'none' : '' }}>
                                                Save
                                            </Button>
                                            <Button loading={this.props.saving} disabled={this.state.errorStatus}
                                                onClick={() => {
                                                    // TODO: Stop in case of Error
                                                    if (!this.checkIfEmptyPrescription()) {
                                                        notification.error({ message: 'Please fill any data' });
                                                        return;
                                                    }
                                                    if (this.checkForRequiredData()) {
                                                        notification.error({ message: 'Please fill all the required fields' });
                                                        return;
                                                    }
                                                    this.props.onSubmit(this.state.dynamicFormData, true);
                                                }}
                                                type={'primary'}
                                                style={{ display: this.props.isView ? 'none' : '' }}>
                                                Submit
                                            </Button>
                                        </Space>
                                    </Col>
                                    <Col>
                                        <Space>
                                            <Button disabled={this.state.errorStatus || this.props.saving}
                                                onClick={() => this.props.onCancel()}
                                                type={'primary'} danger={true}
                                                style={{ display: this.props.isView ? 'none' : '' }}>
                                                Cancel
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>

                </>

            </>
        );

    }
}

export default SectionViewComponent;

export const RowParent = ({ isEditable, children }) => {
    return isEditable ? <ul className='w-100'>{children}</ul>
        : <>{children}</>;
};
export const RowSubParent = ({ isEditable, children, idx, onDragOver, onDragStart, onDragEnd }) => {
    return isEditable ? <li key={idx} onDragOver={() => onDragOver()}>
        <div className='mb-3 ml-3' draggable onDragStart={e => onDragStart(e)}
            onDragEnd={onDragEnd.bind(this)}>{children}</div>
    </li>
        :
        <>{children}</>;
};
export const XyTableColumnHeader = ({ section }) => {
    const row = section.rows[0];
    const columnHeaders = section.colHeaders && section.colHeaders.split(',') || [];
    return <>
        <div className={`col-lg-${row.spaceNeeded ? row.spaceNeeded : '12'}`}>
            <div
                className={!section.hideShowOff ? `row align-items-center prescription-list radius-0 mb-2 pb-1 pt-0` : 'row no-gutters'}>
                {section.hideShowOff &&
                    <div className='col p-1 pt-3' style={{ visibility: 'hidden' }}>{section.title} </div>
                }
                <div className='col p-1 pt-3' style={{ visibility: 'hidden' }}><strong>{row.label_x}</strong></div>
                {row.columns && row.columns.map((indColumnData, index) => <div className={`col`}
                    style={{ textTransform: 'capitalize' }}>
                    <strong>{columnHeaders[index] || '-'}</strong>
                </div>)}

            </div>
        </div>
    </>;
};
