import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ListViewDesktop from 'shared/components/layout/Desktop/ListView/ListViewDesktop';
import {
    DEPARTMENT_ADD_ROUTE, DEPARTMENT_EDIT_ROUTE, ROLE_ADD_ROUTE, ROLE_EDIT_ROUTE,
} from 'shared/constants/RouteConstants';
import { Button, Space, Col, Row, Table, Tooltip, Typography, Input, Pagination } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import useDebounce from 'shared/hooks/useDebounce';
import { GlobalListLimit, ListPagination } from 'shared/constants/AppConstants';
import { getPaginationLimit, hasPermissionOf, numberFormat, setPaginationLimit } from 'shared/services/Utility';
import {
   ROLES_INSERT_ONE, ROLES_UPDATE_ONE,
} from 'shared/constants/Permissions';
import useRoles from 'shared/hooks/Roles/useRoles';
import Paragraph from 'antd/lib/typography/Paragraph';


const { Text } = Typography;
type Props = {}


const RolesPage: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [search, setSearch] = useState();
    const [pagination, setPagination] = useState({
        limit: getPaginationLimit(),
        currentPage: 1,
    } as { limit: number, currentPage: number });    const debouncedSearch = useDebounce(search, 500);

    const { roles, total, loading: loadingRoles, refetch } = useRoles({
        offset: 0,
        limit: pagination.limit,
    });

    const columns = [
        {
            title: '#',
            key: 'sNo',
            render: (a: any, value: any, index: number) =>
                <Text>{(index + 1) + ((pagination.currentPage - 1) * pagination.limit)}</Text>,        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (a: any) => <Paragraph>{a}</Paragraph>,
        },
        {
            fixed: 'right' as 'right',
            width: '150px',
            title: 'Action',
            key: 'action',
            render: (a: any) => {
                return <Row gutter={10}>
                    {
                        hasPermissionOf(ROLES_UPDATE_ONE) && <Col>
                            <Tooltip title={t('genDict.edit')}>
                                <Button shape='circle' onClick={() => history.push(ROLE_EDIT_ROUTE(a.id))}
                                        icon={<EditFilled />}
                                        size={'middle'} />
                            </Tooltip>
                        </Col>
                    }
                </Row>;
            },
        },
    ];
    useEffect(() => {
        setPagination({ ...pagination, currentPage: 1 });
    }, [debouncedSearch]);
    useEffect(() => {
        refetch({
            search, offset: (pagination.currentPage - 1) * pagination.limit,
            limit: pagination.limit,
        });
    }, [pagination]);
    return (
        <div className={'roles-page page-wrapper'}>
            <ListViewDesktop title={t('genDict.roles')}
                             filters={[
                                 <Space size={0}>
                                     <Input value={search} placeholder={t('genDict.search')} onChange={
                                         (e: any) => {
                                             setSearch(e.target.value);
                                         }
                                     } />
                                 </Space>,
                             ]}
                             addActionConfig={
                                 hasPermissionOf(ROLES_INSERT_ONE) && {
                                     text: t('genDict.add'),
                                     onClick: () => history.push(ROLE_ADD_ROUTE),
                                 }
                             }
            >
                <Table
                    sticky={true}

                    locale={{
                        emptyText: (
                            <>
                                <Space direction={'vertical'} size={10} style={{ padding: '10px' }}>
                                    <Title level={3} className={'text-primary'}>{t('genDict.woops')}</Title>
                                    <Text className={'text-primary'}>No Role added</Text>
                                    <Button type={'primary'}
                                            onClick={() => history.push(ROLE_ADD_ROUTE)}>{t('genDict.add')}</Button>
                                </Space>
                            </>
                        ),
                    }}
                    pagination={{
                        hideOnSinglePage: true,
                        showSizeChanger: true,
                        total,
                        current: pagination.currentPage,
                        pageSize: pagination.limit,
                        showTotal: (total: number) => <Text>Total <b>{numberFormat(total)}</b> items</Text>,
                        onChange: (page: number, pageSize?: number) => {
                            if (pageSize !== pagination.limit) {
                                setPaginationLimit(pageSize);
                                page=1;
                            }
                            setPagination({ currentPage: page, limit: (pageSize || pagination.limit) });
                        },
                    }} scroll={{ x: true }}
                    dataSource={roles}
                    loading={loadingRoles}
                    columns={columns} />
            </ListViewDesktop>
        </div>
    );
};

export default RolesPage;
