import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import { GlobalListLimit } from '../../constants/AppConstants';

type ReturnType = {
    userDepartments: any[];
    loading: boolean;
    error: any;
    total: number;
    refetch: any;
};


const QUERY = gql`
    query ($where: c_org_user_departments_bool_exp, $limit: Int, $offset: Int){
        c_org_user_departments(where: $where, limit: $limit, offset: $offset, distinct_on: [department_id]) {
            created_at
            id
            deleted_at
            user_id
            department{
                name
                id
                branch{
                    name
                    id
                }
            }
            department_id
        }
        c_org_user_departments_aggregate(where: $where, offset: $offset, distinct_on: [department_id]){
            aggregate{
                count
            }
        }
    }
`;

const createFilters = (filters?: Filters) => {
    const result: any = { where: { _and: [] }, limit: GlobalListLimit, offset: 0 };
    if (!filters) {
        return result;
    }
    if (filters['search']) {
        result['where']['_and'].push({
            '_or': [
                { name: { _ilike: `%${filters['search']}%` } },
            ],
        });
    }
    if (filters['user_id']) {
        result['where']['_and'].push({
            '_or': [
                { user_id: { _eq: filters['user_id'] } },
            ],
        });
    }
    if (filters['id']) {
        result['where']['_and'].push({
            '_or': [
                { department: { id: { _eq: filters['id'] } } },
            ],
        });
    }
    if (filters['branch_ids']) {
        result['where']['_and'].push({ department: { branch_id: { _in: filters['branch_ids'] } } });
    }
    result['limit'] = filters['limit'] || GlobalListLimit;
    result['offset'] = filters['offset'] || 0;
    return result;
};

const useUserDepartments: (filters?: Filters) => ReturnType = (filters) => {
    const { data, loading, error, refetch: refresh } = useQuery(QUERY, { variables: createFilters(filters) });
    let userDepartments = [];
    let total = 0;
    if (!loading && !error) {
        userDepartments = data.c_org_user_departments;
        total = data.c_org_user_departments_aggregate.aggregate.count;
    }
    const refetch = (filters?: Filters) => {
        return refresh(createFilters(filters));
    };
    return {
        total,
        userDepartments,
        loading,
        error,
        refetch,
    };
};
export default useUserDepartments;
