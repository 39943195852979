import BaseUrlConstants from "./BaseUrlConstants";

export const ROUTE_PROFILE = '/profile';
export const ROUTE_PAGE_400 = '/pages/400';
export const ROUTE_PAGE_403 = '/pages/403';
export const ROUTE_PAGE_404 = '/pages/404';


export const PROCEDURES_LIST_ROUTE = '/procedures';
export const PROCEDURES_ADD_ROUTE = '/procedures/add';
export const PROCEDURES_EDIT_ROUTE = (id: any) => `/procedures/${id}/edit`;
export const PROCEDURES_VIEW_ROUTE = (id: any) => `/procedures/${id}/view`;
export const PROCEDURES_REVIEW_ROUTE = (id: any) => `/procedures/${id}/review`;
export const PROCEDURES_PRESCRIPTION_REVIEW_ROUTE = (id: any, prescriptionId: string) => `/procedures/${id}/review/${prescriptionId}`;

export const PATIENT_REGISTRATION_LIST_ROUTE = '/patients';
export const PATIENT_REGISTRATION_ADD_ROUTE = '/patients/add';
export const PATIENT_ADMISSIONS_ROUTE = '/patients/add';
export const PATIENT_ADMISSION_ADD = (id: any, params?: any) => {
    let p = '';
    if (params) {
        p = '?';
        let items: any[] = [];
        for (let i in params) {
            items.push(`${i}=${params[i]}`);
        }
        p += items.join('&');
    }
    return `/patients/${id}/admission${p}`;
};
export const PATIENT_REGISTRATION_EDIT_ROUTE = (id: any) => `/patients/${id}/edit`;
export const PATIENT_REGISTRATION_VIEW_ROUTE = (id: any) => `/patients/${id}/view`;

export const GROUPS_LIST_ROUTE = '/groups';
export const GROUP_ADD_ROUTE = '/groups/add';
export const GROUP_EDIT_OR_VIEW_ROUTE = '/groups/:id/:mode';
export const GROUP_EDIT_ROUTE = (id: any) => `/groups/${id}/edit`;
export const GROUP_VIEW_ROUTE = (id: any) => `/groups/${id}/view`;

export const QUESTIONNAIRES_LIST_ROUTE = '/questionnaire';
export const QUESTIONNAIRE_ADD_ROUTE = '/questionnaire/add';
export const QUESTIONNAIRE_EDIT_OR_VIEW_ROUTE = '/questionnaire/:id/:mode';
export const QUESTIONNAIRE_EDIT_ROUTE = (id: any) => `/questionnaire/${id}/edit`;

export const FORM_BUILDER = '/form-builder';

export const LOCATIONS_LIST_ROUTE = '/locations';
export const LOCATION_ADD_ROUTE = '/locations/add';
export const LOCATION_EDIT_OF_VIEW_ROUTE = '/locations/:id/:mode';
export const LOCATION_EDIT_ROUTE = (id: any) => `/locations/${id}/edit`;

export const BRANCHES_LIST_ROUTE = '/branches';
export const BRANCH_ADD_ROUTE = '/branches/add';
export const BRANCH_EDIT_ROUTE = (id: any) => `/branches/${id}/edit`;

export const DEPARTMENTS_LIST_ROUTE = '/departments';
export const DEPARTMENT_ADD_ROUTE = '/departments/add';
export const DEPARTMENT_EDIT_ROUTE = (id: any) => `/departments/${id}/edit`;

export const USERS_LIST_ROUTE = '/users';
export const USERS_BULK = '/users-bulk';
export const USER_ADD_ROUTE = '/users/add';
export const USER_EDIT_ROUTE = (id: any) => `/users/${id}/edit`;

export const ROLES_LIST_ROUTE = '/roles';
export const ROLE_ADD_ROUTE = '/roles/add';
export const ROLE_EDIT_ROUTE = (id: any) => `/roles/${id}/edit`;

export const PREFERENCES_ROUTE = '/preferences';

export const TEAMS_LIST_ROUTE = '/teams';
export const TEAM_ADD_ROUTE = '/teams/add';
export const TEAM_EDIT_ROUTE = (id: any) => `/teams/${id}/edit`;

export const TABLE_PERMISSIONS_LIST_ROUTE = '/table-permissions';
export const TABLE_PERMISSION_ADD_ROUTE = '/table-permissions/add';
export const TABLE_PERMISSION_EDIT_ROUTE = (id: any) => `/table-permissions/${id}/edit`;

export const ENCOUNTER_TYPES_LIST_ROUTE = '/procedure-types';
export const ENCOUNTER_TYPE_ADD_ROUTE = '/procedure-types/add';
export const ENCOUNTER_TYPE_EDIT_OR_VIEW_ROUTE = '/procedure-types/:id/:mode';
export const ENCOUNTER_TYPE_EDIT_ROUTE = (id: any) => `/procedure-types/${id}/edit`;
export const ENCOUNTER_TYPE_VIEW_ROUTE = (id: any) => `/procedure-types/${id}/view`;


export const PROM_EDIT_OR_VIEW_ROUTE = `/prom/:pid/:id`
export const DASHBOARD_VIEW = '/dashboard';

export const EXTERNAL_DASHBOARD_URL = () => {
    return BaseUrlConstants.DASH_BOARD_URL
}