import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ListViewDesktop from 'shared/components/layout/Desktop/ListView/ListViewDesktop';
import * as _ from 'lodash';

import {
    USER_ADD_ROUTE, USER_EDIT_ROUTE,
} from 'shared/constants/RouteConstants';
import {
    Button,
    Space,
    Col,
    Row,
    Table,
    Tooltip,
    Typography,
    Input,
    Pagination,
    Modal,
    Upload,
    Popover,
    Card, Spin, notification,
} from 'antd';
import { EditFilled, UploadOutlined, DeleteFilled, CheckCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import useDebounce from 'shared/hooks/useDebounce';
import { DateFormat, GlobalListLimit, ListPagination } from 'shared/constants/AppConstants';
import {
    getPaginationLimit,
    hasPermissionOf,
    numberFormat,
    parseBulkUsersFromXLSX,
    setPaginationLimit,
} from 'shared/services/Utility';
import {
    USERS_INSERT_ONE,
    USERS_UPDATE_ONE,
} from 'shared/constants/Permissions';
import * as XLSX from 'xlsx';

import useUsers from 'shared/hooks/Users/useUsers';
import Link from 'antd/lib/typography/Link';
import moment from 'moment';
import useRoles from 'shared/hooks/Roles/useRoles';
import useDepartments from 'shared/hooks/Departments/useDepartments';
import useTemplates from 'shared/hooks/Templates/useQuestionnaires';
import useLocations from 'shared/hooks/useLocations';
import useUserCreate from 'shared/hooks/Users/useUserCreate';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import UserForm from './CreateOrEdit/UserForm';
import useUserCreateWithMappingBulk from 'shared/hooks/Users/useUserCreateWithMappingBulk';


const { Text } = Typography;
type Props = {}


const UsersBulkPage: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [search, setSearch] = useState('');
    const [file, setFile] = useState(null as any);
    const [pagination, setPagination] = useState({
        limit: getPaginationLimit(),
        currentPage: 1,
    } as { limit: number, currentPage: number });
    const debouncedSearch = useDebounce(search, 500);
    const [users, setUsers] = useState([] as any[]);
    const [showUpload, setShowUpload] = useState(false);
    const [emailsToLookFor, setEmailsToLookFor] = useState([] as any[]);
    const [departmentMappingsToLookFor, setDepartmentMappingsToLookFor] = useState([] as any[]);
    const [roleNamesToLookFor, setRoleNamesToLookFor] = useState([] as any[]);
    const { mutation, saving } = useUserCreateWithMappingBulk((e: any) => {
        if (e) {
            notification.success({ message: 'Created successfully' });
        }
    });
    const { users: usersByEmails, loading: loadingUsersByEmails, refetch } = useUsers({
        emails: emailsToLookFor,
        limit: 10000,
    });
    const {
        departments: departmentsByMappings,
        loading: loadingDepartmentsByMappings,
        refetch: refetchDepartmentsByMappings,
    } = useDepartments({
        department_branch_mapping: departmentMappingsToLookFor,
        limit: 10000,
    });
    const { roles: rolesByNames, loading: loadingRolesByNames, refetch: refetchRolesByNames } = useRoles({
        names: [],
        limit: 10000,
    });
    const {
        questionnaires: questionnairesByNames,
        loading: loadingQuestionnairesByNames,
        refetch: refetchQuestionnairesByNames,
    } = useTemplates({
        names: [],
        limit: 10000,
    });
    const columns = [
        {
            title: '#',
            key: 'sNo',
            render: (a: any, value: any, index: number) =>
                <Text>{(index + 1) + ((pagination.currentPage - 1) * pagination.limit)}</Text>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
        },
        {
            title: 'Email',
            render: (a: any) => loadingUsersByEmails ? <Spin /> : (
                a.user ? <Popover content={
                        <>
                            <div>
                                <Text strong>Name: </Text>
                                <Text>{a.user.name}</Text>
                            </div>
                            <div>
                                <Text strong>Email: </Text>
                                <Text>{a.user.email}</Text>
                            </div>
                            <div>
                                <Text strong>Phone: </Text>
                                <Text>{a.user.phone || 'NA'}</Text>
                            </div>
                            <div>
                                <Text strong>Created At: </Text>
                                <Text>{moment(a.created_at).format(DateFormat)}</Text>
                            </div>
                            <div>
                                <Text strong>Created By: </Text>
                                <Text>{a.created_by ? a.created_by.name : 'NA'}</Text>
                            </div>
                        </>
                    } title={a.name}>
                        <Link href={'#'} onClick={(e) => e.preventDefault()}>
                            {a.email}
                        </Link>
                    </Popover> :
                    <Text>{a.email}</Text>
            ),
        },
        {
            title: 'Branch',
            dataIndex: ['branch'],
            render: (a: any) => loadingDepartmentsByMappings ? <Spin /> : <div>
                {
                    a.id ? <Popover content={
                            <>
                                <div>
                                    <Text strong>Name: </Text>
                                    <Text>{a.name}</Text>
                                </div>
                                <div>
                                    <Text strong>Created By: </Text>
                                    <Text>{a.created_by ? a.created_by.name : 'NA'}</Text>
                                </div>
                            </>
                        } title={a.name}>
                            <Link href={'#'} onClick={(e) => e.preventDefault()}>
                                {a.name}
                            </Link>
                        </Popover> :
                        <Text>{a.name}</Text>
                }
            </div>,
        },
        {
            title: 'Department',
            dataIndex: ['department'],
            render: (a: any) => loadingDepartmentsByMappings ? <Spin /> : <div>
                {
                    a.id ? <Popover content={
                            <>
                                <div>
                                    <Text strong>Name: </Text>
                                    <Text>{a.name}</Text>
                                </div>
                                <div>
                                    <Text strong>Created By: </Text>
                                    <Text>{a.created_by ? a.created_by.name : 'NA'}</Text>
                                </div>
                            </>
                        } title={a.name}>
                            <Link href={'#'} onClick={(e) => e.preventDefault()}>
                                {a.name}
                            </Link>
                        </Popover> :
                        <Text>{a.name}</Text>
                }
            </div>,
        },

        {
            title: 'Questionnaires',
            dataIndex: ['questionnaires'],
            render: (a: any[]) => loadingQuestionnairesByNames ? <Spin /> : <div>{a.map((q: any, index: number) => {
                return <div>{
                    q.id ? <Popover content={
                            <>
                                <div>
                                    <Text strong>Name: </Text>
                                    <Text>{q.name}</Text>
                                </div>
                                <div>
                                    <Text strong>Group: </Text>
                                    <Text>{q.group ? q.group.name : 'NA'}</Text>
                                </div>
                                <div>
                                    <Text strong>Created At: </Text>
                                    <Text>{moment(q.created_at).format(DateFormat)}</Text>
                                </div>
                                <div>
                                    <Text strong>Created By: </Text>
                                    <Text>{q.created_by ? q.created_by.name : 'NA'}</Text>
                                </div>
                            </>
                        } title={q.name}>
                            <Link href={'#'} onClick={(e) => e.preventDefault()}>
                                {q.name}
                            </Link>
                        </Popover> :
                        <Text>{q.name}</Text>
                }</div>;
            })}</div>,
        },
        {
            title: 'Roles',
            dataIndex: ['roles'],
            render: (a: any[]) => loadingRolesByNames ? <Spin /> : <div>{a.map((q: any, index: number) => {
                return <div>{
                    q.id ? <Popover content={
                            <>
                                <div>
                                    <Text strong>Name: </Text>
                                    <Text>{q.name}</Text>
                                </div>
                                <div>
                                    <Text strong>Description: </Text>
                                    <Text>{q.description}</Text>
                                </div>
                                <div>
                                    <Text strong>Created At: </Text>
                                    <Text>{moment(q.created_at).format(DateFormat)}</Text>
                                </div>
                                <div>
                                    <Text strong>Created By: </Text>
                                    <Text>{q.created_by ? q.created_by.name : 'NA'}</Text>
                                </div>
                            </>
                        } title={q.name}>
                            <Link href={'#'} onClick={(e) => e.preventDefault()}>
                                {q.name}
                            </Link>
                        </Popover> :
                        <Text>{q.name}</Text>
                }</div>;
            })}</div>,
        },
        {
            title: 'Is Doctor',
            dataIndex: 'is_doctor',
            render: (a: any) => a && <CheckCircleOutlined />,
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
        },
        {
            fixed: 'right' as 'right',
            width: '150px',
            title: 'Action',
            key: 'action',
            render: (a: any, v: any, index: number) => {
                return <Row gutter={10}>
                    {
                        <Col>
                            <Tooltip title={'Delete'}>
                                <Button shape='circle' danger={true} onClick={() => {
                                    users.splice(index, 1);
                                    setUsers(JSON.parse(JSON.stringify(users)));
                                }}
                                        icon={<DeleteFilled />}
                                        size={'middle'} />
                            </Tooltip>
                        </Col>
                    }
                </Row>;
            },
        },
    ];
    const fetchUsersByEmails = (u?: any[]) => {
        u = u || users;
        const emails = u.map((a: any) => a.email);

        setEmailsToLookFor(emails);
    };
    const fetchRolesByNames = (u?: any[]) => {
        u = u || users;

        const r = u.filter((a: any) => !!a.roles).map((a: any) => a.roles);
        let roles: any[] = [];
        r.forEach((a: any) => {
            roles = [...roles, ...a];
        });
        const names = roles.map((a: any) => a.name);
        refetchRolesByNames({ names: _.uniq(names), limit: 1000 });
    };
    const fetchDepartmentsByNames = (u?: any[]) => {
        u = u || users;
        const r = u.filter((a: any) => !!a.department && !!a.branch).map((a: any) => {
            return {
                department_name: a.department.name,
                branch_name: a.branch.name,
                slug: a.branch.name + a.department.name,
            };
        });
        refetchDepartmentsByMappings({ department_branch_mapping: _.uniqBy(r, 'slug'), limit: 1000 });
    };
    const fetchQuestionnairesByNames = (u?: any[]) => {
        u = u || users;
        const r = u.filter((a: any) => !!a.questionnaires).map((a: any) => a.questionnaires);
        let questionnaires: any[] = [];
        r.forEach((a: any) => {
            questionnaires = [...questionnaires, ...a];
        });
        const names = questionnaires.map((a: any) => a.name);
        refetchQuestionnairesByNames({ names: _.uniq(names), limit: 1000 });
    };
    useEffect(() => {
        refetch({ emails: emailsToLookFor, limit: 1000 });
    }, [emailsToLookFor]);
    useEffect(() => {
        users.forEach((u: any) => {
            const f = usersByEmails.find((a: any) => {
                return a.email === u.email;
            });
            if (f) {
                u.user = f;
            } else {
                u.user = null;
            }
        });
        setUsers(JSON.parse(JSON.stringify(users)));
    }, [usersByEmails]);
    useEffect(() => {
        users.forEach((u: any) => {
            const f = departmentsByMappings.find((a: any) => {
                return u.department && u.branch && u.department.name.toLowerCase() === a.name.toLowerCase() && u.branch.name.toLowerCase() === a.branch.name.toLowerCase();
            });
            if (f) {
                u.branch = f.branch;
                u.department = f;
            } else {
                u.department = { name: u.department.name };
                u.branch = { name: u.branch.name };
            }
        });
        setUsers(JSON.parse(JSON.stringify(users)));
    }, [departmentsByMappings]);
    useEffect(() => {
        users.forEach((u: any) => {
            u.roles.forEach((r: any) => {
                const f = rolesByNames.find((a: any) => {
                    return a.name === r.name;
                });
                if (f) {
                    r.id = f.id;
                    r.created_by = f.created_by;
                    r.created_at = f.created_at;
                    r.description = f.description;
                } else {
                    delete r.id;
                    delete r.created_by;
                    delete r.created_at;
                    delete r.description;
                }
            });
        });
        setUsers(JSON.parse(JSON.stringify(users)));
    }, [rolesByNames]);
    useEffect(() => {
        users.forEach((u: any) => {
            u.questionnaires.forEach((r: any) => {
                const f = questionnairesByNames.find((a: any) => {
                    return a.name.toLowerCase() === r.name.toLowerCase();
                });
                if (f) {
                    r.id = f.id;
                    r.group = f.group;
                    r.created_by = f.created_by;
                    r.created_at = f.created_at;
                    r.description = f.description;
                } else {
                    delete r.id;
                    delete r.created_by;
                    delete r.created_at;
                    delete r.description;
                }
            });
        });
        setUsers(JSON.parse(JSON.stringify(users)));
    }, [questionnairesByNames]);

    return (
        <div className={'users-page page-wrapper'}>
            <Modal title='Upload Users' visible={showUpload} okButtonProps={{ hidden: true }} onCancel={
                () => {
                    setShowUpload(false);
                }
            }>
                <Upload maxCount={1} beforeUpload={(file) => {
                    let name = file.name;
                    const reader = new FileReader();
                    reader.onload = (evt: any) => { // evt = on_file_select event
                        /* Parse data */
                        const bstr = evt.target.result;
                        const wb = XLSX.read(bstr, { type: 'binary' });
                        /* Get first worksheet */
                        const wsname = wb.SheetNames[0];
                        const ws = wb.Sheets[wsname];
                        const u = parseBulkUsersFromXLSX(ws);
                        setUsers(u);
                    };
                    reader.readAsBinaryString(file);
                    setShowUpload(false);
                    return false;
                }}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Modal>
            <ListViewDesktop title={t('genDict.users')}
                             filters={[
                                 <Space size={0}>
                                     <Input value={search} placeholder={t('genDict.search')} onChange={
                                         (e: any) => {
                                             setSearch(e.target.value);
                                         }
                                     } />
                                 </Space>,
                             ]}
                             addActionConfig={
                                 hasPermissionOf(USERS_INSERT_ONE) && {
                                     text: 'Upload file',
                                     onClick: () => {
                                         setShowUpload(true);
                                     },
                                 }
                             }
                             additionalActionConfig={
                                 [
                                     {
                                         text: 'Go Sync',
                                         show: hasPermissionOf(USERS_INSERT_ONE),
                                         onClick: () => {
                                             console.log(users);
                                             const reqObjects: any[] = [];
                                             const obj: any[] = [];

                                             users.forEach((u) => {
                                                 if (!u.id && !u.user) {
                                                     const f = obj.find((a: any) => a.email === u.email);
                                                     if (!f) {
                                                         const user: any = {
                                                             name: u.name,
                                                             gender: u.gender,
                                                             is_doctor: u.is_doctor === 'Yes' || u.is_doctor === 'YES',
                                                             email: u.email,
                                                             phone: u.phone + '',
                                                             user_branches: {
                                                                 data: [],
                                                             }, user_roles: {
                                                                 data: [],
                                                             }, user_departments: {
                                                                 data: [],
                                                             }, user_groups: {
                                                                 data: [],
                                                             },
                                                         };
                                                         if (u.branch && u.branch.id) {
                                                             user.user_branches.data.push({ branch_id: u.branch.id });
                                                             user.user_branches.data = _.uniqBy(user.user_branches.data, 'branch_id');
                                                         }
                                                         if (u.department && u.department.id) {
                                                             user.user_departments.data.push({ department_id: u.department.id });
                                                             user.user_departments.data = _.uniqBy(user.user_departments.data, 'department_id');

                                                         }
                                                         if (u.questionnaires) {
                                                             u.questionnaires.forEach((q: any) => {
                                                                 console.log(q);
                                                                 if (q.id && q.group && q.group.id) {
                                                                     user.user_groups.data.push({ group_id: q.group.id });
                                                                     user.user_groups.data = _.uniqBy(user.user_groups.data, 'group_id');
                                                                 }
                                                             });
                                                         }
                                                         if (u.roles) {
                                                             u.roles.forEach((q: any) => {
                                                                 if (q.id) {
                                                                     user.user_roles.data.push({ role_id: q.id });
                                                                     user.user_roles.data = _.uniqBy(user.user_roles.data, 'role_id');
                                                                 }
                                                             });
                                                         }
                                                         obj.push(user);
                                                     } else {
                                                         const user: any = obj[obj.findIndex((a: any) => a.email === u.email)];
                                                         if (u.branch && u.branch.id) {
                                                             user.user_branches.data.push({ branch_id: u.branch.id });
                                                             user.user_branches.data = _.uniqBy(user.user_branches.data, 'branch_id');
                                                         }
                                                         if (u.department && u.department.id) {
                                                             user.user_departments.data.push({ department_id: u.department.id });
                                                             user.user_departments.data = _.uniqBy(user.user_departments.data, 'department_id');

                                                         }
                                                         if (u.questionnaires) {
                                                             u.questionnaires.forEach((q: any) => {
                                                                 if (q.id && q.group && q.group.id) {
                                                                     user.user_groups.data.push({ group_id: q.group.id });
                                                                     user.user_groups.data = _.uniqBy(user.user_groups.data, 'group_id');
                                                                 }
                                                             });
                                                         }
                                                         if (u.roles) {
                                                             u.roles.forEach((q: any) => {
                                                                 if (q.id) {
                                                                     user.user_roles.data.push({ role_id: q.id });
                                                                     user.user_roles.data = _.uniqBy(user.user_roles.data, 'role_id');
                                                                 }
                                                             });
                                                         }
                                                     }

                                                     //     // if (u) {
                                                     //     //
                                                     //     // }
                                                 }

                                             });
                                             console.log(obj);
                                             mutation({ variables: { objects: obj } });
                                         },
                                     },
                                     {
                                         text: 'Sync Users',
                                         show: hasPermissionOf(USERS_INSERT_ONE),
                                         onClick: () => {
                                             fetchUsersByEmails();
                                         },
                                     },
                                     {
                                         text: 'Sync Departments',
                                         show: hasPermissionOf(USERS_INSERT_ONE),
                                         onClick: () => {
                                             fetchDepartmentsByNames();
                                         },
                                     },
                                     {
                                         text: 'Sync Questionnaires',
                                         show: hasPermissionOf(USERS_INSERT_ONE),
                                         onClick: () => {
                                             fetchQuestionnairesByNames();
                                         },
                                     },
                                     {
                                         text: 'Sync Roles',
                                         show: hasPermissionOf(USERS_INSERT_ONE),
                                         onClick: () => {
                                             fetchRolesByNames();
                                         },
                                     },
                                 ]
                             }
            >
                <Table
                    sticky={true}

                    locale={{
                        emptyText: (
                            <>
                                <Space direction={'vertical'} size={10} style={{ padding: '10px' }}>
                                    <Title level={3} className={'text-primary'}>{t('genDict.woops')}</Title>
                                    <Text className={'text-primary'}>No User added</Text>
                                    <Button type={'primary'}
                                            onClick={() => setShowUpload(true)}>{t('genDict.add')}</Button>
                                </Space>
                            </>
                        ),
                    }}
                    pagination={{
                        pageSize: 10000,
                        hideOnSinglePage: true,
                    }}
                    scroll={{ x: true }}
                    dataSource={users}
                    columns={columns} />
            </ListViewDesktop>
        </div>
    );
};


const UserCreateBulk: React.FunctionComponent<any> = (users: any[]) => {
    const history = useHistory();
    const location = useLocations();
    const [currentUserIndex, setCurrentUser] = useState(0);
    const { mutation, saving } = useUserCreate((e: any) => {
        if (e) {
            notification.success({ message: 'Created successfully' });
            history.goBack();
        }
    });

    const onSubmit = (object: any) => {
        mutation({ variables: { object } });
    };
    return (
        <div className={'user-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'User Create'}>
                <UserForm saving={saving} onCancel={() => history.goBack()} onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};
export default UsersBulkPage;
