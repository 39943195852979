import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    notification,
    Skeleton,
} from 'antd';
import BranchForm from './BranchForm';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import useBranchById from 'shared/hooks/Branches/useBranchById';
import useBranchUpdate from 'shared/hooks/Branches/useBranchUpdate';

type Props = {}

const BranchEdit: React.FunctionComponent<Props> = () => {
    const history = useHistory();
    const RouteParams: { id: string, mode: 'edit' } = useParams();
    const { loading: loadingBranch, branch } = useBranchById(RouteParams['id']);

    const {
        mutation,
        saving,
    } = useBranchUpdate((e: any) => {
        if (e) {
            notification.success({ message: 'Updated successfully' });
            history.goBack();
        }
    });

    if (!RouteParams['id']) {
        history.goBack();
    }

    const onSubmit = (set: any) => {
        mutation({ variables: { set, id: RouteParams['id'] } });
    };
    return (
        <div className={'branch-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Branch Edit '}>
                <BranchForm loading={loadingBranch} saving={saving} branch={branch}
                            onCancel={() => history.goBack()}
                            onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};


export default BranchEdit;
