import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
  procedureType: any;
  loading: boolean;
  error: any;
};

const QUERY = gql`
  query ($id:uuid!){
    c_meta_encounter_types_by_pk(id: $id) {
      name
      id
      department_id
      enable_multiple_prescriptions
      department{
        name
        branch{
          name
        }
      }
    }
  }
`;

const useProcedureTypeById: (id: string) => ReturnType = (id) => {
  const { data, loading, error } = useQuery(QUERY, { variables: { id } });
  let procedureType = null;
  if (!loading && !error) {
    procedureType = data.c_meta_encounter_types_by_pk;
  }
  return {
    procedureType,
    loading,
    error,
  };
};
export default useProcedureTypeById;
