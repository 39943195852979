import React from "react";
import ValidationComponent from "./ValidationComponent";
import { Space, Col, Tooltip, Radio } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import "./tooltip.css"
class RadioComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue = (event) => {
        let name = event.target.name.split("-")
        this.props.emitTextboxValue({ name: name[2], value: event.target.value, type: 'radio' })
    }

    getUId() {
        var charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charSetSize = charSet.length;
        var id = '';
        for (var i = 1; i <= 4; i++) {
            var randPos = Math.floor(Math.random() * charSetSize);
            id += charSet[randPos];
        }
        return id;
    }

    onRadioButtonClicked = (event) => {
        // console.log(event.target.name);
    }

    checkIfValuePresentInValuesArray(value, valuesArray) {
        if (!value || !valuesArray) {
            return;
        }
        for (let index in valuesArray) {
            if (valuesArray[index].key === value) {
                return true
            }
        }
        return false;
    }

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str, value) {
        if (this.props.viewType) {
            if (!value) {
                return false
            }
            if (!str || !str.trim()) {
                return false;
            }
            return true
        } else {
            if (!str || !str.trim()) {
                return false;
            }
            return true
        }

    }

    render() {
        const { type, name, classname, value, placeholder, label, labelSize, size, options = [],
            offset, rowType, info, viewType, validation } = this.props;
        return (
            <>
                {labelSize &&
                    <>
                        <Col span={`${labelSize * 2}`}
                            style={{
                                textAlign: "left",
                                paddingRight: 10,
                                display: this.checkForLabelValue(label, value) ? '' : 'none'
                            }}>
                            <label ant-form-item-label for="basic_username" className="ant-form-item"
                                style={{ color: "#000000d9" }}>
                                {/* ant-form-item-required */}
                                {label} {validation && validation.required &&
                                    <span style={{ color: '#ff4d4f' }}>*</span>
                                } {info &&
                                    <Tooltip title={info}>
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            </label>
                        </Col>
                    </>
                }


                <Col span={`${size * 2}`} style={{ marginBottom: '2rem' }}>
                    {!labelSize && <label className="form-label "
                        style={{
                            display: this.checkForLabelValue(label, value) ? '' : 'none',
                            paddingRight: 10, color: "#000000d9"
                        }}>{label}</label>}
                    <div className="d-sm-flex" style={{ flexWrap: 'wrap' }}>
                        <Space size={[8, 14]} wrap>
                            {viewType ? (
                                <>
                                    {value}
                                </>
                            ) : (
                                <>
                                    {options.map((indOption, index) => {
                                        return <div className="form-check mr-3 mt-1 mb-1" key={index} >
                                            <label className="form-check-label">
                                                <Radio type="radio"
                                                    className="form-check-input"
                                                    name={`${this.getUId()}-${label}-${name}`}
                                                    id={name} value={indOption.value || ''}
                                                    checked={indOption.value === value ? true : false}
                                                    onClick={this.changeValue.bind(this)} />
                                                {indOption.value}   <i className="input-helper"></i></label>
                                        </div>
                                    })}
                                </>
                            )

                            }

                        </Space>
                    </div>
                    {this.props.validation && <ValidationComponent value={value} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)} />}
                </Col>
                {offset &&
                    <div className={offset}> </div>
                }
            </>
        );
    }
}

export default RadioComponent;
