import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    department: any;
    loading: boolean;
    error: any;
};

const QUERY = gql`
    query ($id:uuid!){
        c_org_departments_by_pk(id: $id) {
            id
            name
            branch_id
            code
            created_at
        }
    }
`;

const useDepartmentById: (id: string) => ReturnType = (id) => {
    const { data, loading, error } = useQuery(QUERY, { variables: { id } });
    let department = null;
    if (!loading && !error) {
        department = data.c_org_departments_by_pk;
    }
    return {
        department,
        loading,
        error,
    };
};
export default useDepartmentById;
