import * as React from 'react';
import { Button, Col, Divider, Row, Typography } from 'antd';
import './index.scss';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface FormViewDesktopProps {
    title?: any,
    backActionConfig?: any,
    children?: any,
}

export default function FormViewDesktop({ title, children, backActionConfig }: FormViewDesktopProps) {
    return (
        <div className={'desktop-form-view-wrapper'}>
            {
                title && <div className={'title-bar'}>
                    <Row gutter={20} align={'middle'} wrap={false}>
                        <Col flex={'auto'}>
                            <Row gutter={20} align={'middle'}>
                                {
                                    backActionConfig && backActionConfig.onClick && <Col>
                                        <Button type={'primary'}
                                                onClick={backActionConfig.onClick}>
                                            {backActionConfig.text}
                                        </Button>
                                    </Col>
                                }

                                <Col flex={'auto'}>
                                    <Title level={4} className={'page-title'}>{title}</Title>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            <div className='form-wrapper'>
                {
                    children && children
                }
            </div>
        </div>
    );
}
;
