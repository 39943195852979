import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Skeleton,
} from 'antd';
import { useEffect, useState } from 'react';
import useLocations from 'shared/hooks/useLocations';
import useUserGroupById from 'shared/hooks/Groups/useGroupById';
import useGroupsUpdate from 'shared/hooks/Groups/useGroupsUpdate';
import GroupForm from './GroupForm';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import DepartmentForm from '../../Departments/CreateOrEdit/DepartmentForm';

type Props = {}

const GroupEdit: React.FunctionComponent<Props> = () => {
    const history = useHistory();
    const location = useLocations();
    const RouteParams: { id: string, mode: 'edit' } = useParams();
    const { loading: loadingGroup, group, error: fetchingGroupError } = useUserGroupById(RouteParams['id']);

    const {
        mutation,
        saving,
        group: savedUserGroup,
    } = useGroupsUpdate((e: any) => {
        if (e) {
            history.goBack();
        }
    });
    if (loadingGroup) {
        return <Skeleton active={true} />;
    }
    const onSubmit = (data: any) => {
        mutation({ variables: { name: data.name, id: RouteParams['id'] } });
    };
    return (
        <div className={'user-group-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Q Group Edit'}>
                <GroupForm saving={saving} group={group} onCancel={() => history.goBack()}
                           onSubmit={onSubmit} />
            </FormViewDesktop>

        </div>
    );
};


export default GroupEdit;
