import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    encounter: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
    mutation ( $id: uuid!, $set: c_patient_patient_encounters_set_input!){
        update_c_patient_patient_encounters_by_pk(_set: $set , pk_columns: {id:$id}) {
            id
        }
    }
`;

const useUpdatePatientsProcedures: (onCompleted: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted });
    let encounter = [];
    if (!saving && !error && data) {
        encounter = data.update_c_patient_patient_encounters_by_pk;
    }
    return {
        mutation,
        encounter,
        saving,
        error,
    };
};
export default useUpdatePatientsProcedures;
