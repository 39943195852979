import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import { GlobalListLimit } from '../../constants/AppConstants';

type ReturnType = {
    cities: any[];
    loading: boolean;
    error: any;
    refetch: any;
};

const QUERY = gql`
    query($where: c_meta_locations_bool_exp!) {
        c_meta_locations(distinct_on: [city_code], where: $where){
            city
            id
        }
    }
`;

const createFilters = (filters?: Filters) => {
    const result: any = { where: { _and: [] } };
    if (!filters) {
        return result;
    }
    if (filters['city']) {
        result['where']['_and'].push({ 'city': { _ilike: `%${filters['city']}%` } });
    }
    if (filters['state']) {
        result['where']['_and'].push({ 'state': { _ilike: `%${filters['state']}%` } });
    }
    if (filters['country']) {
        result['where']['_and'].push({ 'country': { _ilike: `%${filters['country']}%` } });
    }
    if (filters['city_code']) {
        result['where']['_and'].push({ 'city_code': { _ilike: `%${filters['city_code']}%` } });
    }
    if (filters['state_code']) {
        result['where']['_and'].push({ 'state_code': { _ilike: `%${filters['state_code']}%` } });
    }
    if (filters['country_code']) {
        result['where']['_and'].push({ 'country_code': { _ilike: `%${filters['country_code']}%` } });
    }
    return result;
};
const useCities: (filters: Filters) => ReturnType = (filters) => {
    const { data, loading, error, refetch: refresh } = useQuery(QUERY, { variables: createFilters(filters) });
    let cities = [];
    const refetch = (filters?: Filters) => {
        return refresh(createFilters(filters));
    };
    if (!loading && !error) {
        cities = data.c_meta_locations;
    }
    return {
        cities,
        loading,
        error,
        refetch,
    };
};
export default useCities;
