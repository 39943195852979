import * as React from 'react';
import { Button, Space, Menu, Dropdown, Input, Select } from 'antd';

import { useEffect, useState } from 'react';
import useBranches from '../../hooks/Branches/useBranches';
import useDepartments from '../../hooks/Departments/useDepartments';
import useUserDepartments from '../../hooks/UserDepartments/useUserDepartments';
import useRoles from '../../hooks/Roles/useRoles';


export const SelectSearchFilter:
    React.FunctionComponent<{ searchSelectionItems: { name: string, key: string }[], value?: any, onChange: any }> =
    ({
         onChange,
         value,
         searchSelectionItems,
     }) => {
        const [selectedSearchOption, setSelectedSearchOption] = useState(searchSelectionItems[0]);
        const [search, setSearch] = useState('');
        useEffect(() => {
            if (value) {
                for (let i in value) {
                    const selected = searchSelectionItems?.find((a) => a.key === i);
                    if (selected) {
                        setSelectedSearchOption(selected);
                        setSearch(value[i]);
                    }
                }
            }
        }, []);
        const searchSelectionMenu = (
            <Menu onClick={(e: any) => {
                // @ts-ignore
                setSelectedSearchOption(searchSelectionItems.find((a: any) => a.key === e.key));
                setSearch('');
                onChange({});
            }}>
                {
                    searchSelectionItems.map((sO) => {
                        return <Menu.Item key={sO.key}>
                            {sO.name}
                        </Menu.Item>;
                    })
                }
            </Menu>
        );
        return <Space size={0} className={'select-search-filter'}>
            <Dropdown overlay={searchSelectionMenu}>
                <Button type={'primary'} style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                }}>
                    {selectedSearchOption.name}
                </Button>
            </Dropdown>
            <Input style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            }} value={search} placeholder={`Search ${selectedSearchOption.name}`} onChange={(e: any) => {
                onChange({ key: selectedSearchOption.key, value: e.target.value });
                setSearch(e.target.value);
            }
            } />
        </Space>;
    };


export const BranchSelectFilter:
    React.FunctionComponent<{ onChange: any, value?: string }> =
    ({
         onChange,
         value,

     }) => {
        const [selectedBranch, setSelectedBranch] = useState(value);
        const { branches } = useBranches({ limit: 100 });
        useEffect(() => {
            if (branches.length === 1 && !selectedBranch) {
                onChange(branches[0].id);
                setSelectedBranch(branches[0].id);
            }
        }, [branches]);
        return <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
            allowClear={true} placeholder={'Select Hospital'} style={{ minWidth: '200px' }}
                       value={selectedBranch} onChange={(e: any) => {
            setSelectedBranch(e);
            onChange(e);
        }
        }>
            <Select.Option value={''}>All Hospitals</Select.Option>
            {
                branches.map((branch: any) => {
                    return <Select.Option value={branch.id}>{branch.name}</Select.Option>;
                })
            }
        </Select>;
    };

export const RoleSelectFilter:
    React.FunctionComponent<{ onChange: any, value?: string }> =
    ({
         onChange,
         value,

     }) => {
        const [selectedRole, setSelectedRole] = useState(value);
        const { roles } = useRoles({ limit: 100 });
        useEffect(() => {
            if (roles.length === 1 && !selectedRole) {
                onChange(roles[0].id);
                setSelectedRole(roles[0].id);
            }
        }, [roles]);
        return <Select allowClear={true} placeholder={'Select Role'} style={{ minWidth: '200px' }}
                       value={selectedRole} onChange={(e: any) => {
            setSelectedRole(e);
            onChange(e);
        }
        }>
            <Select.Option value={''}>All Roles</Select.Option>
            {
                roles.map((branch: any) => {
                    return <Select.Option value={branch.id}>{branch.name}</Select.Option>;
                })
            }
        </Select>;
    };


export const DepartmentSelectFilter:
    React.FunctionComponent<{ branchId?: any, userId: any, value?: string, onChange: any }> =
    ({
         branchId,
         userId,
         value,
         onChange,
     }) => {
        const [selectedDepartment, setSelectedDepartment] = useState(value);
        const { userDepartments, loading: loadingUserDepartments } = useUserDepartments({
            limit: 100,
            user_id: userId,
            branch_ids: [branchId],
        });
        return <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
            loading={loadingUserDepartments} allowClear={true} placeholder={'Select Department'}
                       style={{ minWidth: '200px' }}
                       value={selectedDepartment} onChange={(e: any) => {
            setSelectedDepartment(e);
            onChange(e);
        }
        }>
            <Select.Option value={''}>All Departments</Select.Option>
            {
                userDepartments.map((department: any) => {
                    return <Select.Option value={department.department.id}>{department.department.name}</Select.Option>;
                })
            }
        </Select>;
    };

