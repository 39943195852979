import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import PreferencesView from 'shared/view/preferences/Preferences';
import {
    Button,
    Card,
    Col,
    Modal,
    Divider,
    Form,
    Row,
    Select,
    Skeleton,
    Space,
    Tooltip,
    Steps,
    notification,
} from 'antd';

import { AuthConsumer } from 'shared/contexts/AuthContext';
import usePrintPreference from 'shared/hooks/AccountPreferences/usePrintPreference';
import usePrintPreferenceAdd from 'shared/hooks/AccountPreferences/usePrintPreferenceAdd';
import { hasPermissionOf } from 'shared/services/Utility';

import { log } from 'util';


const { confirm } = Modal;
const { Step } = Steps;
type Props = {}

const PreferencesPage: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const RouteParams: any = useParams();
    
    
    const { preferences, loading, refetch } = usePrintPreference();

    const {
        mutation,
        saving,
    } = usePrintPreferenceAdd((e: any) => {
        if (e) {
            notification.success({ message: 'Updated successfully' });
            
        }
    });

    const onSubmit=(data: any)=>{
        //console.log(data);
        //console.log("json data",JSON.stringify(data));
        mutation({ variables: {  data: JSON.stringify(data) } });
        //console.log(draftToHtml((data.signature)));
    };
    
    if (loading) {
        return <Skeleton active={true} />;
    }
    return (
        <AuthConsumer>
            {({ user }) => (
                <div className={'preferences-edit-page page-wrapper'}>
                    
                   <PreferencesView preferences={preferences} onSubmit={onSubmit} onCancel={() => history.goBack()} loading={loading}/>
                 

                   
                </div>
            )}
        </AuthConsumer>

    );
};


export default PreferencesPage;
