import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ListViewDesktop from 'shared/components/layout/Desktop/ListView/ListViewDesktop';
import {
    TEAM_ADD_ROUTE, TEAM_EDIT_ROUTE,
} from 'shared/constants/RouteConstants';
import { Button, Space, Col, Row, Table, Tooltip, Typography, Input, Pagination } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import useDebounce from 'shared/hooks/useDebounce';
import { GlobalListLimit, ListPagination } from 'shared/constants/AppConstants';
import { hasPermissionOf } from 'shared/services/Utility';
import {
    BRANCHES_UPDATE_ONE, TEAMS_INSERT_ONE, TEAMS_UPDATE_ONE,
} from 'shared/constants/Permissions';
import useTeams from 'shared/hooks/Teams/useTeams';


const { Text } = Typography;
type Props = {}


const TeamsPage: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [search, setSearch] = useState();
    const [pagination, setPagination] = useState({ limit: GlobalListLimit, offset: 0 } as ListPagination);
    const debouncedSearch = useDebounce(search, 500);

    const { teams, total, loading: loadingTeams, refetch } = useTeams({ ...pagination });

    const columns = [
        {
            title: '#',
            key: 'sNo',
            render: (a: any, value: any, index: number) => <Text>{index + 1}</Text>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Department',
            dataIndex: ['department', 'name'],
        },
        {
            fixed: 'right' as 'right',
            width: '150px',
            title: 'Action',
            key: 'action',
            render: (a: any) => {
                return <Row gutter={10}>
                    {
                        hasPermissionOf(TEAMS_UPDATE_ONE) && <Col>
                            <Tooltip title={t('genDict.edit')}>
                                <Button shape='circle' onClick={() => history.push(TEAM_EDIT_ROUTE(a.id))}
                                        icon={<EditFilled />}
                                        size={'middle'} />
                            </Tooltip>
                        </Col>
                    }
                </Row>;
            },
        },
    ];
    useEffect(() => {
        refetch({ search, ...pagination });
    }, [debouncedSearch, pagination]);
    return (
        <div className={'teams-page page-wrapper'}>
            <ListViewDesktop title={t('genDict.teams')}
                             filters={[
                                 <Space size={0}>
                                     <Input value={search} placeholder={t('genDict.search')} onChange={
                                         (e: any) => {
                                             setSearch(e.target.value);
                                         }
                                     } />
                                 </Space>,
                             ]}
                             addActionConfig={
                                 hasPermissionOf(TEAMS_INSERT_ONE) && {
                                     text: t('genDict.add'),
                                     onClick: () => history.push(TEAM_ADD_ROUTE),
                                 }
                             }
            >
                <Table
                    locale={{
                        emptyText: (
                            <>
                                <Space direction={'vertical'} size={10} style={{ padding: '10px' }}>
                                    <Title level={3} className={'text-primary'}>{t('genDict.woops')}</Title>
                                    <Text className={'text-primary'}>No Team added</Text>
                                    <Button type={'primary'}
                                            onClick={() => history.push(TEAM_ADD_ROUTE)}>{t('genDict.add')}</Button>
                                </Space>
                            </>
                        ),
                    }}
                    pagination={{
                        pageSize: pagination.limit,
                        total,
                        hideOnSinglePage: true,
                        onChange: (newPage: number) => {
                            setPagination({ ...pagination, offset: (newPage - 1) * pagination.limit });
                        },
                        current: (pagination.offset / pagination.limit) + 1,
                    }} scroll={{ x: true }}
                    dataSource={teams}
                    loading={loadingTeams}
                    columns={columns} />
            </ListViewDesktop>
        </div>
    );
};

export default TeamsPage;
