import React from "react";

class NewCompactViewComponent extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { data, sectionData } = this.props;
        return !!data &&
            data.map((indObj, index) =>
                indObj.type === 'group' ?
                    <>
                        <>
                            <p className="mt-0 mb-1 align-items-center" style={{ display: indObj && indObj.rowName ? '' : 'none' }}><span style={{ borderBottom: "1px solid", fontStyle: "italic" }}>{indObj.rowName} </span>:</p>
                            {indObj.data && indObj.data.map((indArray, jndex) =>
                                <React.Fragment key={jndex}>
                                    {indArray && indArray.length > 0 && indArray.map((indItem, kndex) =>
                                        <>
                                            <span className="mr-0 pb-2" key={kndex} style={{ margin: '0px 5px' }}>{indItem}{(indArray.length - 1) === kndex ? '' : ','}</span>
                                        </>
                                    )}
                                    {/* {!sectionData.ordered &&
                                        <>
                                            {((data.length - 1) !== jndex) &&
                                                <>, </>
                                            }
                                        </>
                                    } */}
                                </React.Fragment>
                            )}
                        </>
                    </> : <React.Fragment key={index}>
                        {indObj.data && indObj.data.map((indArray, jndex) =>
                            <>
                                {indArray &&
                                    <span key={jndex} className="mr-0 pb-2">{indObj.ordered && <>{index + 1})</>} {indArray} {(indObj.data.length - 1) === jndex ? `` : ','}</span>
                                }
                                {!sectionData.ordered ? (
                                    <>
                                        {((sectionData.data.length - 1) !== index) &&
                                            <>, </>
                                        }
                                    </>
                                ) : (
                                        <span className="mr-1"> </span>
                                    )
                                }
                            </>
                        )}
                    </React.Fragment>
            )
    }
}

export default NewCompactViewComponent;
