import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    editor: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
    mutation ( $id: uuid!,  $set: c_patient_patient_encounter_editors_set_input!){
        update_c_patient_patient_encounter_editors_by_pk(pk_columns: {id: $id} ,_set:$set) {
            id
        }
    }
`;

const useRemoveEditorsToProcedures: (onCompleted: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted });
    let editor = null;
    if (!saving && !error && data) {
        editor = data.update_c_patient_patient_encounter_editors_by_pk;
    }
    return {
        mutation,
        editor,
        saving,
        error,
    };
};
export default useRemoveEditorsToProcedures;
