import React from "react";


class XYTableViewComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        }
        // this.formatTableDataBasedOnType();
    }

    getFormatedDate(date) {
        if (!date) {
            return
        }
        let newDate = "";
        if (date.indexOf("Date :") !== -1) {
            let dateArray = date.split('Date :');
            newDate = dateArray[1];
            newDate = newDate.trim();

            if ((new Date(newDate).toString()) !== "Invalid Date") {
                var startDate = new Date(newDate);
                var sdate = startDate.getDate();
                var smonth = startDate.getMonth() + 1;
                var syear = startDate.getFullYear();
                if (sdate < 10) {
                    sdate = '0' + sdate;
                }
                if (smonth < 10) {
                    smonth = '0' + smonth;
                }
                return sdate + "-" + (smonth) + '-' + syear
            }
            return ''
        }
        return date;
    }

    render() {
        const { data } = this.props;
        return (
            <React.Fragment>
                
                {data && data.length > 0 &&
                    <React.Fragment>
                        {data.map((inData, index) =>
                            <div className="table-responsive drugstable" key={index + inData}>
                                 
                                <table className="table table-sm table-bordered" style={{ border: "1px solid #ebedf2" }}>
                                    <thead className=" thead" style={{ textTransform: 'capitalize', color: '#000' }}>
                                        <tr>
                                            {inData.headerData &&
                                                <React.Fragment>
                                                     <th>#</th>
                                                    {inData.headerData.map((indHeader, jndex) =>
                                                        <th key={jndex + indHeader}> {indHeader} </th>
                                                    )}
                                                </React.Fragment>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody style={{ color: '#000' }}>
                                        {!!inData.bodyData &&
                                            <React.Fragment>
                                                 
                                                    <>
                                                        
                                                            {inData.bodyData&&inData.bodyData.map((indTableElement, kndex) =>
                                                            <tr key={kndex}>
                                                                <React.Fragment key={kndex}>
                                                                {indTableElement.map((inElem,fndex)=>
                                                                 <td key={fndex} style={{ whiteSpace: "break-spaces" }}>{inElem}</td>
                                                                )}
                                                                   
                                                                </React.Fragment>
                                                                 </tr>
                                                                )}
                                                       
                                                    </>
                                            </React.Fragment>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </React.Fragment>
                }

            </React.Fragment>
        );
    }
}

export default XYTableViewComponent;


