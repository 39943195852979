import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import { GlobalListLimit } from '../../constants/AppConstants';

type ReturnType = {
    groups: any[];
    loading: boolean;
    error: any;
    total: number;
    refetch: any;
};

const QUERY = gql`
    query ($where: c_org_groups_bool_exp, $limit: Int, $offset: Int){
        c_org_groups(where: $where, limit: $limit, offset: $offset) {
            name
            created_at
            id
            branch_id
            branch{
                name
                location{
                    city
                    state
                    country
                }
            }
            created_by{
                name
            }
        }
        c_org_groups_aggregate(where: $where,){
            aggregate{
                count
            }
        }
    }
`;

const createFilters = (filters?: Filters) => {
    const result: any = { where: { _and: [] }, limit: GlobalListLimit, offset: 0 };
    if (!filters) {
        return result;
    }
    if (filters['search']) {
        result['where']['_and'].push({
            '_or': [
                { name: { _ilike: `%${filters['search']}%` } },
            ],
        });
    }
    if (filters['notIds']) {
        result['where']['_and'].push({
            id: {
                _nin: filters['notIds'],
            },
        });
    }
    result['limit'] = filters['limit'] || GlobalListLimit;
    result['offset'] = filters['offset'] || 0;
    return result;
};

const useGroups: (filters?: Filters) => ReturnType = (filters) => {
    const { data, loading, error, refetch: refresh } = useQuery(QUERY, { variables: createFilters(filters) });
    let groups = [];
    let total = 0;
    if (!loading && !error) {
        groups = data.c_org_groups;
        total = data.c_org_groups_aggregate.aggregate.count;
    }
    const refetch = (filters?: Filters) => {
        return refresh(createFilters(filters));
    };
    return {
        total,
        groups,
        loading,
        error,
        refetch,
    };
};
export default useGroups;
