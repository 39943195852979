import * as React from 'react';
import { useEffect, useState } from 'react';
import './index.scss';
import { Typography, Avatar, Layout, Row, Col, notification, Tag, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import {
    BRANCHES_LIST_ROUTE,
    DEPARTMENTS_LIST_ROUTE, ENCOUNTER_TYPE_ADD_ROUTE, ENCOUNTER_TYPES_LIST_ROUTE,
    GROUPS_LIST_ROUTE,
    LOCATIONS_LIST_ROUTE,
    PATIENT_REGISTRATION_LIST_ROUTE,
    PROCEDURES_LIST_ROUTE,
    QUESTIONNAIRES_LIST_ROUTE,
    ROLES_LIST_ROUTE, ROUTE_PROFILE,
    PREFERENCES_ROUTE,
    TABLE_PERMISSIONS_LIST_ROUTE,
    TEAMS_LIST_ROUTE,
    USERS_LIST_ROUTE,
    EXTERNAL_DASHBOARD_URL,
} from '../../../../constants/RouteConstants';
import { SignOutButton } from '../../../../Apps/CustomAzureAD/MSComponents/SignOutButton';
import {
  BRANCHES_INSERT_ONE,
  BRANCHES_SELECT,
  DEPARTMENTS_INSERT_ONE,
  DEPARTMENTS_SELECT, ENCOUNTER_TYPES_INSERT_ONE,
  ENCOUNTER_TYPES_SELECT,
  ENCOUNTERS_SELECT,
  GROUPS_INSERT_ONE,
  GROUPS_SELECT,
  LOCATIONS_INSERT_ONE,
  LOCATIONS_SELECT, LOCATIONS_UPDATE_ONE,
  PATIENTS_ENCOUNTER_SELECT,
  PATIENTS_SELECT,
  ROLES_SELECT,
  PREFERENCES,
  TABLE_PERMISSIONS_SELECT,
  TEAMS_SELECT, TEMPLATES_INSERT_ONE,
  TEMPLATES_SELECT,
  USERS_INSERT_ONE,
  USERS_SELECT, SHOW_DASHBOARD, ACCOUNT_PREFERENCES, ROLES_INSERT_ONE,
} from '../../../../constants/Permissions';
import { latestAllowedRoute } from '../../../../services/Utility';
// @ts-ignore
import LOGO from '../../../../images/Logo.jpg';

const { Header } = Layout;

const { Text } = Typography;
export default function DesktopNavbar({ hasPermissionOf, user, authStatus }: any) {
    const { t } = useTranslation();
    const [changed, setChanged] = useState(0);

    if (authStatus !== 'AUTHENTICATED') {
        return <></>;
    }
    return (
        <Header className={'desktop-header'}>
            <Row gutter={20} align={'middle'}>
                <Col flex={'auto'}>
                    <Row gutter={20} align={'middle'}>
                        <Col>
                            <div className={'logo'}>
                                <NavLink to={latestAllowedRoute()}>
                                    <img src={LOGO} alt='Image' className='logo-thb' />
                                </NavLink>
                            </div>
                        </Col>
                        {
                            hasPermissionOf(PATIENTS_SELECT) && <Col>
                                <NavLink to={PATIENT_REGISTRATION_LIST_ROUTE} activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        {t('genDict.registrations')}
                                    </div>
                                </NavLink>
                            </Col>
                        }
                        {
                            hasPermissionOf(PATIENTS_ENCOUNTER_SELECT) && <Col>
                                <NavLink to={PROCEDURES_LIST_ROUTE} activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        {t('genDict.procedures')}
                                    </div>
                                </NavLink>
                            </Col>
                        }

                        {
                            hasPermissionOf(TEMPLATES_INSERT_ONE) && <Col>
                                <NavLink to={QUESTIONNAIRES_LIST_ROUTE} activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        {t('genDict.questionnaires')}
                                    </div>
                                </NavLink>
                            </Col>
                        }
                        {
                            hasPermissionOf(ENCOUNTER_TYPES_INSERT_ONE) && <Col>
                                <NavLink to={ENCOUNTER_TYPES_LIST_ROUTE} activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        {t('genDict.procedureTypes')}
                                    </div>
                                </NavLink>
                            </Col>
                        }
                        {
                            hasPermissionOf(LOCATIONS_INSERT_ONE) && hasPermissionOf(LOCATIONS_UPDATE_ONE) && <Col>
                                <NavLink to={LOCATIONS_LIST_ROUTE} activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        {t('genDict.locations')}
                                    </div>
                                </NavLink>
                            </Col>
                        }
                        {
                            hasPermissionOf(BRANCHES_INSERT_ONE) && <Col>
                                <NavLink to={BRANCHES_LIST_ROUTE} activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        {t('genDict.branches')}
                                    </div>
                                </NavLink>
                            </Col>
                        }
                        {
                            hasPermissionOf(DEPARTMENTS_INSERT_ONE) && <Col>
                                <NavLink to={DEPARTMENTS_LIST_ROUTE} activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        {t('genDict.departments')}
                                    </div>
                                </NavLink>
                            </Col>
                        }
                        {
                            hasPermissionOf(GROUPS_INSERT_ONE) && <Col>
                                <NavLink to={GROUPS_LIST_ROUTE} activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        Q Groups
                                    </div>
                                </NavLink>
                            </Col>
                        }
                        {/*{*/}
                        {/*    hasPermissionOf(TEAMS_SELECT) && <Col>*/}
                        {/*        <NavLink to={TEAMS_LIST_ROUTE} activeClassName='active-nav-link'>*/}
                        {/*            <div className='custom-nav-link'>*/}
                        {/*                {t('genDict.teams')}*/}
                        {/*            </div>*/}
                        {/*        </NavLink>*/}
                        {/*    </Col>*/}
                        {/*}*/}
                        {
                            hasPermissionOf(USERS_INSERT_ONE) && <Col>
                                <NavLink to={USERS_LIST_ROUTE} activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        {t('genDict.users')}
                                    </div>
                                </NavLink>
                            </Col>
                        }
                        {
                            hasPermissionOf(ROLES_INSERT_ONE) && <Col>
                                <NavLink to={ROLES_LIST_ROUTE} activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        {t('genDict.roles')}
                                    </div>
                                </NavLink>
                            </Col>
                        }
                        {
                            hasPermissionOf(ACCOUNT_PREFERENCES) && <Col>
                                <NavLink to={PREFERENCES_ROUTE} activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        {t('nav.preferences')}
                                    </div>
                                </NavLink>
                            </Col>
                        }
                        {/*{*/}
                        {/*    <Col>*/}
                        {/*        <NavLink to={{ pathname: EXTERNAL_DASHBOARD_URL() }} target="_blank" activeClassName='active-nav-link'>*/}
                        {/*            <div className='custom-nav-link'>*/}
                        {/*                {t('nav.dashboard')}*/}
                        {/*            </div>*/}
                        {/*        </NavLink>*/}
                        {/*    </Col>*/}
                        {/*}*/}

                        { // REPLACE from staging
                          (hasPermissionOf(SHOW_DASHBOARD) ||user.action_role === 'superadmin')&& <Col>
                                <NavLink to={{pathname:"https://fhlazappclosolp08.azurewebsites.net"}} target='_blank' activeClassName='active-nav-link'>
                                    <div className='custom-nav-link'>
                                        Dashboard
                                    </div>
                                </NavLink>
                            </Col>
                        }
                    </Row>
                </Col>
                <Col>
                    <Row align={'middle'} gutter={10}>
                        <Col>
                            {
                                <Col>
                                    <NavLink to={ROUTE_PROFILE} activeClassName='active-nav-link'>
                                        <div className='custom-nav-link'>
                                            <Space>
                                                <Avatar shape='circle' size={30} icon={<UserOutlined />} />
                                                <Text strong>{user.name}</Text>
                                            </Space>
                                        </div>
                                    </NavLink>
                                </Col>
                            }
                        </Col>
                        <Col><SignOutButton /></Col>
                    </Row>
                </Col>
            </Row>
        </Header>
    );
}
