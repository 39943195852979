import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Skeleton,
} from 'antd';
import { useEffect, useState } from 'react';
import useLocations from 'shared/hooks/useLocations';
import useGroupsCreate from 'shared/hooks/Groups/useGroupsCreate';
import GroupForm from './GroupForm';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';

type Props = {
    user: any
}

const GroupCreate: React.FunctionComponent<Props> = ({ user }) => {
    const history = useHistory();
    const location = useLocations();
    const { mutation, saving, group: savedUserGroup, error } = useGroupsCreate((e: any) => {
        if (e) {
            history.goBack();
        }
    });
    const onSubmit = (object: any) => {
        const req = {
                user_id: user.id, group:
                    {
                        data: object,
                    }
                ,
            }
        ;
        mutation({ variables: { object: req } });
    };
    return (
        <div className={'user-group-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Q Group Create'}>
                <GroupForm saving={saving} onCancel={() => history.goBack()} onSubmit={onSubmit} />

            </FormViewDesktop>
        </div>
    );
};


export default GroupCreate;
