import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import { GlobalListLimit } from '../../constants/AppConstants';

type ReturnType = {
    roles: any[];
    loading: boolean;
    error: any;
    total: number;
    refetch: any;
};

const QUERY = gql`
    query ($where: c_acl_roles_bool_exp, $limit: Int, $offset: Int){
        c_acl_roles(where: $where, limit: $limit, offset: $offset , order_by: [{created_at: desc}]) {
            name
            created_at
            id
            branch_id
            description
            role_permissions{
                id
                role{
                    name
                    id
                }
                permission{
                    name
                    id
                }
            }
            branch{
                name
                location{
                    city
                }
            }
            created_by{
                name
            }
        }
        c_acl_roles_aggregate (where: $where, limit: $limit, offset: $offset){
            aggregate{
                count
            }
        }
    }
`;

const createFilters = (filters?: Filters) => {
    const result: any = { where: { _and: [] }, limit: GlobalListLimit, offset: 0 };
    if (!filters) {
        return result;
    }
    if (filters['search']) {
        result['where']['_and'].push({
            '_or': [
                { name: { _ilike: `%${filters['search']}%` } },
            ],
        });
    }
    if (filters['names']) {
        result['where']['_and'].push({
            '_or': [
                { name: { _in: filters['names'] } },
            ],
        });
    }
    result['limit'] = filters['limit'] || GlobalListLimit;
    result['offset'] = filters['offset'] || 0;
    return result;
};

const useRoles: (filters?: Filters) => ReturnType = (filters) => {
    const { data, loading, error, refetch: refresh } = useQuery(QUERY, { variables: createFilters(filters) });
    let roles = [];
    let total = 0;
    if (!loading && !error) {
        roles = data.c_acl_roles;
        total = data.c_acl_roles_aggregate.aggregate.count;
    }
    const refetch = (filters?: Filters) => {
        return refresh(createFilters(filters));
    };
    return {
        total,
        roles,
        loading,
        error,
        refetch,
    };
};
export default useRoles;
