import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    group: any;
    loading: boolean;
    error: any;
};

const QUERY = gql`
    query ($id:uuid!){
        c_org_groups_by_pk(id: $id) {
            name
            id
            branch_id
            branch{
                name
                location{
                    city
                    state
                    country
                }
            }
        }
    }
`;

const useGroupById: (id: string) => ReturnType = (id) => {
    const { data, loading, error } = useQuery(QUERY, { variables: { id } });
    let group = null;
    if (!loading && !error) {
        group = data.c_org_groups_by_pk;
    }
    return {
        group,
        loading,
        error,
    };
};
export default useGroupById;
