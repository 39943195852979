import gql from 'graphql-tag';
import {useQuery} from '@apollo/react-hooks';

type ReturnType = {
    states: any[];
    loading: boolean;
    error: any;
    refetch: any;
};

const QUERY = gql`
    query($country_code: String!) {
        c_meta_locations(distinct_on: [state_code], where: {country_code: {_eq: $country_code}}){
            state
            state_code
            id
        }
    }
`;

const useStates: (country_code: string) => ReturnType = (country_code) => {
    const {data, loading, error, refetch} = useQuery(QUERY, {variables: {country_code}});
    let states = [];
    if (!loading && !error) {
        states = data.c_meta_locations;
    }
    return {
        states,
        refetch,
        loading,
        error,
    };
};
export default useStates;
