import React from "react";



class CompactTableViewComponent extends React.PureComponent {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { data } = this.props;
        return (
            <React.Fragment>
                {data.splitTable ? (
                    <div id="table" className="table-editable ">
                        <div className="row">
                            {data.tableData &&
                                <>
                                    {data.tableData.map((indTableData, index) =>
                                        <div className={`col-${indTableData.gridSize} mb-2`} key={index}>
                                            <table className="table table-bordered table-responsive-md table-hover text-center table-sm">
                                                {!!indTableData.header &&
                                                    <thead className="thead-light" style={{ textTransform: 'capitalize' }}>
                                                        <tr>
                                                            {indTableData.header.map(headerLabel => <th className="text-center">{headerLabel ? headerLabel : '-'}</th>)}
                                                        </tr>
                                                    </thead>
                                                }
                                                <tbody>
                                                    {indTableData.body.map((indBody, index) =>
                                                        <>
                                                            {indBody.map((indData, jndex) =>
                                                                <tr key={jndex}>
                                                                    {indData.map((data, kndex) =>
                                                                        <td key={kndex}> {data} </td>
                                                                    )
                                                                    }
                                                                </tr>
                                                            )}
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </>
                            }
                        </div>
                    </div>
                ) : (
                        <div id="table" className="table-editable ">
                            <table className="table table-bordered table-responsive-md table-hover text-center table-sm">
                                {data.header &&
                                    <thead className="thead-light">
                                        <tr>
                                            {data.header.map(headerLabel => <th className="text-center">{headerLabel}</th>)}
                                        </tr>
                                    </thead>
                                }

                                <tbody>
                                    {data.body.map((indBody, index) => {
                                        return <tr key={index}>
                                            {indBody.map((indData, jndex) => {
                                                return <td key={jndex}> {indData} </td>
                                            })}
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    )

                }


            </React.Fragment>
        );
    }
}

export default CompactTableViewComponent;
