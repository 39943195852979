import React from "react";
import SearchPageViewComponent from "../../SearchPage/SearchPageViewComponent";
class DoctorListView extends React.Component {
  constructor(props) {
    super(props);
    let filteredList = props.doctorList ? props.doctorList.filter(d => (d.cognito_user_sub && d.cognito_user_sub !== "-")).map(d => ({ doctor_id: d.cognito_user_sub, doctor_name: d.name, speciality: d.speciality })) : [];
    this.state = {
      doctorList: filteredList,
      finalDoctorList: filteredList,
      currentSearchString: ''
    }

  }

  componentWillReceiveProps(nextProps) {
    let { doctorList, finalDoctorList } = this.state;
    if (nextProps.doctorList !== doctorList) {
      doctorList = nextProps.doctorList ? nextProps.doctorList.filter(d => (d.cognito_user_sub && d.cognito_user_sub !== "-")).map(d => ({ doctor_id: d.cognito_user_sub, doctor_name: d.name, speciality: d.speciality })) : [];
      finalDoctorList = nextProps.doctorList ? nextProps.doctorList.filter(d => (d.cognito_user_sub && d.cognito_user_sub !== "-")).map(d => ({ doctor_id: d.cognito_user_sub, doctor_name: d.name, speciality: d.speciality })) : [];
    }
    this.setState({ doctorList, finalDoctorList });
  }

  handleSearch = (event) => {
    let { currentSearchString } = this.state;
    currentSearchString = event.target.value
    if (currentSearchString === '') {
      this.setState({ finalDoctorList: this.state.doctorList, currentSearchString });
      return;
    }
    this.setState({ currentSearchString });
    let filteredData = [];
    filteredData = this.state.doctorList.filter(e => {
      let mathesItems = Object.values(e)
      let retVal = true;
      let matched = [];
      mathesItems.forEach(e => {
        const regex = new RegExp(currentSearchString, 'gi')
        if (typeof (e) === 'string') {
          retVal = e.match(regex)
        }
        if ((retVal && typeof (retVal) === 'object') && retVal.length > 0) {
          matched = retVal;
        }
      })
      if (matched.length > 0) {
        return matched
      }
      return retVal;
    })
    this.setState({ finalDoctorList: filteredData });
  }

  getAppointmentListForDoctor = (doctor) => {
    this.setState({ currentSearchString: '' }, () => this.props.getListForDoctor(doctor));
  }

  render() {
    let { finalDoctorList } = this.state;
    let { selectedDoc } = this.props;
    return <><li className={`nav-item nav-profile dropdown`}>
      <a className="nav-link dropdown-toggle " id="doctorlist" data-toggle="dropdown" style={{ cursor: 'pointer' }}>
        <div className="nav-profile-img">
          <img src="images/doctor-male.png" alt="image" />
        </div>
        <div className="nav-profile-text doctorlist-icon">
          <p className="mb-1 text-white text-truncate">{selectedDoc && selectedDoc.doctor_name ? selectedDoc.doctor_name : 'Select Doctor'}</p>
        </div>
      </a>
      <div className={`dropdown-menu dropdown-menu-right navbar-dropdown doc-list preview-list`} aria-labelledby="doctorlist">
        <div className="dropdown-item preview-item  bg-light" >
          <div className="form-group has-search mb-0 w-100">
            <i className="mdi mdi mdi-magnify input-icon-search"></i>
            <input type="text" className="form-control" value={this.state.currentSearchString} placeholder="Search Doctor" onChange={this.handleSearch.bind(this)} />
          </div>
        </div>
        {finalDoctorList && finalDoctorList.length > 0 && finalDoctorList.map((doctor) => <React.Fragment key={doctor.doctor_id}><div key={doctor.doctor_id} className="dropdown-divider"></div>
          <a className="dropdown-item preview-item" onClick={this.getAppointmentListForDoctor.bind(this, doctor)} style={{ cursor: 'pointer' }}>
            <div className="preview-thumbnail">
              <img src="images/doctor-male.png" alt="image" className="profile-pic" />{selectedDoc && (doctor.doctor_id === selectedDoc.doctor_id) && <i className="mdi mdi-check text-success" style={{ color: 'red !important' }}></i>}
            </div>
            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
              <h6 className="preview-subject ellipsis mb-0 mt-1 font-weight-normal">{doctor.doctor_name}</h6>
              {/* <p className="text-gray mb-0   mt-0 ">
                {doctor.speciality}
              </p> */}
            </div>
          </a></React.Fragment>
        )}
      </div>
    </li>
    </>
  }
}

export default DoctorListView;
