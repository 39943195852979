import gql from 'graphql-tag';
import {useQuery} from '@apollo/react-hooks';

type ReturnType = {
    patient: any;
    loading: boolean;
    error: any;
};

const QUERY = gql`
    query($id: uuid!) {
        c_patient_pii_by_pk(id: $id) {
            id
            first_name
            last_name
            age
            gender
            phone
            uhid
            date_of_birth
            email
            alternate_phone
            branch_id
            address
            branch{
                id
                name
                location{
                    city
                    state
                }
            }
            zipcode
            location_id
            location{
                state
                state_code
                country_code
                country
                city
                city_code
                id
            }
        }
    }
`;

const usePatientById: (id: string, forForm?: boolean) => ReturnType = (id, forForm) => {
    const {data, loading, error} = useQuery(QUERY, {variables: {id}});
    let patient = null;
    if (!loading && !error && data) {
        patient = data.c_patient_pii_by_pk;
    }
    return {
        patient,
        loading,
        error,
    };
};
export default usePatientById;
