import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ListViewDesktop from 'shared/components/layout/Desktop/ListView/ListViewDesktop';
import {
  PATIENT_ADMISSION_ADD,
  PATIENT_REGISTRATION_ADD_ROUTE,
  PATIENT_REGISTRATION_EDIT_ROUTE,
} from 'shared/constants/RouteConstants';
import { Button, Space, Col, DatePicker, Row, Table, Tooltip, Typography, Select } from 'antd';
import { EditFilled, FileAddOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Title from 'antd/es/typography/Title';
import usePatients from 'shared/hooks/Patients/usePatients';
import useDebounce from 'shared/hooks/useDebounce';
import { BranchSelectFilter, SelectSearchFilter } from 'shared/components/Common/CommonFilterComponents';
import {
  Filters,
  getPaginationLimit,
  hasPermissionOf,
  numberFormat,
  setPaginationLimit,
} from 'shared/services/Utility';
import {
  PATIENTS_ENCOUNTER_INSERT_ONE,
  PATIENTS_ENCOUNTER_SELECT,
  PATIENTS_INSERT_ONE,
  PATIENTS_UPDATE_ONE,
} from 'shared/constants/Permissions';
import { DateFormat, GlobalListLimit } from 'shared/constants/AppConstants';
import Paragraph from 'antd/es/typography/Paragraph';


const { Text } = Typography;
const { Option } = Select;
type Props = {}
const { RangePicker } = DatePicker;

const PatientRegistration: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedBranch, setSelectedBranch] = useState(null as any);
  const [search, setSearch] = useState({} as Filters);
  const debouncedSearch = useDebounce(search, 500);
  const [selectedRange, setSelectedRange]: any = useState([]);
  const [pagination, setPagination] = useState({
    limit: getPaginationLimit(),
    currentPage: 1,
  } as { limit: number, currentPage: number });
  const {
    patients,
    loading: loadingPatients,
    refetch,
    total,
  } = usePatients({ created_at_range: selectedRange, ...{ limit: pagination.limit, offset: 0 } });
  const columns = [
    {
      title: '#',
      key: 'sNo',
      render: (a: any, value: any, index: number) =>
        <Text>{(index + 1) + ((pagination.currentPage - 1) * pagination.limit)}</Text>,
    },
    {
      title: 'UHID',
      dataIndex: 'uhid',
      key: 'UHID',
    },
    {
      title: 'Name & Phone',
      render: (a: any) => <>
        <div><Text strong>{a.first_name || 'NA'} {a.last_name || ''}</Text></div>
        <div><Text>{a.phone}</Text></div>
      </>,
    },
    {
      title: <div>Age & Gender</div>,
      key: 'age',
      render: (a: any) => <>
        <div style={{ minWidth: '70px' }}><Text>{a.age ? (a.age + ' years') : 'NA'}</Text></div>
        <div><Text>{a.gender}</Text></div>
      </>,
    },
    {
      title: 'Hospital',
      dataIndex: 'branch',
      key: 'branch',
      className: 'word-wrap',
      render: (a: any) => <Paragraph
        style={{ width: '150px', wordWrap: 'break-word' }}>{`${a.name}, ${a.location.city}`}</Paragraph>,
    },
    {
      title: 'Address',
      key: 'address',
      render: (a: any) => a.location ?
        <Text>{a ? `${a.location.state}, ${a.location.country}` : 'NA'}</Text> : 'NA',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },


    {
      title: 'Registered On',
      dataIndex: 'created_at',
      render: (a: any) => moment(a).format(DateFormat),
    },
    {
      title: 'Latest Episode',
      dataIndex: ['encounters', 0, 'created_at'],
      render: (a: any) => <div style={{ minWidth: '72px' }}>{a ?
        <Text>{moment(a).format('LL')} </Text> : 'NA'}</div>,
    },
    {
      fixed: 'right' as 'right',
      width: 150,
      title: 'Action',
      key: 'action',
      render: (a: any) => {
        return <Row gutter={[10, 10]} wrap={false}>
          {
            hasPermissionOf(PATIENTS_UPDATE_ONE) && <Col>
              <Tooltip title={t('genDict.edit')}>
                <Button shape='circle' icon={<EditFilled />}
                        onClick={() => history.push(PATIENT_REGISTRATION_EDIT_ROUTE(a.id))}
                        size={'middle'} />
              </Tooltip>
            </Col>
          }
          {
            hasPermissionOf(PATIENTS_ENCOUNTER_INSERT_ONE) && <Col>
              <Tooltip title={t('genDict.procedures')}>
                <Button shape='circle' icon={<FileAddOutlined />}
                        onClick={() => history.push(PATIENT_ADMISSION_ADD(a.id))}
                        size={'middle'} />
              </Tooltip>
            </Col>
          }
        </Row>;
      },
    },
  ];
  useEffect(() => {
    setPagination({ ...pagination, currentPage: 1 });
  }, [debouncedSearch, selectedBranch, selectedRange]);
  useEffect(() => {
    refetch({
      ...search,
      branch_id: selectedBranch,
      created_at_range: selectedRange && selectedRange.length ? selectedRange : [] ,
      offset: (pagination.currentPage - 1) * pagination.limit,
      limit: pagination.limit,
    });
  }, [pagination]);
  if (!hasPermissionOf(PATIENTS_ENCOUNTER_SELECT)) {
    delete columns[8];
  }
  if (!hasPermissionOf(PATIENTS_UPDATE_ONE) && !hasPermissionOf(PATIENTS_ENCOUNTER_INSERT_ONE)) {
    delete columns[columns.length - 1];
  }
  const searchSelectionItems = [{
    name: 'UHID',
    key: 'uhid',
  }, {
    name: 'Patient Name',
    key: 'name',
  }, {
    name: 'Email',
    key: 'email',
  }, {
    name: 'Phone',
    key: 'phone',
  }];
  console.log(selectedRange, '====');
  return (
    <div className={'procedure-page page-wrapper'}>
      <ListViewDesktop title={'All Patients'}
                       filters={[
                         <SelectSearchFilter searchSelectionItems={searchSelectionItems}
                                             onChange={({ key, value }: { key: string, value: string }) => {
                                               setSearch({ [key]: value });
                                             }} />,
                         <BranchSelectFilter onChange={(e: any) => setSelectedBranch(e)} />,
                         <RangePicker style={{ maxWidth: '235px' }} format={DateFormat}
                                      defaultValue={selectedRange}
                                      onChange={(e: any) => {
                                        if (e && e.length) {
                                          setSelectedRange([e[0].startOf('day'), e[1].endOf('day')]);
                                        } else {
                                          setSelectedRange([]);
                                        }
                                      }} />,
                       ]}
                       addActionConfig={
                         hasPermissionOf(PATIENTS_INSERT_ONE) ? {
                           text: t('genDict.add'),
                           onClick: () => history.push(PATIENT_REGISTRATION_ADD_ROUTE),
                         } : null
                       }>
        <Table
          sticky={true}
          locale={{
            emptyText: (
              <>
                <Space direction={'vertical'} size={10} style={{ padding: '10px' }}>
                  <Title level={3} className={'text-primary'}>{t('genDict.woops')}</Title>
                  <Text className={'text-primary'}>No Patient</Text>
                  <Button onClick={() => history.push(PATIENT_REGISTRATION_ADD_ROUTE)}
                          type={'primary'}>{t('patients.addNewPatient')}</Button>
                </Space>
              </>
            ),
          }}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: true,
            total,
            current: pagination.currentPage,
            pageSize: pagination.limit,
            showTotal: (total: number) => <Text>Total <b>{numberFormat(total)}</b> items</Text>,
            onChange: (page: number, pageSize?: number) => {
              if (pageSize !== pagination.limit) {
                setPaginationLimit(pageSize);
                page = 1;
              }
              setPagination({ currentPage: page, limit: (pageSize || pagination.limit) });
            },
          }}
          scroll={{ x: true }}
          dataSource={patients}
          loading={loadingPatients}
          columns={columns} />
      </ListViewDesktop>
    </div>
  );
};
;

export default PatientRegistration;
