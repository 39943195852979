import React from "react";



class ListViewComponent extends React.PureComponent {
    render() {
        const { data } = this.props;
        return (
            <React.Fragment>
                {data.map((indData, index) =>
                    <React.Fragment key={index}>
                        {indData.type === "group" &&
                            <React.Fragment>
                                <div className="absolute left top bottom h-100 v-strock-2 compact-card-border"></div>
                                <p className="mt-0 mb-1 text-compact align-items-center">
                                    <i className="mdi mdi-calendar-today"></i>{indData.date}</p>
                                <div className="table-responsive drugstable table table-sm">
                                    <table className="table">
                                        <thead style={{ textTransform: 'capitalize', color: '#000' }}>
                                            <tr>
                                                {indData.headerData &&
                                                    <React.Fragment>
                                                        {indData.headerData.map((indHeader, jndex) =>
                                                            <th key={jndex + indHeader}> {indHeader} </th>
                                                        )}
                                                    </React.Fragment>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody style={{ color: '#000' }}>
                                            {!!indData.bodyData &&
                                                <React.Fragment>
                                                    {indData.bodyData.map((indTableElement, index) =>
                                                        <React.Fragment key={index}>
                                                            {indTableElement.map((indElement, jndex) =>
                                                                <tr key={jndex}>
                                                                    <React.Fragment key={jndex}>
                                                                        {indElement.map((value, kndex) =>
                                                                            <td key={kndex}>{value}</td>
                                                                        )}
                                                                    </React.Fragment>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>)}
                                                </React.Fragment>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                        }
                        {indData.type === "row" &&
                            <div className="table-responsive drugstable table table-sm">
                                <table className="table" >
                                    <thead style={{ textTransform: 'capitalize', color: '#000' }}>
                                        <tr>
                                            {indData.headerData &&
                                                <React.Fragment>
                                                    {indData.headerData.map((indHeader, jndex) =>
                                                        <th key={jndex + indHeader}> {indHeader} </th>
                                                    )}
                                                </React.Fragment>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody style={{ color: '#000' }}>
                                        {!!indData.bodyData &&
                                            <React.Fragment>
                                                <tr key={index}>
                                                    {indData.bodyData.map((indTableElement, index) =>
                                                        <React.Fragment key={index}>
                                                            <td key={index}>{indTableElement}</td>
                                                        </React.Fragment>)}</tr>
                                            </React.Fragment>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

export default ListViewComponent;
