import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import { GlobalListLimit } from '../../constants/AppConstants';

type ReturnType = {
  questionnaires: any[];
  loading: boolean;
  error: any;
  total: number;
  refetch: any;
};

const QUERY = gql`
  query ($where: c_prescription_templates_bool_exp, $limit: Int, $offset: Int){
    c_prescription_templates(where: $where, limit: $limit, offset: $offset) {
      name
      id
      code
      is_supplementary
      group{
        name
        id
      }
      created_by{
        name
      }
    }
    c_prescription_templates_aggregate(where: $where){
      aggregate{
        count
      }
    }
  }
`;

const createFilters = (filters?: Filters) => {
  const result: any = { where: { _and: [] }, limit: GlobalListLimit, offset: 0 };
  if (!filters) {
    return result;
  }
  if (filters['search']) {
    result['where']['_and'].push({
      '_or': [
        { name: { _ilike: `%${filters['search']}%` } },
      ],
    });
  }
  if (filters['notIds']) {
    result['where']['_and'].push({
      id: {
        _nin: filters['notIds'],
      },
    });
  }
  if (filters['names']) {
    result['where']['_and'].push({
      '_or': filters['names'].map((a: any) => {
        return { name: { _ilike: `%${a}%` } };
      }),
    });
  }
  if (filters['is_supplementary']) {
    result['where']['_and'].push({ is_supplementary: { _is_null: false } });
  }
  if (filters['is_supplementary'] === false) {
    result['where']['_and'].push({_or: [{ is_supplementary: { _is_null: true } }, { is_supplementary: { _eq: false } }]});
  }
  result['limit'] = filters['limit'] || GlobalListLimit;
  result['offset'] = filters['offset'] || 0;
  return result;
};

const useTemplates: (filters?: Filters) => ReturnType = (filters) => {
  const { data, loading, error, refetch: refresh } = useQuery(QUERY, { variables: createFilters(filters) });
  let questionnaires = [];
  let total = 0;
  if (!loading && !error) {
    questionnaires = data.c_prescription_templates;
    total = data.c_prescription_templates_aggregate.aggregate.count;
  }
  const refetch = (filters?: Filters) => {
    return refresh(createFilters(filters));
  };
  return {
    total,
    questionnaires,
    loading,
    error,
    refetch,
  };
};
export default useTemplates;
