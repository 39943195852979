import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    followUp: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
mutation MyMutation(
    $encounter_type_id: uuid!, 
    $encounter_template_id: uuid!, 
    $followup_days: Int!, 
    $followup_template_id: uuid!) {
    insert_c_prom_follow_up_one(object: {encounter_type_id: $encounter_type_id, encounter_template_id: $encounter_template_id, followup_days: $followup_days, followup_template_id: $followup_template_id}) {
      id
    }
  }
`;

const useFollowUpCreate: (onCompleted?: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted: onCompleted });
    let followUp = null;
    if (!saving && !error && data) {
        followUp = data.c_prom_follow_up;
    }
    return {
        mutation,
        followUp,
        saving,
        error,
    };
};
export default useFollowUpCreate;
