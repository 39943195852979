// import request from '../../../Api/request';
// import userAuth from '../../../Authentication/authActions';

function saveForm(data, settinglevel, accountId, id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://emrapi.hlthclub.in/admin`;
    } else {
        api = `https://betaemrapi.hlthclub.in/admin`;
    }
    return request({
        baseURL: api,
        url:  `/super-settings/${accountId.toLowerCase()}/${settinglevel}/${id.toLowerCase()}`,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}
function saveFormForInstantPresc(data, settinglevel, accountId, id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://emrapi.hlthclub.in/admin`;
    } else {
        api = `https://betaemrapi.hlthclub.in/admin`;
    }
    return request({
        baseURL: api,
        url: `/doctor-settings/${accountId}/${settinglevel}/${id}`,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function getUsersList(filterParams, id) {
    let queryString = ''
    if (!filterParams || Object.keys(filterParams).length === 0) {
        queryString = ""
    } else {
        if (Object.keys(filterParams).length > 0) {
            for (let key in filterParams) {
                let str = `${key}=${filterParams[key]}&`
                queryString = queryString.concat(str);
            }
        }
    }
    let formatedQueryParams = formatQueryParams((queryString).toLowerCase());
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/`
    }
    if (!formatedQueryParams) {
        return request({
            baseURL: api,
            url: apiStage + "/users?account_id=" + id,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    } else {
        return request({
            baseURL: api,
            url: apiStage + `/users?account_id=${id}&` + formatedQueryParams,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    }
}

function formatQueryParams(queryString) {
    if (queryString.indexOf('&') !== -1) {
        let paramArray = queryString.split('&');
        return paramArray[1] !== "" ? `${paramArray[0]}&${paramArray[1]}` : `${paramArray[0]}`
    }
    return queryString;
}

function fetchSavedTemplates(accountId, templateType) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://emrapi.hlthclub.in/admin`;
    } else {
        api = `https://betaemrapi.hlthclub.in/admin`;
    }
    if (!accountId) {
        accountId = sessionStorage.getItem('s3AccountId')
    }
    return request({
        baseURL: api,
        url: `/super-user/templates/${(accountId).toLowerCase()}/${templateType?templateType:'prescription_form'}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function getAllAccountData() {
    return request({
        url: "/admin/account/all",
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

const formBuilderService = {
    saveForm,
    saveFormForInstantPresc,
    getUsersList,
    fetchSavedTemplates,
    getAllAccountData
};

export default formBuilderService;