import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import DesktopLayout from 'shared/components/layout/Desktop/DesktopLayout';
import ProceduresPage from './Procedures/ProceduresPage';
import ProcedureReview from './Procedures/CreateOrEdit/ProcedureReview';
import PatientRegistration from './PatientRegistration/PatientRegistration';
import PatientRegistrationCreateOrEdit from './PatientRegistration/CreateOrEdit/PatientRegistrationCreateOrEdit';
import {
    BRANCH_ADD_ROUTE,
    BRANCH_EDIT_ROUTE,
    BRANCHES_LIST_ROUTE,
    DEPARTMENT_ADD_ROUTE,
    DEPARTMENT_EDIT_ROUTE,
    DEPARTMENTS_LIST_ROUTE, ENCOUNTER_TYPE_ADD_ROUTE, ENCOUNTER_TYPE_EDIT_ROUTE, ENCOUNTER_TYPES_LIST_ROUTE,
    GROUP_ADD_ROUTE,
    GROUP_EDIT_OR_VIEW_ROUTE,
    GROUP_EDIT_ROUTE,
    GROUPS_LIST_ROUTE,
    LOCATION_ADD_ROUTE,
    LOCATION_EDIT_OF_VIEW_ROUTE,
    LOCATION_EDIT_ROUTE,
    LOCATIONS_LIST_ROUTE,
    PATIENT_ADMISSION_ADD,
    PATIENT_REGISTRATION_ADD_ROUTE,
    PATIENT_REGISTRATION_EDIT_ROUTE,
    PATIENT_REGISTRATION_LIST_ROUTE,
    PATIENT_REGISTRATION_VIEW_ROUTE, PROCEDURES_EDIT_ROUTE,
    PROCEDURES_LIST_ROUTE,
    PROCEDURES_PRESCRIPTION_REVIEW_ROUTE,
    PROCEDURES_REVIEW_ROUTE,
    PREFERENCES_ROUTE,
    QUESTIONNAIRE_ADD_ROUTE,
    QUESTIONNAIRE_EDIT_OR_VIEW_ROUTE,
    QUESTIONNAIRE_EDIT_ROUTE,
    QUESTIONNAIRES_LIST_ROUTE,
    ROLE_ADD_ROUTE,
    ROLE_EDIT_ROUTE,
    ROLES_LIST_ROUTE, ROUTE_PROFILE,
    TABLE_PERMISSION_ADD_ROUTE,
    TABLE_PERMISSIONS_LIST_ROUTE,
    TEAM_ADD_ROUTE,
    TEAM_EDIT_ROUTE,
    TEAMS_LIST_ROUTE,
    USER_ADD_ROUTE,
    USER_EDIT_ROUTE, USERS_BULK,
    USERS_LIST_ROUTE,
    FORM_BUILDER,
    DASHBOARD_VIEW,
} from 'shared/constants/RouteConstants';
import GroupsPage from './Groups/GroupsPage';

import GroupCreate from './Groups/CreateOrEdit/GroupCreate';
import GroupEdit from './Groups/CreateOrEdit/GroupEdit';

import QuestionnaireCreate from './Questionnaire/CreateOrEdit/QuestionnaireCreate';
import QuestionnaireEdit from './Questionnaire/CreateOrEdit/QuestionnaireEdit';
import PatientEdit from './PatientRegistration/CreateOrEdit/PatientEdit';
import PreferencesPage from './Preferences/PreferencesPage';

import PatientCreate from './PatientRegistration/CreateOrEdit/PatientCreate';
import ProcedureTypesPage from './ProcedureTypes/ProcedureTypesPage';
import ProcedureTypeCreate from './ProcedureTypes/CreateOrEdit/ProcedureTypeCreate';
import ProcedureTypeEdit from './ProcedureTypes/CreateOrEdit/ProcedureTypeEdit';
import { hasPermissionOf } from 'shared/services/Utility';
import {
    BRANCHES_INSERT_ONE,
    BRANCHES_SELECT,
    BRANCHES_UPDATE_ONE,
    DEPARTMENTS_INSERT_ONE,
    DEPARTMENTS_SELECT,
    DEPARTMENTS_UPDATE_ONE,
    ENCOUNTER_TYPES_INSERT_ONE,
    ENCOUNTER_TYPES_SELECT,
    ENCOUNTER_TYPES_UPDATE_ONE,
    ENCOUNTERS_SELECT, ENCOUNTERS_UPDATE_ONE,
    GROUPS_INSERT_ONE,
    GROUPS_SELECT,
    GROUPS_SELECT_ONE,
    GROUPS_UPDATE_ONE,
    LOCATIONS_INSERT_ONE,
    LOCATIONS_SELECT,
    LOCATIONS_UPDATE_ONE, PATIENT_ENCOUNTER_UPDATE,
    PATIENTS_ENCOUNTER_INSERT_ONE, PATIENTS_ENCOUNTER_SELECT,
    PATIENTS_INSERT_ONE,
    PATIENTS_SELECT,
    PATIENTS_SELECT_ONE,
    PATIENTS_UPDATE_ONE,
    PRESCRIPTIONS_SELECT,
    PREFERENCES,
    ACCOUNT_PREFERENCES,
    ROLES_INSERT_ONE,
    ROLES_SELECT,
    ROLES_UPDATE_ONE, TABLE_PERMISSIONS_INSERT_ONE,
    TEAMS_INSERT_ONE,
    TEAMS_SELECT,
    TEAMS_UPDATE_ONE,
    TEMPLATES_INSERT_ONE,
    TEMPLATES_SELECT,
    TEMPLATES_UPDATE_ONE,
    USERS_INSERT_ONE,
    USERS_SELECT,
    USERS_UPDATE_ONE,
} from 'shared/constants/Permissions';
import LocationsPage from './Locations/LocationsPage';
import LocationCreate from './Locations/CreateOrEdit/LocationCreate';
import LocationEdit from './Locations/CreateOrEdit/LocationEdit';
import BranchesPage from './Branches/BranchesPage';
import BranchCreate from './Branches/CreateOrEdit/BranchCreate';
import BranchEdit from './Branches/CreateOrEdit/BranchEdit';
import DepartmentsPage from './Departments/DepartmentsPage';
import DepartmentCreate from './Departments/CreateOrEdit/DepartmentCreate';
import DepartmentEdit from './Departments/CreateOrEdit/DepartmentEdit';
import UsersPage from './Users/UsersPage';
import UserCreate from './Users/CreateOrEdit/UserCreate';
import UserEdit from './Users/CreateOrEdit/UserEdit';
import RolesPage from './Roles/RolesPage';
import RoleCreate from './Roles/CreateOrEdit/RoleCreate';
import RoleEdit from './Roles/CreateOrEdit/RoleEdit';
import TeamsPage from './Teams/TeamsPage';
import TeamCreate from './Teams/CreateOrEdit/TeamCreate';
import TeamEdit from './Teams/CreateOrEdit/TeamEdit';
import TablePermissionCreate from './TablePermissions/CreateOrEdit/TablePermissionCreate';
import TablePermissionsPage from './TablePermissions/TablePermissionsPage';
import PatientAdmissionEdit from './PatientRegistration/CreateOrEdit/PatientAdmissionEdit';
import { AuthConsumer } from 'shared/contexts/AuthContext';
import PatientAdmissionAdd from './PatientRegistration/CreateOrEdit/PatientAdmissionAdd';
import ProfilePage from './Profile/ProfilePage';
import UsersBulkPage from './Users/UsersBulkPage';
import QuestionnaireBuilderComponent from './Questionnaire/Builder/QuestionaireBuilderComponent';
import DashboardView from './Dashboard/DashboardView';
import ProcedurePrescriptionPage from './Procedures/CreateOrEdit/ProcedurePrescriptionsPage';

type Props = {}
const ROUTES = [
    //Encounters
    {
        path: PROCEDURES_LIST_ROUTE,
        component: ProceduresPage,
        permission: PATIENTS_ENCOUNTER_SELECT,
    },
    {
        path: PROCEDURES_REVIEW_ROUTE(':id'),
        component: ProcedurePrescriptionPage,
        permission: PATIENTS_ENCOUNTER_SELECT,
    },
    {
        path: PROCEDURES_EDIT_ROUTE(':id'),
        component: PatientAdmissionEdit,
        permission: PATIENT_ENCOUNTER_UPDATE,
    },
    {
        path: PROCEDURES_PRESCRIPTION_REVIEW_ROUTE(':id', ':prescriptionId'),
        component: ProcedurePrescriptionPage,
        permission: PRESCRIPTIONS_SELECT,
    },

    // Patients
    {
        path: PATIENT_REGISTRATION_LIST_ROUTE,
        component: PatientRegistration,
        permission: PATIENTS_SELECT,
    },
    {
        path: PATIENT_REGISTRATION_ADD_ROUTE,
        component: PatientCreate,
        permission: PATIENTS_INSERT_ONE,
    },

    {
        path: PATIENT_REGISTRATION_EDIT_ROUTE(':id'),
        component: PatientEdit,
        permission: PATIENTS_UPDATE_ONE,
    },
    {
        path: PATIENT_REGISTRATION_VIEW_ROUTE(':id'),
        component: PatientEdit,
        permission: PATIENTS_SELECT_ONE,
    },
    {
        path: PATIENT_ADMISSION_ADD(':id'),
        component: PatientAdmissionAdd,
        permission: PATIENTS_ENCOUNTER_INSERT_ONE,
    },


    //PREFERENCES
    {
        path:PREFERENCES_ROUTE,
        component:PreferencesPage,
        permission:PREFERENCES,
    },

    // Groups
    {
        path: GROUPS_LIST_ROUTE,
        component: GroupsPage,
        permission: GROUPS_SELECT,
    },
    {
        path: GROUP_ADD_ROUTE,
        component: GroupCreate,
        permission: GROUPS_INSERT_ONE,
    },
    {
        path: GROUP_EDIT_ROUTE(':id'),
        component: GroupEdit,
        permission: GROUPS_UPDATE_ONE,
    },

    // // Encounter Types
    {
        path: ENCOUNTER_TYPES_LIST_ROUTE,
        component: ProcedureTypesPage,
        permission: ENCOUNTER_TYPES_SELECT,
    },
    {
        path: ENCOUNTER_TYPE_ADD_ROUTE,
        component: ProcedureTypeCreate,
        permission: ENCOUNTER_TYPES_INSERT_ONE,
    },
    {
        path: ENCOUNTER_TYPE_EDIT_ROUTE(':id'),
        component: ProcedureTypeEdit,
        permission: ENCOUNTER_TYPES_UPDATE_ONE,
    },

    // //Templates
    //QuestionnairesPage
    {
        path: QUESTIONNAIRES_LIST_ROUTE,
        component: QuestionnaireBuilderComponent,
        permission: TEMPLATES_SELECT,
    },
    {
        path: QUESTIONNAIRE_ADD_ROUTE,
        component: QuestionnaireCreate,
        permission: TEMPLATES_INSERT_ONE,
    },
    {
        path: QUESTIONNAIRE_EDIT_ROUTE(':id'),
        component: QuestionnaireEdit,
        permission: TEMPLATES_UPDATE_ONE,
    },
    //Formbuilder
    {
        path: FORM_BUILDER,
        component: QuestionnaireBuilderComponent,
        permission: TEMPLATES_SELECT
    },
    // //Locations
    {
        path: LOCATIONS_LIST_ROUTE,
        component: LocationsPage,
        permission: LOCATIONS_SELECT,
    },
    {
        path: LOCATION_ADD_ROUTE,
        component: LocationCreate,
        permission: LOCATIONS_INSERT_ONE,
    },
    {
        path: LOCATION_EDIT_ROUTE(':id'),
        component: LocationEdit,
        permission: LOCATIONS_UPDATE_ONE,
    },
    //
    // //Branches
    {
        path: BRANCHES_LIST_ROUTE,
        component: BranchesPage,
        permission: BRANCHES_SELECT,
    },
    {
        path: BRANCH_ADD_ROUTE,
        component: BranchCreate,
        permission: BRANCHES_INSERT_ONE,
    },
    {
        path: BRANCH_EDIT_ROUTE(':id'),
        component: BranchEdit,
        permission: BRANCHES_UPDATE_ONE,
    },
    // //Departments
    {
        path: DEPARTMENTS_LIST_ROUTE,
        component: DepartmentsPage,
        permission: DEPARTMENTS_SELECT,
    },
    {
        path: DEPARTMENT_ADD_ROUTE,
        component: DepartmentCreate,
        permission: DEPARTMENTS_INSERT_ONE,
    },
    {
        path: DEPARTMENT_EDIT_ROUTE(':id'),
        component: DepartmentEdit,
        permission: DEPARTMENTS_UPDATE_ONE,
    },
    // //Users
    {
        path: USERS_LIST_ROUTE,
        component: UsersPage,
        permission: USERS_SELECT,
    },
    {
        path: USERS_BULK,
        component: UsersBulkPage,
        permission: USERS_SELECT,
    },
    {
        path: USER_ADD_ROUTE,
        component: UserCreate,
        permission: USERS_INSERT_ONE,
    },
    {
        path: USER_EDIT_ROUTE(':id'),
        component: UserEdit,
        permission: USERS_UPDATE_ONE,
    },
    //Roles
    {
        path: ROLES_LIST_ROUTE,
        component: RolesPage,
        permission: ROLES_SELECT,
    },
    {
        path: ROLE_ADD_ROUTE,
        component: RoleCreate,
        permission: ROLES_INSERT_ONE,
    },
    {
        path: ROLE_EDIT_ROUTE(':id'),
        component: RoleEdit,
        permission: ROLES_UPDATE_ONE,
    },
    //Teams
    {
        path: TEAMS_LIST_ROUTE,
        component: TeamsPage,
        permission: TEAMS_SELECT,
    },
    {
        path: TEAM_ADD_ROUTE,
        component: TeamCreate,
        permission: TEAMS_INSERT_ONE,
    },
    {
        path: TEAM_EDIT_ROUTE(':id'),
        component: TeamEdit,
        permission: TEAMS_UPDATE_ONE,
    },
    //Table Permissions
    {
        path: TABLE_PERMISSIONS_LIST_ROUTE,
        component: TablePermissionsPage,
        permission: TEAMS_SELECT,
    },
    {
        path: TABLE_PERMISSION_ADD_ROUTE,
        component: TablePermissionCreate,
        permission: TABLE_PERMISSIONS_INSERT_ONE,
    },
    {
        path: DASHBOARD_VIEW,
        component: DashboardView,
    },
    {
        path: ROUTE_PROFILE,
        component: ProfilePage,
    },
];
const Layout: React.FunctionComponent<Props> = () => {
    const AppLayout = DesktopLayout;

    const RoutesPermitted = ROUTES.filter((route: any) => !route.permission || hasPermissionOf(route.permission));
    return (
        <AuthConsumer>
            {({ user }) => (
                <AppLayout>
                    <Switch>
                        {
                            RoutesPermitted.map((route: any) => {
                                return <Route exact path={route.path}
                                              component={(e: any) => {
                                                  return route.component({ ...e, user });
                                              }
                                              } />;
                            })
                        }
                    </Switch>
                </AppLayout>
            )}</AuthConsumer>
    );
};

export default Layout;
