import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Skeleton,
    notification
} from 'antd';

import useLocations from 'shared/hooks/useLocations';
import QuestionnaireForm from './QuestionnaireForm';
import useQuestionnaireUpdate from 'shared/hooks/Templates/useQuestionnaireUpdate';
import useQuestionnaireById from 'shared/hooks/Templates/useQuestionnaireById';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';

type Props = {}

const QuestionnaireEdit: React.FunctionComponent<Props> = (props:any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocations();
    const RouteParams: { id: string, mode: 'edit' } = useParams();
    console.log(props)
    // const {
    //     loading: loadingQuestionnaire,
    //     questionnaire,variables
    //     error: fetchingQuestionnaireError,
    // } = useQuestionnaireById(RouteParams['id']);
    const {
        mutation,
        saving,
        questionnaire: savedQuestionnaire,
    } = useQuestionnaireUpdate((e: any) => {
        notification.success({message:'Questionnaire saved successfully'})
        if(props.saved){
            props.saved()
        }
    });
    // if (loadingQuestionnaire) {
    //     return <Skeleton active={true} />;
    // }
    const onSubmit = (data: any) => {
        mutation({ variables: { ...data, id: props?.questionnaire?.id } });
    };
    return (
        <div className={'user-group-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Questionnaire Update'}>
                <QuestionnaireForm saving={saving} questionnaire={props.questionnaire} onCancel={() => history.goBack()}
                                   onSubmit={onSubmit} />
            </FormViewDesktop>

        </div>
    );

};


export default QuestionnaireEdit;
