import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    branch: any;
    loading: boolean;
    error: any;
};

const QUERY = gql`
    query ($id:uuid!){
        c_org_branches_by_pk(id: $id) {
            id
            name
            location_id
            code
            created_at
        }
    }
`;

const useBranchById: (id: string) => ReturnType = (id) => {
    const { data, loading, error } = useQuery(QUERY, { variables: { id } });
    let branch = null;
    if (!loading && !error) {
        branch = data.c_org_branches_by_pk;
    }
    return {
        branch,
        loading,
        error,
    };
};
export default useBranchById;
