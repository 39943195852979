import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    location: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
    mutation (  $object: c_meta_locations_insert_input!){
        insert_c_meta_locations_one(object: $object) {
            id
            city
            city_code
            state
            state_code
            country
            country_code
        }
    }
`;

const useLocationCreate: (onCompleted?: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted: onCompleted });
    let location = null;
    if (!saving && !error && data) {
        location = data.insert_c_meta_locations_one;
    }
    return {
        mutation,
        location,
        saving,
        error,
    };
};
export default useLocationCreate;
