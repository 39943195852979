import React, { useState, useEffect } from 'react';
// Component
import NewCompactViewComponent from '../Common/NewCompactViewComponent';
import XYTableViewComponent from '../Common/XYTableViewComponent';
import TableViewComponent from '../Common/TableViewComponent';
import ListViewComponent from '../Common/ListViewComponent';
import CompactTableViewComponent from '../Common/CompactTableViewComponent';
import NGridComponent from '../Common/NGridComponent';
import SectionTitleViewComponent from "../Common/SectionTitleViewComponent";
import { Row, Col, Divider } from 'antd';

function FormViewSwitch(props) {


    return (
        <>

            <Row>
                <Col span={24}>
                    <>
                        {props.formRenderingData && props.formRenderingData.map((indSection, index) =>
                            <>
                                {(indSection.showType === "compact" || indSection.showType === "none" || indSection.showType === "compactTextbox") &&
                                    <Row>
                                        <SectionTitleViewComponent indSection={indSection} />
                                        <Col span={20}>
                                            <NewCompactViewComponent sectionData={indSection} data={indSection.data}></NewCompactViewComponent>
                                        </Col>
                                    </Row>

                                }
                                {(indSection.showType === "xyTable") &&
                                    <div className="row">
                                        <SectionTitleViewComponent indSection={indSection} />
                                        <Col span={20}>
                                            <XYTableViewComponent sectionData={indSection} data={indSection.data}></XYTableViewComponent>
                                        </Col>
                                    </div>

                                }
                                {(indSection.showType === "1d" || indSection.showType === "2d" || indSection.showType === "3d") &&
                                    <Row >
                                        <SectionTitleViewComponent indSection={indSection} />
                                        <Col span={24}>
                                            <Row style={{ padding: "0px 20px" }}>
                                                <NGridComponent showType={indSection.showType} sectionData={indSection} data={indSection.data}></NGridComponent>
                                            </Row>
                                        </Col>
                                    </Row>

                                }
                                {indSection.showType === "table" &&
                                    <Row>
                                        <Col span={24}>
                                            {indSection.data && indSection.data.length > 0 &&
                                                <h5 className="mt-0 mb-2 vname "> {indSection.title ? indSection.title + ":" : indSection.sectionName && (indSection.sectionName.indexOf("Drugs") !== -1) ? "Rx" : indSection.sectionName + ":"}</h5>
                                            }
                                            <TableViewComponent data={indSection.data}></TableViewComponent>
                                        </Col>
                                    </Row>
                                }
                                {indSection.showType === "freeText" &&
                                    <Row>
                                        <Col span={24}>
                                            <h5 className="mt-0 mb-2 vname "> {indSection.title ? indSection.title + ":" : indSection.sectionName && indSection.sectionName === "Drugs" ? "Rx" : indSection.sectionName + ":"}</h5>
                                            <div dangerouslySetInnerHTML={{ __html: indSection.data && indSection.data[0] && indSection.data[0].bodyData ? indSection.data[0].bodyData[0] : '' }}></div>
                                        </Col>
                                    </Row>
                                }
                                {indSection.showType === "list" &&
                                    <Row >
                                        <Col span={24}>
                                            <h5 className="mt-0 mb-2 vname "> {indSection.title ? indSection.title + ":" : indSection.sectionName && indSection.sectionName === "Drugs" ? "Rx" : indSection.sectionName + ":"}</h5>
                                            <ListViewComponent data={indSection.data} />
                                        </Col>
                                    </Row>
                                }
                                {indSection.showType === "compactTable" &&
                                    <Row>
                                        <Col span={24}>
                                            <h5 className="mt-0 mb-2 vname "> {indSection.title ? indSection.title + ":" : indSection.sectionName && indSection.sectionName === "Drugs" ? "Rx" : indSection.sectionName + ":"}</h5>
                                            <CompactTableViewComponent data={indSection} />
                                        </Col>
                                    </Row>
                                }
                            </>
                        )}
                    </>
                </Col>

            </Row>

        </>

    )
};

export default FormViewSwitch;


