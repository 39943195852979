import gql from 'graphql-tag';
// @ts-ignore
import { useQuery } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import moment from 'moment';
import { GlobalListLimit } from '../../constants/AppConstants';

type ReturnType = {
    procedures: any[];
    prescriptions: any[];
    loading: boolean;
    error: any;
    total: number;
    refetch: any;
};

const QUERY = gql`
    query($where: c_prescription_encounter_prescriptions_temp_6_bool_exp, $limit: Int, $offset: Int) {
        c_prescription_encounter_prescriptions_temp_6(where: $where, limit: $limit, offset: $offset) {
            uhid
            first_name
            last_name
            encounter{
                department{
                    name
                }
                patient{
                    id
                    branch{
                        name
                    }
                    phone
                    gender
                    age
                }
            }
            admission_date
            editor_status
            encounter_id
            created_by_name
            
            type
            type_id
            
            encounter_type_name
            is_supplementary
            latest_editor_status
            latest_reviewer_status
            patient_id
            performing_doctor_name
            prescription_id
            primary_doctor_name
            review_id
            reviewer_status
            template_name
            start_date
            visit_date
            reviewed_at
            edited_at
        }
        c_prescription_encounter_prescriptions_temp_6_aggregate(where: $where){
            aggregate{
                count
            }
        }

    }
`;

const QUERY_EXPORT = gql`
    query($where: c_prescription_encounter_prescriptions_temp_6_bool_exp, $limit: Int, $offset: Int) {
        c_prescription_encounter_prescriptions_temp_6(where: $where, limit: $limit, offset: $offset) {
            uhid
            first_name
            last_name
            encounter{
                department{
                    name
                }
                patient{
                    id
                    branch{
                        name
                    }
                    phone
                    gender
                    age
                }
                prescriptions {
                    report {
                        meta {
                            level3
                        }
                        result_t
                        result_arr
                    }
                }
            }
            admission_date
            editor_status
            encounter_id
            created_by_name
            
            type
            type_id
            first_name
            last_name
            encounter_type_name
            is_supplementary
            latest_editor_status
            latest_reviewer_status
            patient_id
            performing_doctor_name
            prescription_id
            primary_doctor_name
            review_id
            reviewer_status
            template_name
            start_date
            visit_date
            reviewed_at
            edited_at
        }
        c_prescription_encounter_prescriptions_temp_6_aggregate(where: $where){
            aggregate{
                count
            }
        }

    }
`;

export const createFilters = (filters?: Filters) => {
    const result: any = {
        where: {}, limit: GlobalListLimit, offset: 0,
    };

    if (!filters) {
        return result;
    }
    let prescriptionExtra: any = { _is_null: true };
    if (filters['template_id']) {
        prescriptionExtra = null;
        result['where']['template_id'] = { _eq: filters['template_id'] };
    }
    if (filters['latest_editor_note_status']) {
        prescriptionExtra = null;
        result['where']['editor_status'] = { _eq: filters['latest_editor_note_status'] };
    }
    if (filters['latest_reviewer_note_status']) {
        prescriptionExtra = null;
        result['where']['reviewer_status'] = { _eq: filters['latest_reviewer_note_status'] };
    }
    const patient: any = {};
    if (filters['patientName']) {
        const nameComponents = filters['patientName'].split(' ');
        nameComponents.forEach((c: string) => {
            const orCondition: any[] = [];
            orCondition.push({ first_name: { _ilike: `%${c}%` } });
            orCondition.push({ last_name: { _ilike: `%${c}%` } });
            patient['_or'] = { _or: orCondition };
        });
    }
    if (filters['patientUHID']) {
        patient['uhid'] = { _ilike: `%${filters['patientUHID']}%` };
    }
    if (filters['patientPhone']) {
        patient['phone'] = { _ilike: `%${filters['patientPhone']}%` };

    }
    if (filters['patientEmail']) {
        patient['email'] = { _ilike: `%${filters['patientEmail']}%` };

    }
    if (filters['branch_id']) {
        patient['branch_id'] = { _eq: `${filters['branch_id']}` };

    }
    const encounter: any = {};

    if (filters['department_id']) {
        // result['where']['_and'].push({ department_id: { _eq: `${filters['department_id']}` } });
        // patient['branch_id'] = { _eq: `${filters['branch_id']}` }
        encounter['department_id'] = { _eq: `${filters['department_id']}` };
    }

    if (filters['procedureName']) {
        result['where']['encounter_type_name'] = { _ilike: `%${filters['procedureName']}%` };
    }

    if (filters['questionnaireName']) {
        // result['where']['_and'].push({ prescriptions: { template: { name: { _ilike: `%${filters['questionnaireName']}%` } } } });
        prescriptionExtra = null;

        result['where']['template_name'] = { _ilike: `%${filters['questionnaireName']}%` };

    }
    if (filters['template_id']) {
        // result['where']['_and'].push({ prescriptions: { template_id: { _eq: `${filters['template_id']}` } } });
        prescriptionExtra = null;

        result['where']['template_id'] = { _eq: `${filters['template_id']}` };

    }

    if (filters['primaryDoctorName']) {
        // result['where']['_and'].push({ primary_doctor: { name: { _ilike: `%${filters['primaryDoctorName']}%` } } });
        result['where']['primary_doctor_name'] = { _ilike: `%${filters['primaryDoctorName']}%` };

    }
    if (filters['performingDoctorName']) {
        // result['where']['_and'].push({ performing_doctor: { name: { _ilike: `%${filters['performingDoctorName']}%` } } });
        result['where']['performing_doctor_name'] = { _ilike: `%${filters['performingDoctorName']}%` };

    }

    if (filters['procedure_date_range']) {
        result['where']['_or'] = {
            _or: [{
                _and: [
                    {
                        type: { _eq: 'OPD' },
                    },
                    {
                        visit_date: {
                            _gte: filters['procedure_date_range'][0],
                            _lte: filters['procedure_date_range'][1],
                        },
                    },
                ],
            },
                {
                    _and: [
                        {
                            type: { _neq: 'OPD' },
                        },
                        {
                            start_date: {
                                _gte: filters['procedure_date_range'][0],
                                _lte: filters['procedure_date_range'][1],
                            },
                        },
                    ],
                }],
        };
    }
    if (Object.keys(encounter).length) {
        result['where'] = { ...result['where'], encounter };
    }
    if (Object.keys(patient).length) {
        result['where'] = {
            ...result['where'],
            encounter: {
                ...encounter, patient,
            },
        };
    }
    if (filters['limit']) {
        result['limit'] = filters['limit'];
    }
    if (filters['offset']) {
        result['offset'] = filters['offset'];
    }
    return result;
};

const usePatientProcedures: (filters?: Filters) => ReturnType = (filters) => {
    const { data, loading, error, refetch: refresh } = useQuery(filters?.export ? QUERY_EXPORT : QUERY, { variables: createFilters(filters) });
    let procedures: any[] = [];
    let total = 0;
    let prescriptions: any[] = [];
    if (!loading && !error) {

        prescriptions = data.c_prescription_encounter_prescriptions_temp_6;
        total = data.c_prescription_encounter_prescriptions_temp_6_aggregate.aggregate.count;
        // procedures = data.c_patient_patient_encounters;
        // procedures.forEach((procedure: any) => {
        //     if (!procedure.prescriptions) {
        //         procedure.prescriptions = [];
        //     }
        // });
        // total = data.c_patient_patient_encounters_aggregate.aggregate.count;
        // const q: any[] = [];
        // if (procedures) {
        //     procedures.forEach((p: any) => {
        //         if (p.prescriptions && p.prescriptions.length) {
        //             p.prescriptions.forEach((prescription: any) => {
        //                 const latest_editor_note = prescription.review['editor_notes'][0];
        //                 let latest_note = prescription.review['editor_notes'][0];
        //                 let latest_reviewer_note = prescription.review['reviewer_notes'][0];
        //                 if (latest_reviewer_note) {
        //                     if (moment(latest_editor_note.created_at).diff(moment(latest_reviewer_note.created_at)) < 0) {
        //                         latest_note = latest_reviewer_note;
        //                     }
        //                 }
        //                 prescription.review['latest_editor_note'] = latest_editor_note;
        //                 prescription.review['latest_editor_status'] = latest_editor_note;
        //                 prescription.review['latest_reviewer_note'] = latest_reviewer_note;
        //                 prescription.review['latest_reviewer_status'] = latest_reviewer_note;
        //                 prescription.review['latest_note'] = latest_note;
        //                 q.push({ ...p, prescription });
        //             });
        //         } else {
        //             q.push({ ...p });
        //         }
        //         delete q[q.length - 1].prescriptions;
        //     });
        //     prescriptions = q;
        // }
    }
    const refetch = (filters?: Filters) => {
        return refresh(createFilters(filters));
    };
    console.log(total,prescriptions);
    return {
        procedures,
        loading,
        prescriptions,
        refetch,
        total,
        error,
    };
};
export default usePatientProcedures;

//
// import gql from 'graphql-tag';
// import { useQuery } from '@apollo/react-hooks';
// import { Filters } from '../../services/Utility';
// import { GlobalListLimit } from '../../constants/AppConstants';
//
// type ReturnType = {
//   procedures: any[];
//   prescriptions: any[];
//   loading: boolean;
//   error: any;
//   total: number;
//   refetch: any;
// };
//
// // const QUERY = gql`
// //     query($where: c_patient_patient_encounters_bool_exp, $limit: Int, $offset: Int) {
// //         c_patient_patient_encounters(where: $where, order_by: [{created_at: desc}], limit: $limit, offset: $offset){
// //             id
// //             patient_id
// //             latest_editor_status
// //             visit_date
// //             admission_date
// //             start_date
// //             latest_reviewer_status
// //             patient{
// //                 id
// //                 first_name
// //                 last_name
// //                 uhid
// //             }
// //             type
// //             type_id
// //             reviewers{
// //                 user_id
// //                 id
// //                 user{
// //                     id
// //                     name
// //                 }
// //             }
// //             prescriptions ( order_by: [{created_at: desc}]){
// //                 created_by{
// //                     name
// //                 }
// //                 created_at
// //                 id
// //                 template{
// //                     name
// //                 }
// //
// //             }
// //             encounter_type{
// //                 id
// //                 name
// //             }
// //             created_at
// //             performing_doctor{
// //                 id
// //                 name
// //             }
// //             primary_doctor{
// //                 id
// //                 name
// //             }
// //             created_by{
// //                 name
// //             }
// //         }
// //         c_patient_patient_encounters_aggregate(where: $where, order_by: [{created_at: desc}]){
// //             aggregate{
// //                 count
// //             }
// //         }
// //     }
// // `;
//
// const QUERY = gql`
//   query($where: c_prescription_prescription_editor_notes_bool_exp, $limit: Int, $offset: Int) {
//     c_prescription_prescription_editor_notes(where: $where, order_by: [{created_at: desc}], limit: $limit, offset: $offset){
//       id
//       status
//       prescription_review{
//         reviewer_notes(where:{latest: {_eq: true}}){
//           status
//           id
//         }
//         prescription{
//           encounter{
//             patient_id
//             id
//             latest_editor_status
//             visit_date
//             admission_date
//             start_date
//             latest_reviewer_status
//             patient{
//               id
//               first_name
//               last_name
//               uhid
//             }
//             type
//             type_id
//             reviewers{
//               user_id
//               id
//               user{
//                 id
//                 name
//               }
//             }
//             prescriptions ( order_by: [{created_at: desc}]){
//               created_by{
//                 name
//               }
//               created_at
//               id
//               template{
//                 name
//               }
//
//             }
//             encounter_type{
//               id
//               name
//             }
//             created_at
//             performing_doctor{
//               id
//               name
//             }
//             primary_doctor{
//               id
//               name
//             }
//           }
//         }
//       }
//       created_at
//       created_by{
//         name
//       }
//     }
//     c_prescription_prescription_editor_notes_aggregate(where: $where, order_by: [{created_at: desc}]){
//       aggregate{
//         count
//       }
//     }
//   }
// `;
//
// const createFilters = (filters?: Filters) => {
//   const result: any = { where: { _and: [] }, limit: GlobalListLimit, offset: 0 };
//   if (!filters) {
//     return result;
//   }
//   if (filters['latest_editor_note_status']) {
//     result['where']['_and'].push({
//       status: { _eq: filters['latest_editor_note_status'] },
//     });
//   }
//   if (filters['latest_reviewer_note_status']) {
//     result['where']['_and'].push({
//       prescription_review: {
//         reviewer_notes: {
//           status: { _eq: filters['latest_reviewer_note_status'] },
//         },
//       },
//     });
//   }
//   if (filters['patientName']) {
//     const nameComponents = filters['patientName'].split(' ');
//     nameComponents.forEach((c: string) => {
//       const orCondition: any[] = [];
//       orCondition.push({
//         'prescription_review': {
//           'prescription': {
//             'encounter':
//               {
//                 'patient': {
//                   first_name: {
//                     _ilike: `%${c}%`,
//                   }
//                   ,
//                 },
//               }
//             ,
//           },
//         },
//       });
//       orCondition.push({
//         'prescription_review': {
//           'prescription': {
//             'encounter':
//               {
//                 'patient': {
//                   last_name: { _ilike: `%${c}%` },
//                 },
//               }
//             ,
//           },
//         },
//       });
//       result['where']['_and'].push({ _or: orCondition });
//     });
//   }
//   if (filters['patientUHID']) {
//     result['where']['_and'].push({
//       'prescription_review': {
//         'prescription': {
//           'encounter':
//             {
//               'patient': { uhid: { _ilike: `%${filters['patientUHID']}%` } },
//             }
//           ,
//         },
//       },
//     });
//   }
//   if (filters['patientPhone']) {
//     result['where']['_and'].push({
//       'prescription_review': {
//         'prescription': {
//           'encounter':
//             {
//               'patient': { phone: { _ilike: `%${filters['patientPhone']}%` } },
//             }
//           ,
//         },
//       },
//     });
//   }
//   if (filters['patientEmail']) {
//     result['where']['_and'].push({
//       'prescription_review': {
//         'prescription': {
//           'encounter':
//             {
//               'patient': { email: { _ilike: `%${filters['patientEmail']}%` } },
//             }
//           ,
//         },
//       },
//     });
//   }
//   if (filters['branch_id']) {
//     result['where']['_and'].push({
//       'prescription_review': {
//         'prescription': {
//           'encounter':
//             {
//               'patient': { branch_id: { _eq: `${filters['branch_id']}` } },
//             }
//           ,
//         },
//       },
//     });
//   }
//   if (filters['department_id']) {
//     result['where']['_and'].push({
//       'prescription_review': {
//         'prescription': {
//           'encounter':
//             { department_id: { _eq: `${filters['department_id']}` } }
//           ,
//         },
//       },
//     });
//   }
//
//   if (filters['procedureName']) {
//     result['where']['_and'].push({
//       'prescription_review': {
//         'prescription': {
//           'encounter':
//             { encounter_type: { name: { _ilike: `%${filters['procedureName']}%` } } },
//         },
//       },
//     });
//   }
//
//   if (filters['primaryDoctorName']) {
//     result['where']['_and'].push({
//       'prescription_review': {
//         'prescription': {
//           'encounter':
//             { primary_doctor: { name: { _ilike: `%${filters['primaryDoctorName']}%` } } },
//         },
//       },
//     });
//   }
//   if (filters['performingDoctorName']) {
//     result['where']['_and'].push({
//       'prescription_review': {
//         'prescription': {
//           'encounter':
//             { performing_doctor: { name: { _ilike: `%${filters['performingDoctorName']}%` } } },
//         },
//       },
//     });
//   }
//
//   if (filters['procedure_date_range']) {
//     result['where']['_and'].push({
//       'prescription_review': {
//         'prescription': {
//           _or: [
//             {
//               'encounter':
//                 {
//                   _or: [{
//                     _and: [
//                       {
//                         type: { _eq: 'OPD' },
//                       },
//                       {
//                         visit_date: {
//                           _gte: filters['procedure_date_range'][0],
//                           _lte: filters['procedure_date_range'][1],
//                         },
//                       },
//                     ],
//                   },
//                     {
//                       _and: [
//                         {
//                           type: { _neq: 'OPD' },
//                         },
//                         {
//                           start_date: {
//                             _gte: filters['procedure_date_range'][0],
//                             _lte: filters['procedure_date_range'][1],
//                           },
//                         },
//                       ],
//                     }],
//                 },
//             },
//             // {
//             //   _and: [
//             //     {
//             //       encounter_template: {
//             //         template: {
//             //           is_follow_up: { _eq: true },
//             //         },
//             //       },
//             //     },
//             //     {
//             //       created_at: {
//             //         _gte: filters['procedure_date_range'][0],
//             //         _lte: filters['procedure_date_range'][1],
//             //       },
//             //     },
//             //   ],
//             // },
//           ],
//         },
//       },
//     });
//   }
//   if (filters['limit']) {
//     result['limit'] = filters['limit'];
//   }
//   if (filters['offset']) {
//     result['offset'] = filters['offset'];
//   }
//   return result;
// };
//
// const usePatientProcedures: (filters?: Filters) => ReturnType = (filters) => {
//   const { data, loading, error, refetch: refresh } = useQuery(QUERY, { variables: createFilters(filters) });
//   let procedures: any[] = [];
//   let total = 0;
//   let prescriptions: any[] = [];
//   if (!loading && !error) {
//     console.log(data);
//
//     procedures = data.c_prescription_prescription_editor_notes.map((a: any) => {
//       const encounter = a.prescription_review && a.prescription_review.prescription && a.prescription_review.prescription.encounter ? a.prescription_review.prescription.encounter : {};
//       return {
//         ...encounter,
//         latest_editor_status: { status: a.status },
//         latest_reviewer_status: a.prescription_review.reviewer_notes[0],
//       };
//     });
//     procedures.forEach((procedure: any) => {
//       if (!procedure.prescriptions) {
//         procedure.prescriptions = [];
//       }
//     });
//     total = data.c_prescription_prescription_editor_notes_aggregate.aggregate.count;
//     const q: any[] = [];
//     if (procedures) {
//       procedures.forEach((p: any) => {
//         if (p.prescriptions && p.prescriptions.length) {
//           p.prescriptions.forEach((prescription: any) => {
//             q.push({ ...p, prescription });
//           });
//         } else {
//           q.push({ ...p });
//         }
//         delete q[q.length - 1].prescriptions;
//       });
//       prescriptions = q;
//     }
//   }
//   const refetch = (filters?: Filters) => {
//     return refresh(createFilters(filters));
//   };
//   return {
//     procedures,
//     loading,
//     prescriptions,
//     refetch,
//     total,
//     error,
//   };
// };
// export default usePatientProcedures;
