import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    printPreference: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
mutation($data:jsonb!){
    insert_c_management_account_preferences_one(object: {type:"PRINT_PREFERENCES",data:$data
    
    }){
      id
    }
  }
`;

const usePrintPreferenceAdd: (onCompleted?: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted: onCompleted });
    let printPreference = null;
    if (!saving && !error && data) {
        printPreference = data.insert_c_management_account_preferences_one;
    }
    return {
        mutation,
        printPreference,
        saving,
        error,
    };
};
export default usePrintPreferenceAdd;
