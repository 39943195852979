import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import AdmissionInfoForm from './PatientAdmissionForm';
import useProcedureById from 'shared/hooks/Procedures/useProcedureById';
import { AuthConsumer } from 'shared/contexts/AuthContext';

const PatientAdmissionEdit: React.FunctionComponent<{ patientId: string }> = ({ patientId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const RoutParams: any = useParams();
    const { procedure, loading, refetch } = useProcedureById(RoutParams['id']);
    if (!procedure) {
        return <Skeleton />;
    }
    return (
        <AuthConsumer>
            {({ user }) => (
                <AdmissionInfoForm user={user} refresh={refetch} procedure={procedure} />
            )}
        </AuthConsumer>

    );
};
export default PatientAdmissionEdit;
