import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    procedureTypeQuestionnaires: any[];
    loading: boolean;
    error: any;
    refetch:any;
};

const QUERY = gql`
    query  ($where: c_prescription_encounter_templates_bool_exp){
        c_prescription_encounter_templates(where: $where) {
            id
            encounter_type_id
            encounter_type{
                id
                name
            }
            template_id
            template{
                id
                name
                is_follow_up

            }
            id
        }
    }
`;

const useProcedureTypeQuestionnaires: (options?: { procedure_type_id?: string, questionnaire_id?: string }) => ReturnType = (options?) => {
    const WhereCondition: any = {};
    if (options) {
        if (options.procedure_type_id && options.questionnaire_id) {
            WhereCondition['_and'] = [{ 'encounter_type_id': { '_eq': options.procedure_type_id } }, { 'template_id': { '_eq': options.questionnaire_id } }];
        } else if (options.procedure_type_id) {
            WhereCondition['encounter_type_id'] = { '_eq': options.procedure_type_id };
        } else if (options.questionnaire_id) {
            WhereCondition['template_id'] = { '_eq': options.questionnaire_id };
        }
    }
    const { data, loading, error ,refetch} = useQuery(QUERY, { variables: { where: WhereCondition } });
    let procedureTypeQuestionnaires = [];
    if (!loading && !error) {
        procedureTypeQuestionnaires = data.c_prescription_encounter_templates;
    }
    return {
        procedureTypeQuestionnaires,
        loading,
        error,
        refetch
    };
};
export default useProcedureTypeQuestionnaires;
