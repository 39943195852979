import React from 'react';
import Card from 'react-bootstrap/Card';
import ReactTooltip from 'react-tooltip';
import ID from '../../Utils/UUID';
import RowComponent from './Row';
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
import SectionViewComponent from '../../../components/ProcedureFromView/Component/SectionViewComponent';
import { FormOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';

class Section extends React.Component {
    constructor(props) {
        super(props);
        let showHideArray = [];
        if (props.section.rows && props.section.rows.length) {
            props.section.rows.forEach((row, rowIdx) => {
                if (row.columns && row.columns.length) {
                    row.columns.forEach((col, colIdx) => {
                        if (col.colId && col.class === 'displayparent') showHideArray.push({ colIdValue: col.colId, colIdKey: col.colId, values: col.uiData, type: col['type'], rowIndex: rowIdx, colIndex: colIdx });
                    })
                }
            });
        }
        this.state = {
            isEditMode: true,
            // expanded: props.serialNo === 1 ? true : false,
            expanded: true,
            requiredError: false,
            currentRowId: '',
            showHideParentArray: showHideArray,
            alert: {
                show: false,
                type: 'error',
                text: 'Are you sure you want to delete this section and all its children?',
                title: 'Confirm Delete'
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.section.rows && this.props.section.rows.length) {
                let showHideParentArray = [];
                this.props.section.rows.forEach((row, rowIdx) => {
                    if (row.columns && row.columns.length) {
                        row.columns.forEach((col, colIdx) => {
                            if (col.colId && col.class === 'displayparent') showHideParentArray.push({ colIdValue: col.colId, colIdKey: col.colId, values: col.uiData, type: col['type'], rowIndex: rowIdx, colIndex: colIdx });
                        })
                    }
                });
                this.setState({ showHideParentArray });
            }
        }
    }

    onSectionMetaChange = (e) => {
        let { name, value } = e.target ? e.target : e;
        if (name === 'sectionOffset') {
            value = value ? "w-100" : "";
        }
        this.props.onSectionMetaEmit({ sectionId: this.props.section['id'], name, value });
    }

    onRowMetaChange = (meta) => {
        let { rows } = this.props.section;
        let idx = rows.findIndex(r => r.rowId === meta.rowId);
        rows[idx][meta.name] = meta.value;
        this.onSectionMetaChange({ name: 'rows', value: rows });
        this.setState({ currentRowId: meta.rowId });
    }

    onDelButtonClick = () => {
        let { alert } = this.state;
        alert.show = true;
        this.setState({ alert });
    }

    onAlertClose = (status) => {
        let { alert } = this.state;
        alert.show = false;
        this.setState({ alert });
        if (status) {
            this.props.onDelSectionButtonEmit(this.props.section['id']);
        }
    }

    toggleSectionExpand = (e) => {
        this.setState({ expanded: !this.state.expanded });
    }

    onSectionModeToggle = () => {
        // add row only when on editmode and toggling to rowmode && there are no rows
        if (this.state.isEditMode && !this.props.section['rows'].length) {
            this.props.onRowAddition({ sectionId: this.props.section['id'], rowId: null });
        };
        ReactTooltip.hide(this.toggleSectionModeRef);
        this.props.onEditSection({ sectionIndex: this.props.serialNo - 1 });
    }

    addRow = (meta) => {
        meta = {
            ...meta,
            sectionId: this.props.section['id']
        };
        this.props.onRowAddition(meta);
    }

    deleteRow = (meta) => {
        meta = {
            ...meta,
            sectionId: this.props.section['id']
        };
        this.setState({ currentRowId: meta.rowId }, () => this.props.onRowDeletion(meta));
    }

    onCloneSectionClick = (e) => {
        this.props.cloneSection(JSON.parse(JSON.stringify(this.props.section)));
    }

    onCloneRow = (idx, row) => {
        let { rows } = this.props.section;

        //replace ID of cloned row
        row['rowId'] = ID.uuid();
        if (row.children && row.children.length) {
            //replace ID of each sub row in row
            row.children.forEach((child, cidx) => {
                row.children[cidx]['rowId'] = ID.uuid();
            });
        }
        rows.splice(idx + 1, 0, row);
        this.onSectionMetaChange({ name: 'rows', value: rows })
    }

    deleteFromShowHideConfig = config => {
        let { showHideParentArray } = this.state;
        let idx = showHideParentArray.findIndex(o => o.rowIndex === config.rowIndex && o.colIndex === config.colIndex);
        if (idx !== -1) {
            showHideParentArray.splice(idx, 1);
        }
        this.setState({ showHideParentArray });
    }

    addSectionIndexForEditingRows = e => {
        if (e.childIndex === undefined) {
            this.props.onEditRow({ ...e, sectionIndex: this.props.serialNo - 1, showHideParentArray: this.state.showHideParentArray });
            this.setState({ currentRowId: e.row.rowId });
        }
        else {
            this.props.onEditSubrow({ ...e, sectionIndex: this.props.serialNo - 1 });
            this.setState({ currentRowId: this.props.section.rows[e.rowIndex].rowId });
        }
    }

    addSectionIndexForEditingColumns = e => {
        if (e.childIndex === undefined) {
            this.props.onEditColumn({ ...e, sectionIndex: this.props.serialNo - 1 });
        }
        else this.props.onEditColumn({ ...e, sectionIndex: this.props.serialNo - 1 });
        this.setState({ currentRowId: this.props.section.rows[e.rowIndex].rowId });
    }

    onDragStart = (e, index) => {
        if (e.currentTarget !== e.target) return;
        this.draggedItem = index;
        let row = this.props.section.rows[index];
        let dragImg = document.createElement('div');
        dragImg.className = 'row-ghost';
        dragImg.innerText = row['name'] ? row['name'] : row['type'] === 'subSection' ? `Sub Section ${index + 1}` : `Row ${index + 1}`;
        document.body.appendChild(dragImg);
        dragImg.setAttribute('style', 'position: absolute; left: 0px; top: 0px; z-index: -1');
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setDragImage(dragImg, 20, 20);
    };

    onDragOver = index => {
        this.draggedOverItem = index;
    };

    onDragEnd = (e) => {
        if (e.currentTarget !== e.target) return;
        // if the item is dragged over itself, ignore
        if (this.draggedItem === this.draggedOverItem) {
            return;
        }

        // filter out the currently dragged item
        let rows = this.props.section.rows.filter((item, indx) => indx !== this.draggedItem);

        // add the dragged item after the dragged over item
        rows.splice(this.draggedOverItem, 0, this.props.section.rows[this.draggedItem]);
        this.onSectionMetaChange({ name: 'rows', value: rows });
        this.draggedItem = this.draggedOverItem = null;
    };

    rowProps = () => {
        return {
            sectionOffset: this.props.section['sectionOffset'],
            onRowMetaEmit: this.onRowMetaChange.bind(this),
            showDelete: this.props.section.rows.length > 1,
            onRowAddition: this.addRow.bind(this),
            onCloneRow: this.onCloneRow.bind(this), // send idx, row here
            onSubRowEditClick: this.addSectionIndexForEditingRows.bind(this),
            onEditRowClick: this.addSectionIndexForEditingRows.bind(this),
            onEditColumnClick: this.addSectionIndexForEditingColumns.bind(this),
            uiDataDict: this.props.section['uiDataDic'],
            onRowDeletion: this.deleteRow.bind(this),
            deleteFromShowHideConfig: this.deleteFromShowHideConfig.bind(this),
            emitFreeTextData: this.onFreeTextDataChange.bind(this),
            showHideParentArray: this.state.showHideParentArray,
        }
    }

    onFreeTextDataChange = (event, sectionIndex) => {
        console.log(event, 'section index: ' + sectionIndex);
        console.log(this.props.section);
        this.props.onFreeTextChange(event, sectionIndex)
    }

    onAddCustomRow = () => {
        this.props.onAddCustomRow({ sectionIndex: this.props.serialNo - 1 });
    }

    render() {
        const { title, rows, showType } = this.props.section;
        const { expanded, isEditMode } = this.state;
        const icon_style={marginLeft:"5px" }
        let dynamicForm = this.props.section ? { sections: [this.props.section] } : { sections: [] };
        const compactTableEdgeCase = this.props.section && this.props.section['showType'] === 'compactTable' && this.props.section['splitTable'] && this.props.section.rows && this.props.section.rows.length === 1 ? true : false;
        return (
            <>
                <Card>
                    <Card.Header>
                       
                        <Row className="row section-header pointer-cursor" onClick={this.onSectionModeToggle.bind(this)}>
                            <Col  span={6} className="col-md-7 element-names"><h6>{title ? title : `Section ${this.props.serialNo}`}{' '}</h6>
                                :: Rows:{rows.length} | Showtype: {showType}</Col>
                            <Col offset={15} className="col-md-5 d-flex justify-content-end" style={{fontSize:'18px' }}>
                                {/* <a className="actions text-info" onClick={this.onAddCustomRow.bind(this)} href="#">Add Row Template..</a> */}
                                <a style={icon_style} className="actions" onClick={this.onSectionModeToggle.bind(this)} ref={ref => this.toggleSectionModeRef = ref} data-tip data-for="editSection"><FormOutlined /></a>
                                <a style={icon_style} className="actions" onClick={this.onDelButtonClick.bind(this)} data-tip data-for='deleteSection'><DeleteOutlined /></a>
                                <a style={icon_style} className="actions" onClick={this.onCloneSectionClick.bind(this)} data-tip data-for='cloneSection'><CopyOutlined /></a>
                                {/* <a className="actions" onClick={this.toggleSectionExpand.bind(this)}><i className={`mdi mdi-chevron-${expanded ? 'up' : 'down'}`}></i></a> */}
                            </Col>
                        </Row>
                    </Card.Header>
                    <h5 className="text-info ml-3">Take your mouse over any row to start editing it...</h5>
                    {showType !== 'compactTable' && <div className="content-wrap">
                        <SectionViewComponent
                            viewType={""}
                            isEditable={true}
                            dynamicFormData={dynamicForm}
                            emitEventIfFormValueChange={() => { }}
                            isView={true}
                            template_id={this.props.template_id}
                            emitPartialPrescFormData={() => { }}
                            emitFreeTextData={this.onFreeTextDataChange.bind(this)}
                            onDragOver={(e) => this.onDragOver(e)} onDragStart={this.onDragStart.bind(this)} onDragEnd={this.onDragEnd.bind(this)}
                            rowProps={this.rowProps()}
                        />
                    </div>}

                    {showType === 'compactTable' &&
                        <Card.Body>
                            <ul>
                                {rows.map((row, idx) => <li key={idx} onDragOver={() => this.onDragOver(idx)}>
                                    <div draggable onDragStart={e => this.onDragStart(e, idx)} onDragEnd={this.onDragEnd.bind(this)}>
                                        <RowComponent key={row['rowId']}
                                            currentRowId={compactTableEdgeCase ? row['rowId'] : this.state.currentRowId}
                                            sectionOffset={this.props.section['sectionOffset']}
                                            expanded={true}
                                            isHoverView={false}
                                            row={row}
                                            showType="compactTable"
                                            isChildRow={false}
                                            serialNo={idx + 1}
                                            onRowMetaEmit={this.onRowMetaChange.bind(this)}
                                            showDelete={rows.length > 1}
                                            onRowAddition={this.addRow.bind(this)}
                                            onCloneRow={this.onCloneRow.bind(this, idx)}
                                            onSubRowEditClick={this.addSectionIndexForEditingRows.bind(this)}
                                            onEditRowClick={this.addSectionIndexForEditingRows.bind(this)}
                                            onEditColumnClick={this.addSectionIndexForEditingColumns.bind(this)}

                                            uiDataDict={this.props.section['uiDataDic']}
                                            onRowDeletion={this.deleteRow.bind(this)}

                                            deleteFromShowHideConfig={this.deleteFromShowHideConfig.bind(this)}
                                            showHideParentArray={this.state.showHideParentArray}
                                        />
                                    </div>
                                </li>)}
                            </ul>
                        </Card.Body>
                    }
                </Card>
                {this.state.alert.show && <SweetAlertComponent type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text}
                    sweetAlertClose={this.onAlertClose.bind(this, true)}
                    showCancel={true}
                    onCancel={this.onAlertClose.bind(this, false)}></SweetAlertComponent>}
            </>
        );
    }
}

export default Section;