import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAddPatientsProcedures from 'shared/hooks/Procedures/useAddPatientsProcedures';
import useDepartments from 'shared/hooks/Departments/useDepartments';
import { PROCEDURES_EDIT_ROUTE, PROCEDURES_LIST_ROUTE } from 'shared/constants/RouteConstants';
import { Button, Col, DatePicker, Divider, Form, Input, List, notification, Row, Select, Spin, Tooltip } from 'antd';
import { DoctorsFromDepartment, ProcedureTypesFromDepartment } from 'shared/components/Common/CommonDependingFields';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import moment from 'moment';
import * as _ from 'lodash';

import useUpdatePatientsProcedures from 'shared/hooks/Procedures/useUpdatePatientsProcedures';
import useAddReviewersToProcedures from 'shared/hooks/Procedures/useAddReviewersToProcedures';
import useRemoveReviewersToProcedures from 'shared/hooks/Procedures/useRemoveReviewersToProcedures';
import useAddEditorsToProcedures from 'shared/hooks/Procedures/useAddEditorsToProcedures';
import useRemoveEditorsToProcedures from 'shared/hooks/Procedures/useRemoveEditorsToProcedures';
import useUserDepartments from 'shared/hooks/UserDepartments/useUserDepartments';
import { hasPermissionOf } from 'shared/services/Utility';
import { PATIENT_ENCOUNTER_REVIEW, PATIENT_ENCOUNTER_UPDATE } from 'shared/constants/Permissions';
import { DateFormat, PrescriptionReviewerApproved } from 'shared/constants/AppConstants';

const AdmissionInfoForm: React.FunctionComponent<{ procedure?: any, patient?: any, user?: any, refresh: any }> = ({
                                                                                                                    patient,
                                                                                                                    user,
                                                                                                                    procedure,
                                                                                                                    refresh,
                                                                                                                  }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const RoutParams: any = useParams();
  const [fields]: any[] = useState([]);
  const [form] = Form.useForm();
  const [selectedDepartment, setSelectedDepartment] = useState(null as any);
  const [primaryPrescription, setPrimaryPrescription] = useState(null as any);
  const [admissionDate, setAdmissionDate] = useState(null as any);
  const [selectedEpisodeType, setSelectedEpisodeType] = useState(null as any);
  const [loadedFromUrl, setLoadedFromURL] = useState(false);
  const [dischargeDate, setDischargeDate] = useState(new Date() as any);
  const [startDate, setStartDate] = useState(new Date() as any);
  const [initialValues, setInitialValues] = useState({
    type: 'IPD',
  } as any);

  useEffect(() => {
    const queryString = require('query-string');

    const parsed = queryString.parse(window.location.search);
    if (parsed) {
      let inV: any = {};
      if (parsed.episode) {
        inV['type'] = parsed.episode;
        setLoadedFromURL(true);
      }
      if (!isNaN(parsed.admission_date)) {
        inV['admission_date'] = moment(new Date(parseInt(parsed.admission_date)));
      }
      if (!isNaN(parsed.visit_date)) {
        inV['visit_date'] = moment(new Date(parseInt(parsed.visit_date)));
      }
      if (parsed.episode_id) {
        inV['type_id'] = parsed.episode_id;
      }
      setInitialValues(inV);
      form.setFieldsValue(inV);
    }
  }, []);

  useEffect(()=>{
    if(procedure && procedure.prescriptions?.length){
      setPrimaryPrescription(procedure.prescriptions.find((p: any)=>!p.template?.is_supplementary));
    }
  }, [procedure])
  const { mutation, saving } = useAddPatientsProcedures((e: any) => {
    if (e) {
      notification.success({ message: 'Added successfully' });
      history.push(PROCEDURES_LIST_ROUTE);
    }
  });

  const {
    mutation: addReviewerToProcedureMutation,
    saving: addingReviewerToProcedure,
  } = useAddReviewersToProcedures((e: any) => {
    if (e) {
      refresh({ id: procedure.id });
    }
  });
  const {
    mutation: removeReviewerToProcedureMutation,
    saving: removingReviewerToProcedure,
  } = useRemoveReviewersToProcedures((e: any) => {
    if (e) {
      refresh({ id: procedure.id });
    }
  });
  const {
    mutation: addEditorToProcedureMutation,
    saving: addingEditorToProcedure,
  } = useAddEditorsToProcedures((e: any) => {
    if (e) {
      refresh({ id: procedure.id });
    }
  });
  const {
    mutation: removeEditorToProcedureMutation,
    saving: removingEditorToProcedure,
  } = useRemoveEditorsToProcedures((e: any) => {
    if (e) {
      refresh({ id: procedure.id });
    }
  });
  const { mutation: updateProcedureMutation, saving: savingUpdate } = useUpdatePatientsProcedures((e: any) => {
    if (e) {
      notification.success({ message: 'Updated successfully' });
      history.push(PROCEDURES_LIST_ROUTE);
    }
  });
  const { departments, loading: loadingDepartments } = useDepartments({
    limit: 100,
    branch_ids: !procedure ? [patient.branch_id] : [procedure.patient.branch_id],
  });
  const { userDepartments, loading: loadingUserDepartments } = useUserDepartments({
    limit: 100,
    user_id: user.id,
    branch_ids: !procedure ? [patient.branch_id] : [procedure.patient.branch_id],
  });
  useEffect(() => {
    if (userDepartments.length === 1) {
      form.setFieldsValue({ department_id: userDepartments[0].department.id });
      setSelectedDepartment(userDepartments[0].department.id);
    }
  }, [userDepartments]);


  console.log(primaryPrescription);
  return (
    <div className={'form-wrapper'}>
      <FormViewDesktop title={'Episode Details'}>
        <Form
          size='large'
          form={form}
          initialValues={procedure ? {
            admission_date: procedure.admission_date ? moment(procedure.admission_date) : null,
            discharge_date: procedure.discharge_date ? moment(procedure.discharge_date) : null,
            start_date: procedure.start_date ? moment(procedure.start_date) : null,
            visit_date: procedure.visit_date ? moment(procedure.visit_date) : null,
            type: procedure.type,
            type_id: procedure.type_id,
            department_id: procedure.department_id,
            encounter_type_id: procedure.encounter_type_id,
            primary_doctor_id: procedure.primary_doctor_id,
            performing_doctor_id: procedure.performing_doctor_id,
          } : initialValues}
          fields={fields}
          onFinish={(formValues) => {
            if (formValues) {
              if (formValues['type'] === 'OPD') {
                if (formValues['visit_date'] && moment().diff(formValues['visit_date'], 'hours') < 0) {
                  notification.error({ message: 'Visit date cannot be from future!' });
                  return;
                }
              } else {
                const {
                  admission_date: ad, start_date: sd, discharge_date: dd,
                } = formValues;
                const admission_date = moment(ad).startOf('day');
                const start_date = moment(sd).startOf('day');
                const discharge_date = moment(dd).startOf('day');
                // Admission Date
                if (discharge_date && discharge_date.diff(admission_date, 'hours') < 0) {
                  notification.error({ message: 'Admission date cannot be after discharge date!' });
                  return;
                }
                if (start_date.diff(admission_date, 'hours') < 0) {
                  notification.error({ message: 'Admission date cannot be after start date!' });
                  return;
                }
                if (moment().diff(admission_date, 'hours') < 0) {
                  notification.error({ message: 'Admission date cannot be from future!' });
                  return;
                }
                if (discharge_date && discharge_date.diff(start_date, 'hours') < 0) {
                  notification.error({ message: 'Procedure date cannot be after discharge date!' });
                  return;
                }
              }
            }
            if (procedure) {
              updateProcedureMutation({
                variables: {
                  id: RoutParams['id'],
                  set: formValues,
                },
              });
            } else {
              formValues['patient_id'] = RoutParams['id'];
              mutation({
                variables: {
                  object: formValues,
                },
              });
            }

          }
          }
          requiredMark={true}
          layout='vertical'
        >
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <Row gutter={[20, 20]}>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label={'Episode Type'}
                    name={['type']}
                    rules={[
                      {
                        required: true,
                        message: 'Episode Type Required',
                      },
                    ]}>
                    <Select
                      onChange={(e: any) => setSelectedEpisodeType(e)}
                      disabled={loadedFromUrl || !hasPermissionOf(PATIENT_ENCOUNTER_UPDATE) || (procedure && procedure.prescriptions && procedure.prescriptions.length)}>
                      <Select.Option
                        value={'OPD'}>{'OPD'}</Select.Option>
                      <Select.Option
                        value={'IPD'}>{'IPD'}</Select.Option>
                      <Select.Option
                        value={'DAY_CARE'}>{'Day Care'}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label={'Episode ID'}
                    name={['type_id']}

                    rules={[
                      {
                        required: true,
                        message: 'Episode ID Required',
                      },
                    ]}>
                    <Input
                      disabled={loadedFromUrl || !hasPermissionOf(PATIENT_ENCOUNTER_UPDATE) || (procedure && procedure.prescriptions && procedure.prescriptions.length)} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>

            <>
              {
                form.getFieldsValue()['type'] === 'OPD' ? <>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label={'Visit Date'}
                      name={['visit_date']}
                      rules={[
                        {
                          required: true,
                          message: 'Episode ID Required',
                        },
                      ]}>
                      <DatePicker format={DateFormat}
                                  disabled={loadedFromUrl || !hasPermissionOf(PATIENT_ENCOUNTER_UPDATE) || (primaryPrescription && primaryPrescription.review?.latest_note?.status === PrescriptionReviewerApproved)} />
                    </Form.Item>
                  </Col>
                </> : (!!form.getFieldsValue()['type'] ? <>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t('patients.admissionDate')}
                      name={'admission_date'}
                      rules={[
                        {
                          required: true,
                          message: 'Admission date Required',
                        },
                        // ({ getFieldValue }) => ({
                        //   validator(_, value) {
                        //     if (value && getFieldValue('discharge_date') && getFieldValue('discharge_date').diff(value, 'hours') < 0) {
                        //       return Promise.reject(
                        //         'Admission date cannot be after discharge date!',
                        //       );
                        //     }
                        //     if (value && getFieldValue('start_date') && getFieldValue('start_date').diff(value, 'hours') < 0) {
                        //       return Promise.reject(
                        //         'Admission date cannot be after start date!',
                        //       );
                        //     }
                        //     if (value && moment().diff(value, 'hours') < 0) {
                        //       return Promise.reject(
                        //         'Admission date cannot be from future!',
                        //       );
                        //     }
                        //     return Promise.resolve();
                        //   },
                        // }),
                      ]}>
                      <DatePicker format={DateFormat}
                                  onChange={(e: any) => {
                                    setAdmissionDate(e);
                                  }}
                                  disabled={loadedFromUrl || !hasPermissionOf(PATIENT_ENCOUNTER_UPDATE)|| (primaryPrescription && primaryPrescription.review?.latest_note?.status === PrescriptionReviewerApproved)} />
                    </Form.Item>
                  </Col>

                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t('patients.procedureDate')}
                      name={['start_date']}
                      rules={[
                        {
                          required: true,
                          message: 'Start date Required',
                        },
                        // ({ getFieldValue }) => ({
                        //   validator(_, value) {
                        //     if (value && getFieldValue('admission_date') && getFieldValue('admission_date').diff(value, 'hours') > 0) {
                        //       console.log(getFieldValue('admission_date').diff(value, 'hours'));
                        //       return Promise.reject(
                        //         'Procedure start date cannot be earlier to admission date!',
                        //       );
                        //     }
                        //
                        //     if (value && getFieldValue('discharge_date') && getFieldValue('discharge_date').diff(value, 'hours') < 0) {
                        //       return Promise.reject(
                        //         'Procedure start date cannot be after  discharge date!',
                        //       );
                        //     }
                        //     return Promise.resolve();
                        //   },
                        // }),
                      ]}>
                      <DatePicker
                        format={DateFormat}
                        onChange={(e) => setStartDate(e)}
                        disabled={!hasPermissionOf(PATIENT_ENCOUNTER_UPDATE) || (primaryPrescription && primaryPrescription.review?.latest_note?.status === PrescriptionReviewerApproved)} />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t('patients.dischargeDate')}
                      name={'discharge_date'}
                      rules={[
                        // ({ getFieldValue }) => ({
                        //   validator(_, value) {
                        //     if (value && getFieldValue('admission_date') && getFieldValue('admission_date').diff(value, 'hours') > 0) {
                        //       return Promise.reject(
                        //         'Discharge date cannot be earlier than admission date!',
                        //       );
                        //     }
                        //     if (value && getFieldValue('start_date') && getFieldValue('start_date').diff(value, 'hours') > 0) {
                        //       return Promise.reject(
                        //         'Discharge  date cannot be earlier to procedure start date!',
                        //       );
                        //     }
                        //     return Promise.resolve();
                        //   },
                        // }),
                      ]}>
                      <DatePicker
                        format={DateFormat}

                        onChange={(e) => setDischargeDate(e)}
                        disabled={!hasPermissionOf(PATIENT_ENCOUNTER_UPDATE)} />
                    </Form.Item>
                  </Col>
                </> : <></>)
              }
            </>


            <Col md={8} sm={12} xs={24}>
              <Form.Item
                label={'Department'}
                name={['department_id']}
                rules={[
                  {
                    required: true,
                    message: 'Department required',
                  },
                ]}>
                <Select
                  disabled={!hasPermissionOf(PATIENT_ENCOUNTER_UPDATE) || loadingDepartments || loadingUserDepartments || (procedure && procedure.prescriptions && procedure.prescriptions.length)}
                  showSearch={true}
                  onChange={(e) => setSelectedDepartment(e)}>
                  {
                    (userDepartments.length ? _.uniqBy(userDepartments.map((d: any) => d.department), 'id') : departments).map((department: any) => {
                      return <Select.Option
                        value={department.id}>{`${department.name} | ${department.branch.name}`}</Select.Option>;
                    })
                  }

                </Select>
              </Form.Item>
            </Col>
            {
              form.getFieldsValue() && form.getFieldsValue()['department_id'] && <>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label={'Procedure Type'}
                    name={['encounter_type_id']}

                    rules={[
                      {
                        required: true,
                        message: 'Encounter type required',
                      },
                    ]}>
                    <ProcedureTypesFromDepartment
                      disabled={!hasPermissionOf(PATIENT_ENCOUNTER_UPDATE) || (procedure && procedure.prescriptions && procedure.prescriptions.length)}
                      initialValue={procedure ? procedure.encounter_type_id : null}
                      departmentId={form.getFieldsValue()['department_id']} />
                  </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label={'Primary Doctor'}
                    name={['primary_doctor_id']}
                    key={'primary_doctor_ids'}
                    rules={[
                      {
                        required: true,
                        message: 'Primary doctor required',
                      },
                    ]}>
                    <DoctorsFromDepartment
                      disabled={procedure && procedure.prescriptions && procedure.prescriptions.length}
                      initialValue={procedure ? procedure.primary_doctor_id : null}
                      departmentId={form.getFieldsValue()['department_id']} />
                  </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label={'Performing Doctor'}
                    name={['performing_doctor_id']}
                    key={'performing_doctor_id'}
                    rules={[
                      {
                        required: true,
                        message: 'Primary doctor required',
                      },
                    ]}>
                    <DoctorsFromDepartment
                      disabled={!hasPermissionOf(PATIENT_ENCOUNTER_UPDATE) || (procedure && procedure.prescriptions && procedure.prescriptions.length)}
                      initialValue={procedure ? procedure.performing_doctor_id : null}
                      departmentId={form.getFieldsValue()['department_id']} />
                  </Form.Item>
                </Col>

              </>
            }

          </Row>
        </Form>

        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col>
            <Form.Item>
              <Button type='default'
                      onClick={() => {
                        history.goBack();
                      }}
                      disabled={saving || savingUpdate}
                      size={'large'}>
                {t('genDict.cancel')}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type='primary'
                      loading={saving || savingUpdate}
                      onClick={form.submit}
                      size={'large'}>
                {/*{procedure ? 'Save' : 'Add Reviewers & Editors'}*/}
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </FormViewDesktop>
    </div>
  );
};
export default AdmissionInfoForm;
