import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Form,
    Input,
    Col,
    Row,
    Button, Space, Skeleton, Select, List, Tooltip, Divider, Checkbox,
} from 'antd';
import { useEffect, useState } from 'react';
import { GENDERS, USER_TYPES } from 'shared/constants/AppConstants';
import { RegexNumbersOnly } from 'shared/constants/RegexConstants';

type Props = {
    user?: any
    onSubmit: any
    saving?: boolean
    loading?: boolean
    onCancel?: any
    refresh?: any
}

const UserForm: React.FunctionComponent<Props> = ({
                                                      onSubmit,
                                                      saving,
                                                      user,
                                                      loading,
                                                      onCancel,
                                                      refresh,
                                                  }) => {
    const { t } = useTranslation();
    const [fields]: any[] = useState([]);
    const [form] = Form.useForm();
    if (loading) {
        return <Skeleton active={true} />;
    }
    return (
        <div className={'user-create-or-edit-page'}>
            <Form
                size='large'
                form={form}
                fields={fields}
                onFinish={async (formValues) => {
                    formValues['email'] = formValues['email'].toLowerCase();
                    if (user) {
                        delete formValues['email'];
                    }
                    onSubmit(formValues);
                }}
                initialValues={user || {}}
                requiredMark={true}
                layout='vertical'

            >
                <Row gutter={[20, 20]}>
                    <Col md={6} sm={12} xs={24}>
                        <Form.Item
                            label={'Name'}
                            name={'name'}
                            rules={[
                                { required: true, message: 'Name required' },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                        <Form.Item
                            label={'Phone'}
                            name={'phone'}
                            rules={[
                                {
                                    pattern: RegexNumbersOnly,
                                    message: 'Invalid Phone',
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                        <Form.Item
                            label={'Email'}
                            name={'email'}
                            rules={[
                                { required: true, type: 'email', message: 'Email required' },
                            ]}>
                            <Input disabled={!!user} />
                        </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                        <Form.Item
                            label={'Gender'}
                            name={'gender'}
                            rules={[]}>
                            <Select
                            >
                                {
                                    GENDERS.map((gender: any) => {
                                        return <Select.Option
                                            value={gender}>{gender}</Select.Option>;
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                        <Form.Item
                            label={'User Type'}
                            name={'user_type'}
                            rules={[]}>
                            <Select
                            >
                                {
                                    USER_TYPES.map((type: any) => {
                                        return <Select.Option
                                            value={type.key}>{type.name}</Select.Option>;
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                        <Form.Item
                            label={'Is Doctor'}
                            name={'is_doctor'}
                            valuePropName='checked'
                            rules={[]}>
                            <Checkbox>Yes</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Divider />
                        <Space>
                            <Form.Item>
                                <Button type='default'
                                        onClick={onCancel}
                                        disabled={saving}
                                        size={'large'}>
                                    {t('genDict.cancel')}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary'
                                        htmlType='submit'
                                        loading={saving}
                                        size={'large'}>
                                    {t('genDict.submit')}
                                </Button>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};


export default UserForm;
