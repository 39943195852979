import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
  mutation: any;
  questionnaire: any;
  saving: boolean;
  error: any;
};

const MUTATION = gql`
  mutation (  $id: uuid!, $template_data_id: uuid!,$is_supplementary: Boolean!, $name: String!, $code: String!, $content: jsonb!,$is_follow_up: Boolean,$is_approved:Boolean){
    update_c_prescription_templates_by_pk(_set: {name: $name,is_supplementary: $is_supplementary, code: $code,is_follow_up:$is_follow_up,is_approved: $is_approved},pk_columns: {id: $id} ) {
      name
    }
    update_c_data_template_data_by_pk(_set: {data: $content}, pk_columns: {id: $template_data_id}){
      id
    }
  }
`;

const useQuestionnaireUpdate: (onCompleted?: any) => ReturnType = (onCompleted) => {
  const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted });
  let questionnaire = null;
  if (!saving && !error && data) {
    questionnaire = data.update_c_prescription_templates_by_pk;
  }
  return {
    mutation,
    questionnaire,
    saving,
    error,
  };
};
export default useQuestionnaireUpdate;
