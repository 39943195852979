import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    procedureTypeQuestionnaire: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
    mutation (  $encounter_type_id: uuid!, $template_id: uuid!){
        insert_c_prescription_encounter_templates_one(object: {encounter_type_id: $encounter_type_id, template_id: $template_id}) {
            id
            template_id
            template{
                id
                name
            }
            encounter_type_id
            encounter_type{
                id
                name
            }

        }
    }
`;

const useProcedureTypeQuestionnairesCreate: (onCompleted?: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted: onCompleted });
    let procedureTypeQuestionnaire = null;
    if (!saving && !error && data) {
        procedureTypeQuestionnaire = data.insert_c_prescription_encounter_templates_one;
    }
    return {
        mutation,
        procedureTypeQuestionnaire,
        saving,
        error,
    };
};
export default useProcedureTypeQuestionnairesCreate;
