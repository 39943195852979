import React from "react";



class NGridComponent extends React.Component {

    checkForGridSize = (showType, data) => {
        if (showType === "2d") {
            if (data.indexOf(":") === -1) {
                return '12';
            } else {
                return '6'
            }
        } else if (showType === "3d") {
            if (data.indexOf(":") === -1) {
                return '12';
            } else {
                return '4'
            }
        } else if (showType === "1d") {
            if (data.indexOf(":") === -1) {
                return '12';
            } else {
                return '12'
            }
        }
    }

    render() {
        const { data, sectionData, showType } = this.props;
        return !!data &&
            data.map((indObj, index) =>
                indObj.type === 'group' ?
                    <>
                        <>
                            <div className={`col-${(showType === "2d") ? 6 : (showType === "3d") ? 4 : 12}  p-1`} style={{ display: "flex" }}>
                                <div className="card p-0" style={{ boxShadow: "0 1px 4px 0 rgba(0,0,0,0.2)", marginBottom: 5, flex: 1 }}>

                                    <div className="card-body p-1">
                                        <h6 className="card-title mt-0 mb-0" style={{ fontSize: 14, fontWeight: 500, display: indObj.rowName ? '' : 'none' }}>
                                            {indObj.rowName} :</h6>
                                        {indObj && indObj.data && indObj.data.map((indArray, jndex) =>
                                            <div className="row" key={jndex}>
                                                {indArray && !indArray.map ? (
                                                    <div key={jndex} className={`col-${this.checkForGridSize(showType, indArray)}`}>
                                                        <p className="card-text mt-0 headingSize textColor">{indArray}</p>
                                                    </div>
                                                ) : (
                                                        <>
                                                            {indArray && indArray.length > 0 && indArray.map((indItem, kndex) =>
                                                                <div key={kndex} className={`col-${this.checkForGridSize(showType, indItem)}`}>
                                                                    <p className="card-text mt-0 headingSize textColor">{indItem}</p>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    </> : <div className={`col-${(showType === "2d") ? 6 : (showType === "3d") ? 4 : 12}  p-1`} style={{ display: "flex" }}>
                        {indObj && indObj.data && indObj.data.map((indArray, jndex) =>
                            <div className="card p-0" style={{ boxShadow: "0 1px 4px 0 rgba(0,0,0,0.2)", marginBottom: 5, flex: 1 }}>
                                <div className="card-body p-1">
                                    <div className="row" key={jndex}>
                                        <div className={`col-${this.checkForGridSize(showType, indArray)}`}>
                                            <p className="card-text mt-0 headingSize textColor">{indArray}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
            )
    }
}

export default NGridComponent;
