import React from "react";


class TableViewComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        }
        // this.formatTableDataBasedOnType();
    }

    getFormatedDate(date) {
        if (!date) {
            return
        }
        let newDate = "";
        if (date.indexOf("Date :") !== -1) {
            let dateArray = date.split('Date :');
            newDate = dateArray[1];
            newDate = newDate.trim();

            if ((new Date(newDate).toString()) !== "Invalid Date") {
                var startDate = new Date(newDate);
                var sdate = startDate.getDate();
                var smonth = startDate.getMonth() + 1;
                var syear = startDate.getFullYear();
                if (sdate < 10) {
                    sdate = '0' + sdate;
                }
                if (smonth < 10) {
                    smonth = '0' + smonth;
                }
                return sdate + "-" + (smonth) + '-' + syear
            }
            return newDate && newDate.indexOf("-") !== -1 ? newDate : ''
        }
        return date;
    }

    render() {
        const { data } = this.props;
        return (
            <React.Fragment>
                {/* {date &&
                    <React.Fragment>
                        <div className="badge badge-outline-success" style={{ margin: '5px 2px' }}>
                            <label key={index} style={{ margin: '0px 5px' }}>{date}</label>
                        </div>
                    </React.Fragment>
                } */}
                {data && data.length > 0 &&
                    <React.Fragment>
                        {data.map((inData, index) =>
                            <div className="table-responsive drugstable" key={index + inData}>
                                {inData.date &&
                                    <div>
                                        <div className="btn  radius-0 px-0 py-2" key={index} style={{ display: this.getFormatedDate(inData.date) ? '' : 'none' }}>
                                            <label className="m-0"><i className="mdi mdi-calendar-today" style={{ fontSize: '1.15rem' }}></i>{this.getFormatedDate(inData.date)}</label></div>
                                    </div>
                                }
                                <table className="table table-sm table-bordered" style={{ border: "1px solid #ebedf2" }}>
                                    <thead className=" thead" style={{ textTransform: 'capitalize', color: '#000' }}>
                                        <tr>
                                            {inData.headerData &&
                                                <React.Fragment>
                                                    {inData.ordered && <th style={{ width: '30px' }} key={index}></th>}
                                                    {inData.headerData.map((indHeader, jndex) =>
                                                        <th key={jndex + indHeader}> {indHeader} </th>
                                                    )}
                                                </React.Fragment>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody style={{ color: '#000' }}>
                                        {!!inData.bodyData &&
                                            <React.Fragment>
                                                {inData.type === "row" ? (
                                                    <>
                                                        <tr key={index}>
                                                            {inData.ordered && <td>{index + 1}</td>}
                                                            {inData.bodyData && inData.bodyData.map((indTableElement, kndex) =>
                                                                <React.Fragment key={kndex}>
                                                                    <td key={kndex} style={{ whiteSpace: "break-spaces" }}>{indTableElement}</td>
                                                                </React.Fragment>)}
                                                        </tr>
                                                    </>
                                                ) : (
                                                        <>
                                                            {inData.bodyData && inData.bodyData.map((indTableElement, zndex) =>
                                                                <React.Fragment key={zndex}>
                                                                    {indTableElement && indTableElement.map((indElement, jndex) =>
                                                                        <tr key={jndex}>
                                                                            {inData.ordered && <td>{jndex + 1}</td>}
                                                                            <React.Fragment key={jndex}>
                                                                                {indElement.map((value, kndex) =>
                                                                                    <td key={kndex} style={{ whiteSpace: "break-spaces" }}>{value}</td>
                                                                                )}
                                                                            </React.Fragment>
                                                                        </tr>
                                                                    )}
                                                                </React.Fragment>)}
                                                        </>
                                                    )

                                                }

                                            </React.Fragment>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </React.Fragment>
                }

            </React.Fragment>
        );
    }
}

export default TableViewComponent;


