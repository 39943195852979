import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
// import { msalConfig } from 'shared/Apps/CustomAzureAD/authConfig';
import { msalConfig as msalConfigB2B } from 'shared/Apps/CustomAzureAD/authConfigB2B';
import App from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
    dsn: process.env.SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});
// export const msalInstance = new PublicClientApplication(msalConfig);
export const msalInstance = new PublicClientApplication(msalConfigB2B);
// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

ReactDOM.render(
    <Router>
        <App pca={msalInstance} />
    </Router>,
    document.getElementById('root'),
);
