import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card, Checkbox, Col, Divider, Dropdown, Form, Menu,
  Modal, notification, Row,
  Select, Skeleton,
  Space,
  Steps, Tabs,
} from 'antd';
import * as _ from 'lodash';
import Parameters from 'shared/parameters';
import '../index.scss';
import Text from 'antd/es/typography/Text';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import useProcedureById from 'shared/hooks/Procedures/useProcedureById';
import { useEffect, useState } from 'react';
import usePrescriptionsOfProcedureById from 'shared/hooks/Procedures/usePrescriptionsOfProcedureById';
import Title from 'antd/es/typography/Title';
import {
  PrescriptionEditorStatusInProgress, PrescriptionEditorStatusReWork,
  PrescriptionEditorStatusSubmitted, PrescriptionPatientNew, PrescriptionReviewerApproved, PrescriptionReviewerPending,
} from 'shared/constants/AppConstants';
import DynamicFormContainer from 'shared/components/ProcedureFromView/DynamicFormContainer';
import addOrUpdatePrescription from 'shared/hooks/PatientPrescriptions/addOrUpdatePrescription';
import useQuestionnaireById from 'shared/hooks/Templates/useQuestionnaireById';
import ProcedureView from 'shared/components/ProcedureView/ProcedureView';
import { hasPermissionOf } from 'shared/services/Utility';
import { PATIENT_ENCOUNTER_DELETE, PATIENT_ENCOUNTER_UPDATE } from 'shared/constants/Permissions';
import moment from 'moment';
import useDeletePatientsPrescription from 'shared/hooks/Procedures/useDeletePatientsPrescription';
import { PROCEDURES_PRESCRIPTION_REVIEW_ROUTE } from 'shared/constants/RouteConstants';
import PatientTitleBar, { PatientPrintTitleBar } from 'shared/components/PatientComponents/PatientTitleBar';
import usePrescriptionReviewNotes from 'shared/hooks/Procedures/usePrescriptionReviewNotes';
import StatusTags from 'shared/components/StatusTags/StatusTags';
import usePrintPreference from 'shared/hooks/AccountPreferences/usePrintPreference';
import FormView from 'shared/components/FormView/FormView';
import parameters from 'shared/parameters';

const { TabPane } = Tabs;
const { confirm } = Modal;
const { Step } = Steps;
type Props = {
  user: any
}

const ProcedurePrescriptionPage: React.FunctionComponent<Props> = ({ user }) => {
  const RouteParams: any = useParams();
  return <PrescriptionsTabComponent user={user} prescriptionId={RouteParams['prescriptionId']}
                                    procedureId={RouteParams['id']} />;
};

const PrescriptionsTabComponent: React.FunctionComponent<any> = ({ user, procedureId, prescriptionId }: any) => {
    const { procedure, loading, refetch: refetchProcedure } = usePrescriptionsOfProcedureById(procedureId);
    const [prescriptions, setPrescriptions] = useState(null as any);
    const [componentRef, setComponentRef] = useState(null);
    const [activeKey, setActiveKey] = useState('0' as any);
    const { t } = useTranslation();
    const [displayLogsFor, setDisplayLogsFor] = useState(null as any);
    let { preferences } = usePrintPreference();
    const [displayPrintFor, setDisplayPrintFor] = useState(null as any);
    useEffect(() => {
      if (procedure && !prescriptions) {
        setPrescriptions(procedure.prescriptions || []);
        if (prescriptionId) {
          (procedure.prescriptions || []).forEach((prescription: any, index: any) => {
            if (prescription.id === prescriptionId) {
              setActiveKey(index + '');
            }
          });
        }
      } else if (procedure) {
        const indicesToRemove: number[] = [];
        const newTemplateIds: any = {};
        const indicesToUpdate: any = {};
        prescriptions.forEach((prescription: any, index: number) => {
          if (!prescription.id) {
            newTemplateIds[prescription['template'].id] = index;
          }
          if (prescription.shouldEdit) {
            indicesToUpdate[prescription.id] = index;
          }
        });
        procedure.prescriptions.forEach((prescription: any) => {
          if (newTemplateIds[prescription.template.id] > -1) {
            prescriptions[newTemplateIds[prescription.template.id]] = prescription;
          }

          if (indicesToUpdate[prescription.id] > -1) {
            prescriptions[indicesToUpdate[prescription.id]] = prescription;
          }
        });
        setPrescriptions(JSON.parse(JSON.stringify(prescriptions)));
      }
    }, [procedure, prescriptionId]);
    const filterNewPrescriptionsList = () => {
      const result: any = [];
      procedure?.encounter_type?.encounter_templates?.forEach(({ template }: any) => {
        if (template) {
          let found = false;
          prescriptions.forEach((prescription: any) => {
            if (!(prescription.template && prescription.template.id !== template.id)) {
              found = true;
            }
          });
          if (!found) {
            result.push(template);
          }
        }
      });
      return _.uniqBy(result, 'id');
    };
    const {
      mutation: deletePrescriptionMutation,
      saving: deletingPrescription,
    } = useDeletePatientsPrescription((e: any) => {
      if (e) {
        setActiveKey('0');
        refetchProcedure();
        if (e.update_c_prescription_prescriptions_by_pk) {
          const prescription_id = e.update_c_prescription_prescriptions_by_pk.id;
          let index = null;
          prescriptions.forEach((p: any, i: number) => {
            if (p.id === prescription_id) {
              index = i;
            }
          });

          const d = JSON.parse(JSON.stringify(prescriptions));
          d.splice(index, 1);
          setPrescriptions(JSON.parse(JSON.stringify(d)));
        }
      }
    });
    const deletePrescription = (index: number) => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        title: 'Delete prescription',
        okText: 'Yes',
        cancelText: 'Cancel',
        content: 'Delete prescription permanently?',// TODO
        okButtonProps: { loading: deletingPrescription, type: 'default' },
        cancelButtonProps: { disabled: deletingPrescription, type: 'primary', danger: true },
        onOk: async () => {
          if (prescriptions[index].id) {
            deletePrescriptionMutation({
              variables: {
                encounter_id: procedure['id'],
                id: prescriptions[index].id,
                set: {
                  deleted_at: moment(),
                  deleted_by_id: user.id,
                },
              },
            });
          } else {
            const d = JSON.parse(JSON.stringify(prescriptions));
            d.splice(index, 1);
            setPrescriptions(JSON.parse(JSON.stringify(d)));
          }
        },
      });
    };
    const showContinueEditingToEditor = ({ latest_editor_note }: any) => {
      return (latest_editor_note && (latest_editor_note.status === PrescriptionPatientNew || latest_editor_note.status === PrescriptionEditorStatusInProgress || latest_editor_note.status === PrescriptionEditorStatusReWork));
    };
    if (preferences && preferences.data && typeof preferences.data == 'string') {
      preferences.data = JSON.parse(preferences.data);
    }

    if (!prescriptions) {
      return <Skeleton />;
    }
    if (!prescriptions.length) {
      return <Card>
        <PatientTitleBar patientId={procedure.patient_id} procedure={procedure} />
        <NoPrescriptionView templates={procedure.encounter_type.encounter_templates.map((t: any) => t.template)}
                            onAdd={
                              (template: any) => {
                                setPrescriptions([{ isNew: true, template }, ...prescriptions]);
                                setActiveKey('0');
                              }
                            } />
      </Card>;
    }
    const showDeletePrescriptionButton = (prescription: any) => {
      let result = true;
      if (prescription?.review?.latest_reviewer_status?.status === PrescriptionReviewerApproved) {
        result = false;
      } else if (prescription?.template?.is_supplementary) {
        prescriptions.forEach((p: any) => {
          if (p?.review?.latest_reviewer_status?.status === PrescriptionReviewerApproved) {
            result = false;
          }
        });
      }
      return result;
    };
    const activePrescription = prescriptions[parseInt(activeKey)];
    return <Card>
      <div style={{ marginBottom: '10px' }}>
        <PatientTitleBar patientId={procedure.patient_id} procedure={procedure} />
        <Divider />
        <Modal title='Notes' visible={displayLogsFor} onOk={() => setDisplayLogsFor(null)}
               cancelButtonProps={{ hidden: true }} onCancel={() => setDisplayLogsFor(null)}>
          <PrescriptionNoteLogs prescriptionReviewId={displayLogsFor} />
        </Modal>
        {preferences && preferences.data && preferences.data.printButtonEnable ?
          <>
            <Modal okText={t('genDict.print')} title={t('genDict.printPreview')} width='100%' style={{ top: 60 }}
                   visible={displayPrintFor} onOk={() => {
              window.print();
              return false;
            }}
                   onCancel={() => setDisplayPrintFor(null)}>
              <PrescriptionPrintPreview printPreferences={preferences.data} prescriptionData={activePrescription}
                                        procedure={procedure} />


            </Modal>
          </> : <></>}
      </div>
      <Tabs onChange={(e) => setActiveKey(e)} type='card' defaultActiveKey={'0'} activeKey={activeKey}
            tabBarExtraContent={
              {
                right: <Space>
                  {
                    activePrescription && activePrescription.review && <Button onClick={(e) => {
                      setDisplayLogsFor(activePrescription.review.id);
                    }
                    }>Logs</Button>
                  }
                  {activePrescription && activePrescription.review && preferences && preferences.data && preferences.data.printButtonEnable ?
                    <>
                      <Col>
                        <Button onClick={
                          () => setDisplayPrintFor(activePrescription.review.id)
                        } type={'primary'}>{t('genDict.print')}</Button>
                      </Col>
                    </> : <></>}
                  {
                    filterNewPrescriptionsList().length > 0 && procedure.encounter_type.enable_multiple_prescriptions &&
                    <Dropdown overlay={
                      <Menu>
                        {
                          filterNewPrescriptionsList().map((template: any) => {
                            return <Menu.Item key={template.id} onClick={() => {
                              setPrescriptions([{ isNew: true, template }, ...prescriptions]);
                              setActiveKey('0');
                            }}>
                              {
                                template.name
                              }
                            </Menu.Item>;
                          })
                        }

                      </Menu>
                    } placement='bottomLeft' arrow>
                      <Button type={'primary'}>Add More</Button>
                    </Dropdown>
                  }
                </Space>,
              }
            }>
        {
          prescriptions.map((prescription: any, index: number) => {
            if (!prescription.id) {
              return <TabPane tabKey={index + ''} tab={
                <Space>
                  {
                    hasPermissionOf(PATIENT_ENCOUNTER_DELETE) &&
                    showDeletePrescriptionButton(prescription) &&
                    <DeleteOutlined onClick={() => deletePrescription(index)} />
                  }
                  <Text>{prescription.template?.name}</Text>
                </Space>
              } key={index + ''}>
                <EmptyTemplateView template={prescription.template} procedure={procedure} procedure_id={procedureId}
                                   onDelete={
                                     () => {
                                       deletePrescription(index);
                                     }
                                   }
                                   onAddNewSuccess={
                                     (e: string) => {
                                       refetchProcedure();
                                     }
                                   } />
              </TabPane>;
            }
            if (prescription.shouldEdit) {
              return <TabPane tabKey={index + ''} tab={
                <Space>
                  {
                    hasPermissionOf(PATIENT_ENCOUNTER_DELETE) &&
                    showDeletePrescriptionButton(prescription) &&
                    <DeleteOutlined onClick={() => deletePrescription(index)} />
                  }
                  <Text>{prescription.template?.name}</Text>
                </Space>
              } key={index + ''}>
                <UpdatePrescriptionView procedure={procedure} prescription={prescription} template={prescription.template}
                                        procedure_id={procedureId}
                                        onCancel={
                                          () => {
                                            const d = JSON.parse(JSON.stringify(prescriptions));
                                            d[index].shouldEdit = false;
                                            setPrescriptions(JSON.parse(JSON.stringify(d)));
                                          }
                                        }
                                        onAddNewSuccess={
                                          (e: string) => {
                                            refetchProcedure();
                                          }
                                        } />
              </TabPane>;
            }
            return <TabPane tabKey={index + ''} tab={
              <Space>
                {hasPermissionOf(PATIENT_ENCOUNTER_DELETE) &&
                !(prescription.template?.is_follow_up ===true && prescription.data?.updated_by_id ==Parameters.SYSTEM_USER_ID) && showDeletePrescriptionButton(prescription) && <DeleteOutlined onClick={() => {
                  deletePrescription(index);

                }} />}
                <Text>{prescription.template?.name}</Text>
              </Space>
            } key={index + ''}>
              <ProcedureView
                setComponentRef={setComponentRef}
                episodeType={procedure.type}
                dischargeDate={procedure.discharge_date}
                encounterId={procedure.id}
                onContinueEditing={
                  () => {
                    const d = JSON.parse(JSON.stringify(prescriptions));
                    d[index].shouldEdit = true;
                    setPrescriptions(JSON.parse(JSON.stringify(d)));
                  }
                }
                refresh={() => {
                  refetchProcedure();
                }}
                prescription={prescription} />
              {
                hasPermissionOf(PATIENT_ENCOUNTER_UPDATE) && prescription.review && showContinueEditingToEditor(prescription.review) &&
                <Col span={24}>
                  <Divider />
                  <Button onClick={() => {
                    const d = JSON.parse(JSON.stringify(prescriptions));
                    d[index].shouldEdit = true;
                    setPrescriptions(JSON.parse(JSON.stringify(d)));
                  }
                  } type={'default'}>Continue Editing</Button>
                </Col>
              }
            </TabPane>;
          })
        }
      </Tabs>
    </Card>;
  }
;

const NoPrescriptionView = (
  {
    onAdd, templates,
  }
    : any) => {
    useEffect(() => {
      if (templates && templates.length === 1) {
        onAdd(templates[0]);
      }
    }, []);
    return <div>
      {
        <Card bordered={false}>
          <Space align={'center'} style={{ width: '100%' }} direction={'vertical'}>
            <Title level={3}>Select Questionnaire Template</Title>
            <Select size={'large'} style={{ minWidth: '300px' }}
                    onChange={(e: any) => {
                      onAdd(templates[parseInt(e)]);
                    }
                    }>
              {
                templates.map((template: any, index: number) => {
                  return <Select.Option value={index}>{template.name}</Select.Option>;
                })
              }
            </Select>
          </Space>
        </Card>
      }

    </div>;
  }
;
const EmptyTemplateView = (
  {
    template, onDelete, procedure, onAddNewSuccess, procedure_id,
  }
    : any) => {
    const {
      questionnaire,
    } = useQuestionnaireById(template.id);
    const {
      mutation: addOrUpdatePrescriptionMutation,
      saving: addingOrUpdatingPrescription,
      error,
    } = addOrUpdatePrescription((e: any) => {
      if (e) {
        if (e.add_or_update_prescription && e.add_or_update_prescription.error) {
          notification.error({ message: e.add_or_update_prescription.error });
          return;
        } else {
          onAddNewSuccess(e.add_or_update_prescription.prescription_id);
        }
      }
    });
    const showAlert = (reqObj: any) => confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Submit Procedure',
      okText: 'Yes',
      cancelText: 'Cancel',
      content: 'Are you sure, you want to submit this procedure without discharge date!',// TODO
      okButtonProps: { loading: addingOrUpdatingPrescription, danger: true },
      onOk: async () => {
        addOrUpdatePrescriptionMutation({
          variables: reqObj,
        });
      },
    });
    if (!questionnaire) {
      return <Skeleton />;
    }
    return <div>
      {
        <Card bordered={false}>
          <DynamicFormContainer
            templateId={template.id}
            onCancel={
              onDelete
            }

            saving={addingOrUpdatingPrescription}
            onSubmit={
              (data: any, submitted?: boolean) => {
                const reqObject: any = {};
                reqObject['template_id'] = template.id;
                reqObject['encounter_id'] = procedure_id;
                reqObject['status'] = submitted ? PrescriptionEditorStatusSubmitted : PrescriptionEditorStatusInProgress;
                reqObject['remarks'] = '';
                reqObject['data'] = data;

                if (submitted && (procedure.type !== 'OPD' ) && !procedure.discharge_date) {
                  showAlert(reqObject);
                } else {
                  addOrUpdatePrescriptionMutation({
                    variables: reqObject,
                  });
                }
              }
            }
            formData={questionnaire.content.data.sections ? questionnaire.content.data : JSON.parse(questionnaire.content.data)} />
        </Card>
      }

    </div>;
  }
;

const UpdatePrescriptionView = (
  {
    template, onCancel, procedure, prescription, onAddNewSuccess, procedure_id,
  }
    : any) => {
    const {
      questionnaire,
    } = useQuestionnaireById(template.id);
    const {
      mutation: addOrUpdatePrescriptionMutation,
      saving: addingOrUpdatingPrescription,
      error,
    } = addOrUpdatePrescription((e: any) => {
      if (e) {
        if (e.add_or_update_prescription && e.add_or_update_prescription.error) {
          notification.error({ message: e.add_or_update_prescription.error });
          return;
        } else {
          onAddNewSuccess(e.add_or_update_prescription.prescription_id);
        }
      }
    });
    const showAlert = (reqObj: any) => confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Submit Procedure',
      okText: 'Yes',
      cancelText: 'Cancel',
      content: 'Are you sure, you want to submit this procedure without discharge date!',// TODO
      okButtonProps: { loading: addingOrUpdatingPrescription, danger: true },
      onOk: async () => {
        addOrUpdatePrescriptionMutation({
          variables: reqObj,
        });
      },
    });
    if (!questionnaire) {
      return <Skeleton />;
    }
    return <div>
      {
        <Card bordered={false}>
          <DynamicFormContainer
            templateId={template.id}
            onCancel={
              onCancel
            }

            saving={addingOrUpdatingPrescription}
            onSubmit={
              (data: any, submitted?: boolean) => {
                const reqObject: any = {};
                reqObject['template_id'] = template.id;
                reqObject['encounter_id'] = procedure_id;
                reqObject['id'] = prescription.id;
                reqObject['prescription_review_id'] = prescription.review.id;
                reqObject['status'] = submitted ? PrescriptionEditorStatusSubmitted : PrescriptionEditorStatusInProgress;
                reqObject['remarks'] = '';
                reqObject['data'] = data;
                if (submitted && (procedure.type !== 'OPD') && !procedure.discharge_date) {
                  showAlert(reqObject);
                } else {
                  addOrUpdatePrescriptionMutation({
                    variables: reqObject,
                  });
                }
              }
            }
            formData={prescription.data.data.sections ? prescription.data.data : JSON.parse(prescription.data.data)} />
        </Card>
      }

    </div>;
  }
;
const PrescriptionNoteLogs = (
  {
    prescriptionReviewId,
  }
    : any) => {
    const { logs, loading } = usePrescriptionReviewNotes(prescriptionReviewId);
    if (loading) {
      return <Skeleton active={true} />;
    }
    return <Steps progressDot current={logs.length - 1} direction='vertical'>
      {
        logs.map((log: any) => {
          if (log.status === PrescriptionReviewerPending)
            return <></>;
          return <Step
            title={<Space size={5}><StatusTags type={log.status} />
              <Text>by {(log?.created_by?.id ==undefined || (log?.created_by?.id == parameters.SYSTEM_USER_ID)) ? 'Patient':(log?.created_by?.name || 'NA')}</Text></Space>}
            description={<Card bordered={!!log.remarks}>
              <Text>{log.remarks || ''}</Text>
              <div><small>
                On {moment(log.created_at).format('LLL')}
              </small></div>
            </Card>} />;
        })
      }
    </Steps>;
  }
;
const PrescriptionPrintPreview = ({ prescriptionData, printPreferences, procedure }: any) => {
  const { t } = useTranslation();
  //console.log("Prescription data",prescriptionData.data.data.sections);
  const [printableSections, setprintableSections] = useState([] as any);
  const prescriptionSections = prescriptionData.data.data.sections;


  /*const printPreferences={
                          top:0,
                          bottom:0,
                          left:0,
                          right:0,
                          printDocInfo:false,
                          signature:'',
                          headerBanner:'https://thbemrlive-userfiles-mobilehub-1407691031.s3.ap-south-1.amazonaws.com/public/logos/dev/thb_test/header_image/1623758718707/paragchandraupdate.png',
                          footerBanner:'https://thbemrlive-userfiles-mobilehub-1407691031.s3.ap-south-1.amazonaws.com/public/logos/dev/thb_test/footer_image/1623758303650/footer.png'
                      };*/


  return (
    <>
      <Row gutter={[20, 20]} id='prescriptionPrintPage'>
        <Col xs={24} md={6} sm={6} id='prescriptionHiddenDialogue'>
          <Title level={5}>{t('genDict.CheckTheSectionsToBePrinted')}</Title>
          {
            (prescriptionData.data.data.sections).map((section: any) => {
              return <div>
                <Checkbox
                  checked={!printableSections[section.formname]}
                  onChange={(e: any) => {
                    let sampARR = {
                      [section.formname]: !e.target.checked,
                    };
                    setprintableSections({ ...printableSections, ...sampARR });
                    //console.log("prescriptionSections",printableSections);
                    //console.log("target",e.target.checked);
                  }}
                >
                  <Form.Item
                    label={section.title}
                    name={section.formname}


                    rules={[]}>
                  </Form.Item>
                </Checkbox>
              </div>;
            })
          }
          <Title level={5}>{t('genDict.otherPrintOptions')}</Title>
          <div>
            <Checkbox
              checked={!printableSections.printBlankFields}
              onChange={(e: any) => {
                let sampARR = {
                  printBlankFields: !e.target.checked,
                };
                setprintableSections({ ...printableSections, ...sampARR });

              }}
            >
              <Form.Item
                label={t('genDict.displayBlankFields')}
                name='printBlankFields'


                rules={[]}>
              </Form.Item>
            </Checkbox>
          </div>
        </Col>
        <Col xs={24} md={18} sm={18} id='printPreviewHolder'>
          <div id='printPreviewContainer' style={{
            marginTop: printPreferences.marginTop,
            marginBottom: printPreferences.marginBottom,
            marginLeft: printPreferences.marginLeft,
            marginRight: printPreferences.marginRight,
          }}>
            {printPreferences.headerBanner ? <>
              <div className='header'>
                <img style={{ width: '100%' }} src={printPreferences.headerBanner} />
              </div>
            </> : <></>}

            <table style={{ width: '100%' }}>
              <thead>
              <tr>
                <th>

                  <div className='empty-header'></div>

                </th>
              </tr>

              </thead>
              <tbody>
              <tr>
                <td>
                  <div className='content'>
                    <PatientPrintTitleBar patientId={procedure.patient_id} procedure={procedure} />
                    {/*
                      add this in future need to work on print
                      commented by : keshav
                      }
                    <FormView prescriptionId={prescriptionData.id} />
                    {*/}
                    {
                      (prescriptionData.data.data.sections).map((section: any) => {
                        return <>


                          {!printableSections[section.formname] ? <>

                            <div className='site-card-border-less-wrapper'
                                 style={{ marginTop: '10px', marginBottom: '10px' }} id={section.formname}>
                              <Card title={section.title} bordered={true} style={{ width: '100%' }}>
                                {
                                  (section.rows).map((row: any) => {
                                    return <>
                                      {row.type == 'row' ? <>
                                        {(row.status == 'filled' || !printableSections.printBlankFields) ? <>
                                          {(typeof row.class != 'undefined' && row.class == 'deferRendering hide') ? <></> : <>
                                            <p>
                                              {
                                                (row.columns).map((column: any) => {
                                                  return <>

                                                    {column.label ? <>


                                                      {column.type == 'header' ? <>
                                                        <span className='printPageLabel'><b>{column.label}</b>  </span>
                                                      </> : <>
                                                        <span className='printPageLabel'>
                                                          {column.bold_label == true ?
                                                            <b>{column.label}  </b> : column.label}


                                                        </span>
                                                        <span className='printPageSeperator'>:</span>

                                                        <span className='printPageValue'>
                                                                                                                    {column.type == 'checkbox' ? <>
                                                                                                                      {column.bold_value == true ?
                                                                                                                        <b>{(column.values).join(',')} </b> : (column.values).join(',')}

                                                                                                                    </> : <>
                                                                                                                      {column.bold_value == true ?
                                                                                                                        <b>{column.value} </b> : column.value}
                                                                                                                    </>}
                                                                                                                </span>

                                                      </>}


                                                    </> : <>
                                                    <span className='printPageValue'>
                                                    {column.type == 'checkbox' ? <>
                                                      {column.bold_value == true ?
                                                        <b>{(column.values).join(',')} </b> : (column.values).join(',')}

                                                    </> : <>
                                                      {column.bold_value == true ?
                                                        <b>{column.value} </b> : column.value}
                                                    </>}
                                                    </span>
                                                    </>}

                                                  </>;
                                                })
                                              }
                                            </p>
                                          </>}


                                        </> : <></>}

                                      </> : <>


                                      </>}
                                      {row.type == 'group' ? <>
                                        <Card title={row.name} bordered={true} style={{ width: '100%' }}>
                                          {(row.children).map((child: any) => {
                                            return <>
                                              {(child.status == 'filled' || !printableSections.printBlankFields) ? <>
                                                {(typeof child.class != 'undefined' && child.class == 'deferRendering hide') ? <></> : <>

                                                  <p>
                                                    {

                                                      (child.columns).map((ccolumn: any) => {
                                                        return <>
                                                          {ccolumn.label ? <>

                                                            {ccolumn.type == 'header' ? <>
                                                              <span className='printPageLabel'><b>{ccolumn.label}</b>  </span>
                                                            </> : <>
                                                              <span className='printPageLabel'>
                                                              {ccolumn.bold_label == true ?
                                                                <b>{ccolumn.label}  </b> : ccolumn.label}
                                                              </span>
                                                              <span className='printPageSeperator'>:</span>

                                                              <span className='printPageValue'>
                                                                                                                                {ccolumn.type == 'checkbox' ? <>
                                                                                                                                  {ccolumn.bold_value == true ?
                                                                                                                                    <b>{(ccolumn.values).join(',')} </b> : (ccolumn.values).join(',')}

                                                                                                                                </> : <>
                                                                                                                                  {ccolumn.bold_value == true ?
                                                                                                                                    <b>{ccolumn.value} </b> : ccolumn.value}

                                                                                                                                </>}
                                                                                                                            </span>

                                                            </>}


                                                          </> : <>
                                                          <span className='printPageValue'>
                                                          {ccolumn.type == 'checkbox' ? <>
                                                            {ccolumn.bold_value == true ?
                                                              <b>{(ccolumn.values).join(',')} </b> : (ccolumn.values).join(',')}

                                                          </> : <>
                                                            {ccolumn.bold_value == true ?
                                                              <b>{ccolumn.value} </b> : ccolumn.value}
                                                          </>}
                                                          </span>
                                                          </>}
                                                        </>;
                                                      })
                                                    }
                                                  </p>
                                                </>}
                                              </> : <></>}
                                            </>;
                                          })}
                                        </Card>


                                      </> : <></>}


                                    </>;
                                  })
                                }


                              </Card>
                            </div>

                          </> : <></>}


                        </>;
                      })
                    }
                  </div>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th>
                  <div className='empty-footer'></div>
                </th>
              </tr>

              </tfoot>
            </table>
            {printPreferences.footerBanner ? <>
              <div className='header'>
                <img style={{ width: '100%' }} src={printPreferences.footerBanner} />
              </div>
            </> : <></>}
          </div>


        </Col>
      </Row>
    </>
  );
};

export default ProcedurePrescriptionPage;
