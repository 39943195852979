import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import * as _ from 'lodash';

type ReturnType = {
    logs: any[];
    loading: boolean;
    error: any;
    refetch: any;
};

const QUERY = gql`
    query($prescriptionReviewId: uuid!) {
        c_prescription_prescription_editor_notes(where: {status:{_eq: SUBMITTED} ,prescription_review_id: {_eq: $prescriptionReviewId}}){
            created_at
            created_by{
                id
                name
            }
            status
            remarks
        }
        c_prescription_prescription_reviewer_notes(where: {prescription_review_id: {_eq: $prescriptionReviewId}}){
            created_at
            created_by{
                id
                name
            }
            status
            remarks
        }
    }
`;

const usePrescriptionReviewNotes: (prescriptionReviewId: string) => ReturnType = (prescriptionReviewId) => {
    const {
        data,
        loading,
        refetch,
        error,
    } = useQuery(QUERY, { variables: { prescriptionReviewId } });
    let logs: any[] = [];
    if (!loading && !error) {
        logs = _.sortBy([...data.c_prescription_prescription_editor_notes, ...data.c_prescription_prescription_reviewer_notes],
            [(o) => {
                return o.created_at;
            }]);
    }
    return {
        logs,
        loading,
        refetch,
        error,
    };
};
export default usePrescriptionReviewNotes;
