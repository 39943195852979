// Encounters
export const REOPEN_APPROVED = 'reopen_approved_procedures';
export const EDIT_UHID = 'edit_uhid';
export const ENCOUNTERS_SELECT = 'c_meta_encounter_types';
export const ENCOUNTERS_UPDATE_ONE = 'update_c_meta_encounter_types';
export const PRESCRIPTIONS_SELECT = 'c_prescription_prescriptions';
export const PRESCRIPTIONS_INSERT = 'insert_c_prescription_prescriptions';

// Patients
export const PATIENTS_SELECT = 'c_patient_pii';
export const PATIENTS_SELECT_ONE = 'c_patient_pii';
export const PATIENTS_INSERT_ONE = 'insert_c_patient_pii';
export const PATIENTS_UPDATE_ONE = 'update_c_patient_pii';

//Patient Encounters
export const PATIENTS_ENCOUNTER_INSERT_ONE = 'insert_c_patient_patient_encounters';
export const PATIENTS_ENCOUNTER_SELECT = 'c_patient_patient_encounters';
export const PATIENT_ENCOUNTER_DELETE = 'delete_c_patient_patient_encounters';
export const PATIENT_ENCOUNTER_UPDATE = 'update_c_patient_patient_encounters';
export const PATIENT_ENCOUNTER_REVIEW = 'insert_c_prescription_prescription_reviewer_notes';
export const PATIENT_ENCOUNTER_REVIEWERS_INSERT = 'insert_c_patient_patient_encounter_reviewers';
export const PATIENT_ENCOUNTER_REVIEWERS_UPDATE = 'update_c_patient_patient_encounter_reviewers';

// Groups
export const GROUPS_SELECT = 'c_org_groups';
export const GROUPS_SELECT_ONE = 'c_org_groups';
export const GROUPS_INSERT_ONE = 'insert_c_org_groups';
export const GROUPS_UPDATE_ONE = 'update_c_org_groups';

// Teams
export const TEAMS_SELECT = 'c_org_teams';
export const TEAMS_SELECT_ONE = 'c_org_teams';
export const TEAMS_INSERT_ONE = 'insert_c_org_teams';
export const TEAMS_UPDATE_ONE = 'update_c_org_teams';

// Teams
export const TABLE_PERMISSIONS_SELECT = 'c_acl_table_permissions';
export const TABLE_PERMISSIONS_SELECT_ONE = 'c_acl_table_permissions';
export const TABLE_PERMISSIONS_INSERT_ONE = 'insert_c_acl_table_permissions';
export const TABLE_PERMISSIONS_UPDATE_ONE = 'update_c_acl_table_permissions';


// Encounter Types
export const ENCOUNTER_TYPES_SELECT = 'c_meta_encounter_types';
export const ENCOUNTER_TYPES_SELECT_ONE = 'c_meta_encounter_types';
export const ENCOUNTER_TYPES_INSERT_ONE = 'insert_c_meta_encounter_types';
export const ENCOUNTER_TYPES_UPDATE_ONE = 'update_c_meta_encounter_types';


// Templates
export const TEMPLATES_SELECT = 'c_prescription_templates';
export const TEMPLATES_SELECT_ONE = 'c_prescription_templates';
export const TEMPLATES_INSERT_ONE = 'insert_c_prescription_templates';
export const TEMPLATES_UPDATE_ONE = 'update_c_prescription_templates';


// Locations
export const LOCATIONS_SELECT = 'c_meta_locations';
export const LOCATIONS_SELECT_ONE = 'c_meta_locations';
export const LOCATIONS_INSERT_ONE = 'insert_c_meta_locations';
export const LOCATIONS_UPDATE_ONE = 'update_c_meta_locations';

// Branches
export const BRANCHES_SELECT = 'c_org_branches';
export const BRANCHES_SELECT_ONE = 'c_org_branches';
export const BRANCHES_INSERT_ONE = 'insert_c_org_branches';
export const BRANCHES_UPDATE_ONE = 'update_c_org_branches';


// Departments
export const DEPARTMENTS_SELECT = 'c_org_departments';
export const DEPARTMENTS_SELECT_ONE = 'c_org_departments';
export const DEPARTMENTS_INSERT_ONE = 'insert_c_org_departments';
export const DEPARTMENTS_UPDATE_ONE = 'update_c_org_departments';


// Users
export const USERS_SELECT = 'c_org_users';
export const USERS_SELECT_ONE = 'c_org_users';
export const USERS_INSERT_ONE = 'insert_c_org_users';
export const EXPORT_USERS = 'export_user';
export const EXPORT_PROCEDURES = 'export_procedure';
export const USERS_UPDATE_ONE = 'update_c_org_users';


// Roles
export const ROLES_SELECT = 'c_acl_roles';
export const ROLES_SELECT_ONE = 'c_acl_roles';
export const ROLES_INSERT_ONE = 'insert_c_acl_roles';
export const ROLES_UPDATE_ONE = 'update_c_acl_roles';

// User Roles
export const USER_ROLES_SELECT = 'c_acl_user_roles';
export const USER_ROLES_SELECT_ONE = 'c_acl_user_roles';
export const USER_ROLES_INSERT_ONE = 'insert_c_acl_user_roles';
export const USER_ROLES_UPDATE_ONE = 'update_c_acl_user_roles';


// User Role Accounts
export const USER_ROLE_ACCOUNTS_SELECT = 'c_acl_user_role_accounts';
export const USER_ROLE_ACCOUNTS_SELECT_ONE = 'c_acl_user_role_accounts';
export const USER_ROLE_ACCOUNTS_INSERT_ONE = 'insert_c_acl_user_role_accounts';
export const USER_ROLE_ACCOUNTS_UPDATE_ONE = 'update_c_acl_user_role_accounts';


// User Role Cities
export const USER_ROLE_CITIES_SELECT = 'c_acl_user_role_cities';
export const USER_ROLE_CITIES_SELECT_ONE = 'c_acl_user_role_cities';
export const USER_ROLE_CITIES_INSERT_ONE = 'insert_c_acl_user_role_cities';
export const USER_ROLE_CITIES_UPDATE_ONE = 'update_c_acl_user_role_cities';


// User Role Department
export const USER_ROLE_DEPARTMENTS_SELECT = 'c_acl_user_role_departments';
export const USER_ROLE_DEPARTMENTS_SELECT_ONE = 'c_acl_user_role_departments';
export const USER_ROLE_DEPARTMENTS_INSERT_ONE = 'insert_c_acl_user_role_departments';
export const USER_ROLE_DEPARTMENTS_UPDATE_ONE = 'update_c_acl_user_role_departments';


// User Role Branch
export const USER_ROLE_BRANCHES_SELECT = 'c_acl_user_role_branches';
export const USER_ROLE_BRANCHES_SELECT_ONE = 'c_acl_user_role_branches';
export const USER_ROLE_BRANCHES_INSERT_ONE = 'insert_c_acl_user_role_branches';
export const USER_ROLE_BRANCHES_UPDATE_ONE = 'update_c_acl_user_role_branches';

// User Role Team
export const USER_ROLE_TEAMS_SELECT = 'c_acl_user_role_teams';
export const USER_ROLE_TEAMS_SELECT_ONE = 'c_acl_user_role_teams';
export const USER_ROLE_TEAMS_INSERT_ONE = 'insert_c_acl_user_role_teams';
export const USER_ROLE_TEAMS_UPDATE_ONE = 'update_c_acl_user_role_teams';

// User Role Group
export const USER_ROLE_GROUPS_SELECT = 'c_acl_user_role_groups';
export const USER_ROLE_GROUPS_SELECT_ONE = 'c_acl_user_role_groups';
export const USER_ROLE_GROUPS_INSERT_ONE = 'insert_c_acl_user_role_groups';
export const USER_ROLE_GROUPS_UPDATE_ONE = 'update_c_acl_user_role_groups';


// User Role Department
export const USER_DEPARTMENTS_SELECT = 'c_org_user_departments';
export const USER_DEPARTMENTS_SELECT_ONE = 'c_org_user_departments';
export const USER_DEPARTMENTS_INSERT_ONE = 'insert_c_org_user_departments';
export const USER_DEPARTMENTS_UPDATE_ONE = 'update_c_org_user_departments';


// User Role Branch
export const USER_BRANCHES_SELECT = 'c_org_user_branches';
export const USER_BRANCHES_SELECT_ONE = 'c_org_user_branches';
export const USER_BRANCHES_INSERT_ONE = 'insert_c_org_user_branches';
export const USER_BRANCHES_UPDATE_ONE = 'update_c_org_user_branches';

// User Role Team
export const USER_TEAMS_SELECT = 'c_acl_user_teams';
export const USER_TEAMS_SELECT_ONE = 'c_acl_user_teams';
export const USER_TEAMS_INSERT_ONE = 'insert_c_acl_user_teams';
export const USER_TEAMS_UPDATE_ONE = 'update_c_acl_user_teams';

// User Role Group
export const USER_GROUPS_SELECT = 'c_org_user_groups';
export const USER_GROUPS_SELECT_ONE = 'c_org_user_groups';
export const USER_GROUPS_INSERT_ONE = 'insert_c_org_user_groups';
export const USER_GROUPS_UPDATE_ONE = 'update_c_org_user_groups';


// preferences
export const ACCOUNT_PREFERENCES ="account_preferences";
export const SHOW_DASHBOARD ="show_dashboard";
export const PREFERENCES ="c_org_user_departments";

