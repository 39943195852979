import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    user: any;
    loading: boolean;
    error: any;
};

const QUERY = gql`
    query ($email:String!){
        c_org_users(where: {email: {_eq: $email}}) {
            id
            name
            email
            action_role
            user_type
            phone
            gender
            user_roles{
                role{
                    name
                }
            }
            created_at
            user_branches{
                branch{
                    name
                }
            }
            user_departments{
                department{
                    name
                }
            }
            user_groups{
                group{
                    name
                }
            }
        }
    }
`;

const useUserByEmail: (id: string) => ReturnType = (email) => {
    const { data, loading, error } = useQuery(QUERY, { variables: { email } });
    let user = null;
    if (!loading && !error) {
        user = data.c_org_users[0];
    }
    return {
        user,
        loading,
        error,
    };
};
export default useUserByEmail;
