import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    procedureType: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
    mutation (  $id: uuid!,$set: c_meta_encounter_types_set_input!){
        update_c_meta_encounter_types_by_pk(_set: $set,pk_columns: {id: $id} ) {
            id
        }
    }
`;

const useProcedureTypeUpdate: (onCompleted?: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted });
    let procedureType = null;
    if (!saving && !error && data) {
        procedureType = data.update_c_meta_encounter_types_by_pk;
    }
    return {
        mutation,
        procedureType,
        saving,
        error,
    };
};
export default useProcedureTypeUpdate;
