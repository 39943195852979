import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    preferences: any;
    loading: boolean;
    error: any;
    refetch: any;
};

const QUERY = gql`
query{
    c_management_account_preferences(limit: 1,  where: {type:{_eq:"PRINT_PREFERENCES"}},order_by:{created_at:desc}){
      id
      data
    }
  }
`;

const usePrintPreference: () => ReturnType = () => {
    const { data, loading, error, refetch } = useQuery(QUERY);
    let preferences = null;
    if (!loading && !error && data) {
        if(data.c_management_account_preferences && data.c_management_account_preferences.length){
            preferences = data.c_management_account_preferences[0];
        }else{
            preferences = {
                data: JSON.stringify({})
            }
        }
        //preferences = data.c_management_account_preferences.map((permission: any) => permission.query);
    }
    //console.log("preferences data",preferences);
    //console.log("preferences loading",loading);
    //console.log("preferences error",error);
    return {
        preferences,
        loading,
        refetch,
        error,
    };
};
export default usePrintPreference;
