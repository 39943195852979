


import * as React from 'react';
import { Row, Col } from "antd"

class HyperLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: []
        }
    }

    render() {
        const { value,label } = this.props;
        return <Col className="gutter-row" span={24}>
                <Row>
                    <a href={value} target={"_blank"}> {label}</a>
                </Row>
            </Col>;
    }

};


export default HyperLink
