import React from 'react';
import useQuestionnaires from '../../hooks/Templates/useQuestionnaires'
import useQuestionnaireById from '../../hooks/Templates/useQuestionnaireById'

import { getPaginationLimit, hasPermissionOf, numberFormat, setPaginationLimit } from 'shared/services/Utility';
import useDebounce from 'shared/hooks/useDebounce';
import { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import ListViewDesktop from 'shared/components/layout/Desktop/ListView/ListViewDesktop';
import { Button, Space, Col, Row, Table, Tooltip, Typography, Input, Pagination } from 'antd';
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import {
    TEMPLATES_INSERT_ONE as QUESTIONNAIRES_INSERT_ONE, TEMPLATES_UPDATE_ONE as QUESTIONNAIRES_UPDATE_ONE,
} from 'shared/constants/Permissions';
import { useTranslation } from 'react-i18next';


const { Text } = Typography;

type Props = {}




const FetchTemplateContainer: React.FunctionComponent<Props> = (props: any) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState();
    const [template_id = '', set_template_id] = useState();
    const [pagination, setPagination] = useState({
        limit: getPaginationLimit(),
        currentPage: 1,
    } as { limit: number, currentPage: number });
    const debouncedSearch = useDebounce(search, 500);

    const { questionnaires, total, loading: loadingQuestionnaires, refetch } = useQuestionnaires({ ...pagination });


    const columns = [
        {
            title: '#',
            key: 'sNo',
            width: 80,
            render: (a: any, value: any, index: number) =>
                <Text>{(index + 1) + ((pagination.currentPage - 1) * pagination.limit)}</Text>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Code',
            dataIndex: 'code',
        },
        {
            title: 'Group',
            dataIndex: ['group', 'name'],
        },
        {
            title: 'Created By',
            dataIndex: ['created_by', 'name'],
        },
        {
            fixed: 'right' as 'right',
            width: 100,
            title: 'Action',
            key: 'action',
            render: (a: any) => {
                return <Row gutter={10} wrap={false}>
                    {
                        hasPermissionOf(QUESTIONNAIRES_UPDATE_ONE) && <Col>
                            <Tooltip title={t('genDict.edit')}>
                                <Button shape='circle'
                                    icon={<EditFilled />}
                                    size={'middle'}
                                    onClick={() => set_template_id(a.id)} />
                            </Tooltip>
                        </Col>

                    }
                    {
                        hasPermissionOf(QUESTIONNAIRES_UPDATE_ONE) && <Col>
                            <Tooltip title={t('genDict.delete')}>
                                <Button shape='circle'
                                    icon={<DeleteFilled />}
                                    size={'middle'} />
                            </Tooltip>
                        </Col>
                    }
                </Row>;
            },
        }
    ];
    useEffect(() => {
        setPagination({ ...pagination, currentPage: 1 });
    }, [debouncedSearch]);
    useEffect(() => {
        refetch({
            search, offset: (pagination.currentPage - 1) * pagination.limit,
            limit: pagination.limit,
        });
    }, [pagination]);
    return (
        <div className={'questionnaires-page page-wrapper'}>

            <ListViewDesktop title={t('genDict.questionnaires')}
                filters={[
                    <Space size={0}>
                        <Input value={search} placeholder={t('genDict.search')} onChange={
                            (e: any) => {
                                setSearch(e.target.value);
                            }
                        } />
                    </Space>,
                ]}
                addActionConfig={
                    hasPermissionOf(QUESTIONNAIRES_INSERT_ONE) && {
                        text: t('genDict.add')
                    }
                }
            >

                <Table
                    sticky={true}
                    key={Math.random() * 100000}
                    locale={{
                        emptyText: (
                            <>
                                <Space direction={'vertical'} size={10} style={{ padding: '10px' }}>
                                    <Title level={3} className={'text-primary'}>{t('genDict.woops')}</Title>
                                    <Text className={'text-primary'}>No Questionnaire added</Text>
                                    <Button type={'primary'}>{t('genDict.add')}</Button>
                                </Space>
                            </>
                        ),
                    }}
                    pagination={{
                        hideOnSinglePage: true,
                        showSizeChanger: true,
                        total,
                        current: pagination.currentPage,
                        pageSize: pagination.limit,
                        showTotal: (total: number) => <Text>Total <b>{numberFormat(total)}</b> items</Text>,
                        onChange: (page: number, pageSize?: number) => {
                            if (pageSize !== pagination.limit) {
                                setPaginationLimit(pageSize);
                                page = 1;
                            }
                            setPagination({ currentPage: page, limit: (pageSize || pagination.limit) });
                        },
                    }} scroll={{ x: true }}
                    dataSource={questionnaires}
                    loading={loadingQuestionnaires}
                    columns={columns} />
            </ListViewDesktop>
            {template_id && <EditContainerWrapper template_id={template_id} onTemplateSelected={props.onTemplateSelected} />}

        </div>
    );
};

export default FetchTemplateContainer;

const EditContainerWrapper: React.FunctionComponent<Props> = (props: any) => {
    const {
        questionnaire,
    } = useQuestionnaireById(props.template_id)
    props.onTemplateSelected(questionnaire)

    return <></>

}