import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useDebounce from 'shared/hooks/useDebounce';
import { GlobalListLimit, ListPagination } from 'shared/constants/AppConstants';
import Text from 'antd/es/typography/Text';
import { Button, Col, Divider, Row, Select, Space, Table, Tooltip } from 'antd';
import { hasPermissionOf } from 'shared/services/Utility';
import { USER_BRANCHES_UPDATE_ONE, USER_ROLE_ACCOUNTS_UPDATE_ONE } from 'shared/constants/Permissions';
import moment from 'moment';
import { DeleteFilled } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import useBranches from 'shared/hooks/Branches/useBranches';
import useUserBranches from 'shared/hooks/UserBranches/useUserBranches';
import useDeleteUserBranches from 'shared/hooks/UserBranches/useDeleteUserBranches';
import useAddUserBranches from 'shared/hooks/UserBranches/useAddUserBranches';

const UserBranches = (
    {
        userId,
    }
        :
        {
            userId: string,
        },
    ) => {
        const { t } = useTranslation();
        const { branches, loading: loadingBranches, refetch: refreshBranches } = useBranches();
        const [search, setSearch] = useState('' as string);
        const [selectedBranch, setSelectedBranch] = useState(null as any);
        const debouncedSearch = useDebounce(search, 500);
        const [pagination, setPagination] = useState({ limit: GlobalListLimit, offset: 0 } as ListPagination);

        useEffect(() => {
            setSearch('');
        }, [userId]);

        const {
            userBranches,
            loading: loadingUserBranches,
            refetch: refreshUserBranches,
            total,
        } = useUserBranches({ user_id: userId });
        useEffect(() => {
            refreshBranches({ 'notIds': userBranches.map((a: any) => a.branch_id), search, ...pagination });
        }, [debouncedSearch, pagination, UserBranches]);
        const {
            mutation: deleteUserBranchMutation,
            saving: deletingUserBranch,
        } = useDeleteUserBranches((e: any) => {
            if (e) {
                refreshUserBranches({ user_id: userId });
            }
        });
        const {
            mutation: addUserBranchMutation,
            saving: addingUserBranch,
        } = useAddUserBranches((e: any) => {
            if (e) {
                refreshUserBranches({ user_id: userId });
                setSelectedBranch(null);
            }
        });

        const columns = [
            {
                title: '#',
                key: 'sNo',
                width: 100,
                render: (a: any, value: any, index: number) => <Text>{index + 1}</Text>,
            },
            {
                title: 'Name',
                dataIndex: ['branch', 'name'],
            },
            {
                fixed: 'right' as 'right',
                width: 100,
                title: 'Action',
                key: 'action',
                render: (a: any) => {
                    return <Row gutter={10}>
                        {
                            hasPermissionOf(USER_BRANCHES_UPDATE_ONE) && <Col>
                                <Tooltip title={'Delete'}>
                                    <Button disabled={deletingUserBranch} shape='circle'
                                            onClick={() => {
                                                deleteUserBranchMutation({
                                                    variables: {
                                                        id: a.id,
                                                        branchId: a.branch_id,
                                                        set: {
                                                            deleted_by_id: userId,
                                                            deleted_at: moment(),
                                                        },
                                                        userId: userId,
                                                        setDepartment: {
                                                            deleted_by_id: userId,
                                                            deleted_at: moment(),
                                                        },
                                                    },
                                                });
                                            }}

                                            icon={<DeleteFilled />}
                                            size={'middle'} />
                                </Tooltip>
                            </Col>
                        }
                    </Row>;
                },
            },
        ];
        return <div>
            <Title level={5}>Branches {(userBranches.length)}</Title>
            <Select
                style={{ width: '100%' }}
                showSearch
                loading={loadingBranches}
                optionFilterProp='children'
                value={selectedBranch}
                disabled={addingUserBranch}
                onChange={(e) => {
                    setSelectedBranch(e);
                    addUserBranchMutation({
                        variables: {
                            object: {
                                user_id: userId,
                                branch_id: e,
                            },
                        },
                    });
                }}
                onSearch={
                    (v) => {
                        setSearch(v);
                    }
                }
                filterOption={(input: string, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {
                    branches.filter((branch: any) => {
                        let found = false;
                        userBranches.forEach((userBranch: any) => {
                            if (userBranch.branch_id === branch.id) {
                                found = true;
                            }
                        });
                        return !found;
                    }).map((branch: any, index: number) => {
                        return <Select.Option key={index}
                                              value={branch.id}>{branch.name}</Select.Option>;
                    })
                }
            </Select>
            <Divider />
            <Table
                locale={{
                    emptyText: (
                        <>
                            <Space direction={'vertical'} size={10} style={{ padding: '10px' }}>
                                <Title level={3} className={'text-primary'}>{t('genDict.woops')}</Title>
                                <Text className={'text-primary'}>No Branch added</Text>
                            </Space>
                        </>
                    ),
                }}
                pagination={{
                    pageSize: pagination.limit,
                    total,
                    hideOnSinglePage: true,
                    onChange: (newPage: number) => {
                        setPagination({ ...pagination, offset: (newPage - 1) * pagination.limit });
                    },
                    current: (pagination.offset / pagination.limit) + 1,
                }} scroll={{ x: true }}
                dataSource={userBranches}
                loading={loadingUserBranches}
                columns={columns} />
        </div>;
    }
;
export default UserBranches;
