import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
    notification,
} from 'antd';
import useLocations from 'shared/hooks/useLocations';
import BranchForm from './BranchForm';
import useLocationCreate from 'shared/hooks/Locations/useLocationCreate';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import useBranchCreate from 'shared/hooks/Branches/useBranchCreate';

type Props = {
    user: any
}

const BranchCreate: React.FunctionComponent<Props> = ({ user }) => {
    const history = useHistory();
    const location = useLocations();
    const { mutation, saving } = useBranchCreate((e: any) => {
        if (e) {
            notification.success({ message: 'Created successfully' });
            history.goBack();
        }
    });
    const onSubmit = (object: any) => {
        const req = {
                user_id: user.id, branch:
                    {
                        data: object,
                    }
                ,
            }
        ;
        mutation({ variables: { object: req } });
    };
    return (
        <div className={'branch-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Branch Create'}>
                <BranchForm saving={saving} onCancel={() => history.goBack()} onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};


export default BranchCreate;
