import gql from 'graphql-tag';
// @ts-ignore
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';

type ReturnType = {
  procedure: any;
  loading: boolean;
  error: any;
  refetch: any;
};

const QUERY = gql`
  query($id: uuid!) {
    c_patient_patient_encounters_by_pk(id: $id) {
      id
      department_id
      discharge_date
      patient_id
      type
      admission_date
      performing_doctor{
        id
        name
      }
      type_id
      start_date
      visit_date
      primary_doctor{
        id
        name
      }
      prescriptions ( distinct_on: [id],  order_by: [{id: desc}]){

        created_at
        created_by_id
        updated_by_id
        id
        data{
          data
          id
          created_by_id
          updated_by_id
        }
        template{
          name
          code
          is_supplementary
          id
          is_follow_up
        }
        review {
          id
          latest_editor_status{
            status
            latest
            remarks
            created_by{
              name
            }
          }
          latest_reviewer_status{
            status
            remarks
            latest
            created_by{
              name
            }
          }
          editor_notes(limit: 1, order_by: [{created_at: desc}]){
            remarks
            status
            id
            created_by{
              name
            }
            created_at
          }
          reviewer_notes(limit: 1, order_by: [{created_at: desc}]){
            remarks
            status
            id
            created_by{
              name
            }
            created_at
          }
        }
      }
      encounter_type{
        id
        name
        enable_multiple_prescriptions
        encounter_templates (where: {template: {id:{_is_null: false}}}){
          encounter_type{
            name
            id
          }
          template{
            name
            id
          }
        }
      }
    }
  }
`;

const usePrescriptionsOfProcedureById: (id: string) => ReturnType = (id) => {
  const { data, loading, error, refetch } = useQuery(QUERY, { variables: { id } });
  let procedure = null;
  if (!loading && !error && data) {
    data.c_patient_patient_encounters_by_pk.prescriptions.forEach((prescription: any) => {
      const latest_editor_note = prescription.review['editor_notes'][0];
      let latest_note = prescription.review['editor_notes'][0];
      let latest_reviewer_note = prescription.review['reviewer_notes'][0];
      if (latest_reviewer_note) {
        if (moment(latest_editor_note.created_at).diff(moment(latest_reviewer_note.created_at)) < 0) {
          latest_note = latest_reviewer_note;
        }
      }
      prescription.review['latest_editor_note'] = latest_editor_note;
      prescription.review['latest_editor_status'] = latest_editor_note;
      prescription.review['latest_reviewer_note'] = latest_reviewer_note;
      prescription.review['latest_reviewer_status'] = latest_reviewer_note;
      prescription.review['latest_note'] = latest_note;
    });
    procedure = data.c_patient_patient_encounters_by_pk;
  }
  return {
    procedure,
    loading,
    refetch,
    error,
  };
};
export default usePrescriptionsOfProcedureById;
