import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Menu, Row, Col, Tooltip } from 'antd';
const { SubMenu } = Menu;
import {
    UnorderedListOutlined,
    CodepenCircleOutlined,
    EyeOutlined,
    CopyOutlined,
    CodeOutlined,
    AppstoreAddOutlined,
    QuestionOutlined
} from '@ant-design/icons';

import axios from 'axios';

import FetchTemplateContainer from "./Views/FetchTemplateContainer.tsx";
import EditContainer from "./Views/EditContainer";
import JsonViewerComponent from './Views/JsonViewerComponent';
import HelpComponent from './Views/HelpComponent';
import HeaderContainer from './Views/HeaderContainer';

import formBuilderService from './services/formBuilder.service';

import SweetAlertComponent from './Common/Alert/SweetAlertComponent';
import PreviewComponent from './Views/PreviewComponent';
import PasteJsonComponent from './Views/PasteJsonComponent';
import CommonModalView from './Common/Modal/CommonModalView';
import QuestionnaireEdit from '../../client/src/views/Questionnaire/CreateOrEdit/QuestionnaireEdit';
import QuestionnaireCreate from '../../client/src/views/Questionnaire/CreateOrEdit/QuestionnaireCreate';

// import TemplateListComponent from '../Prescription/Component/TemplateList/Component/TemplateListComponent';
// import userService from '../../Services/users.service';

require('./scss/form-builder.scss');
// const $ = window["jQuery"];

const ContainerLayout = (props) => (
    <div className="card">
        <div className="card-body">
            {props.children}
        </div>
    </div>
);

class FormBuilderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // queryParams: this.props.location.search !== '' ? JSON.parse('{"' + decodeURI(this.props.location.search.substr(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : this.props.location.state ? this.props.location.state : '',
            queryParams: {},
            globalJSONObj: { sections: [] },
            preSavedTemplates: [],
            activeKey: 'fetchTemplate',
            isTemplateLoading: true,
            templateType: "prescription_form",
            isEditMode: false,
            errorOccuredInFetchTemplate: false,
            selectedTemplateData: null,
            disableData: null,
            isSelectStatic: false,
            // scrollToSection: '',
            openBookmarks: false,
            showPreview: false,
            alert: {
                show: false,
                type: '',
                text: '',
                title: ''
            },
            mobileAlertShow: true,
            template_data: {}
        }
    }

    componentDidMount() {
        // top-scroller
        // $(window).scroll(function () {
        //     if ($(this).scrollTop() > 400) {
        //         $('.top-scroller').fadeIn();
        //     } else {
        //         $('.top-scroller').fadeOut();
        //     }
        //     if ($(this).scrollTop() > 141) {
        //         $('.actions-column').addClass('fixme-actions');
        //         $('.tab-content').addClass('temp-tab-content-margin');
        //     } else {
        //         $('.actions-column').removeClass('fixme-actions');
        //         $('.tab-content').removeClass('temp-tab-content-margin');
        //     }
        // });
        // //Click event to scroll to top
        // $('.top-scroller').click(function () {
        //     $('html, body').animate({ scrollTop: 0 }, 1000);
        //     return false;
        // });
    }


    // getEmitedTemplateTypeData = (event) => {
    //     let { templateType } = this.state;
    //     templateType = event;
    //     this.setState({ templateType }, () => this.fetchSavedTemplates())
    // }

    async logErrorToService(error, errorInfo) {
        try {
            // add service call here
            let data = {
                error: error,
                errorInfo: errorInfo
            }
            // const response = await userService.logAppError(data);
            console.log(data)
        } catch (err) {
            console.log(err)
        }
    }

    // async fetchSavedTemplates() {
    //     if (!this.state.isTemplateLoading) this.setState({ isTemplateLoading: true });
    //     let { preSavedTemplates } = this.state;
    //     try {
    //         const response = await formBuilderService.fetchSavedTemplates(this.state.queryParams['accountId'], this.state.templateType);
    //         if (response && response.message.toLowerCase() === 'success') {
    //             preSavedTemplates = response.data;
    //             this.setState({ preSavedTemplates, isTemplateLoading: false, errorOccuredInFetchTemplate: false });
    //         } else {
    //             this.setError('Error occured while loading! Please refresh.');
    //             this.setState({ isTemplateLoading: false, errorOccuredInFetchTemplate: true });
    //         }
    //     } catch (error) {
    //         this.logErrorToService(error);
    //         this.setError('Error occured while loading! Please refresh.');
    //         this.setState({ isTemplateLoading: false, errorOccuredInFetchTemplate: true });
    //     }
    // }

    setError(msg) {
        this.setState({
            alert: {
                show: true,
                type: 'error',
                text: msg,
                title: 'Error'
            }
        });
    }

    onSaveTemplateClicked = () => {
        this.setState({ activeKey: 'save' });
    }

    modifyGlobalJSON = (form) => {
        let { globalJSONObj } = this.state;
        globalJSONObj.sections = form;
        this.setState({ globalJSONObj });
    }

    changeGlobalConfig = (config) => {
        let { globalJSONObj } = this.state;
        globalJSONObj.config = config;
        this.setState({ globalJSONObj });
    }

    onAlertClose = (event) => {
        if (this.state.alert && this.state.alert.type === "confirmation") {
            this.disableIndTemplate(this.state.disableData);
        }
        this.setState({
            alert: {
                show: false,
                type: '',
                text: "",
                title: "",
                confirmBtnText: ""
            }
        });
    }

    async disableIndTemplate(data) {
        try {
            data['is_active'] = false;
            let finalData = {
                "templates": [data]
            }
            const response = await formBuilderService.saveForm(finalData, "account", this.state.queryParams['accountId'], this.state.queryParams['accountId']);
            if (response && response.type.toLowerCase() === 'success') {
                this.setState({
                    alert: {
                        show: true,
                        type: 'success',
                        text: 'Template Disabled successfully!',
                        title: 'Disabled Success'
                    }
                });
            } else {
                this.setError('Error occured while deleting template! Please try again.');
            }

        } catch (error) {
            this.setError('Error occured while deleting form! Please try again.');
            this.logErrorToService(error, 'FE: SaveFormComponent : onDeleteForm');
        }
        this.setState({
            isLoading: false
        })
    }

    onTemplateDeleteEvent = (data) => {
        this.setState({
            disableData: data,
            alert: {
                show: true,
                type: 'confirmation',
                text: "Do you want to delete?",
                title: "Are you sure?",
                confirmBtnText: "Delete"
            }
        });

    }

    onTemplateSelected = (template_data) => {
        if (template_data?.content?.data) {
            let templateJson = JSON.parse(template_data?.content?.data)
            // this.setState({globalJSONObj:templateJson.sections})
            this.modifyGlobalJSON(templateJson.sections)
            template_data['template_data_id']=template_data?.content?.id
            template_data['is_follow_up']=!!template_data.is_follow_up
        }
        if (template_data) {
            delete template_data['content']
            this.setState({ activeKey: 'edit', isEditMode: true, template_data });
        }
    }


    onBackArrowClicked = () => {
        this.props.history.goBack();
    }



    async fetchStaticTemplateFromS3(url) {
        delete axios.defaults.headers.common["Authorization"];
        axios({
            method: 'get',
            url: url
        })
            .then((res) => {
                let json = res && res.data ? (res.data.hasOwnProperty('sections') ? res.data : JSON.parse(res.data)) : { sections: [] };
                this.appendJsonToGlobalObj(json);
            })
            .catch((error) => {
                this.setError('Error occured while loading template! Please try again.');
                this.logErrorToService(error, 'FE: FormBuilderComponent : fetchStaticTemplateFromS3');
            });
    }

    appendJsonToGlobalObj(json) {
        // try {
        //     let { globalJSONObj } = this.state;
        //     globalJSONObj.sections = globalJSONObj.sections.concat(json.sections)
        //     // globalJSONObj = {
        //     //     ...globalJSONObj,
        //     //     sections: globalJSONObj.sections.concat(json.sections)
        //     // }
        //     this.setState({ globalJSONObj, isSelectStatic: false }, this.onTabChange('edit'));
        //     //scroll to bottom pf page where section was added
        //     if (json && json.sections && json.sections.length) {
        //         $("html, body").animate({ scrollTop: $(document).height() - $(window).height() });
        //     }
        // } catch (error) {
        //     this.logErrorToService(error, 'FE: FormBuilderComponent : appendJsonToGlobalObj');
        // }
    }

    onTabChange = (k) => {
        if (k === 'preview') {
            this.setState({ showPreview: true });
            // return;
        }
        if (k === 'static') {
            this.openStaticTemplateModal();
            return;
        }
        this.setState({ activeKey: k, isEditMode: k !== 'fetchTemplate' });
    }

    changeActiveKey = (isEditMode) => {
        let { activeKey, selectedTemplateData } = this.state;
        selectedTemplateData = null;
        activeKey = isEditMode ? 'edit' : 'fetchTemplate';
        this.setState({ activeKey, selectedTemplateData, isEditMode, globalJSONObj: { sections: [] } });
    }

    onPastedJsonEmit = (validatedJson) => {
        this.setState({ globalJSONObj: validatedJson, showPreview: true }, () => this.onTabChange('edit'));
    }

    openStaticTemplateModal = () => {
        this.setState({ isSelectStatic: true });
    }

    getEmitedModalCloseEvent = (e) => {
        this.setState({ isSelectStatic: false });
    }

    onPreviewCloseEvent = (e) => {
        this.setState({ showPreview: false });
    }

    render() {
        const icon_size = '20px';
        const { isEditMode, globalJSONObj, activeKey } = this.state;
        console.log(activeKey)
        // console.log(this.state)
        const isJSONEmpty = globalJSONObj && globalJSONObj.sections && globalJSONObj.sections.length === 0;
        let staticTemplates = this.state.preSavedTemplates ? this.state.preSavedTemplates.filter(t => t.templateType === 'static') : null;
        return <>
            <div className="form-builder">

                <Row>
                    <Col span={2} justify="center">
                        <Menu>
                            <Menu.Item key="All templates" onClick={() => this.onTabChange('fetchTemplate')}>
                                <Tooltip
                                    placement="right"
                                    title="All templates"
                                    className="tooltip"
                                >
                                    <UnorderedListOutlined style={{ fontSize: icon_size }} />
                                </Tooltip>
                            </Menu.Item>

                            <Menu.Item key="Build template" onClick={() => this.onTabChange('edit')}>
                                <Tooltip
                                    placement="right"
                                    title="Build template"
                                    className="tooltip"
                                >
                                    <CodepenCircleOutlined style={{ fontSize: icon_size }} />
                                </Tooltip>
                            </Menu.Item>

                            <Menu.Item key="preview" onClick={() => this.onTabChange('preview')}>
                                <Tooltip
                                    placement="right"
                                    title="preview"
                                    className="tooltip"
                                >
                                    <EyeOutlined style={{ fontSize: icon_size }} />
                                </Tooltip>
                            </Menu.Item>

                            <Menu.Item key="JSON" onClick={() => this.onTabChange('json')}>
                                <Tooltip
                                    placement="right"
                                    title="JSON"
                                    className="tooltip"
                                >
                                    <CodeOutlined style={{ fontSize: icon_size }} />
                                </Tooltip>
                            </Menu.Item>

                            <Menu.Item key="Paste JSON" onClick={() => this.onTabChange('pasteJson')}>
                                <Tooltip
                                    placement="right"
                                    title="Paste JSON"
                                    className="tooltip"
                                >
                                    <CopyOutlined style={{ fontSize: icon_size }} />
                                </Tooltip>
                            </Menu.Item>

                            <Menu.Item key="Save" onClick={() => this.onTabChange('save')}>
                                <Tooltip
                                    placement="right"
                                    title="Save"
                                    className="tooltip"
                                >
                                    <AppstoreAddOutlined style={{ fontSize: icon_size }} />
                                </Tooltip>
                            </Menu.Item>

                            <Menu.Item key="Help" item='Help' onClick={() => this.onTabChange('help')}>
                                <Tooltip
                                    placement="right"
                                    title="Help"
                                    className="tooltip"
                                >
                                    <QuestionOutlined style={{ fontSize: icon_size }} />
                                </Tooltip>
                            </Menu.Item>
                        </Menu>

                    </Col>
                    <Col span={22}>
                        {this.state.activeKey == 'edit' && <>
                            <EditContainer onFormChange={this.modifyGlobalJSON.bind(this)}
                                sections={globalJSONObj['sections']} activeKey={activeKey}
                                globalConfig={globalJSONObj['config']}
                                changeGlobalConfig={this.changeGlobalConfig.bind(this)} />
                        </>}

                        {
                            this.state.activeKey == 'pasteJson' && (
                                <ContainerLayout>
                                    <div style={{ minHeight: '500px' }}>
                                        <PasteJsonComponent activeKey={activeKey} onPastedJsonEmit={this.onPastedJsonEmit.bind(this)}></PasteJsonComponent>
                                    </div>
                                </ContainerLayout>
                            )
                        }

                        {
                            this.state.activeKey == 'help' && (
                                <ContainerLayout>
                                    <div style={{ minHeight: '500px' }}>
                                        <HelpComponent activeKey={activeKey}></HelpComponent>
                                    </div>
                                </ContainerLayout>
                            )
                        }

                        {
                            this.state.activeKey == 'json' && (
                                <ContainerLayout>
                                    <div >
                                        {globalJSONObj &&
                                            <JsonViewerComponent JsonObj={globalJSONObj} activeKey={activeKey}></JsonViewerComponent>
                                        }
                                    </div>
                                </ContainerLayout>
                            )
                        }

                        {
                            this.state.activeKey == 'preview' && (
                                <div style={{ minHeight: '500px' }}>
                                    {globalJSONObj &&
                                        <PreviewComponent activeKey={activeKey} previewObj={globalJSONObj} updatePreview={this.state.showPreview} template_id={this.state.selectedTemplateData ? this.state.selectedTemplateData.id : ''}></PreviewComponent>
                                    }
                                </div>
                            )
                        }
                        {
                            this.state.activeKey == 'fetchTemplate' && (
                                <FetchTemplateContainer
                                    onTemplateSelected={this.onTemplateSelected}
                                >
                                </FetchTemplateContainer>
                            )
                        }
                        {
                            this.state.activeKey == 'save' && (
                                this.state.template_data?.id ?
                                    (<QuestionnaireEdit
                                        questionnaire={{ ...this.state.template_data, content: { data: JSON.stringify(this.state.globalJSONObj) } }}
                                        saved={()=>this.onTabChange('fetchTemplate')} />)
                                    : (<QuestionnaireCreate
                                        questionnaire={{ ...this.state.template_data, content: { data: JSON.stringify(this.state.globalJSONObj) } }}
                                        saved={()=>this.onTabChange('fetchTemplate')} />)
                            )
                        }

                    </Col>
                </Row>
            </div>

        </>;
    }
}

export default FormBuilderComponent;


