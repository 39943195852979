import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    updated_followup: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
    mutation MyMutation(
        $id: uuid!,
        $encounter_template_id: uuid!,
        $followup_template_id: uuid!, 
        $followup_days: Int!) {
        update_c_prom_follow_up_by_pk(pk_columns: {id: $id}, 
        _set: {followup_template_id: $followup_template_id, followup_days: $followup_days, encounter_template_id: $encounter_template_id, deleted_at:"now()"}) {
        encounter_template_id
        followup_template_id
        followup_days
        }
    }
  
`;

const useUpdateFollowUp: (onCompleted?: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted });
    let updated_followup = null;
    if (!saving && !error && data) {
        updated_followup = data.update_c_prom_follow_up_by_pk;
    }
    return {
        mutation,
        updated_followup,
        saving,
        error,
    };
};
export default useUpdateFollowUp;
