import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import { GlobalListLimit } from '../../constants/AppConstants';

type ReturnType = {
    patients: any[];
    loading: boolean;
    error: any;
    refetch: any;
    total: number;
};

const QUERY = gql`
    query($where: c_patient_pii_bool_exp, $limit: Int, $offset: Int) {
        c_patient_pii(where: $where, order_by: [{created_at: desc}], limit: $limit, offset: $offset){
            id
            first_name
            last_name
            age
            date_of_birth
            gender
            phone
            encounters(limit: 1, order_by: [{created_at: desc}]){
                created_at
            }
            uhid
            email
            created_at
            address
            alternate_phone
            branch_id
            branch{
                id
                name
                location{
                    city
                    country
                    state
                }
            }
            zipcode
            location_id
            location{
                state
                city
                country
                id
                
            }
        }
        c_patient_pii_aggregate (where: $where){
            aggregate{
                count
            }
        }
    }
`;

const createFilters = (filters?: Filters) => {
    const result: any = { where: { _and: [] }, limit: GlobalListLimit, offset: 0 };
    if (!filters) {
        return result;
    }
    if (filters['name']) {
        const nameComponents = filters['name'].split(' ');

        nameComponents.forEach((c: string) => {
            const orCondition: any[] = [];
            orCondition.push({ first_name: { _ilike: `%${c}%` } });
            orCondition.push({ last_name: { _ilike: `%${c}%` } });
            result['where']['_and'].push({ _or: orCondition });
        });
    }
    if (filters['uhid']) {
        result['where']['_and'].push({ 'uhid': { _ilike: `%${filters['uhid']}%` } });
    }
    if (filters['e_uhid']) {
        result['where']['_and'].push({ 'uhid': { _ilike: `${filters['e_uhid']}` } });
    }
    if (filters['created_at_range']) {
        result['where']['_and'].push({
            created_at: {
                _gte: filters['created_at_range'][0],
                _lte: filters['created_at_range'][1],
            },
        });
    }
    if (filters['email']) {
        result['where']['_and'].push({ 'email': { _ilike: `%${filters['email']}%` } });
    }
    if (filters['phone']) {
        result['where']['_and'].push({ 'phone': { _ilike: `%${filters['phone']}%` } });
    }
    if (filters['branch_id']) {
        result['where']['_and'].push({ 'branch_id': { _eq: `${filters['branch_id']}` } });
    }
    if (filters['limit']) {
        result['limit'] = filters['limit'];
    }
    if (filters['offset']) {
        result['offset'] = filters['offset'];
    }
    return result;
};
const usePatients: (filters?: Filters) => ReturnType = (filters) => {
    let total = 0;
    const {
        data,
        loading,
        refetch: refresh,
        error,

    } = useQuery(QUERY, { variables: createFilters(filters) });
    const refetch = (filters?: Filters) => {
        return refresh(createFilters(filters));
    };
    let patients = [];
    if (!loading && !error) {
        patients = data.c_patient_pii;
        total = data.c_patient_pii_aggregate.aggregate.count;
    }
    return {
        patients,
        total,
        loading,
        refetch,
        error,
    };
};
export default usePatients;
