import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import { GlobalListLimit } from '../../constants/AppConstants';

type ReturnType = {
    permissions: any[];
    loading: boolean;
    error: any;
    total: number;
    refetch: any;
};

const QUERY = gql`
    query ($where: c_acl_permissions_bool_exp, $limit: Int, $offset: Int){
        c_acl_permissions(where: $where, limit: $limit, offset: $offset) {
            name
            id
            description
        }
        c_acl_permissions_aggregate(where: $where, limit: $limit, offset: $offset){
            aggregate{
                count
            }
        }
    }
`;

const createFilters = (filters?: Filters) => {
    const result: any = { where: { _and: [] }, limit: 500, offset: 0 };
    if (!filters) {
        return result;
    }
    if (filters['search']) {
        result['where']['_and'].push({
            '_or': [
                { name: { _ilike: `%${filters['search']}%` } },
            ],
        });
    }
    if (filters['notIds']) {
        result['where']['_and'].push({
            id: {
                _nin: filters['notIds'],
            },
        });
    }
    result['limit'] = filters['limit'] || 500;
    result['offset'] = filters['offset'] || 0;
    return result;
};

const usePermissions: (filters?: Filters) => ReturnType = (filters) => {
    const { data, loading, error, refetch: refresh } = useQuery(QUERY, { variables: createFilters(filters) });
    let permissions = [];
    let total = 0;
    if (!loading && !error) {
        permissions = data.c_acl_permissions;
        total = data.c_acl_permissions_aggregate.aggregate.count;
    }
    const refetch = (filters?: Filters) => {
        return refresh(createFilters(filters));
    };
    return {
        total,
        permissions,
        loading,
        error,
        refetch,
    };
};
export default usePermissions;
