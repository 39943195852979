import React, { useEffect, useState } from 'react';
import Login from 'shared/view/login/Login';
import { Col, notification, Row, Spin } from 'antd';
import { AuthConsumer, logout } from 'shared/contexts/AuthContext';
import { Redirect } from 'react-router-dom';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import useUserById from 'shared/hooks/useUserById';
import useCurrentUserPermissions from 'shared/hooks/useCurrentUserPermissions';
import useUserByEmail from 'shared/hooks/Users/useUserByEmail';
import { latestAllowedRoute } from 'shared/services/Utility';
import useUserLoginCreate from 'shared/hooks/Users/useUserLoginCreate';

interface Props {
    location: any;
}

export default function VerifyLoginPage(props: Props) {

    console.log('inside success Login');
    return (
        <AuthConsumer>
            {({ authStatus, authToken }) => {

                return authStatus === 'AUTHENTICATED' ? (
                    <Redirect to={latestAllowedRoute()} /> //TODO: User route by permission
                ) : (
                    authToken && authToken.account ? <FetchingUserDetails authToken={authToken} /> :
                        <Redirect to={'/login'} />
                );
            }
            }
        </AuthConsumer>
    );
}

function VerifyLogin({ token }: any) {
    const { currentUser, error } = useCurrentUser(token);
    if (error) {
        notification.error({ message: 'Something went wrong' });
        logout();
    }
    if (currentUser && currentUser.userId) {
        localStorage.setItem('token', currentUser.token);
        return <FetchingUserDetails id={currentUser.userId} />;
    }
    return (
        <div style={{
            flexDirection: 'column',
            height: '100vh',
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Spin />
        </div>
    );
}


function FetchingUserDetails({ authToken: { account: { username } } }: any) {
    const { user, loading, error } = useUserByEmail(username.toLowerCase());
    if (!user) {
        return <Spin />;
    }
    return <Row justify={'center'} align={'middle'}>
        <Col>
            <FetchingPermissions user={user} />
        </Col>
    </Row>;
}


function FetchingPermissions({ user }: any) {
    const { permissions, loading, refetch: refetchPermissions } = useCurrentUserPermissions();
    const {
        mutation: addUserLoginMutation,
        saving: addingUserLogin,
    } = useUserLoginCreate((e: any) => {
        if (e) {

        }
    });
    useEffect(() => {
        localStorage.setItem('permissions', JSON.stringify(permissions));

    }, [permissions]);
    useEffect(() => {
        addUserLoginMutation({
            variables: {
                objects: [{}],
            },
        });
    }, []);
    if (!user || loading) {
        return <Spin />;
    }
    if (!permissions.length) {
        return <Redirect to={latestAllowedRoute()} />;
    }
    return <Row justify={'center'} align={'middle'}>
        <Col>
            <AuthConsumer>
                {({ setAuth, user: savedUser }) => {
                    if (user && !savedUser) {
                        localStorage.setItem('user', JSON.stringify(user));
                        setAuth(user);
                        return <div style={{
                            flexDirection: 'column',
                            height: '100vh',
                            width: '100vw',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Spin />
                        </div>;
                    }
                    return <div style={{
                        flexDirection: 'column',
                        height: '100vh',
                        width: '100vw',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Spin />
                    </div>;
                }
                }
            </AuthConsumer>
        </Col>
    </Row>;
}
