import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Skeleton,
} from 'antd';
import useLocations from 'shared/hooks/useLocations';
import useQuestionnaireUpdate from 'shared/hooks/Templates/useQuestionnaireUpdate';
import useQuestionnaireById from 'shared/hooks/Templates/useQuestionnaireById';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import FormBuilderComponent from 'shared/FormBuilder/FormBuilderComponent';

type Props = {}

const QuestionnaireBuilderComponent: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocations();
    const RouteParams: { id: string, mode: 'edit' } = useParams();
    // const {
    //     loading: loadingQuestionnaire,
    //     questionnaire,
    //     error: fetchingQuestionnaireError,
    // } = useQuestionnaireById(RouteParams['id']);
    const {
        mutation,
        saving,
        questionnaire: savedQuestionnaire,
    } = useQuestionnaireUpdate((e: any) => {
        if (e) {
            history.goBack();
        }
    });

    // if (loadingQuestionnaire) {
    //     return <Skeleton active={true} />;
    // }
    const onSubmit = (data: any) => {
        mutation({ variables: { ...data, id: RouteParams['id'] } });
    };
    return (
        <div className={'user-group-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Questionnaire Builder'}>
              <FormBuilderComponent></FormBuilderComponent>
            </FormViewDesktop>

        </div>
    );

};


export default QuestionnaireBuilderComponent;
