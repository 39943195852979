import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    user: any;
    loading: boolean;
    error: any;
};

const QUERY = gql`
    query ($id:uuid!){
        c_org_users_by_pk(id: $id) {
            id
            name
            email
            user_type
            is_doctor
            phone
            gender
            created_at
        }
    }
`;

const useUserById: (id: string) => ReturnType = (id) => {
    const { data, loading, error } = useQuery(QUERY, { variables: { id } });
    let user = null;
    if (!loading && !error) {
        user = data.c_org_users_by_pk;
    }
    return {
        user,
        loading,
        error,
    };
};
export default useUserById;
