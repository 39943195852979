import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Form,
    Input,
    Col,
    Row,
    Button, Space, Skeleton, Select, List, Tooltip, Divider,
} from 'antd';
import { useEffect, useState } from 'react';

type Props = {
    location?: any
    onSubmit: any
    saving?: boolean
    country?: any
    city?: any
    state?: any
    loading?: boolean
    onCancel?: any
    refresh?: any
}

const LocationForm: React.FunctionComponent<Props> = ({
                                                          onSubmit,
                                                          saving,
                                                          state = {},
                                                          city,
                                                          country = {},
                                                          location,
                                                          loading,
                                                          onCancel,
                                                          refresh,
                                                      }) => {
    const { t } = useTranslation();
    const [fields]: any[] = useState([]);
    const [form] = Form.useForm();
    if (loading) {
        return <Skeleton active={true} />;
    }
    return (
        <div className={'user-group-create-or-edit-page'}>
            <Form
                size='large'
                form={form}
                fields={fields}
                onFinish={async (formValues) => {
                    formValues['country_code'] = formValues['country'].toLowerCase();
                    formValues['state_code'] = (formValues['country'] + formValues['state']).toLowerCase();
                    formValues['city_code'] = (formValues['country'] + formValues['state'] + formValues['city']).toLowerCase();
                    onSubmit(formValues);
                }}
                initialValues={location || {}}
                requiredMark={true}
                layout='vertical'
            >
                <Row gutter={[20, 20]}>
                    <Col md={6} sm={12} xs={24}>
                        <Form.Item
                            label={'City'}
                            name={'city'}
                            rules={[
                                { required: true, message: 'City required' },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col md={6} sm={12} xs={24}>
                        <Form.Item
                            label={'State'}
                            name={'state'}
                            rules={[
                                { required: true, message: 'State required' },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col md={6} sm={12} xs={24}>
                        <Form.Item
                            label={'Country'}
                            name={'country'}
                            rules={[
                                { required: true, message: 'Country required' },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Divider />
                        <Space>
                            <Form.Item>
                                <Button type='default'
                                        onClick={onCancel}
                                        disabled={saving}
                                        size={'large'}>
                                    {t('genDict.cancel')}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary'
                                        htmlType='submit'
                                        loading={saving}
                                        size={'large'}>
                                    {t('genDict.submit')}
                                </Button>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};


export default LocationForm;
