import React from 'react';

import {useState} from "react";
import {useMsal} from "@azure/msal-react";
import { Button } from 'antd';
import { logout } from '../../../contexts/AuthContext';

export const SignOutButton = () => {
    const {instance} = useMsal();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleLogout = (logoutType: string) => {
        setAnchorEl(null);

        if (logoutType === "popup") {
            instance.logoutPopup({
                // @ts-ignore
                mainWindowRedirectUri: "/login"
            }).then(()=>{
                logout();
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect();
        }
    }

    return (
        <div>
            <Button type={'primary'} onClick={() => handleLogout('popup')}>Logout</Button>
        </div>
    )
};
