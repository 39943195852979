import AXIOS from 'axios';
import Parameters from 'shared/parameters';
import { getAuthToken } from '../contexts/AuthContext';
import BaseUrlConstants from '../constants/BaseUrlConstants';
import { GetHeaders } from './ApolloClient';

function getPublicInstance() {
    const headers = {};
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': Parameters.ASVCUrl,
        'headers': GetHeaders(),
    });
}

function getProtectedInstance(addAuth = false) {
    const headers: any = {
        'X-localization': 'en',
    };
    const token = localStorage.getItem('auth-token');
    if (token) {
        headers['Authorization'] = `Bearer ${JSON.parse(token as string).access_token}`;
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': Parameters.ApiUrl + (addAuth && token ? '/auth/' : '/'),
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
    });
}

function handleErrors(error: any) {
    let message = 'Something went wrong!!';
    if (error && error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
    } else if (error && error.response && error.response.data && error.response.data.errors) {
        let e = [];
        for (let i in error.response.data.errors) {
            e.push(`${i}: ${error.response.data.errors[i]}`);
        }
        message = e.join(',');
    }
    return message;
}

async function azureLogin() {
    const instance = getPublicInstance();
    return await instance.post('azure-login');
}

async function register(data: any) {
    const instance = getPublicInstance();
    return await instance.post('signup', data);
}

async function fetchQueryRoot() {
    const instance = getPublicInstance();
    return await instance.post('v1/graphql/', {});
}

const API_SERVICE = {
    azureLogin,
    register,
    fetchQueryRoot,

    handleErrors,
};
export default API_SERVICE;
