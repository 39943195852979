import * as React from 'react';
import { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { notification, Row, Col, Typography } from 'antd';
// @ts-ignore
import LOGO from '../../images/Logo.jpg';

import './Login.scss';

import { AuthConsumer } from 'shared/contexts/AuthContext';
import SignInSignOutButton from '../../Apps/CustomAzureAD/MSComponents/SignInSignOutButton';
import { latestAllowedRoute } from '../../services/Utility';


interface Props {
    location: any;

}


export default function Login({ location }: Props) {
    const { from } = location.state || { from: { pathname: latestAllowedRoute() } };
    return (
        <AuthConsumer>
            {({ authStatus, updateAuthToken }) => {
                return authStatus === 'AUTHENTICATED' ? (
                    <Redirect to={from} />
                ) : (
                    <div className={'page-wrapper azure-login-container'}>
                        <div className='main'>
                            <img src={LOGO} alt='Image' className='logo-thb' />
                            <p className='signp'>Sign into Exclusive Clinical Outcome</p>
                            <SignInSignOutButton />

                            <div className='ocean'>
                                <div className='wave'></div>
                                <div className='wave'></div>
                            </div>
                        </div>
                    </div>
                );
            }
            }
        </AuthConsumer>
    );
}
