import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  Form,
  Input,
  Col,
  Row,
  Button, Space, Skeleton, Select, List, Tooltip, Tabs, Checkbox,
} from 'antd';
import { useEffect, useState } from 'react';
import useLocations from 'shared/hooks/useLocations';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { DeleteFilled, LogoutOutlined } from '@ant-design/icons';
import useQuestionnaires from 'shared/hooks/Templates/useQuestionnaires';
import useProcedureTypeQuestionnairesCreate
    from 'shared/hooks/ProcedureTypes/useProcedureTypeQuestionnairesCreate';
import useProcedureTypeQuestionnaireDelete
    from 'shared/hooks/ProcedureTypes/useProcedureTypeQuestionnaireDelete';
import useDepartments from 'shared/hooks/Departments/useDepartments';
import useDebounce from 'shared/hooks/useDebounce';
import moment from 'moment';
import { AuthConsumer, logout } from 'shared/contexts/AuthContext';

type Props = {
    procedureType?: any
    procedureTypeQuestionnaires?: any
    onSubmit: any
    saving?: boolean
    onCancel?: any
    refresh?: any
    refetchOption?:any
}

const ProcedureTypeForm: React.FunctionComponent<Props> = ({
                                                               onSubmit,
                                                               procedureTypeQuestionnaires: UG,
                                                               saving,
                                                               procedureType,
                                                               onCancel,
                                                               refresh,
                                                               refetchOption
                                                           }) => {
    const { t } = useTranslation();
    const [fields]: any[] = useState([]);
    const [search, setSearch] = useState('' as string);
    const debouncedSearch = useDebounce(search, 500);
    const [procedureTypeQuestionnaires, setProcedureTypeQuestionnaires] = useState(UG || []);
    const [form] = Form.useForm();
    const { departments, loading: loadingDepartments, refetch: refetchDepartments } = useDepartments({ limit: 1000 });

    const { questionnaires, loading: loadingQuestionnaires } = useQuestionnaires();
    useEffect(() => {
        setProcedureTypeQuestionnaires(UG);
    }, [UG]);
    useEffect(() => {
        const p: any = { search, limit: 1000 };
        refetchDepartments(p);
    }, [debouncedSearch, procedureType]);
    const {
        mutation,
        saving: savingProcedureTypeQuestionnaire,
        procedureTypeQuestionnaire: savedQuestionnaire,
        error,
    } = useProcedureTypeQuestionnairesCreate((response: any) => {
        if (response) {
            const { insert_c_prescription_encounter_templates_one } = response;
            const ug = procedureTypeQuestionnaires || [];
            ug.push(insert_c_prescription_encounter_templates_one);
            setProcedureTypeQuestionnaires(JSON.parse(JSON.stringify(ug)));
            form.resetFields(['template_id']);
            refetchOption()
        }

    });
    const {
        mutation: deleteProcedureTypeQuestionnaireMutation,
        saving: deletingProcedureTypeQuestionnaire,
    } = useProcedureTypeQuestionnaireDelete((response: any) => {
        if (response) {
            const { update_c_prescription_encounter_templates_by_pk } = response;
            const ug = procedureTypeQuestionnaires || [];
            form.resetFields(['template_id']);
            let index = -1;
            procedureTypeQuestionnaires.forEach((u: any, i: number) => {
                if (u.id === update_c_prescription_encounter_templates_by_pk.id) {
                    index = i;
                }
            });
            ug.splice(index, 1);
            setProcedureTypeQuestionnaires(JSON.parse(JSON.stringify(ug)));
            refetchOption()
        }

    });

    return (
        <div className={'user-group-create-or-edit-page form-wrapper'}>
            <Form
                size='large'
                form={form}
                fields={fields}
                onFinish={async (formValues) => {
                    if (procedureType) {
                        delete formValues['department_id'];
                    }
                    onSubmit(formValues);
                }}
                initialValues={procedureType || {}}
                requiredMark={true}
                layout='vertical'
            >
                <Row gutter={[20, 20]} align={'bottom'}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={t('procedureTypes.name')}
                            name={'name'}
                            rules={[
                                { required: true, message: 'Name required' },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={t('procedureTypes.department')}
                            name={'department_id'}
                            rules={[
                                { required: true, message: 'Department required' },
                            ]}>
                            <Select
                                onSearch={
                                    (v) => {
                                        setSearch(v);
                                    }
                                }
                                showSearch={true}
                                optionFilterProp='children'
                                filterOption={
                                    (input: string, option: any) => {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                }
                            >

                                {
                                    departments.map((department: any) => {
                                        return <Select.Option
                                            value={department.id}>{`${department.name} / ${department.branch.name}`}</Select.Option>;
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={'Enable Multiple Prescriptions'}
                      name={'enable_multiple_prescriptions'}
                      valuePropName='checked'
                      rules={[]}>
                      <Checkbox>Yes</Checkbox>
                    </Form.Item>
                    </Col>
                    {
                        procedureType && <Col span={24}>
                            <Row gutter={[20, 20]} align={'middle'}>
                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <Title level={5}>Questionnaires {(procedureTypeQuestionnaires.length)}</Title>

                                    <AuthConsumer>
                                        {({ user }) => (
                                            <List
                                                className='demo-loadmore-list'
                                                loading={loadingQuestionnaires}
                                                itemLayout='horizontal'
                                                dataSource={procedureTypeQuestionnaires || []}
                                                renderItem={(item: any, index) => (
                                                    <List.Item
                                                        actions={[
                                                            <Tooltip title={t('genDict.delete')}>
                                                                <Button disabled={deletingProcedureTypeQuestionnaire}
                                                                        shape='circle'
                                                                        onClick={() => {
                                                                            deleteProcedureTypeQuestionnaireMutation({
                                                                                variables: {
                                                                                    id: item.id, 
                                                                                    set: {
                                                                                        deleted_by_id: user.id,
                                                                                        deleted_at: moment(),
                                                                                    },
                                                                                    template_id:item.template_id,
                                                                                    encounter_type_id:item.encounter_type_id
                                                                                },
                                                                            });
                                                                        }}

                                                                        icon={<DeleteFilled />}
                                                                        size={'middle'} />
                                                            </Tooltip>,
                                                        ]}
                                                    >
                                                        <Text>{item.template?.name}</Text>
                                                    </List.Item>
                                                )}
                                            />
                                        )}
                                    </AuthConsumer>
                                </Col>
                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('procedureTypes.questionnaires')}
                                        name={'template_id'}
                                        rules={[]}>
                                        <Select
                                            showSearch
                                            loading={savingProcedureTypeQuestionnaire}
                                            placeholder='Select a Questionnaire'
                                            optionFilterProp='children'
                                            onChange={(e, a: any) => {
                                                mutation({
                                                    variables: {
                                                        encounter_type_id: procedureType.id,
                                                        template_id: e,
                                                    },
                                                });
                                            }}
                                            filterOption={(input: string, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                questionnaires.filter((d: any) => {
                                                    let found = false;
                                                    procedureTypeQuestionnaires.forEach((a: any) => {
                                                        if (a.template_id === d.id) {
                                                            found = true;
                                                        }
                                                    });
                                                    return !found;
                                                }).map((doc: any, index: number) => {
                                                    return <Select.Option key={index}
                                                                          value={doc.id}>{doc.name}</Select.Option>;
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Col>
                    }
                    <Col span={24}>
                        <Space>
                            <Form.Item>
                                <Button type='default'
                                        onClick={onCancel}
                                        disabled={saving}
                                        size={'large'}>
                                    {t('genDict.cancel')}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary'
                                        htmlType='submit'
                                        loading={saving}
                                        size={'large'}>
                                    {t('genDict.submit')}
                                </Button>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};


export default ProcedureTypeForm;
