import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
  questionnaire: any;
  loading: boolean;
  error: any;
};

const QUERY = gql`
  query ($id:uuid!){
    c_prescription_templates_by_pk(id: $id) {
      name
      is_follow_up
      id
      is_supplementary
      is_approved
      code
      content{
        data
        id
      }
      group_id
    }
  }
`;

const useQuestionnaireById: (id: string) => ReturnType = (id) => {
  const { data, loading, error } = useQuery(QUERY, { variables: { id } });
  let questionnaire = null;
  if (!loading && !error) {
    questionnaire = data.c_prescription_templates_by_pk;
  }
  return {
    questionnaire,
    loading,
    error,
  };
};
export default useQuestionnaireById;
