import * as React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { AuthConsumer } from 'shared/contexts/AuthContext';
import { latestAllowedRoute } from '../services/Utility';
import { ROUTE_PAGE_403 } from '../constants/RouteConstants';

export default function ProtectedRoute({ component: Component, ...rest }: any) {
    return (
        <Route
            {...rest}
            render={(props) => (
                <AuthConsumer>
                    {({ authStatus, authToken }) => {
                        if (latestAllowedRoute() === ROUTE_PAGE_403) {
                            return <Redirect
                                to={{
                                    pathname: latestAllowedRoute(),
                                    state: { from: rest.location },
                                }}
                            />;
                        }
                        return authStatus === 'AUTHENTICATED' ? (
                            <Component {...props} />
                        ) : (
                            authToken ? <Redirect
                                to={{
                                    pathname: latestAllowedRoute(),
                                    state: { from: rest.location },
                                }}
                            /> : <Redirect
                                to={{
                                    pathname: '/login',
                                    state: { from: rest.location },
                                }}
                            />
                        );
                    }
                    }
                </AuthConsumer>
            )}
        />
    );
}
