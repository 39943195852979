import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ListViewDesktop from 'shared/components/layout/Desktop/ListView/ListViewDesktop';
import { Alert } from 'antd';

import {
    PATIENT_ADMISSION_ADD,
    PATIENT_REGISTRATION_ADD_ROUTE,
    PROCEDURES_ADD_ROUTE, PROCEDURES_EDIT_ROUTE, PROCEDURES_LIST_ROUTE, PROCEDURES_PRESCRIPTION_REVIEW_ROUTE,
    PROCEDURES_REVIEW_ROUTE,
} from 'shared/constants/RouteConstants';
import {
    Button,
    Space,
    Col,
    DatePicker,
    Row,
    Table,
    Tooltip,
    Typography,
    Select,
    Dropdown,
    Menu,
    Input,
    Modal, Divider, Spin, List, Skeleton, notification, message,
} from 'antd';
import {
    EditFilled,
    EyeFilled,
    InfoCircleOutlined,
    UsergroupAddOutlined,
    PushpinOutlined,
    FileAddOutlined, DeleteFilled, ExclamationCircleOutlined,
} from '@ant-design/icons';

// @ts-ignore
import { CSVLink } from 'react-csv';

import { useEffect, useState } from 'react';
import moment from 'moment';
import StatusTags from 'shared/components/StatusTags/StatusTags';
import Title from 'antd/es/typography/Title';
import usePatientProcedures, { createFilters } from 'shared/hooks/Patients/usePatientProcedures';
import {
    Filters,
    getEpisodeType,
    getPaginationLimit,
    hasPermissionOf,
    numberFormat,
    setPaginationLimit,
} from 'shared/services/Utility';
import useDebounce from 'shared/hooks/useDebounce';
import {
    BranchSelectFilter,
    DepartmentSelectFilter,
    SelectSearchFilter,
} from 'shared/components/Common/CommonFilterComponents';
import {
    DateFormat,
    PrescriptionEditorStatuses, PrescriptionEditorStatusInProgress,
    PrescriptionEditorStatusReWork,
    PrescriptionEditorStatusSubmitted, PrescriptionReviewerApproved,
    PrescriptionReviewerPending, PrescriptionReviewerReopen, PrescriptionReviewerUpdateRejected, PrescriptionReviewStatuses,
} from 'shared/constants/AppConstants';
import {
    EXPORT_PROCEDURES,
    PATIENT_ENCOUNTER_DELETE,
    PATIENT_ENCOUNTER_REVIEW, PATIENT_ENCOUNTER_REVIEWERS_INSERT, PATIENT_ENCOUNTER_REVIEWERS_UPDATE,
    PATIENT_ENCOUNTER_UPDATE,
    PATIENTS_ENCOUNTER_INSERT_ONE, PATIENTS_ENCOUNTER_SELECT, USERS_INSERT_ONE,
} from 'shared/constants/Permissions';
import { DoctorsFromDepartment } from 'shared/components/Common/CommonDependingFields';
import { AuthConsumer } from 'shared/contexts/AuthContext';
import useAddReviewersToProcedures from 'shared/hooks/Procedures/useAddReviewersToProcedures';
import useRemoveReviewersToProcedures from 'shared/hooks/Procedures/useRemoveReviewersToProcedures';
import useProcedureById from 'shared/hooks/Procedures/useProcedureById';
import useUpdatePatientsProcedures from 'shared/hooks/Procedures/useUpdatePatientsProcedures';
import useQuestionnaires from 'shared/hooks/Templates/useQuestionnaires';
import useExportReport from 'shared/hooks/Patients/exportReport';

const { RangePicker } = DatePicker;

const { Text } = Typography;
const { confirm } = Modal;
type Props = {
    user: any
}


const ProcedurePage: React.FunctionComponent<Props> = ({ user }) => {
    const { t } = useTranslation();
    const [_export, setExport] = useState(false);


    const history = useHistory();
    const localFilters = localStorage.getItem('procedure-filters');
    const storedFilters = localFilters ? JSON.parse(localFilters) : {};
    const [collapsedActionBar, setCollapsedActionBar] = useState(false);
    const [search, setSearch] = useState(storedFilters?.search || {} as Filters);
    const [selectedSearchOption, setSelectedSearchOption] = useState('UHID');
    const [selectedStatus, setSelectedStatus] = useState(storedFilters?.selectedStatus || null as any);
    const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState(storedFilters?.template_id || null as any);
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(storedFilters?.department_id || null as any);
    const { questionnaires } = useQuestionnaires({ is_supplementary: false });
    const [procedureToEditReviewers, setProcedureToEditReviewers] = useState(storedFilters?.procedureToEditReviewers || null as any);
    const [selectedBranchId, setSelectedBranchId] = useState(storedFilters?.branch_id || null as any);
    const [pagination, setPagination] = useState({
        limit: storedFilters?.limit || getPaginationLimit(),
        currentPage: storedFilters?.currentPage || 1,
    } as { limit: number, currentPage: number });
    const debouncedSearch = useDebounce(search, 500);
    const {
        mutation: deleteProcedureMutation,
        saving: deletingPatientProcedureUpdate,
    } = useUpdatePatientsProcedures((e: any) => {
        if (e) {
            notification.success({ message: 'Deleted successfully' });
            refreshList();
        }
    });
    const [selectedRange, setSelectedRange]: any = useState(storedFilters?.procedure_date_range?.length ? [moment(storedFilters?.procedure_date_range[0]), moment(storedFilters?.procedure_date_range[1])] : [moment().subtract(30, 'days').startOf('day'), moment().endOf('day')]);
    const {
        procedures,
        prescriptions,
        loading: loadingProcedures,
        refetch,
        total,
        error: errorProcedures,
    } = usePatientProcedures({});
    const searchOptions = ['UHID', 'Department', 'Branch', 'Procedure Name', 'Primary Doctor'];
    const getParams = () => {
        const params: any = {
            ...search,
            branch_id: selectedBranchId,
            template_id: selectedQuestionnaireId,
            procedure_date_range: selectedRange,
            department_id: selectedDepartmentId,
            currentPage: pagination.currentPage,
            offset: (pagination.currentPage - 1) * pagination.limit,
            limit: pagination.limit,
        };
        localStorage.setItem('procedure-filters', JSON.stringify({ ...params, search, selectedStatus }));
        if (selectedStatus === PrescriptionEditorStatusReWork || selectedStatus === PrescriptionEditorStatusInProgress || selectedStatus === PrescriptionEditorStatusSubmitted) {
            params['latest_editor_note_status'] = selectedStatus;
        } else if (selectedStatus === PrescriptionReviewerApproved || selectedStatus === PrescriptionReviewerUpdateRejected || selectedStatus === PrescriptionReviewerPending || selectedStatus === PrescriptionReviewerReopen) {
            params['latest_reviewer_note_status'] = selectedStatus;
        }
        return params;
    };
    const refreshList = () => {

        refetch(getParams());
    };

    useEffect(() => {
        setPagination({ ...pagination, currentPage: 1 });
    }, [debouncedSearch, selectedQuestionnaireId, selectedBranchId, selectedDepartmentId, selectedRange, selectedStatus]);
    useEffect(() => {
        refreshList();
    }, [pagination]);
    const searchSelectionMenu = (
        <Menu onClick={(e: any) => {
            setSelectedSearchOption(e.key);
        }}>
            {
                searchOptions.map((sO) => {
                    return <Menu.Item key={sO}>
                        {sO}
                    </Menu.Item>;
                })
            }
        </Menu>
    );

    const searchSelectionItems = [{
        name: 'Procedure Name',
        key: 'procedureName',
    }, {
        name: 'Questionnaire Name',
        key: 'questionnaireName',
    }, {
        name: 'Primary Doctor Name',
        key: 'primaryDoctorName',
    }, {
        name: 'Performing Doctor Name',
        key: 'performingDoctorName',
    }, {
        name: 'Patient Name',
        key: 'patientName',
    }, {
        name: 'Patient UHID',
        key: 'patientUHID',
    }, {
        name: 'Patient Email',
        key: 'patientEmail',
    }, {
        name: 'Patient Phone',
        key: 'patientPhone',
    }];
    const columns = [
        {
            title: '#',
            key: 'sNo',
            render: (a: any, value: any, index: number) =>
                <Text>{(index + 1) + ((pagination.currentPage - 1) * pagination.limit)}</Text>,
        },
        {
            title: 'UHID',
            key: 'fullName',
            render: (a: any, value: any, index: number) => a ? <div>
                <div>
                    <Text strong>{a.uhid}</Text>
                </div>
            </div> : 'NA',

        },
        {
            title: 'Patient Name',
            key: 'fullName',
            render: (a: any, value: any, index: number) => a ? <div>
                <div>
                    <Text strong>{a.first_name || 'NA'} {a.last_name || ''}</Text>
                </div>
            </div> : 'NA',

        },
        {
            title: 'Episode ID',
            key: 'episode',
            render: (a: any, value: any, index: number) => a && <div>
                <div>
                    <Text strong>{getEpisodeType(a.type)}: {a.type_id || '-'}</Text>

                </div>
            </div>,
        },
        {
            title: 'Procedure & Date',
            key: 'procedureName',
            render: (a: any) => a && <div style={{ minWidth: '120px' }}>
                <div>
                    <Text strong>{a.encounter_type_name || ''}</Text>
                </div>
                <div>
                    <small><Text>{moment(a.start_date || a.visit_date).format('LL')}</Text></small>
                </div>
            </div>,
        },

        {
            title: 'Doctors',
            key: 'primaryDoctor',
            render: (a: any, value: any, index: number) => <div>
                <div>
                    <Space>
                        <Text>{a.primary_doctor_name || 'NA'}</Text>
                        <Tooltip title={'Primary Doctor'}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Space>
                </div>
                <div>
                    <Space><Text>{a.performing_doctor_name || 'NA'}</Text>
                        <Tooltip title={'Performing Doctor'}>
                            <InfoCircleOutlined />
                        </Tooltip></Space>
                </div>
            </div>,
        },
        {
            title: 'Questionnaire',
            dataIndex: ['template_name'],
            key: 'procedureQuestionnaire',
            render: (a: any) => <div style={{ minWidth: '80px' }}>{a || 'NA'}</div>,
        },
        {
            title: 'Created By',
            render: (a: any) => <div
                style={{ minWidth: '60px' }}>{a.created_by_name || a.encounter_created_by_name || 'NA'}</div>,
        },
        {
            title: 'Status',
            dataIndex: ['editor_status'],
            key: 'status',
            render: (status: any) => status ? <StatusTags
                type={status} /> : 'NA',
        },
        {
            title: 'Approval Status',
            dataIndex: ['reviewer_status'],
            key: 'approvalStatus',
            render: (status: any) => <div style={{ 'minWidth': '100px' }}>
                {
                    status ? <StatusTags
                        type={status} /> : 'NA'
                }
            </div>,
        },
        {
            fixed: collapsedActionBar ? undefined : 'right' as 'right',
            title: <div>
                <div style={{ display: 'inline-block', verticalAlign: 'sub' }}>{'Action'}</div>
                <Button shape='circle'
                    onClick={
                        () => {
                            setCollapsedActionBar(!collapsedActionBar);
                        }
                    }
                    style={{ float: 'right' }}
                    icon={<PushpinOutlined />}
                    size={'middle'} /></div>,
            key: 'action',
            render: (a: any, b: any, index: number) => {
                return <Row gutter={[10, 10]} wrap={false}>
                    {
                        hasPermissionOf(PATIENT_ENCOUNTER_DELETE) && !a.prescription_id && <Col>
                            <Tooltip title={'Delete'}>
                                <Button shape='circle'
                                    onClick={() => confirm({
                                        icon: <ExclamationCircleOutlined />,
                                        title: 'Delete Procedure',
                                        okText: 'Yes',
                                        cancelText: 'Cancel',
                                        content: 'Delete procedures permanently?',// TODO
                                        okButtonProps: { loading: deletingPatientProcedureUpdate, danger: true },
                                        onOk: async () => {
                                            deleteProcedureMutation({
                                                variables: {
                                                    id: a['encounter_id'],
                                                    set: {
                                                        deleted_at: moment(),
                                                        deleted_by_id: user.id,
                                                    },
                                                },
                                            });
                                        },
                                    })}
                                    icon={<DeleteFilled />}
                                    size={'middle'} />
                            </Tooltip>
                        </Col>
                    }
                    {
                        hasPermissionOf(PATIENT_ENCOUNTER_UPDATE) && <Col>
                            <Tooltip title={t('genDict.edit')}>
                                <Button shape='circle'
                                    onClick={() => history.push(PROCEDURES_EDIT_ROUTE(a.encounter_id))}
                                    icon={<EditFilled />}
                                    size={'middle'} />
                            </Tooltip>
                        </Col>
                    }
                    {
                        hasPermissionOf(PATIENTS_ENCOUNTER_INSERT_ONE) && <Col>
                            <Tooltip title={'Add Procedures For Current Episode'}>
                                <Button shape='circle' icon={<FileAddOutlined />}
                                    onClick={() => history.push(PATIENT_ADMISSION_ADD(a.encounter.patient.id, {
                                        episode_id: a.type_id,
                                        episode: a.type,
                                        visit_date: moment(a.visit_date).toDate().getTime(),
                                        start_date: moment(a.start_date).toDate().getTime(),
                                        admission_date: moment(a.admission_date).toDate().getTime(),
                                    }))}
                                    size={'middle'} />
                            </Tooltip>
                        </Col>
                    }
                    {
                        hasPermissionOf(PATIENT_ENCOUNTER_REVIEWERS_INSERT) && hasPermissionOf(PATIENT_ENCOUNTER_REVIEWERS_UPDATE) &&
                        <Col>
                            <Tooltip title={'Add or Edit Reviewers'}>
                                <Button shape='circle' icon={<UsergroupAddOutlined />}
                                    onClick={() => setProcedureToEditReviewers(a)}
                                    size={'middle'} />
                            </Tooltip>
                        </Col>
                    }
                    <Col>
                        <Tooltip title={t('genDict.view')}>
                            <Button shape='circle'
                                disabled={!a.prescription_id && !hasPermissionOf(PATIENTS_ENCOUNTER_INSERT_ONE)}
                                onClick={() => history.push(a.prescription_id ? PROCEDURES_PRESCRIPTION_REVIEW_ROUTE(a.encounter_id, a.prescription_id) : PROCEDURES_REVIEW_ROUTE(a.encounter_id))}
                                icon={<EyeFilled />}
                                size={'middle'} />
                        </Tooltip>
                    </Col>


                </Row>;
            },
        },
    ];

    return (
        <div className={'patient-registration-page page-wrapper'}>
            {
                _export &&
                <Modal visible={_export} title={'Exporting'} onCancel={() => setExport(false)}
                okButtonProps={{ hidden: true }}>
                    <UserExport onFinish={() => setExport(false)} filters={getParams()} />
                </Modal>
            }
            {
                procedureToEditReviewers && <ProcedureReviewers procedureId={procedureToEditReviewers.encounter_id}
                    onClose={() => (setProcedureToEditReviewers(null))} />

            }
            <ListViewDesktop title={t('genDict.procedurePages')}
                filters={[
                    <SelectSearchFilter searchSelectionItems={searchSelectionItems}
                        value={search}
                        onChange={({ key, value }: { key: string, value: string }) => {
                            setSearch({ [key]: value });
                        }} />,
                    <Select placeholder={<Text>Select Status</Text>} allowClear={true}
                        style={{ minWidth: '200px' }}
                        onChange={(e: any) => setSelectedStatus(e)}
                        value={selectedStatus}>
                        <Select.OptGroup label='Editor Status'>
                            {
                                PrescriptionEditorStatuses.map((status: any) => {
                                    return <Select.Option value={status.key}>{status.name}</Select.Option>;
                                })
                            }
                        </Select.OptGroup>
                        <Select.OptGroup label='Reviewer Status'>
                            {
                                PrescriptionReviewStatuses.map((status: any) => {
                                    return <Select.Option value={status.key}>{status.name}</Select.Option>;
                                })
                            }
                        </Select.OptGroup>
                    </Select>,
                    <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                        placeholder={<Text>Select Questionnaire</Text>} allowClear={true}
                        style={{ minWidth: '200px' }}
                        onChange={(e: any) => setSelectedQuestionnaireId(e)}
                        value={selectedQuestionnaireId}>
                        {
                            questionnaires?.sort(function(a, b){
                                let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                                if (nameA < nameB) //sort string ascending
                                    return -1;
                                if (nameA > nameB)
                                    return 1;
                                return 0; //default return value (no sorting)
                            }).map((q: any) => {
                                return <Select.Option value={q.id}>{q.name}</Select.Option>;
                            })
                        }
                    </Select>,
                    <BranchSelectFilter value={selectedBranchId} onChange={(e: any) => {
                        setSelectedBranchId(e);
                        setSelectedDepartmentId(null);
                    }} />,
                    selectedBranchId ? <DepartmentSelectFilter
                        userId={user.id}
                        value={selectedDepartmentId}
                        branchId={selectedBranchId}
                        onChange={(e: any) => setSelectedDepartmentId(e)} />
                        : null,
                    <RangePicker style={{ maxWidth: '235px' }} format={DateFormat}
                        defaultValue={selectedRange}
                        onChange={(e: any) => {
                            if (!e) {
                                setSelectedRange([]);
                                return;
                            }
                            setSelectedRange([e[0].startOf('day'), e[1].endOf('day')]);
                        }} />,
                    hasPermissionOf(EXPORT_PROCEDURES) &&
                    <Button type={'primary'} onClick={() => {
                        console.log('StoredFilter==>', getParams(),selectedRange[1]?.diff(selectedRange[0], 'days'))
                        if(!selectedQuestionnaireId){
                            notification.error({ message: 'Please select a Questionnaire.' });
                            return
                        }
                        if (selectedRange[1]?.diff(selectedRange[0], 'days') <= 365) {
                            setExport(true);
                        } else {
                            notification.error({ message: 'Date Range should be one year at most.' });
                        }
                    }}>
                        Export
                    </Button>,
                ]}>
                <Table
                    sticky={true}

                    locale={{
                        emptyText: (
                            <>
                                <Space direction={'vertical'} size={10} style={{ padding: '10px' }}>
                                    <Title level={3} className={'text-primary'}>{t('genDict.woops')}</Title>
                                    <Text className={'text-primary'}>No Procedure Added</Text>
                                </Space>
                            </>
                        ),
                    }}
                    pagination={{
                        hideOnSinglePage: false,
                        showSizeChanger: true,
                        total,
                        current: pagination.currentPage,
                        pageSize: pagination.limit,
                        showTotal: (total: number) => <Text>Total <b>{numberFormat(total)}</b> items</Text>,
                        onChange: (page: number, pageSize?: number) => {
                            if (pageSize !== pagination.limit) {
                                setPaginationLimit(pageSize);
                                page = 1;
                            }
                            setPagination({ currentPage: page, limit: (pageSize || pagination.limit) });
                        },
                    }} scroll={{ x: true }} dataSource={prescriptions}
                    loading={loadingProcedures}
                    columns={columns} />
            </ListViewDesktop>
        </div>
    );
};

const ProcedureReviewers: React.FunctionComponent<{ onClose: any, procedureId: string }> = ({
    onClose,
    procedureId,
}) => {
    const { t } = useTranslation();
    const { procedure, refetch } = useProcedureById(procedureId);
    const {
        mutation: addReviewerToProcedureMutation,
        saving: addingReviewerToProcedure,
    } = useAddReviewersToProcedures((e: any) => {
        if (e) {
            refetch();
        }
    });
    const {
        mutation: removeReviewerToProcedureMutation,
        saving: removingReviewerToProcedure,
    } = useRemoveReviewersToProcedures((e: any) => {
        if (e) {
            refetch();

        }
    });

    return (
        <Modal title={'Add or Remove Reviewers'} visible={true}
            onCancel={onClose} destroyOnClose={true} okButtonProps={{ hidden: true }}>
            {!procedure ? <Skeleton /> :
                <Row gutter={[20, 20]}>
                    <Col md={24} sm={25} xs={24}>
                        {
                            addingReviewerToProcedure ? <Spin /> : <DoctorsFromDepartment
                                onChange={
                                    (doctor_id: string) => {
                                        addReviewerToProcedureMutation({
                                            variables: {
                                                object: {
                                                    user_id: doctor_id,
                                                    patient_encounter_id: procedureId,
                                                },
                                            },
                                        });
                                    }
                                }
                                avoid={procedure.reviewers.map((reviewer: any) => reviewer.user_id)}

                                departmentId={procedure.department_id} />
                        }
                        <Divider />
                        <AuthConsumer>
                            {({ user }) => (
                                <List
                                    className='demo-loadmore-list'
                                    loading={addingReviewerToProcedure}
                                    itemLayout='horizontal'
                                    dataSource={procedure.reviewers || []}
                                    renderItem={(item: any, index) => (
                                        <List.Item
                                            actions={[
                                                <Tooltip title={'Delete'}>
                                                    <Button
                                                        disabled={removingReviewerToProcedure || (user.id === item.user.id)}
                                                        shape='circle'
                                                        onClick={() => {
                                                            removeReviewerToProcedureMutation({
                                                                variables: {
                                                                    id: item.id,
                                                                    set: {
                                                                        deleted_by_id: user.id,
                                                                        deleted_at: moment(),
                                                                    },
                                                                },
                                                            });
                                                        }}

                                                        icon={<DeleteFilled />}
                                                        size={'middle'} />
                                                </Tooltip>,
                                            ]}
                                        >
                                            <Text>{item.user.name}</Text>
                                        </List.Item>
                                    )}
                                />
                            )}
                        </AuthConsumer>
                    </Col>
                </Row>
            }
        </Modal>

    );
};

const UserExport = ({ filters, onFinish }: any) => {
    const [exportingReport, SetExportingReport] = useState(false);

    const { mutation } = useExportReport((d: any) => {
        let url = d?.generateReport?.url
        if(!url){
            notification.success({ message: 'Something went wrong.' });
        }
        SetExportingReport(false)
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        console.log('data===>', d)
    })
    return <>
        <Button loading={exportingReport} onClick={
            () => {
                SetExportingReport(true)
                mutation({ variables: { payload: JSON.stringify(createFilters({ ...filters })) } })
            }
        }>
            Export to Procedures.csv
        </Button>
        {exportingReport && <Alert message="Please don't close this dialog while downloading" type="warning" style={{marginTop:'10px'}} banner/>}
    </>;
};

export default ProcedurePage;
