import * as React from 'react';
import { useMsal } from '@azure/msal-react';
import { AuthConsumer } from '../../../contexts/AuthContext';
import { Redirect, useHistory } from 'react-router-dom';
import { Button } from 'antd';

export const SignInButton = () => {
    const { instance } = useMsal();

    const history = useHistory();

    return (
        <AuthConsumer>
            {({ authStatus, updateAuthToken }) =>
                authStatus === 'AUTHENTICATED' ? (
                    <Redirect to={'/'} />
                ) : (
                    // @ts-ignore
                    <a className='submit' align='center' onClick={() => {
                        // @ts-ignore
                        instance.loginPopup().then((a) => {
                            updateAuthToken(a);
                            history.push('/verify');
                        }).catch((e) => {
                            console.error('login failed', e);
                        });

                    }}>Sign in</a>
                )
            }
        </AuthConsumer>
    );
};
