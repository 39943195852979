import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
    notification,
} from 'antd';
import useLocations from 'shared/hooks/useLocations';
import DepartmentForm from './DepartmentForm';
import useLocationCreate from 'shared/hooks/Locations/useLocationCreate';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import useBranchCreate from 'shared/hooks/Branches/useBranchCreate';
import useDepartmentCreate from 'shared/hooks/Departments/useDepartmentCreate';


type Props = {
    user: any
}

const DepartmentCreate: React.FunctionComponent<Props> = ({user}) => {
    const history = useHistory();
    const location = useLocations();
    const { mutation, saving } = useDepartmentCreate((e: any) => {
        if (e) {
            notification.success({ message: 'Created successfully' });
            history.goBack();
        }
    });
    const onSubmit = (object: any) => {
        const req = {
                user_id: user.id, department:
                    {
                        data: object,
                    }
                ,
            }
        ;
        mutation({ variables: { object: req } });
    };
    return (
        <div className={'department-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Department Create'}>
                <DepartmentForm saving={saving} onCancel={() => history.goBack()} onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};


export default DepartmentCreate;
