import * as React from 'react';
import {
    Button,
    Col, Divider, Form, Input, List,
    Checkbox, Row, Select, Space, Spin, Tooltip, Table, Tag,
} from 'antd';
import useLocations from 'shared/hooks/Locations/useLocations';
import useUserRoles from 'shared/hooks/Users/useUserRoles';
import Title from 'antd/es/typography/Title';
import { AuthConsumer } from 'shared/contexts/AuthContext';
import moment from 'moment';
import { DeleteFilled, EditFilled, EyeFilled } from '@ant-design/icons';
import Text from 'antd/es/typography/Text';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useRoles from 'shared/hooks/Roles/useRoles';
import useDeleteUserRole from 'shared/hooks/Users/useDeleteUserRole';
import useAddUserRole from 'shared/hooks/Users/useAddUserRole';
import useScopes from 'shared/hooks/Roles/useScopes';
import { USER_ROLE_ACCOUNTS_UPDATE_ONE, USER_ROLES_INSERT_ONE, USERS_UPDATE_ONE } from 'shared/constants/Permissions';
import { hasPermissionOf } from 'shared/services/Utility';
import useBranches from 'shared/hooks/Branches/useBranches';
import useDepartments from 'shared/hooks/Departments/useDepartments';
import useTeams from 'shared/hooks/Teams/useTeams';
import useGroups from 'shared/hooks/Groups/useGroups';
import UserRoleAccounts from './UserRoleAccounts';
import UserRoleBranches from './UserRoleBranches';
import UserRoleCities from './UserRoleCities';
import UserRoleDepartments from './UserRoleDepartments';
import UserRoleTeams from './UserRoleTeams';
import UserRoleGroups from './UserRoleGroups';

type Props = {
    userId: string
}

const UserRoles: React.FunctionComponent<Props> = ({ userId }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [addNew, setAddNew] = useState(false);
    const [viewRole, setViewRole] = useState(null as any);
    const [selectedRole, setSelectedRole] = useState(null as any);
    const { userRoles, loading, refetch } = useUserRoles({ user_id: userId });
    const { roles, loading: loadingRoles } = useRoles();
    const [form] = Form.useForm();
    const {
        mutation,
        saving: savingUserRole,
    } = useAddUserRole((e: any) => {

        if (e) {
            refetch({ user_id: userId });
            setAddNew(false);
            setSelectedRole(null);
            form.resetFields();
        }
    });
    const {
        mutation: deleteUserRoleMutation,
        saving: deletingUserRole,
    } = useDeleteUserRole((e: any) => {
        if (e) {
            refetch({ user_id: userId });
        }
    });
    // const {
    //     mutation: updateUserRoleMutation,
    //     saving: updatingUserRole,
    // } = useUpdateUserRole((e: any) => {
    //     if (e) {
    //         refetch({ user_id: userId });
    //         form.resetFields();
    //     }
    //
    // });
    const onSubmit = (object: any) => {
        mutation({ variables: { object } });
    };
    return (
        <Row gutter={20}>
            {!addNew &&
            <Col md={12} sm={24} xs={24}>
                <Row gutter={10} justify={'space-between'}>
                    <Col flex={'auto'}><Title level={5}>Roles {(userRoles.length)} </Title></Col>
                    {
                        !addNew && <Col>
                            <Button type='primary'
                                    onClick={() => setAddNew(true)}
                                    size={'small'}>
                                Add new role
                            </Button>
                        </Col>
                    }
                </Row>
                <AuthConsumer>
                    {({ user }) => {
                        return <List
                            className='demo-loadmore-list'
                            loading={loading}
                            itemLayout='horizontal'
                            dataSource={userRoles || []}
                            renderItem={(item: any, index) => (
                                <List.Item
                                    actions={[
                                        // <Tooltip title={t('genDict.view')}>
                                        //     <Button shape='circle'
                                        //             onClick={() => {
                                        //                 setSearch('');
                                        //                 setViewRole(item);
                                        //             }}
                                        //             icon={<EyeFilled />}
                                        //             size={'middle'} />
                                        // </Tooltip>,
                                        <Tooltip title={t('genDict.delete')}>
                                            <Button disabled={deletingUserRole} shape='circle'
                                                    onClick={() => {
                                                        deleteUserRoleMutation({
                                                            variables: {
                                                                id: item.id,
                                                                set: {
                                                                    deleted_by_id: user.id,
                                                                    deleted_at: moment(),
                                                                },
                                                            },
                                                        });
                                                    }}

                                                    icon={<DeleteFilled />}
                                                    size={'middle'} />
                                        </Tooltip>,
                                    ]}
                                >
                                    <Row gutter={10} style={{ width: '100%' }} justify={'space-between'}>
                                        <Col flex={'auto'}>
                                            <Text>{item.role ? item.role.name : ''}</Text>
                                        </Col>
                                    </Row>

                                </List.Item>
                            )}
                        />;
                    }}
                </AuthConsumer>
            </Col>
            }
            {
                hasPermissionOf(USER_ROLES_INSERT_ONE) && (addNew) && <Col md={24} sm={24} xs={24}>
                    <Form
                        size='large'
                        form={form}
                        fields={[]}
                        onFinish={async (formValues) => {
                            if (addNew) {
                                mutation({
                                    variables: {
                                        object: {
                                            ...formValues, user_id: userId,
                                        },
                                    },
                                });
                            }
                        }}
                        requiredMark={true}
                        layout='vertical'

                    >
                        <Row gutter={20}>
                            <Col md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'Role'}
                                    name={'role_id'}
                                    rules={[
                                        { required: true, message: 'Role required' },
                                    ]}>
                                    <Select
                                        showSearch
                                        onChange={(e) => setSelectedRole(e)}
                                        optionFilterProp='children'
                                        filterOption={(input: string, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            roles.filter((role: any) => {
                                                let found = false;
                                                userRoles.forEach((userRole: any) => {
                                                    if (userRole.role_id === role.id) {
                                                        found = true;
                                                    }
                                                });
                                                return !found;
                                            }).map((role: any, index: number) => {
                                                return <Select.Option key={index}
                                                                      value={role.id}>{role.name}</Select.Option>;
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Divider />
                                <Space>
                                    <Form.Item>
                                        <Button type='default'
                                                disabled={savingUserRole}
                                                onClick={() => {
                                                    setSelectedRole(null);
                                                    setAddNew(false);
                                                    form.resetFields();
                                                }}
                                                size={'large'}>
                                            Cancel
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type='primary'
                                                htmlType='submit'
                                                loading={savingUserRole}
                                                size={'large'}>
                                            {t('genDict.add')}
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            }
        </Row>
    );
};
;
export default UserRoles;
