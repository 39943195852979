import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
    notification,
} from 'antd';
import useLocations from 'shared/hooks/useLocations';
import RoleForm from './RoleForm';
import useLocationCreate from 'shared/hooks/Locations/useLocationCreate';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import useBranchCreate from 'shared/hooks/Branches/useBranchCreate';
import useDepartmentCreate from 'shared/hooks/Departments/useDepartmentCreate';
import useRoleCreate from 'shared/hooks/Roles/useRoleCreate';

type Props = {}

const RoleCreate: React.FunctionComponent<Props> = () => {
    const history = useHistory();
    const location = useLocations();
    const { mutation, saving } = useRoleCreate((e: any) => {
        if (e) {
            notification.success({ message: 'Created successfully' });
            history.goBack();
        }
    });
    const onSubmit = (object: any) => {
        mutation({ variables: { object } });
    };
    return (
        <div className={'role-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Role Create'}>
                <RoleForm saving={saving} onCancel={() => history.goBack()} onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};


export default RoleCreate;
