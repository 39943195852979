import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    countries: any[];
    loading: boolean;
    error: any;
};

const QUERY = gql`
    query {
        c_meta_locations (distinct_on: [country_code]){
            country
            country_code
            id
        }
    }
`;

const useCountries: () => ReturnType = () => {
    const { data, loading, error } = useQuery(QUERY);
    let countries = [];
    if (!loading && !error) {
        countries = data.c_meta_locations;
    }
    return {
        countries,
        loading,
        error,
    };
};
export default useCountries;
