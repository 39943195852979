import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Card, Divider,
    notification,
    Tabs,
} from 'antd';
import UserForm from './UserForm';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import useUserById from 'shared/hooks/Users/useUserById';
import useUserUpdate from 'shared/hooks/Users/useUserUpdate';
import { useState } from 'react';
import UserRoles from './UserRoles';
import UserBranches from './UserBranches';
import UserDepartments from './UserDepartments';
import UserGroups from './UserGroups';

const { TabPane } = Tabs;
type Props = {
    user?: any
}

const UserEdit: React.FunctionComponent<Props> = ({ user: selfUser }) => {
    const history = useHistory();
    const RouteParams: { id: string, mode: 'edit' } = useParams();
    const { loading: loadingUser, user } = useUserById(RouteParams['id']);
    const [activeTab, setActiveTab] = useState('2');
    const {
        mutation,
        saving,
    } = useUserUpdate((e: any) => {
        if (e) {
            notification.success({ message: 'Updated successfully' });
            history.goBack();
        }
    });

    if (!RouteParams['id']) {
        history.goBack();
    }

    const onSubmit = (set: any) => {
        mutation({ variables: { set, id: RouteParams['id'] } });
    };
    return (
        <div className={'user-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'User Edit '}>
                <UserForm loading={loadingUser} saving={saving} user={user}
                          onCancel={() => history.goBack()}
                          onSubmit={onSubmit} />

            </FormViewDesktop>
            {
                user && user.id !== selfUser.id && <>
                    <Divider />
                    <Card>
                        <Tabs defaultActiveKey={activeTab}  activeKey={activeTab} onChange={(e) => setActiveTab(e)}>

                            {
                                <TabPane tab='Branches' key='2'>
                                    {
                                        activeTab === '2' && <UserBranches userId={user.id} />
                                    }
                                </TabPane>
                            }
                            {
                                <TabPane tab='Department' key='3'>
                                    {
                                        activeTab === '3' && <UserDepartments userId={user.id} />
                                    }
                                </TabPane>
                            }
                            {/*{*/}
                            {/*    hasPermissionOf(USER_ROLE_TEAMS_SELECT) && <TabPane tab='Teams' key='4'>*/}
                            {/*        Teams*/}
                            {/*    </TabPane>*/}
                            {/*}*/}
                            {
                                <TabPane tab='Q Groups' key='5'>
                                    {
                                        activeTab === '5' && <UserGroups userId={user.id} />
                                    }
                                </TabPane>
                            }
                            {
                                <TabPane tab='Roles' key='1'>
                                    {
                                        activeTab === '1' && <UserRoles userId={user.id} />
                                    }
                                </TabPane>
                            }
                        </Tabs>
                    </Card>
                </>
            }
        </div>
    );
};


export default UserEdit;
