import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    followUpData: any[];
    loading: boolean;
    error: any;
    refresh:any;
};

const QUERY = gql`
query MyQuery($id: uuid!) {
  c_prom_follow_up(where: {encounter_type_id: {_eq: $id}, is_active: {_eq: true}}, order_by: {created_at: desc}) {
    followup_template_data {
      id
      name
    }
    encounter_template_data {
      id
      name
    }
    followup_days
    id
  }
}


`;

const useGetFollowUp: (id: string) => ReturnType = (id) => {
    
    const { data, loading, error ,refetch: refresh} = useQuery(QUERY, { variables: { id: id } });
    let followUpData = [];
    if (!loading && !error) {
        followUpData = data.c_prom_follow_up;
    }
    return {
        followUpData,
        loading,
        error,
        refresh
    };
};
export default useGetFollowUp;
