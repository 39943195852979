import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    team: any;
    loading: boolean;
    error: any;
};

const QUERY = gql`
    query ($id:uuid!){
        c_org_teams_by_pk(id: $id) {
            id
            name
            department_id
            department{
                name
                id
            }
            created_at
        }
    }
`;

const useTeamById: (id: string) => ReturnType = (id) => {
    const { data, loading, error } = useQuery(QUERY, { variables: { id } });
    let team = null;
    if (!loading && !error) {
        team = data.c_org_teams_by_pk;
    }
    return {
        team,
        loading,
        error,
    };
};
export default useTeamById;
