import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import { GlobalListLimit } from '../../constants/AppConstants';

type ReturnType = {
    users: any[];
    loading: boolean;
    error: any;
    total: number;
    refetch: any;
};

const QUERY = gql`
    query ($where: c_org_users_bool_exp, $limit: Int, $offset: Int){
        c_org_users(where: $where, limit: $limit, offset: $offset, order_by: [{name: asc}]) {
            name
            email
            phone
            gender
            is_doctor
            created_at
            id
            user_type
            user_roles{
                role{
                    name
                }
            }
            user_branches{
                branch{
                    name
                }
            }
            user_departments{
                department{
                    name
                }
            }
            user_groups{
                group{
                    name
                }
            }
        }
        c_org_users_aggregate(where: $where, order_by: [{created_at: desc}]){
            aggregate{
                count
            }
        }
    }
`;

const QUERY_SUPER = gql`
    query ($where: c_org_users_bool_exp, $limit: Int, $offset: Int){
        c_org_users(where: $where, limit: $limit, offset: $offset, order_by: [{name: asc}]) {
            name
            email
            phone
            gender
            is_doctor
            created_at
            updated_at
            id
            user_type
            block
            blocked_at
            user_roles{
                role{
                    name
                }
            }
            user_branches{
                branch{
                    name
                }
            }
            user_departments{
                department{
                    name
                }
            }
            user_groups{
                group{
                    name
                }
            }
        }
        c_org_users_aggregate(where: $where, order_by: [{created_at: desc}]){
            aggregate{
                count
            }
        }
    }
`;

const createFilters = (filters?: Filters) => {
    const result: any = { where: { _and: [] }, limit: GlobalListLimit, offset: 0 };
    if (!filters) {
        return result;
    }
    if (filters['search']) {
        result['where']['_and'].push({
            '_or': [
                { name: { _ilike: `%${filters['search']}%` } },
                { phone: { _ilike: `%${filters['phone']}%` } },
                { email: { _ilike: `%${filters['email']}%` } },
            ],
        });
    }
    if (filters['branch_id']) {
        result['where']['_and'].push({
            user_branches:{
                branch_id: { _eq: `${filters['branch_id']}` }
            }
        });
    }
    if (filters['role_id']) {
        result['where']['_and'].push({
            user_roles:{
                role_id: { _eq: `${filters['role_id']}` }
            }
        });
    }
    if (filters['department_id']) {
        result['where']['_and'].push({
            user_departments:{
                department_id: { _eq: `${filters['department_id']}` }
            }
        });
    }
    if (filters['emails']) {
        result['where']['_and'].push({
            '_or': [
                { email: { _in: filters['emails'] } },
            ],
        });
    }
    result['limit'] = filters['limit'] || GlobalListLimit;
    result['offset'] = filters['offset'] || 0;
    console.log(result);
    return result;
};

const useUsers: (filters?: Filters) => ReturnType = (filters) => {
    const userLocal = localStorage.getItem('user') as string;
    const user = JSON.parse(userLocal);
    const {
        data,
        loading,
        error,
        refetch: refresh,
    } = useQuery(user?.action_role === 'superadmin' ? QUERY_SUPER : QUERY, { variables: createFilters(filters) });
    let users = [];
    let total = 0;
    if (!loading && !error) {
        users = data.c_org_users;
        total = data.c_org_users_aggregate.aggregate.count;
    }

    const refetch = (filters?: Filters) => {
        return refresh(createFilters(filters));
    };
    return {
        total,
        users,
        loading,
        error,
        refetch,
    };
};
export default useUsers;
