import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    notification,
} from 'antd';
import TeamForm from './TeamForm';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import useTeamById from 'shared/hooks/Teams/useTeamById';
import useTeamUpdate from 'shared/hooks/Teams/useTeamUpdate';

type Props = {}

const TeamEdit: React.FunctionComponent<Props> = () => {
    const history = useHistory();
    const RouteParams: { id: string, mode: 'edit' } = useParams();
    const { loading: loadingTeam, team } = useTeamById(RouteParams['id']);

    const {
        mutation,
        saving,
    } = useTeamUpdate((e: any) => {
        if (e) {
            notification.success({ message: 'Updated successfully' });
            history.goBack();
        }
    });

    if (!RouteParams['id']) {
        history.goBack();
    }

    const onSubmit = (set: any) => {
        mutation({ variables: { set, id: RouteParams['id'] } });
    };
    return (
        <div className={'branch-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Team Edit '}>
                <TeamForm loading={loadingTeam} saving={saving} team={team}
                          onCancel={() => history.goBack()}
                          onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};


export default TeamEdit;
