import React from "react";
// import { Modal, Container } from 'react-bootstrap';
import { Modal } from 'antd';


class CommonModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open
        }
    }

    onCloseEventClick = (event) => {
        this.props.emitModalCloseEvent(false)
    }
    render() {
        return (
            <Modal title={this.props.title}
             visible={this.state.open}
             onOk={this.onCloseEventClick.bind(this)} 
             onCancel={this.onCloseEventClick}
             footer={null}
             closable={true}
             maskClosable={true}
             >
                    {this.props.children}
            </Modal>

        )
    }
}

export default CommonModalView;