declare const API_URL: string;
declare const ASVC_URL: string;
declare const CLIENT_NAME: string;
declare const IMAGE_BASE_URL: string;
declare const DASH_BOARD_URL: string;
declare const SYSTEM_USER_ID: string;
export default {
    ApiUrl: API_URL,
    ASVCUrl: ASVC_URL,
    clientName: CLIENT_NAME,
    ImageBaseUrl: IMAGE_BASE_URL,
    DashBoardURL: DASH_BOARD_URL,
    SYSTEM_USER_ID: SYSTEM_USER_ID
};
