import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    prescription_response: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
    mutation (
        $id: String
        $status: String!
        $remarks: String
        $data: jsonb
        $prescription_review_id: String
        $template_id: String
        $encounter_id: String
    ){
        add_or_update_prescription(
            id: $id,
            status:$status,
            remarks: $remarks,
            data: $data,
            prescription_review_id: $prescription_review_id,
            template_id: $template_id,
            encounter_id: $encounter_id,
        ){
            error
            prescription_id
        }
    }
`;

const addOrUpdatePrescription: (onCompleted?: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted });
    let prescription_response = null;
    if (!saving && !error && data && data.add_or_update_prescription) {
        prescription_response = data.add_or_update_prescription;
    }
    return {
        mutation,
        prescription_response,
        saving,
        error,
    };
};
export default addOrUpdatePrescription;
