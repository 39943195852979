import * as React from 'react';

import { Tag } from 'antd';


export default function StatusTags({ type }: any) {
    switch (type) {
        case 'SUBMITTED':
            return <Tag color={'purple'}>Submitted</Tag>;
        case 'RE_WORK':
            return <Tag color={'geekblue'}>Re Work</Tag>;
        case 'APPROVED':
            return <Tag color={'green'}>Approved</Tag>;
        case 'REJECTED':
            return <Tag color={'#fe7c96'}>Rejected</Tag>;
        case 'REJECTED_UPDATE':
            return <Tag color={'#fe7c96'}>Rejected</Tag>;
        case 'REOPEN':
            return <Tag color={'red'}>Reopen</Tag>;
        case 'PENDING_REVIEW':
            return <Tag color={'#fed713'}>Pending Review</Tag>;
        case 'IN_PROGRESS':
            return <Tag color={'#fed713'}>In Progress</Tag>;
    }
    return (<Tag>{type}</Tag>);
}
