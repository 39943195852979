import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    encounter: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
    mutation ( $encounter_id: uuid!, $id: uuid!, $set: c_prescription_prescriptions_set_input!){
        update_c_prescription_prescriptions_by_pk(_set: $set , pk_columns: {id:$id}) {
            id
        }
        update_c_patient_patient_encounters_by_pk(pk_columns: {id:$encounter_id} _set: {latest_editor_status: null, latest_reviewer_status: null}){
            id
        }
    }
`;

const useDeletePatientsPrescription: (onCompleted: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted });
    let encounter = [];
    if (!saving && !error && data) {
        encounter = data.update_c_prescription_prescriptions_by_pk;
    }
    return {
        mutation,
        encounter,
        saving,
        error,
    };
};
export default useDeletePatientsPrescription;
