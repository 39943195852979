import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Parameters from 'shared/parameters';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      genDict: {
        version:'App Version: 0.0.2',
        bottom: 'Bottom',
        patientInfo: 'Patient Info',
        groups: 'Groups',
        teams: 'Teams',
        questionnaires: 'Questionnaires',
        locations: 'Locations',
        addRemarksAndStatus: 'Status and Remarks',
        address: 'Address',
        back: 'Back',
        copyRight: 'Copyright THB 2021',
        OPIPId: 'OPIPId',
        OPIPType: 'OPIPType',
        male: 'Male',
        female: 'Female',
        others: 'Others',
        proceduresList: 'Procedures',
        userGroupsList: 'User Groups',
        groupsList: 'Groups',
        questionnaireList: 'Questionnaires',
        previous: 'Previous',
        cancel: 'Cancel',
        remove: 'Remove',
        next: 'Next',
        select: 'Select',
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        woops: 'Woops !!',
        search: 'Search',
        top: 'Top',
        left: 'Left',
        view: 'View',
        userGroupsPages: 'Group',
        registrationsList: 'Registrations',
        right: 'Right',
        branches: 'Hospitals',
        users: 'Users',
        roles: 'Roles',
        patientRegistrations: 'Patient',
        procedureTypes: 'Procedure Types',
        procedurePages: 'Procedures',
        departments: 'Departments',
        procedures: 'Procedures',
        paperMargin: 'Paper Margin',
        printPreferences: 'Print Preferences',
        print: 'Print',
        printPreview: 'Print Preview',
        printDoctorInfoHeader: 'Print Doctor Info (Header)',
        registrations: 'Registrations',
        prescription: 'Prescription',
        submit: 'Submit',
        signature: 'Signature',
        headerBanner: 'Header Banner',
        footerBanner: 'Footer Banner',
        questionnairePages: 'Questionnaires',
        CheckTheSectionsToBePrinted: 'Check the sections to be printed',
        otherPrintOptions: 'Other Print Options',
        printButtonEnable: 'Enable/Disable Print Funtionality',
        displayBlankFields: 'Display Blank Fields',
      },
      home: {},
      nav: {
        preferences: 'Preferences',
        dashboard:  'Dashboard'
      },
      patients: {
        addNewPatient: 'Add New Patient',
        name: 'Name',
        branch: 'Branch',
        procedureDate: 'Procedure Date',
        admissionDate: 'Admission Date',
        dischargeDate: 'Discharge Date',
        uhid: 'UHID',
        OPIPType: 'OPIPType',
        OPIPId: 'OPIPId',
        department: 'Department',
        email: 'Email',
        phone: 'Phone',
        alternatePhone: 'Alternate Phone',
        address: 'Address',
        state: 'State',
        city: 'City',
        zipcode: 'Zipcode',
        country: 'Country',
        age: 'Age',
        gender: 'Gender',
      },
      procedureTypes: {
        name: 'Name',
        department: 'Department',
        questionnaires: 'Questionnaires',
      },
      questionnaires: {
        addNew: 'Add New',
        name: 'Name',
        code: 'Code',
        group: 'Group',
        content: 'Content',
        followup: 'Followup Template',
        isApproved:'Is Approved',
        supplementary: 'Is Supplementary',
      },
      procedureView: {
        status: 'Status',
        remarks: 'Remarks',
      },
      groups: {
        name: 'Name',
        users: 'Users',
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    debug: true,

    //keySeparator: true, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
