import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useLocations from 'shared/hooks/useLocations';
import usePatientById from 'shared/hooks/Patients/usePatientById';
import PatientInfoForm from './PatientForm';
import { PATIENT_ADMISSION_ADD } from 'shared/constants/RouteConstants';
import usePatientsUpdate from 'shared/hooks/Patients/usePatientsUpdate';
import { notification, Skeleton } from 'antd';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';

type Props = {}

const PatientEdit: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const RouteParams: any = useParams();
    const location = useLocations();
    const { patient, loading } = usePatientById(RouteParams['id'], true);

    const { mutation, saving, patient: savedPatient, error } = usePatientsUpdate((e: any) => {
        if (e) {
            notification.success({ message: 'Patient saved successfully!' });
            history.goBack();
        }
    });

    const onSubmit = (data: any) => {
        mutation({ variables: { set: data, id: patient.id } });
    };
    if (loading) {
        return <Skeleton active={true} />;
    }
    return (
        <div className={'user-group-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Patient Edit'}>
                <PatientInfoForm patient={patient} saving={saving} onCancel={() => history.goBack()}
                                 onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};


export default PatientEdit;
