import gql from 'graphql-tag';
import {useMutation} from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    procedureTypeQuestionnaire: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
mutation ($id: uuid!, $set: c_prescription_encounter_templates_set_input!, $encounter_type_id:uuid!, $template_id: uuid!) {
    update_c_prescription_encounter_templates_by_pk(pk_columns: {id: $id}, _set: $set) {
      id
    }
    update_c_prom_follow_up(where: {_and: {encounter_type_id: {_eq: $encounter_type_id}, _or: [{encounter_template_id: {_eq: $template_id}}, {followup_template_id: {_eq: $template_id}}], is_active: {_eq: true}}}, _set: {is_active: false, deleted_at: "now()"}) {
      returning {
        id
      }
    }
  }
  
`;

const useProcedureTypeQuestionnaireDelete: (onCompleted?: any) => ReturnType = (onCompleted) => {
    const [mutation, {data, loading: saving, error,}] = useMutation(MUTATION, {onCompleted: onCompleted});
    let procedureTypeQuestionnaire = null;
    if (!saving && !error && data) {
        procedureTypeQuestionnaire = data.update_c_prescription_encounter_templates_by_pk;
    }
    return {
        mutation,
        procedureTypeQuestionnaire,
        saving,
        error,
    };
};
export default useProcedureTypeQuestionnaireDelete;
