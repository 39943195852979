import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    role_permission: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
    mutation (  $id: uuid!,$set: c_acl_role_permissions_set_input!){
        update_c_acl_role_permissions_by_pk(_set: $set,pk_columns: {id: $id} ) {
            id
        }
    }
`;

const useDeleteRolePermission: (onCompleted?: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted });
    let role_permission = null;
    if (!saving && !error && data) {
        role_permission = data.update_c_acl_role_permissions_by_pk;
    }
    return {
        mutation,
        role_permission,
        saving,
        error,
    };
};
export default useDeleteRolePermission;
