import * as React from 'react';
import FormView from 'shared/components/FormView/FormView';
import { Card } from 'antd';
type Props = { id: string }
const PromPreview: React.FunctionComponent<Props> = (props: any) => {
    return <Card>
        {props?.id && <FormView prescriptionId={props?.id}></FormView>}
    </Card>
}

export default PromPreview