import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    user: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
    mutation (  $object: c_org_users_insert_input!){
        insert_c_org_users_one(object: $object) {
            id
        }
    }
`;

const useUserCreate: (onCompleted?: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted: onCompleted });
    let user = null;
    if (!saving && !error && data) {
        user = data.insert_c_org_users_one;
    }
    return {
        mutation,
        user,
        saving,
        error,
    };
};
export default useUserCreate;
