import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import AdmissionInfoForm from './PatientAdmissionForm';
import useProcedureById from 'shared/hooks/Procedures/useProcedureById';
import usePatientById from 'shared/hooks/Patients/usePatientById';
import { AuthConsumer, AuthContext } from 'shared/contexts/AuthContext';

const PatientAdmissionEdit: React.FunctionComponent<{ patientId: string }> = ({ patientId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const RoutParams: any = useParams();
    const { patient, loading } = usePatientById(RoutParams['id']);
    if (!patient) {
        return <Skeleton />;
    }

    return (
        <AuthConsumer>
            {({ user }) => (
                <AdmissionInfoForm user={user} refresh={() => {
                }} patient={patient} />
            )}
        </AuthConsumer>
    );
};
export default PatientAdmissionEdit;
