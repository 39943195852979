import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Form,
    Input,
    Col,
    Row,
    Button, Space, Skeleton, Select, List, Tooltip, Divider,
} from 'antd';
import { useEffect, useState } from 'react';
import useDepartments from 'shared/hooks/Departments/useDepartments';

type Props = {
    onSubmit: any
    saving?: boolean
    loading?: boolean
    onCancel?: any
    refresh?: any
}

const TablePermissionForm: React.FunctionComponent<Props> = ({
                                                      onSubmit,
                                                      saving,
                                                      loading,
                                                      onCancel,
                                                      refresh,
                                                  }) => {
    const { t } = useTranslation();
    const [fields]: any[] = useState([]);
    const [form] = Form.useForm();
    const { departments, loading: loadingDepartments } = useDepartments();
    if (loading) {
        return <Skeleton active={true} />;
    }
    return (
        <div className={'tablePermission-create-or-edit-page'}>
            <Form
                size='large'
                form={form}
                fields={fields}
                onFinish={async (formValues) => {
                    onSubmit(formValues);
                }}
                requiredMark={true}
                layout='vertical'

            >
                <Row gutter={[20, 20]}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Name'}
                            name={'name'}
                            rules={[
                                { required: true, message: 'Name required' },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Department'}
                            name={'department_id'}

                            rules={[
                                { required: true, message: 'Department required' },
                            ]}>
                            <Select
                                loading={loadingDepartments}
                                showSearch={true}
                                filterOption={
                                    (input, option: any) => {
                                        if (option) {
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }
                                        return false;
                                    }
                                }
                            >
                                {
                                    departments.map((department: any) => {
                                        return <Select.Option
                                            value={department.id}>{`${department.name}`}</Select.Option>;
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Divider />
                        <Space>
                            <Form.Item>
                                <Button type='default'
                                        onClick={onCancel}
                                        disabled={saving}
                                        size={'large'}>
                                    {t('genDict.cancel')}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary'
                                        htmlType='submit'
                                        loading={saving}
                                        size={'large'}>
                                    {t('genDict.submit')}
                                </Button>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};


export default TablePermissionForm;
