import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Typography, Spin } from 'antd';
import usePatientById from '../../hooks/Patients/usePatientById';
import moment from 'moment';
import { getEpisodeType } from '../../services/Utility';

const { Text } = Typography;
const PatientTitleBar: React.FunctionComponent<{ patientId: string, procedure?: any }> = ({ patientId, procedure }) => {
    const { t } = useTranslation();
    const { patient, loading } = usePatientById(patientId);
    if (!patient) {
        return <></>;
    }
    if (loading) {
        return <Spin />;
    }
    return (
        <Card>
            <Row gutter={[20, 20]} justify={'space-between'}>
                <Col md={4} sm={12} xs={24}>
                    <div>
                        <Text>{patient.first_name || 'NA'} {patient.last_name || ''}</Text>,
                    </div>
                    <Text type={'secondary'}>{patient.age} years, {patient.gender}, {patient.phone}</Text>
                </Col>
                <Col md={5} sm={12} xs={24}>
                    <div>
                        <Text>UHID</Text>
                    </div>
                    <Text type={'secondary'}>{patient.uhid}</Text>
                </Col>
                <Col md={5} sm={12} xs={24}>

                    <div>
                        <Text>Episode ID</Text>
                    </div>
                    <Text type={'secondary'}>{getEpisodeType(procedure.type)}: {procedure.type_id}</Text>
                </Col>

                {
                    procedure && <>
                        <Col md={5} sm={12} xs={24}>

                            <div>
                                <Text>Primary Doctor</Text>
                            </div>
                            <Text type={'secondary'}>{procedure.primary_doctor?.name}</Text>
                        </Col>
                        <Col md={5} sm={12} xs={24}>

                            <div>
                                <Text>Performing Doctor</Text>
                            </div>
                            <Text type={'secondary'}>{procedure.performing_doctor?.name}</Text>
                        </Col>

                    </>
                }

            </Row>
            {
                procedure && <Row gutter={[20, 20]} style={{ marginTop: '20px' }} justify={'space-between'}>
                    {
                        procedure.department && <Col md={4} sm={12} xs={24}>

                            <div>
                                <Text>Department</Text>
                            </div>
                            <Text type={'secondary'}>{procedure.department?.name}</Text>
                        </Col>
                    }
                    <Col md={5} sm={12} xs={24}>
                        <div>
                            <Text>Admission/Visit Date</Text>
                        </div>
                        <Text
                            type={'secondary'}>{moment(procedure.admission_date || procedure.visit_date).format('LL')}</Text>
                    </Col>
                    <Col md={5} sm={12} xs={24}>
                        <div>
                            <Text>Procedure Date</Text>
                        </div>
                        <Text
                            type={'secondary'}>{procedure.start_date ? moment(procedure.start_date).format('LL') : 'NA'}</Text>
                    </Col>
                    <Col md={5} sm={12} xs={24}>
                        <div>
                            <Text>Discharge Date</Text>
                        </div>
                        <Text
                            type={'secondary'}>{procedure.discharge_date ? moment(procedure.discharge_date).format('LL') : (procedure.admission_date ? '-' : 'NA')}</Text>
                    </Col>
                    <Col md={5} sm={12} xs={24}>
                        <div>
                            <Text>Email</Text>
                        </div>
                        <Text type={'secondary'}>{patient.email}</Text>
                    </Col>
                </Row>
            }
        </Card>
    );
};
export default PatientTitleBar;

export const PatientPrintTitleBar: React.FunctionComponent<{ patientId: string, procedure?: any }> = ({ patientId, procedure }) => {
    const { t } = useTranslation();
    const { patient, loading } = usePatientById(patientId);
    if (!patient) {
        return <></>;
    }
    if (loading) {
        return <Spin />;
    }
    return (
        <Card>
            <Row gutter={[20, 20]} justify={'space-between'}>
                <Col md={6} sm={12} xs={24}>
                    <div>
                        <Text>{patient.first_name || 'NA'} {patient.last_name || ''}</Text>,
                    </div>
                    <Text type={'secondary'}>{patient.age} years, {patient.gender}, {patient.phone}</Text>
                </Col>
                <Col md={6} sm={12} xs={24}>
                    <div>
                        <Text>UHID</Text>
                    </div>
                    <Text type={'secondary'}>{patient.uhid}</Text>
                </Col>
                <Col md={6} sm={12} xs={24}>

                    <div>
                        <Text>Episode ID</Text>
                    </div>
                    <Text type={'secondary'}>{getEpisodeType(procedure.type)}: {procedure.type_id}</Text>
                </Col>

                {
                    procedure && <>
                        <Col md={6} sm={12} xs={24}>

                            <div>
                                <Text>Primary Doctor</Text>
                            </div>
                            <Text type={'secondary'}>{procedure.primary_doctor?.name}</Text>
                        </Col>
                        

                    </>
                }

            </Row>
            {
                procedure && <Row gutter={[20, 20]} style={{ marginTop: '20px' }} justify={'space-between'}>
                    {
                        procedure.department && <Col md={4} sm={12} xs={24}>

                            <div>
                                <Text>Department</Text>
                            </div>
                            <Text type={'secondary'}>{procedure.department?.name}</Text>
                        </Col>
                    }
                    <Col md={6} sm={12} xs={24}>

                        <div>
                            <Text>Performing Doctor</Text>
                        </div>
                        <Text type={'secondary'}>{procedure.performing_doctor?.name}</Text>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                        <div>
                            <Text>Admission/Visit Date</Text>
                        </div>
                        <Text
                            type={'secondary'}>{moment(procedure.admission_date || procedure.visit_date).format('LL')}</Text>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                        <div>
                            <Text>Procedure Date</Text>
                        </div>
                        <Text
                            type={'secondary'}>{procedure.start_date ? moment(procedure.start_date).format('LL') : 'NA'}</Text>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                        <div>
                            <Text>Discharge Date</Text>
                        </div>
                        <Text
                            type={'secondary'}>{procedure.discharge_date ? moment(procedure.discharge_date).format('LL') : (procedure.admission_date ? '-' : 'NA')}</Text>
                    </Col>

                   
                </Row>
            }
        </Card>
    );
};
