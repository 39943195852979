import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useDebounce from 'shared/hooks/useDebounce';
import { GlobalListLimit, ListPagination } from 'shared/constants/AppConstants';
import Text from 'antd/es/typography/Text';
import { Button, Col, DatePicker, Divider, Row, Select, Space, Table, Tooltip } from 'antd';
import { hasPermissionOf } from 'shared/services/Utility';
import { USER_GROUPS_UPDATE_ONE, USER_ROLE_ACCOUNTS_UPDATE_ONE } from 'shared/constants/Permissions';
import moment from 'moment';
import { DeleteFilled } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import useGroups from 'shared/hooks/Groups/useGroups';
import useUserGroups from 'shared/hooks/UserGroups/useUserGroups';
import useDeleteUserGroups from 'shared/hooks/UserGroups/useDeleteUserGroups';
import useAddUserGroups from 'shared/hooks/UserGroups/useAddUserGroups';

const UserGroups = (
    {
        userId,
    }
        :
        {
            userId: string,
        },
    ) => {
        const { t } = useTranslation();
        const { groups, loading: loadingGroups, refetch: refreshGroups } = useGroups();
        const [search, setSearch] = useState('' as string);
        const [selectedGroup, setSelectedGroup] = useState(null as any);
        const debouncedSearch = useDebounce(search, 500);
        const [pagination, setPagination] = useState({ limit: 1000, offset: 0 } as ListPagination);

        useEffect(() => {
            setSearch('');
        }, [userId]);

        const {
            userGroups,
            loading: loadingUserGroups,
            refetch: refreshUserGroups,
            total,
        } = useUserGroups({ user_id: userId });
        useEffect(() => {
            refreshGroups({ 'notIds': userGroups.map((a: any) => a.group_id), search, ...pagination });
        }, [debouncedSearch, pagination, UserGroups]);
        const {
            mutation: deleteUserGroupMutation,
            saving: deletingUserGroup,
        } = useDeleteUserGroups((e: any) => {
            if (e) {
                refreshUserGroups({ user_id: userId });
            }
        });
        const {
            mutation: addUserGroupMutation,
            saving: addingUserGroup,
        } = useAddUserGroups((e: any) => {
            if (e) {
                refreshUserGroups({ user_id: userId });
                setSelectedGroup(null);
            }
        });

        const columns = [
            {
                title: '#',
                key: 'sNo',
                width: 100,
                render: (a: any, value: any, index: number) => <Text>{index + 1}</Text>,
            },
            {
                title: 'Name',
                dataIndex: ['group', 'name'],
            },
            {
                fixed: 'right' as 'right',
                width: 100,
                title: 'Action',
                key: 'action',
                render: (a: any) => {
                    return <Row gutter={10}>
                        {
                            hasPermissionOf(USER_GROUPS_UPDATE_ONE) && <Col>
                                <Tooltip title={'Delete'}>
                                    <Button disabled={deletingUserGroup} shape='circle'
                                            onClick={() => {
                                                deleteUserGroupMutation({
                                                    variables: {
                                                        id: a.id,
                                                        set: {
                                                            deleted_by_id: userId,
                                                            deleted_at: moment(),
                                                        },
                                                    },
                                                });
                                            }}

                                            icon={<DeleteFilled />}
                                            size={'middle'} />
                                </Tooltip>
                            </Col>
                        }
                    </Row>;
                },
            },
        ];
        return <div>
            <Title level={5}>Groups {(userGroups.length)}</Title>
            <Select
                style={{ width: '100%' }}
                showSearch
                loading={loadingGroups}
                optionFilterProp='children'
                value={selectedGroup}
                disabled={addingUserGroup}
                onChange={(e) => {
                    setSelectedGroup(e);
                    addUserGroupMutation({
                        variables: {
                            object: {
                                user_id: userId,
                                group_id: e,
                            },
                        },
                    });

                }}
                onSearch={
                    (v) => {
                        setSearch(v);
                    }
                }
                filterOption={(input: string, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {
                    groups.filter((group: any) => {
                        let found = false;
                        userGroups.forEach((userGroup: any) => {
                            if (userGroup.group_id === group.id) {
                                found = true;
                            }
                        });
                        return !found;
                    }).map((group: any, index: number) => {
                        return <Select.Option key={index}
                                              value={group.id}>{group.name}</Select.Option>;
                    })
                }
            </Select>
            <Divider />
            <Table
                locale={{
                    emptyText: (
                        <>
                            <Space direction={'vertical'} size={10} style={{ padding: '10px' }}>
                                <Title level={3} className={'text-primary'}>{t('genDict.woops')}</Title>
                                <Text className={'text-primary'}>No Group added</Text>
                            </Space>
                        </>
                    ),
                }}
                pagination={{
                    pageSize: pagination.limit,
                    total,
                    hideOnSinglePage: true,
                    onChange: (newPage: number) => {
                        setPagination({ ...pagination, offset: (newPage - 1) * pagination.limit });
                    },
                    current: (pagination.offset / pagination.limit) + 1,
                }} scroll={{ x: true }}
                dataSource={userGroups}
                loading={loadingUserGroups}
                columns={columns} />
        </div>;
    }
;
export default UserGroups;
