import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type ReturnType = {
    mutation: any;
    userGroup: any;
    saving: boolean;
    error: any;
};

const MUTATION = gql`
    mutation ($object: c_org_user_groups_insert_input!){
        insert_c_org_user_groups_one(object: $object) {
            id
        }
    }
`;

const useAddUserGroups: (onCompleted?: any) => ReturnType = (onCompleted) => {
    const [mutation, { data, loading: saving, error }] = useMutation(MUTATION, { onCompleted });
    let userGroup = null;
    if (!saving && !error && data) {
        userGroup = data.insert_c_org_user_groups_one;
    }
    return {
        mutation,
        userGroup,
        saving,
        error,
    };
};
export default useAddUserGroups;
