import * as React from 'react';
import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { notification, Row, Col, Typography } from 'antd';
import Parameters from 'shared/parameters';
import SignupForm from './SignupForm';

import '../Login.scss';
import { useTranslation } from 'react-i18next';

import { AuthConsumer } from 'shared/contexts/AuthContext';
import API_SERVICE from '../../../services/api-service';

interface Props {
    location: any;
}

const { Title } = Typography;

export default function Signup({ location }: Props) {
    const { t } = useTranslation();
    const [fields, setFields] = useState([] as any[]);
    const [loading, setLoading] = useState(false);

    const { from } = location.state || { from: { pathname: '/' } };

    return (
        <AuthConsumer>
            {({ authStatus, updateAuthToken }) =>
                authStatus === 'AUTHENTICATED' ? (
                    <Redirect to={from} />
                ) : (
                    <Row gutter={20} className='login'>
                        <Col flex={'auto'} />
                        <Col flex={'400px'} className='login-form-wrapper'>
                            <Title level={2}>{Parameters.clientName}</Title>
                            <SignupForm
                                onChange={setFields}
                                fields={fields}
                                onRegister={async (d: any) => {
                                    setLoading(true);
                                    try {
                                        const { data } = await API_SERVICE.register({ ...d, type: 'student' });
                                        updateAuthToken(data.data);
                                    } catch (e) {
                                        notification.error({
                                            message: t(API_SERVICE.handleErrors(e)),
                                        });
                                    }
                                    setLoading(false);
                                }}
                                loading={loading}
                            />
                            <Row gutter={20}>
                                <Col>
                                    <Link to='/login'>
                                        {t('genDict.login')}
                                    </Link>
                                </Col>
                                <Col>
                                    <Link to='/reset-password'>
                                        {t('genDict.resetPassword')}
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            }
        </AuthConsumer>
    );
}
