import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
  prescription: any;
  loading: boolean;
  error: any;
  refetch: any;
};

const QUERY = gql`
  query($id: uuid!) {
    c_prescription_prescriptions_by_pk(id: $id) {
      id
      created_at
      data{
        data
      }
      template{
        id
        code
        content{
          data
        }
        name
      }
      review{
        id
        latest_editor_status{
          status
          latest
        }
        latest_reviewer_status{
          status
          latest
        }
        editor_notes(limit: 1, order_by: [{created_at: desc}]){
          remarks
          status
          id
          latest
          created_by{
            name
          }
          created_at
        }
        reviewer_notes(limit: 1, order_by: [{created_at: desc}]){
          remarks
          status
          id
          latest
          created_by{
            name
          }
          created_at
        }
      }
    }
  }
`;

const usePrescriptionById: (id: string) => ReturnType = (id) => {
  const { data, loading, error, refetch } = useQuery(QUERY, { variables: { id } });
  let prescription = null;
  if (!loading && !error && data) {
    prescription = data.c_prescription_prescriptions_by_pk;
    if (prescription?.review['editor_notes']) {
      prescription.review['latest_editor_note'] = prescription.review['editor_notes'][0];
    }
    if (prescription?.review['reviewer_notes']) {
      prescription.review['latest_reviewer_note'] = prescription.review['reviewer_notes'][0];
    }
  }
  return {
    prescription,
    loading,
    refetch,
    error,
  };
};
export default usePrescriptionById;
