import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
    notification,
} from 'antd';
import useLocations from 'shared/hooks/useLocations';
import LocationForm from './LocationForm';
import useLocationCreate from 'shared/hooks/Locations/useLocationCreate';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';

type Props = {}

const LocationCreate: React.FunctionComponent<Props> = () => {
    const history = useHistory();
    const location = useLocations();
    const { mutation, saving } = useLocationCreate((e: any) => {
        if (e) {
            notification.success({ message: 'Created successfully' });
            history.goBack();
        }
    });
    const onSubmit = (object: any) => {
        mutation({ variables: { object } });
    };
    return (
        <div className={'location-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Location Create'}>
                <LocationForm  saving={saving} onCancel={() => history.goBack()} onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};


export default LocationCreate;
