import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Skeleton,
} from 'antd';
import { useEffect, useState } from 'react';
import useLocations from 'shared/hooks/useLocations';
import useGroupsCreate from 'shared/hooks/Groups/useGroupsCreate';
import ProcedureTypeForm from './ProcedureTypeForm';
import useProcedureTypeCreate from 'shared/hooks/ProcedureTypes/useProcedureTypeCreate';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';

type Props = {}

const ProcedureTypeCreate: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocations();
    const { mutation, saving, procedureType } = useProcedureTypeCreate((e: any) => {
        if (e) {
            history.goBack();
        }
    });
    const onSubmit = (data: any) => {
        mutation({ variables: data });
    };
    return (
        <div className={'user-group-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Procedure Type Create'}>
                <ProcedureTypeForm saving={saving} onCancel={() => history.goBack()} onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};


export default ProcedureTypeCreate;
