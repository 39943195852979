import React from 'react';
import JSONPretty from 'react-json-pretty';
import { DemoJson } from '../Constants/DemoJson';
import ID from '../Utils/UUID';
import { Row, Col } from 'antd';

export default class PasteJsonComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            json: '',
            isJsonValid: true
        }

    }

    shouldComponentUpdate(nextProps) {
        return nextProps.activeKey === 'pasteJson';
    }

    onSubmit = (e) => {
        let json = JSON.parse(this.state.json);
        json['sections'].forEach((section, idx) => {
            if (!section.id) {
                json['sections'][idx] = {
                    ...section,
                    id: ID.uuid()
                }
            }
        });
        this.props.onPastedJsonEmit(json);
    }

    checkJSONValidity = (e) => {
        try {
            let json = JSON.parse(e.target.value);
            let isJsonValid = !!json.hasOwnProperty('sections');
            this.setState({ json: e.target.value, isJsonValid: isJsonValid });
        } catch (error) {
            this.setState({ json: e.target.value, isJsonValid: false });
        }
    }

    render() {
        let isDisabled = !this.state.json || !this.state.isJsonValid;
        return (
            <>
                <Row>
                    <Col span={12}>
                        <div>
                            <h4 className="card-title">Paste Json</h4>
                        </div>
                        <textarea 
                        style={{width:'90%'}}
                        type="text" placeholder="Paste your JSON here"
                            className="form-control"
                            name="json"
                            rows="18"
                            value={this.state.json} onChange={this.checkJSONValidity.bind(this)}
                        />
                        {this.state.json && !this.state.isJsonValid && <small className="text-danger error-text">*Invalid JSON. Please refer required format.</small>}

                    </Col>
                    <Col span={12}>
                        <div className="col-md-5 form-group mb-3">
                            <p style={{ marginBottom: '0.5em' }}>*Required Format</p>
                            <div style={{ height: '265px', overflow: 'auto' }}>
                                <JSONPretty id="json-pretty" data={DemoJson}></JSONPretty>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <button disabled={isDisabled} className={isDisabled ? 'btn btn-outline-secondary btn-rounded' : 'btn btn-success btn-rounded'} onClick={this.onSubmit.bind(this)}>
                        See Form!
                    </button>
                </Row>
            </>
        );
    }
}
