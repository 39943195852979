import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    Form,
    Input,
    Col,
    Row,
    Button, Space, Skeleton, Select, List, Tooltip, Divider, Checkbox,
} from 'antd';
import { useEffect, useState } from 'react';
import useBranches from 'shared/hooks/Branches/useBranches';
import TextArea from 'antd/lib/input/TextArea';
import Title from 'antd/es/typography/Title';
import { DeleteFilled } from '@ant-design/icons';
import Text from 'antd/es/typography/Text';
import useDeleteRolePermission from 'shared/hooks/Roles/useDeleteRolePermission';
import useAddRolePermission from 'shared/hooks/Roles/useAddRolePermission';
import usePermissions from 'shared/hooks/Roles/usePermissions';
import moment from 'moment';
import { AuthConsumer } from 'shared/contexts/AuthContext';
import useDebounce from 'shared/hooks/useDebounce';

type Props = {
    role?: any
    onSubmit: any
    saving?: boolean
    loading?: boolean
    onCancel?: any
    refresh?: any
}

const RoleForm: React.FunctionComponent<Props> = ({
                                                      onSubmit,
                                                      saving,
                                                      role,
                                                      loading,
                                                      onCancel,
                                                      refresh,
                                                  }) => {
        const { t } = useTranslation();
        const [fields]: any[] = useState([]);
        const [form] = Form.useForm();
        const [showAllowedOnly, setShowAllowedOnly] = useState(false);
        const [search, setSearch] = useState('' as string);
        const debouncedSearch = useDebounce(search, 500);
        const { permissions, loading: loadingPermissions, refetch: refreshPermissions } = usePermissions();
        const [selectedPermission, setSelectedPermission] = useState(null as any);
        const { branches, loading: loadingBranches } = useBranches();
        const {
            mutation,
            saving: savingRolePermissions,
        } = useAddRolePermission((e: any) => {
            refresh();
            setSelectedPermission(null);
        });
        const {
            mutation: deleteRolePermissionMutation,
            saving: deletingRolePermission,
        } = useDeleteRolePermission((e: any) => {
            refresh();
        });
        const getRolePermissionId = (permissionId: string) => {
            let result = '';
            role.role_permissions.forEach((rp: any) => {
                if (rp.permission_id === permissionId) {
                    result = rp.id;
                }
            });
            return result;
        };
        if (loading) {
            return <Skeleton active={true} />;
        }
        return (
            <div className={'role-create-or-edit-page'}>
                <Row gutter={20}>
                    <Col md={12} sm={24} xs={24}>
                        <Form
                            size='large'
                            form={form}
                            fields={fields}
                            onFinish={async (formValues) => {
                                onSubmit(formValues);
                            }}
                            initialValues={role || {}}
                            requiredMark={true}
                            layout='vertical'

                        >
                            <Row gutter={[20, 20]}>
                                <Col span={24}>
                                    <Form.Item
                                        label={'Name'}
                                        name={'name'}
                                        rules={[
                                            { required: true, message: 'Name required' },
                                        ]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={'Description'}
                                        name={'description'}
                                        rules={[]}>
                                        <TextArea rows={2} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Divider />
                                    <Space>
                                        <Form.Item>
                                            <Button type='default'
                                                    onClick={onCancel}
                                                    disabled={saving}
                                                    size={'large'}>
                                                {t('genDict.cancel')}
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type='primary'
                                                    htmlType='submit'
                                                    loading={saving}
                                                    size={'large'}>
                                                {t('genDict.submit')}
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    {
                        role && <Col md={12} sm={24} xs={24}>
                            <Row gutter={10} justify={'space-between'}>
                                <Col>
                                    <Title level={5}>Permissions {(role.role_permissions.length)} </Title>
                                </Col>
                                <Col>
                                    <Checkbox checked={showAllowedOnly}
                                              onChange={(e) => setShowAllowedOnly(e.target.checked)}>Show Allowed
                                        Only</Checkbox>
                                </Col>
                            </Row>
                            {/*<Select*/}
                            {/*    showSearch*/}
                            {/*    style={{ width: '100%' }}*/}
                            {/*    loading={loadingPermissions}*/}
                            {/*    placeholder='Select a Permission'*/}
                            {/*    optionFilterProp='children'*/}
                            {/*    onSearch={*/}
                            {/*        (v) => {*/}
                            {/*            setSearch(v);*/}
                            {/*        }*/}
                            {/*    }*/}
                            {/*    value={selectedPermission}*/}
                            {/*    onChange={(e, a: any) => {*/}
                            {/*        setSelectedPermission(e);*/}
                            {/*        mutation({ variables: { object: { permission_id: e, role_id: role.id } } });*/}
                            {/*    }}*/}
                            {/*    filterOption={(input: string, option: any) =>*/}
                            {/*        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
                            {/*    }*/}
                            {/*>*/}
                            {/*    {*/}
                            {/*        permissions.filter((d: any) => {*/}
                            {/*            let found = false;*/}
                            {/*            role.role_permissions.forEach((a: any) => {*/}
                            {/*                if (a.permission.id === d.id) {*/}
                            {/*                    found = true;*/}
                            {/*                }*/}
                            {/*            });*/}
                            {/*            return !found;*/}
                            {/*        }).map((permission: any, index: number) => {*/}
                            {/*            return <Select.Option key={index}*/}
                            {/*                                  value={permission.id}>{permission.name}</Select.Option>;*/}
                            {/*        })*/}
                            {/*    }*/}
                            {/*</Select>*/}

                            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                <AuthConsumer>
                                    {({ user }) => {
                                        return <Space direction={'vertical'}>
                                            {
                                                permissions.map((permission: any) => {
                                                    if (showAllowedOnly && !(role.permission_ids && role.permission_ids.indexOf(permission.id) > -1)) {
                                                        return <></>;
                                                    }
                                                    return <Checkbox
                                                        disabled={savingRolePermissions || deletingRolePermission}
                                                        checked={role.permission_ids && role.permission_ids.indexOf(permission.id) > -1}
                                                        onChange={(e: any) => {
                                                            if (e.target.checked) {
                                                                mutation({
                                                                    variables: {
                                                                        object: {
                                                                            permission_id: permission.id,
                                                                            role_id: role.id,
                                                                        },
                                                                    },
                                                                });
                                                            } else {
                                                                deleteRolePermissionMutation({
                                                                    variables: {
                                                                        id: getRolePermissionId(permission.id),
                                                                        set: {
                                                                            deleted_by_id: user.id,
                                                                            deleted_at: moment(),
                                                                        },
                                                                    },
                                                                });
                                                            }
                                                        }
                                                        }>{permission.name}</Checkbox>;
                                                })
                                            }
                                        </Space>;
                                    }}
                                </AuthConsumer>
                            </div>
                        </Col>
                    }
                </Row>
            </div>
        );
    }
;


export default RoleForm;
