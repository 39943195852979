import * as React from 'react';
import { useState, useRef } from 'react';
import { Collapse, Row, Col, Space, Select, Table, Typography, Popconfirm, InputNumber, Button, Divider, notification } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import useFollowUpCreate from 'shared/hooks/prom/useAddFollowup';
import useGetFollowUp from 'shared/hooks/prom/useGetFollowup';
import useUpdateFollowUp from 'shared/hooks/prom/useUpdateFollowup';
import useDeleteFollowUp from 'shared/hooks/prom/useDeleteFollowup';
import { useParams } from 'react-router';


const { Panel } = Collapse;
const { Option } = Select;
const { Title } = Typography;


type Props = { procedureTypeQuestionnaires: any }
const FollowUpEdit: React.FunctionComponent<Props> = ({
    procedureTypeQuestionnaires
}) => {
    let template_arr: any = []
    const RouteParams: { id: string, mode: 'edit' } = useParams();
    let encounter_type_id = RouteParams.id
    let [main_temp_id, set_main_temp_id] = useState(null as any);
    let [follow_temp_id, set_follow_temp_id] = useState(null as any);
    let [follow_up_day, set_follow_up_day] = useState(null as any);
    let [selected_follow_up_id, set_selected_follow_up_id] = useState(null as any);

    let followUpDayRef: any = useRef(null);
    // GET EXISTING FOLLOWUP DATA
    let { followUpData, refresh } = useGetFollowUp(encounter_type_id)
    console.log(followUpData)
    // SAVE NEW FOLLOWUP
    let { mutation } = useFollowUpCreate((res: any) => {
        console.log(res)
        if (res.insert_c_prom_follow_up_one) {
            notification.success({ message: 'Follow Up added successfully' });
            clearData()
            refresh().then((res: any, d: any) => {
                followUpData = res.data
            })
        }
    })
    // UPDATE FOLLOWUP
    let { mutation: updateFollowup } = useUpdateFollowUp((res: any) => {
        if (res.update_c_prom_follow_up_by_pk) {
            notification.success({ message: 'Follow Up updated successfully' });
            clearData()
            refresh().then((res: any, d: any) => {
                followUpData = res.data
            })
        }

    })
    // DELETE FOLLOWUP
    let { mutation: deleteFollowup } = useDeleteFollowUp((res: any) => {
        console.log(res)
        if (res.update_c_prom_follow_up_by_pk) {
            notification.success({ message: 'Follow Up deleted successfully' });
            clearData()
            refresh().then((res: any, d: any) => {
                followUpData = res.data
            })
        }
    })

    procedureTypeQuestionnaires.forEach((ques: { template: any; }) => {
        if (ques && ques.template) {
            template_arr.push(ques.template)
        }
    });
    const handleAddUpdateFollowup = () => {
        if (!main_temp_id || !follow_temp_id || follow_up_day==undefined) {
            notification.error({ message: "Please fill all the fields" });
            if (followUpDayRef?.current) {
                followUpDayRef.current.focus();
            }
            return
        }
        let variables: any = {
            "encounter_template_id": main_temp_id,
            "followup_days": follow_up_day,
            "followup_template_id": follow_temp_id
        }

        if (selected_follow_up_id) {
            variables['id'] = selected_follow_up_id;
            updateFollowup({
                variables
            })
        } else {
            variables["encounter_type_id"] = encounter_type_id;
            mutation({
                variables
            })
        }
    }

    const handleEditFollowUp = (data: any) => {
        set_follow_up_day(data.follow_up_days);
        set_follow_temp_id(data.follow_up_template_id);
        set_main_temp_id(data.template_id);
        set_selected_follow_up_id(data.id);
        if (followUpDayRef?.current) {
            followUpDayRef.current.focus();
        }
    }

    const clearData = () => {
        set_follow_up_day(null);
        set_follow_temp_id(null);
        set_main_temp_id(null);
        set_selected_follow_up_id(null);
    }

    const handleDeleteFollowUp = (data: any) => {
        deleteFollowup({ variables: { id: data.id } })
    }

    const renderExistingFollowUp = () => {
        const columns = [
            {
                title: 'Template',
                dataIndex: 'template',
                key: 'template',
            },
            {
                title: 'Follow Up Template',
                dataIndex: 'follow_up_template',
                key: 'follow_up_template',
            },
            {
                title: 'Follow Up Days',
                dataIndex: 'follow_up_days',
                key: 'follow_up_days',
            },
            {
                title: 'Action',
                dataIndex: 'actions',
                key: 'actions',
                render: (text: any, record: any) => (
                    <Space size="middle">
                        <Button shape='circle' icon={<EditOutlined type={'middle'} />} onClick={() => { handleEditFollowUp(record) }}></Button>

                        <Popconfirm
                            title="Are you sure to delete this follow up?"
                            onConfirm={() => { handleDeleteFollowUp(record) }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button shape='circle' icon={<DeleteOutlined type={'middle'} />}></Button>
                        </Popconfirm>


                    </Space>
                )
            }]
        let table_data = []
        for (let f of followUpData) {
            table_data.push({
                template: f.encounter_template_data.name,
                template_id: f.encounter_template_data.id,
                follow_up_template: f.followup_template_data.name,
                follow_up_template_id: f.followup_template_data.id,
                follow_up_days: f.followup_days,
                id: f.id
            })
        }
        return followUpData && <Table columns={columns} dataSource={table_data} />
    }
    return (
        <Collapse >
            <Panel header="Follow Up Rules" key="1">
                <Row align="bottom">
                    <Col span={6}>
                        <Title level={5}>Template</Title>
                        <Select
                            onChange={(e) => { set_main_temp_id(e) }}
                            value={main_temp_id}
                        >
                            {
                                template_arr.map(
                                    (tmp: any) => !tmp.is_follow_up && <Option value={tmp.id}>{tmp.name}</Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col span={6}>
                        <Title level={5}>Follow Up Template</Title>

                        <Select onChange={e => set_follow_temp_id(e)} value={follow_temp_id}>
                            {
                                template_arr.map(
                                    (tmp: any) => tmp.is_follow_up && <Option value={tmp.id}>{tmp.name}</Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col span={6}>
                        <Title level={5}>Follow up days </Title>
                        <InputNumber min={0} max={10000} onChange={set_follow_up_day} value={follow_up_day} ref={followUpDayRef} />
                    </Col>
                    <Col span={5} offset={1}>
                        <Button icon={<PlusCircleOutlined shape='circle' />} size="middle" type="primary" onClick={handleAddUpdateFollowup}>

                        </Button>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        {renderExistingFollowUp()}
                    </Col>
                </Row>
            </Panel>

        </Collapse>
    )
}

export default FollowUpEdit