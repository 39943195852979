import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Form,
    Input,
    Col,
    Row,
    Button, Space, Skeleton, Select, List, Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import useBranches from 'shared/hooks/Branches/useBranches';
import Title from 'antd/es/typography/Title';
import { AuthConsumer } from '../../../../../shared/contexts/AuthContext';
import moment from 'moment';
import { DeleteFilled } from '@ant-design/icons';
import Text from 'antd/es/typography/Text';

type Props = {
    group?: any
    onSubmit: any
    saving?: boolean
    onCancel?: any
    refresh?: any
}

const GroupForm: React.FunctionComponent<Props> = ({ onSubmit, saving, group, onCancel, refresh }) => {
    const { t } = useTranslation();
    const [fields]: any[] = useState([]);
    const [form] = Form.useForm();
    return (
        <div className={'user-group-create-or-edit-page form-wrapper'}>
            <Form
                size='large'
                form={form}
                fields={fields}
                onFinish={async (formValues) => {
                    onSubmit(formValues);
                }}
                initialValues={group || {}}
                requiredMark={true}
                layout='vertical'

            >
                <Row gutter={[20, 20]} align={'bottom'}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Name'}
                            name={'name'}
                            rules={[
                                { required: true, message: 'Name required' },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space>
                            <Form.Item>
                                <Button type='default'
                                        onClick={onCancel}
                                        disabled={saving}
                                        size={'large'}>
                                    {t('genDict.cancel')}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary'
                                        htmlType='submit'
                                        loading={saving}
                                        size={'large'}>
                                    {t('genDict.submit')}
                                </Button>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};


export default GroupForm;
