import React from "react";
import ValidationComponent from "./ValidationComponent";
import { Input, Col, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import "./tooltip.css"
const { TextArea } = Input;

class TextareaComponent extends React.Component {

    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    changeValue = (event) => {
        event.preventDefault();
        this.props.emitTextboxValue({ name: event.target.name, value: event.target.value })
    }

    onAddButtonClicked = (event) => {
        this.props.emitAddNewRowObjectData(true);
    }

    onDeleteButtonClicked = (event) => {
        this.props.emitDeleteRowObjectData(true);
    }

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str) {
        if (!str || !str.trim()) {
            return false;
        }
        return true
    }

    onFocus = (event) => {
        event.preventDefault();
        this.props.emitFocusEvent()
    }

    render() {
        const { type, name, value, placeholder, label, labelSize,info,
            size, offset, rowType, showType, showUnit, viewType, validation } = this.props;
        return (
            // .2rem 1rem // 5px 0px
            <>
                {labelSize &&
                    <>
                        <div className={`ant-col ant-col-${labelSize * 2}`}
                            style={{ textAlign: "left", paddingRight: 10, display: this.checkForLabelValue(label) ? '' : 'none' }}>
                            <label for="basic_username" className="ant-form-item" style={{ color: "#000000d9" }}>
                                {label} {validation && validation.required &&
                                    <span style={{ color: '#ff4d4f' }}>*</span>
                                }
                            </label>
                        </div>
                    </>
                }
                <>
                    {!labelSize &&
                    <div className={`ant-col ant-col-${labelSize ? "-" + labelSize * 2 : ''} ${showType === "table" ? 'p-0' : ''}`}>
                        <label className="ant-form-item" style={{ display: this.checkForLabelValue(label) ? '' : 'none', color: "#000000d9" }}>
                            {label}{showUnit && <sub style={{ bottom: 0, marginLeft: '3px' }}>({showUnit})</sub>}{labelSize}</label>
                    </div>
                    }
                    <div className={`ant-col ant-col${size ? "-" + size * 2 : ''}`}>
                    {viewType ? (
                        <>
                            {value}
                        </>
                    ) : (
                        <TextArea
                            className="form-control"
                            name={name}
                            type={type}
                            value={value}
                            onFocus={this.onFocus}
                            placeholder={placeholder ? placeholder : showUnit}
                            onChange={this.changeValue}
                            suffix={<>{showUnit && <span>{showUnit}</span>}</>}
                            prefix={
                                <>
                                    {info &&
                                        <Tooltip title={info}>
                                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                    }
                                </>
                            } >
                        </TextArea>

                    )}

                    </div>
                    

                    {this.props.validation && <ValidationComponent value={value} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)} />}
                </>
                {offset &&
                    <div className={offset}> </div>
                }
            </>
        );
    }
}

export default TextareaComponent;
