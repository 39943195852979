const routes = [{
    type: 'single',
    route: `templates`,
    navbarName: "Templates",
    key: "templates",
    icon: "",
    display: true,
    component: ''
},
{
    type: 'single',
    route: `create-template`,
    navbarName: "Create New Template",
    key: "create-template",
    display: true,
    icon: "",
    component: ''
}
];


export default routes;