import * as React from 'react';
import { Form, Input, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface Props {
    fields: any[];
    onChange: (fields: any[]) => void;
    onRegister: (d: any) => void;
    loading: boolean;
}

export default function SignupForm({
                                       fields,
                                       onChange,
                                       onRegister,
                                       loading,
                                   }: Props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();


    function handleSubmit(values: any) {
        if (onRegister) {
            onRegister(values);
        }
    }

    return (
        <Form
            size="large"
            form={form}
            fields={fields}
            onFieldsChange={(changedFields, allFields) => {
                onChange(allFields);
            }}
            onFinish={handleSubmit}
            layout="vertical"
            requiredMark={false}
        >
            <Form.Item
                label={t('register.name.label')}
                name="name"
                rules={[
                    { required: true, message: t('register.name.required') },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label={t('register.email.label')}
                name="email"
                rules={[
                    {
                        required: true, type: 'email',
                        message: t('register.email.required'),
                    },
                ]}
            >
                <Input type={'email'}/>
            </Form.Item>
            <Form.Item
                label={t('register.phone.label')}
                name="phone"
                rules={[
                    {
                        required: true,
                        message: t('register.phone.required'),
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label={t('register.gender.label')}
                name="gender"
                rules={[
                    {
                        required: true,
                        message: t('register.gender.required'),
                    },
                ]}
            >
                <Select placeholder={t('genDict.select')}>
                    {
                        ['male', 'female'].map((a) => {
                            return <Select.Option value={a}>
                                {
                                    t('genDict.' + a)
                                }
                            </Select.Option>;
                        })
                    }
                </Select>
            </Form.Item>
            <div className="__password">
                <Form.Item
                    label={t('register.password.label')}
                    name="password"
                    rules={[
                        { required: true, message: t('register.password.required') },
                    ]}
                >
                    <Input
                        onChange={() => {
                            form.validateFields(['password_confirmation']);
                        }}
                        type="password"
                    />
                </Form.Item>
            </div>
            <div className="__password">
                <Form.Item
                    label={t('register.password_confirmation.label')}
                    name="password_confirmation"
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value) {
                                    return Promise.reject(
                                        t('register.password.required'),
                                    );
                                }
                                if (value && getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    t('register.password.unmatched'),
                                );
                            },
                        }),
                    ]}
                >
                    <Input
                        type="password"
                    />
                </Form.Item>
            </div>
            <br/>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    shape="round"
                    size={'large'}
                    className="submit"
                >
                    {t('register.connect')}
                </Button>
            </Form.Item>
        </Form>
    );
}
