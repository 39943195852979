import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useLocations from 'shared/hooks/useLocations';
import usePatientsRegister from 'shared/hooks/Patients/usePatientsRegister';
import { PATIENT_ADMISSION_ADD, PATIENT_REGISTRATION_LIST_ROUTE } from 'shared/constants/RouteConstants';
import { LeftOutlined } from '@ant-design/icons';
import PatientInfoForm from './PatientForm';
import FormViewDesktop from 'shared/components/layout/Desktop/FormView/FormViewDesktop';
import { Card, notification } from 'antd';
import { inWords } from 'shared/services/Utility';

type Props = {}

const PatientCreate: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const RouteParams: any = useParams();
    const location = useLocations();

    const { mutation, saving, patient: savedPatient, error } = usePatientsRegister((e: any) => {
        if (e) {
            notification.success({ message: 'Patient added successfully!' });
            history.push(PATIENT_REGISTRATION_LIST_ROUTE);
        }
    });


    const onSubmit = (data: any) => {
        mutation({ variables: { object: data } });
    };
    return (
        <div className={'user-group-create-or-edit-page page-wrapper'}>
            <FormViewDesktop title={'Patient Register'}>
                <PatientInfoForm saving={saving} onCancel={() => history.goBack()} onSubmit={onSubmit} />
            </FormViewDesktop>
        </div>
    );
};


export default PatientCreate;
