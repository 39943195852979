import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Filters } from '../../services/Utility';
import { GlobalListLimit } from '../../constants/AppConstants';

type ReturnType = {
    locations: any[];
    loading: boolean;
    error: any;
    total: number;
    refetch: any;
};

const QUERY = gql`
    query ($where: c_meta_locations_bool_exp, $limit: Int, $offset: Int){
        c_meta_locations(where: $where, limit: $limit, offset: $offset) {
            city
            city_code
            state
            state_code
            country
            country_code
            created_at
            id
        }
        c_meta_locations_aggregate(where: $where){
            aggregate{
                count
            }
        }
    }
`;

const createFilters = (filters?: Filters) => {
    const result: any = { where: { _and: [] }, limit: GlobalListLimit, offset: 0 };
    if (!filters) {
        return result;
    }
    if (filters['search']) {
        result['where']['_and'].push({
            '_or': [
                { state: { _ilike: `%${filters['search']}%` } },
                { city: { _ilike: `%${filters['search']}%` } },
                { country: { _ilike: `%${filters['search']}%` } },
            ],
        });
    }
    if (filters['state_code']) {
        result['where']['_and'].push(
            { state_code: { _ilike: `%${filters['state_code']}%` } });
    }
    if (filters['notIds']) {
        result['where']['_and'].push({
            id: {
                _nin: filters['notIds'],
            },
        });
    }
    result['limit'] = filters['limit'] || GlobalListLimit;
    result['offset'] = filters['offset'] || 0;
    return result;
};

const useLocations: (filters?: Filters) => ReturnType = (filters) => {
    const { data, loading, error, refetch: refresh } = useQuery(QUERY, { variables: createFilters(filters) });
    let locations = [];
    let total = 0;
    if (!loading && !error) {
        locations = data.c_meta_locations;
        total = data.c_meta_locations_aggregate.aggregate.count;
    }
    const refetch = (filters?: Filters) => {
        return refresh(createFilters(filters));
    };
    return {
        total,
        locations,
        loading,
        error,
        refetch,
    };
};
export default useLocations;
