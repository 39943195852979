import * as React from 'react';
import { Select } from 'antd';
import useCountries from '../../hooks/Locations/useCountries';
import useProcedureTypesFromDepartment from '../../hooks/ProcedureTypes/useProcedureTypesFromDepartment';
import useDoctorsByDepartment from '../../hooks/Doctors/useDoctorsByDepartment';
import { useEffect, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import useProcedureTypes from '../../hooks/ProcedureTypes/useProcedureTypes';

const { Option } = Select;
export const ProcedureTypesFromDepartment:
    React.FunctionComponent<{ onChange?: any, initialValue?: any,disabled? :boolean, departmentId: string }> =
    ({
         onChange,
        disabled,
         initialValue,
         departmentId,
     }) => {
        const [search, setSearch] = useState('' as string);
        const debouncedSearch = useDebounce(search, 500);
        useEffect(() => {
            refetch({ search, department_id: departmentId });
        }, [debouncedSearch]);
        const { procedureTypes, loading, refetch } = useProcedureTypes({ department_id: departmentId });
        return <Select
            onSearch={
                (v) => {
                    setSearch(v);
                }
            }
            disabled={disabled}
            defaultValue={initialValue}
            optionFilterProp='children'
            filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={loading} showSearch={true} onChange={(e: any) => {
            if (onChange) {
                onChange(e);
            }
        }}>
            {
                procedureTypes.filter((a: any)=>!a.hide_on_select).map((procedureType: any) => {
                    return <Option value={procedureType.id}>{procedureType.name}</Option>;
                })
            }
        </Select>;
    };


export const DoctorsFromDepartment:
    React.FunctionComponent<{ onChange?: any,disabled?:boolean, avoid?: any[], initialValue?: any, departmentId: string }> =
    ({
         onChange,
         initialValue,
        disabled,
         avoid,
         departmentId,
     }) => {
        const [search, setSearch] = useState('' as string);
        const debouncedSearch = useDebounce(search, 500);

        const { doctors, loading, refetch } = useDoctorsByDepartment({
            notIds: avoid || [],
            department_id: departmentId,
        });
        useEffect(() => {
            refetch({
                search, notIds: avoid || [],
                department_id: departmentId,
            });
        }, [debouncedSearch]);
        return <Select
            onSearch={
                (v) => {
                    setSearch(v);
                }
            }
            disabled={disabled}
            style={{ width: '100%' }}
            optionFilterProp='children'
            filterOption={(input: string, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={initialValue}
            loading={loading} showSearch={true} onChange={(e: any) => {
            if (onChange) {
                onChange(e);
            }
        }}>
            {
                doctors.map((doctor: any) => {
                    return <Option value={doctor.id}>{doctor.name}</Option>;
                })
            }
        </Select>;
    };

